import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import Logo_Signature from "../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import BackgroundBatik from "../../assets/signature/background-batik.jpg"
import SignatureCanvas from 'react-signature-canvas'
import { FaAddressBook, FaAppStoreIos, FaBackspace, FaBackward, FaBell, FaCamera, FaCheckCircle, FaEdit, FaEnvelope, FaEnvelopeOpenText, FaGenderless, FaHandHoldingMedical, FaList, FaListAlt, FaMailBulk, FaMobile, FaMonero, FaMoneyBillAlt, FaMoneyBillWave, FaMoneyCheck, FaPhone, FaPhoneAlt, FaReply, FaReplyAll, FaReplyd, FaSearch, FaSignOutAlt, FaSpinner, FaSync, FaTransgender, FaTrash, FaUndo, FaUndoAlt, FaWatchmanMonitoring } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { Alert } from "react-bootstrap";
import { useMutation } from "react-query";
import { API } from "../../config/api";
import React, { useRef } from 'react';
import Dropdown from "../Auth/Dropdown";
import LoaderHome from "../Loader/LoaderHome"
import "../../index.css"
import logo_side from "../../assets/profileUser.png"
import { Link } from 'react-router-dom';
// import List from './Component/Home'
import { setAuthToken } from '../../config/api';
import masgan from "../../assets/signature/signatureNewIcon.png";
import 'chart.js/auto'
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "bulma/css/bulma.css";
import Swal from "sweetalert2";
import { Container} from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaGoogle, FaInstagram,FaLinkedin, FaGithub } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import NavbarPasienOnline from "../../components/Navbar/NavbarPasienOnline/NavbarDekstop"
import NavbarPasienOnlineRemake from "../../components/Navbar/NavbarPasienOnline/NavbarDekstopRemake"
import NavbarTopHeader from "../../components/Navbar/NavbarPasienOnline/NavbarTopHeader"
import NavbarHeader from "../../components/Navbar/NavbarPasienOnline/NavbarHeader"
import NavbarHeaderRemake from "../../components/Navbar/NavbarPasienOnline/NavbarHeaderToogle"
import DropdownBotton from "../Auth/DropdownBotton";
import { Scheduler } from "@aldabil/react-scheduler";
import FooterBottom from "../../components/Footer/Footer"


export default function Login() {

  document.title = "SDC Apps";
  const [state, dispatch] = useContext(UserContext);
  const [message, setMessage] = useState(null);
  const [imageName, setImageName] = useState([])
  const [imageContent, setImageContent] = useState([])
  const [preview, setPreview] = useState(null);
  const token = localStorage.getItem("token");
  const storageItems = JSON.parse(localStorage.getItem('user_data'));

  const nama = localStorage.getItem("username")
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [pagination,setPagination] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(0);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(1);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [search,setSearch] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataMap, setDataMap] = useState("");
  const [dataSearch, setDataSearch] = useState("");
  
  // modal role add 
  const [id, setId] = useState();
  const [nameUpdate, setNameUpdate] = useState();
  const [UrlUpdate, setUrlUpdate] = useState();
  const [IconUpdate, setIconUpdate] = useState();
  const [NumberUpdate, setNumberUpdate] = useState();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)'})
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [modalShow, setModalShow] = useState(false);
  const firstNameInputRef = useRef(null);
  const isTabletOrMobileRemake = useMediaQuery({ query: '(max-width: 800px)' })
  const isTabletOrMobileRemakeSeribu = useMediaQuery({ query: '(max-width: 1200px)'})
  const [scheduleData, setScheduleData] = useState([]);

  console.log(getData)

  let fetchParams = {
    headers : {"Authorization" : `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzZGMtYXBwcy1hcGkiLCJzdWIiOiIzOWFlMjYzMy0yZGUxLTQ3NDAtYWIyNC0wYzc2ZDIxMzYzNDUiLCJlbWFpbCI6ImR3aWtpX3dhbnRhcmFAZ3JhdGlham0uY28uaWQiLCJpYXQiOjE2OTU3OTY1ODd9.9x1HcD3kr90BnL10uDsV98oXAlKXVsYLSu2uumUJl4M`,"Content-Type" : "application/json"}
  };

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/schedules?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }


  useEffect(() => {
    GetResponseData()
  }, []);


  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response =  await API.delete(`user-service/privileges/menus/${id}`,fetchParams);
        if (response.data.error == false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }  
      }
    })
  };

  const changePage = ({ selected }) => {
    setPage(selected+1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };
 
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const buttonRefresh = () => {
    window.location.reload();
  }

  const viewModalAdd = () => {
   navigate("/appoinment-pasien-online")
  }

  const viewModalUpdate = (id, name, url, icon, number_order) => {
    setModalUpdate(true)
    setId(id)
    setNameUpdate(name)
    setUrlUpdate(url)
    setIconUpdate(icon)
    setNumberUpdate(number_order)
  }

  const logout = (e)=>{
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };

  return (
    <div style={{backgroundColor: "#FDFDFD", minHeight: "100vh" }}>
    {loading && <LoaderHome />}
    
    <NavbarTopHeader />
      
    {isTabletOrMobileRemake? 
    <div style={{ position: "fixed", display: "flex", justifyContent: "center", bottom: "0", zIndex: "1", width:"100%" }}>
    <DropdownBotton GetResponseData={GetResponseData} />
    </div>
      :
    <Col xl="12" style={{ padding: "0px 0px", position: "fixed",width:"90px"}}>
      <div style={{display: "flex", justifyContent: "", marginTop: "150px" }}>
        <Dropdown GetResponseData={GetResponseData}/>  
      </div>
    </Col>  
    }
     
    {isTabletOrMobileRemake ? 
      <NavbarHeaderRemake/>
      :
      <NavbarHeader />    
    }  

    {isTabletOrMobileRemake ? 
    <div style={{display: "flex",backgroundColor:"#0097D8", height:"17vh"}}></div>
    :
    <div style={{display: "flex",backgroundColor:"#0097D8",height:"20vh" }}></div>
    }
      
      <div xl='12' sm='12' style={{display:"flex", justifyContent:"center",boxShadow: "1px 1px 1px #ECECEC", backgroundColor: '#0097D8',marginTop:"", padding:"20px 0px",color:"white", fontSize:"15px"}}>    
          BERANDA / SCEDULE DOKTER
      </div>  

        <div style={{padding:"20px 20px 20px 20px"}}>
          <Col xl='12' sm='12' style={{ backgroundColor: "white", boxShadow: "1px 1px 5px #BFBFBF",backgroundColor:"white", padding:isTabletOrMobileRemake? "20px 20px 30px 20px":"20px 150px 30px 150px", borderRadius:"5px", zIndex: 10,overflow: 'auto' }}>
            {getData.length > 0 ? (
            <Scheduler
              view="month"
              editable={false}
              deletable={false}
              events={getData.map(item => ({
              event_id: item.id,
              title:
              <div style={{ display: "flex", justifyContent: "" }}>
              <div style={{marginRight:"10px"}}>
                <img src={logo_side} style={{width:"20px", height:"20px"}} class="rounded-pill"></img>
              </div>  
                {item.user_data.username}
              </div>,
              start: new Date(`${item.ci_date} ${item.ci_time}`),
              end: new Date(`${item.co_date} ${item.co_time}`),
              }))}
              />
              ) : (
                <p>Loading data ...</p>
              )}
          </Col>
        </div>
      <FooterBottom/>
  </div>
  );
}
