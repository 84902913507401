import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Col} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API } from "../../config/api";
import "bulma/css/bulma.css";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import ModalAddMedis from "./ModalAdminTreatment/ModalAdd"
import ModalShowTreatmentOneQty from "./ModalAdminTreatment/ModalShow"
import ModalAddTreatmentPrices from "./ModalAdminTreatmentPrices/ModalAdd"
import ModalUpdateTreatmentPrices from "./ModalAdminTreatmentPrices/ModalUpdate"
import ModalUpdateMedis from "./ModalAdminTreatment/ModalUpdate"
import LoaderHome from "../Loader/LoaderHome"
import * as XLSX from "xlsx";
import HeaderTreatment from "./Components/HeaderTreatment";
import TableTreatment from "./Components/TableTreatment";
import PaginationTreatment from "./Components/PaginationTreatment";

export default function Login() {
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  // Responsive to mobile or dekstop
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)'})

  // modal role add 
  const [id, setId] = useState();
  const [dataIdCategory, setDataIdCategory] = useState([]);
  const [dataName, setDataName] = useState([]);
  const [dataIdRoomCostLab, setDataIdRoomCostLab] = useState([]);
  const [dataCostLab, setDataCostLab] = useState([]);
  const [dataFeePercentage, setDataFeePercentage] = useState([]);
  const [dataCodeIcd9, setDataCodeIcd9] = useState([]);
  const [dataNameIcd9, setDataNameIcd9] = useState([]);
  const [treatmentPriceName, setTreatmentPriceName] = useState();
  const [treatmentPriceMin, setTreatmentPriceMin] = useState();
  const [treatmentPriceMax, setTreatmentPriceMax] = useState();
  const [modalRoleAdd, setModalRoleAdd] = useState(false);
  const [modalTreatmentOneQtyShow, setModalTreatmentOneQtyShow] = useState(false);
  const [modalTreatmentPrice, setModalTreatmentPrices] = useState(false);
  const [modalRoleUpdate, setModalRoleUpdate] = useState(false);
  const [modalIdUpdateTreatmentPrice, setIdUpdateTreatmentPrice] = useState(false);
  const [modalUpdateTreatmentPrice, setModalUpdateTreatmentPrice] = useState(false);

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/transaction-service/treatments?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false)
        }
    } catch (error) {
      setLoading(false)
      if (error.response.status === 401) {
        navigate('/dashboard')
        swal({
          title: 'Failed',
          text: `Akses dibatasi Tidak Dapat Mengakses Halaman Ini`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }else {
        swal({
          title: 'Failed',
          text: `${error.response.data.message}`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }
    }
  }

  useEffect(() => {
    GetResponseData()
  }, [page])
  
  const deleteById = async (id, id_treatment_price) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (id_treatment_price === null || id_treatment_price === "null") {
          const response = await API.delete(`transaction-service/treatments/${id}`, fetchParams);
          if (response.data.error === false) {
            GetResponseData();
            swal({
              title: 'Success',
              text: "Your data has been successfully deleted",
              icon: 'success',
              timer: 3000,
              buttons: false
            });
          }
        } else {
          const response = await API.delete(`transaction-service/treatments/${id}`, fetchParams);
          const response2 = await API.delete(`transaction-service/treatment-prices/${id_treatment_price}`, fetchParams);
  
          if (response.data.error === false && response2.data.error === false) {
            GetResponseData();
            swal({
              title: 'Success',
              text: "Your data has been successfully deleted",
              icon: 'success',
              timer: 3000,
              buttons: false
            });
          }
        }
      }
    });
  };
  
  const changePage = ({ selected }) => {
    setPage(selected+1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };
 
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const buttonRefresh = () => {
    window.location.reload();
  }
  
  const modalMedisRoleAdd = () => {
    setModalRoleAdd(true)
  }

  const modalTreatmentOneQty = () => {
    setModalTreatmentOneQtyShow(true)
  }

  const modalMedisTreatmentPrices = (id, name) => {
    setId(id)
    setTreatmentPriceName(name)
    setModalTreatmentPrices(true)
  }

  const modalMedisRoleUpdate = (id, id_category, name, id_room_cost_lab, cost_lab, fee_percentage, code_icd9, name_icd9) => {
    setModalRoleUpdate(true)
    setId(id)
    setDataIdCategory(id_category)
    setDataName(name)
    setDataIdRoomCostLab(id_room_cost_lab)
    setDataCostLab(cost_lab)
    setDataFeePercentage(fee_percentage)
    setDataCodeIcd9(code_icd9)
    setDataNameIcd9(name_icd9)
  }

  const modalViewUpdateTreatmentPrice = (id_treatment_price, name, treatment_price_min, treatment_price_max) => {
    setModalUpdateTreatmentPrice(true)
    setIdUpdateTreatmentPrice(id_treatment_price)
    setTreatmentPriceName(name)
    setTreatmentPriceMin(treatment_price_min)
    setTreatmentPriceMax(treatment_price_max)
  }

  const [data, setData] = useState("");
  const [dataNameUpload, setDataNameUpload] = useState("");

  const handleFileUpload = (event) => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onload = (e) => {
  const binaryStr = e.target.result;
  const workbook = XLSX.read(binaryStr, { type: 'binary' });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  const jsonData = XLSX.utils.sheet_to_json(worksheet);
  setData(jsonData);
  setDataNameUpload(file.name);
  event.target.value = "";  
  };
  reader.readAsBinaryString(file);
  };
  
  const postDataTreatmentByExcel = async () => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Mengirim ${dataNameUpload}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true)
          const response = await API.post(`/transaction-service/treatments/store/upload`,{ treatments: data },fetchParams);
          if(response.data.error === false) {
            setLoading(false)
            setData("");
            setDataNameUpload("");
            GetResponseData();
            Swal.fire({
              title: 'Success',
              text: "Your data has been successfully",
              icon: 'success',
              timer: 3000,
              showConfirmButton: false
            });
          }
        } catch (error) {
          setLoading(false)
          setData("");
          setDataNameUpload("");
          Swal.fire({
            title: 'Failed',
            text: `${error.response.data.message}`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false
          });
        }
      } 
      if (result.isDismissed) {  
        setData("");
        setDataNameUpload("");
      }
    });
    };
  
    useEffect(() => {
    if (dataNameUpload !== "") {
      postDataTreatmentByExcel();
    }
    },[dataNameUpload]);
 
  return (
  <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>  
    {modalRoleAdd && <ModalAddMedis GetResponseData={GetResponseData} show={modalRoleAdd} onHide={() => setModalRoleAdd(false)} />}
    {modalTreatmentOneQtyShow && <ModalShowTreatmentOneQty GetResponseData={GetResponseData} show={modalTreatmentOneQtyShow} onHide={() => setModalTreatmentOneQtyShow(false)} />}
    {modalTreatmentPrice && <ModalAddTreatmentPrices GetResponseData={GetResponseData} id={id} treatmentPriceName={treatmentPriceName} show={modalTreatmentPrice} onHide={() => setModalTreatmentPrices(false)} />}
    {modalUpdateTreatmentPrice && <ModalUpdateTreatmentPrices GetResponseData={GetResponseData} modalIdUpdateTreatmentPrice={modalIdUpdateTreatmentPrice}  treatmentPriceName={treatmentPriceName} treatmentPriceMin={treatmentPriceMin} treatmentPriceMax={treatmentPriceMax}  show={modalUpdateTreatmentPrice} onHide={() => setModalUpdateTreatmentPrice(false)} />}
    {modalRoleUpdate && <ModalUpdateMedis GetResponseData={GetResponseData} id={id} dataIdCategory={dataIdCategory} dataName={dataName} dataIdRoomCostLab={dataIdRoomCostLab} dataCostLab={dataCostLab} dataFeePercentage={dataFeePercentage} dataCodeIcd9={dataCodeIcd9} dataNameIcd9={dataNameIcd9} show={modalRoleUpdate} onHide={() => setModalRoleUpdate(false)} />}
    {loading && <LoaderHome />}
      
    {/* start header */}
    <HeaderTreatment
        isTabletOrMobile={window.innerWidth <= 768} 
        modalMedisRoleAdd={modalMedisRoleAdd}
        buttonRefresh={buttonRefresh}
        searchData={searchData}
        query={query}
        setQuery={setQuery}
        handleFileUpload={handleFileUpload}
        modalTreatmentOneQty={modalTreatmentOneQty}
      />
    {/* end header */}
 
    <Col xl='12' sm='12'> 
      <div>
        {/* start table */}
        <TableTreatment 
          isTabletOrMobile={isTabletOrMobile}
          getData={getData}
          page={page}
          modalMedisTreatmentPrices={modalMedisTreatmentPrices}
          modalViewUpdateTreatmentPrice={modalViewUpdateTreatmentPrice}
          modalMedisRoleUpdate={modalMedisRoleUpdate}
          deleteById={deleteById}
        />
        {/* end table */}
      </div>
      {/* start pagination */}
      <PaginationTreatment   
        rows={rows} 
        page={page} 
        pages={pages} 
        msg={msg} 
        changePage={changePage} 
      />
      {/* end pagination */}
    </Col>

</div>
  );
}
