/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API } from "../../config/api";
import {FaUserMd, FaAddressBook, FaPlus, FaStreetView, FaSync, FaTable, FaCheckCircle, FaFileInvoice, FaWatchmanMonitoring, FaCamera, FaMedkit, FaPrint, FaNotesMedical, FaUserCog, FaRegStickyNote, FaTelegramPlane} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import "bulma/css/bulma.css";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import ModalAddArragment from "./ModalAdmin/ModalAdd"
import ModalAddInvoiceAdmin from "./ModalAdmin/ModalAddInvoice"
import ModalUploadMedis from "./ModalAdmin/ModalUpload"
import ModalAddPhysicalCheck from "./ModalPhysicalCheck/ModalAdd"
import ModalAddMedicalRecord from "./ModalMedicalRecord/ModalShow"
import ModalAddMedicalRecordObat from "./ModalMedicalRecord/ModalShowMedichine"
import ModalAddAppoinment from "./ModalAppointmentAdd/ModalAdd"
import ModalAcceptWaiting from "./ModalAdmin/ModalUpdate"
import ModalAddInvoice from "./ModalAdmin/ModalInvoice"
import LoaderHome from "../Loader/LoaderHome"
import ModalDataPatient from "./ModalPatientUpdate/ModalUpdate"
import ModalDataSoapPatient from "./ModalMedicalRecord/ModalShowSoapPatient"
import ModalDataSatuSehat from "./ModalMedicalRecord/ModalShowSatuSehat"
import axios from "axios";
import SearchFilter from "./AppointmentFilter/Search";
import SingleDateFilter from "./AppointmentFilter/SingleDate";
import DateRangeFilter from "./AppointmentFilter/RangeDate";
import MonthFilter from "./AppointmentFilter/SingleMonth";
import MonthRangeFilter from "./AppointmentFilter/RangeMonth";

export default function Login() {
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [search] = useState("");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startMonth, setStartMonth] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [loading, setLoading] = useState(false);

  const [isDateFilterVisible, setIsDateFilterVisible] = useState(false);
  const [isSearchFilterVisible, setIsSearchFilterVisible] = useState(false);
  const [isMonthFilterVisible, setIsMonthFilterVisible] = useState(false);
  const [isSingleDateFilterVisible, setSingleDateFilterVisible] = useState(false);
  const [isMonthRangeFilterVisible, setIsMonthRangeFilterVisible] = useState(false);

  // Responsive to mobile or dekstop
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [id, setId] = useState();
  const [idInvoiceAdmin, setIdInvoiceAdmin] = useState();
  const [idInvoiceAdminPatient, setIdInvoiceAdminPatient] = useState();
  const [idInvoiceAdminDoctor, setIdInvoiceAdminDoctor] = useState();
  const [idInvoiceIdAdmin, setIdInvoiceIdAdmin] = useState();
  const [idUpload, setIdUpload] = useState();
  const [idAppoinmentPhysicalCheck, setIdAppointmentPhysicalCheck] = useState();
  const [idPatientPhysicalCheckPatient, setIdPatientPhysicalCheckPatient] = useState();
  const [idAppointmentPhysicalCheckPatient, setIdAppointmentPhysicalCheckPatient] = useState();
  const [idPatientMedicalRecordName, setIdModalMedicalRecordPatientName] = useState();
  const [idPatientMedicalRecordId, setIdModalMedicalRecordPatientId] = useState();
  const [idAppointmentModalSoapPatient, setIdAppointmentModalSoapPatient] = useState();
  const [idPatientModalSoapPatient, setIdPatientModalSoapPatient] = useState();
  const [idDoctorModalSoapPatient, setIdDoctorModalSoapPatient] = useState();
  const [patientNameModalSoapPatient, setPatientNameModalSoapPatient] = useState();
  const [idAppointmentMedicalPrescriptionObat, setIdModalMedicalPrescriptionIdAppointmentObat] = useState();
  const [idUploadInvoice, setIdUploadInvoice] = useState();
  const levelUser = localStorage.getItem('level');
  const rolesData = JSON.parse(localStorage.getItem('roles')) || [];
  const filteredRolesDoctor = rolesData.filter(role => role.name === 'Doctor');
  const filteredRolesAdmin = rolesData.filter(role => role.name === 'Admin');
  const filteredRolesANurse = rolesData.filter(role => role.name === 'Nurse');
  
  // modal role add 
  const [modalInvoiceAdmin, setModalInvoiceAdmin] = useState(false);
  const [modalArragment, setModalArragment] = useState(false);
  const [modalWaiting, setModalWaiting] = useState(false);
  const [modalInvoice, setModalInvoice] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const [modalAddPhysicalCheck, setModalPhysicalCheck] = useState(false);
  const [modalMedicalRecord, setModalMedicalRecord] = useState(false);
  const [modalMedicalRecordObat, setModalMedicalRecordObat] = useState(false);
  const [modalMedicalRecordSoapPatient, setModalMedicalRecordSoapPatient] = useState(false);
  const [doctoData, setDoctorData] = useState();
  const [patientData, setPatientData] = useState();
  const [modalUpdateDataPatient, setModalUpdateDataPatient] = useState(false);
  const [modalAppointmentAdd, setModalAppointmentAdd] = useState(false);
  const [idUpdatePatient , setIdUpdatePatient] = useState(false);
  const [getDataTokenSatuSehat , setGetDataTokenSatuSehat] = useState([]);
  const [getDataPatientById , setGetDataPatientById] = useState(false);

  // Satu Sehat
  const [idAppointmentModalSatuSehat , setIdAppointmentModalSatuSehat] = useState(false);
  const [idPatientModalSatuSehat , setIdPatientModalSatuSehat] = useState(false);
  const [idDoctorModalSatuSehat , setIdDoctorModalSatuSehat] = useState(false);
  const [patientNameModalSatuSehat , setPatientNameModalSatuSehat] = useState(false);
  const [getDataSatuSehat , setDataSatuSehat] = useState(false);
  const [modalSatuSehat, setModalSatuSehat] = useState(false);
  const [dateSatuSehat, setDateModalSatuSehat] = useState(false);
  const [timeSatuSehat, setTimeNameModalSatuSehat] = useState(false);
  const [arrangementSatuSehat, setArrangementModalSatuSehat] = useState(false);
  const [appointmentSSP, setAppointmentSSP] = useState(false);
  
  
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };


  const GenerateTokenSatuSehat = async () => {
    try {
      setLoading(true);
      let fetchParamsTokenSatuSehat = {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      };

      const data = new URLSearchParams();
      data.append('grant_type', 'client_credentials');
      data.append('client_id', `2BbzciMMACJyubP3Sn811m9naXVrrUrxyt0a8BEuav26n0Dr`);
      data.append('client_secret', `gMTLAxITzLV6qJqT8wuAjYFTR3nv0tGdUsTgFBxA54xt89JJmyEGThxjBMFiJYTp`);
  
      const response = await axios.post(`/oauth2/v1/accesstoken?grant_type=client_credentials`, data, fetchParamsTokenSatuSehat);
      // const response = await axios.post(`https://api-satusehat.kemkes.go.id/oauth2/v1/accesstoken?grant_type=client_credentials`, data, fetchParamsTokenSatuSehat);
  
      if (response?.status === 200) {
        setGetDataTokenSatuSehat(response.data);
        setLoading(false);
      }
      
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        showConfirmButton: false
      });
    }
  };


  const GetResponseData = async () => {
    try {
      setLoading(true);
  
      let url = `/transaction-service/appointments?page=${page}&limit=${limit}&ascending=${ascending}&search=${query}`;

      if (startDate) {
        url += `&start_date=${startDate}`; 
      }

      if (endDate) {
        url += `&end_date=${endDate}`;
      }

      if (startMonth) {
        url += `&start_month=${startMonth}`;
      }

      if (endMonth) {
        url += `&end_month=${endMonth}`;
      }
  
      const response = await API.get(url, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data);
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
      }
    } catch (error) {
      if (error.response.status === 401) {
        navigate('/dashboard');
        swal({
          title: 'Failed',
          text: `Akses dibatasi Tidak Dapat Mengakses Halaman Ini`,
          icon: 'error',
          timer: 3000,
          buttons: false,
        });
      } else {
        swal({
          title: 'Failed',
          text: `${error.response.data.message}`,
          icon: 'error',
          timer: 3000,
          buttons: false,
        });
      }
    } finally {
      setLoading(false); // Set loading false after the request
    }
  };
  

  const GetResponseDataPatientById = async (id_patient) => {
    const response = await API.get(`/transaction-service/patients/${id_patient}`,fetchParams)
    // Checking process
    if (response?.status === 200) {
     setGetDataPatientById(response.data.data)
    }
  }

  useEffect(() => {
    GetResponseData()
  }, [page])

  useEffect(() => {
    if(getDataTokenSatuSehat.length === 0){
      GenerateTokenSatuSehat()
    }
  }, [getDataTokenSatuSehat])

  const changePage = ({ selected }) => {
    setPage(selected+1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };
 
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);

  };
  
  const modalViewTreatment = (id, id_doctor) => {
    setId(id)
    setDoctorData(id_doctor)
    setModalArragment(true)
  }
  

  const AcceptWaitingList = async (id) => {
    Swal.fire({
      title: `<div>Klik Accept Untuk Melanjutkan!</div>`,
      text: ``,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Accept'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response = await API.post(`transaction-service/waiting-lists/store`,
          {
            id_appointment: `${id}`,
            date: "",
            time_start: "",
            time_finish: ""
          }
          , fetchParams);
        if (response.data.error === false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Appointment Anda Telah Berhasil Di proses",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }  
      }
    })
  };

  const WaitingNewAppointmentPasien = async (id) => {
    Swal.fire({
      title: `<div>Apakah Kamu Yakin Konfirmasi Appointment Ini?</div>`,
      text: ``,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Acceppt'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response = await API.put(`transaction-service/appointments/update-status/${id}`,
          {
           status: true
          }
          , fetchParams);
        if (response.data.error === false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Appointment Anda Telah Berhasil Di proses",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }  
      }
    })
  };



  const viewModalUpload = (id, invoice_id, patient_id) => {
    setIdUpload(id)
    setIdUploadInvoice(invoice_id)
    setIdAppointmentModalSoapPatient(patient_id)
    setModalUpload(true)
  }
  
  const viewModalPhysicalCheck = (id, id_patient) => {
    setIdAppointmentPhysicalCheck(id)
    setIdPatientPhysicalCheckPatient(id_patient)
    setModalPhysicalCheck(true)
  }

  const viewModalMedicalRecord = (id,id_patient,patient_name) => {
    setIdAppointmentPhysicalCheckPatient(id)
    setIdModalMedicalRecordPatientId(id_patient)
    setIdModalMedicalRecordPatientName(patient_name)
    setModalMedicalRecord(true)
  }

  const viewModalMedicalRecordObat = (id) => {
    setIdModalMedicalPrescriptionIdAppointmentObat(id)
    setModalMedicalRecordObat(true)
  }

  const viewModalSoapPatient = (id, id_patient, id_doctor, patient_name) => {
    setIdAppointmentModalSoapPatient(id)
    setIdPatientModalSoapPatient(id_patient)
    setIdDoctorModalSoapPatient(id_doctor)
    setPatientNameModalSoapPatient(patient_name)
    setModalMedicalRecordSoapPatient(true)
  }

  const modalViewInvoiceAdmin = (id, id_patient, id_doctor, id_admin) => {
    setIdInvoiceAdmin(id)
    setIdInvoiceAdminPatient(id_patient)
    setIdInvoiceAdminDoctor(id_doctor)
    setIdInvoiceIdAdmin(id_admin)
    setModalInvoiceAdmin(true)
  }

  const TreatmentPlanlAdd = (id, id_patient) => {
    navigate("/treatment-plans/" + id + "/" + id_patient)
  }

  const viewAddInvoices = (id) => {
    // window.open(`/invoices-setup/${id}`, '_blank', 'noreferrer');
    window.open(`/medical-invoices/` + id );
  }

  const viewAddInvoicesSetup = (id, invoice_id, payment_id) => {
    // window.open(`/invoices-setup/${id}`, '_blank', 'noreferrer');
    window.open(`/sales-invoices/` + id + "/" + invoice_id + "/" + payment_id);
  }

  const viewAddInvoiceAssurancesSetup = (id, invoice_id, payment_id) => {
    // window.open(`/invoices-setup/${id}`, '_blank', 'noreferrer');
    window.open(`/sales-invoices-assurances/` + id + "/" + invoice_id + "/" + payment_id);
  }

  const buttonRefresh = () => {
    window.location.reload();
  }

  const viewMedicalRecord = (id, id_patient) => {
    // window.open(`/invoices-setup/${id}`, '_blank', 'noreferrer');
    window.open(`/medical-record-all/` + id + "/" + id_patient);
  }
  
  const modalMedisRoleUpdate = (id_patient) => {
    setModalUpdateDataPatient(true)
    setIdUpdatePatient(id_patient)
  }

  const modalMedisAppointmentAdd = () => {
    setModalAppointmentAdd(true)
  }

  const sendToSatuSehat = (id, id_patient, id_doctor, patient_name, getDataTokenSatuSehat, date, time, arrangement_data, appointment_ssp) => {
    setIdAppointmentModalSatuSehat(id)
    setIdPatientModalSatuSehat(id_patient)
    setIdDoctorModalSatuSehat(id_doctor)
    setPatientNameModalSatuSehat(patient_name)
    setDataSatuSehat(getDataTokenSatuSehat)
    setDateModalSatuSehat(date)
    setTimeNameModalSatuSehat(time)
    setArrangementModalSatuSehat(arrangement_data)
    setAppointmentSSP(appointment_ssp)
    setModalSatuSehat(true)
  }
  

  // Start Button Filter
  const handleSearchToggle = () => {
    setIsSearchFilterVisible(!isSearchFilterVisible);
    if (isDateFilterVisible) setIsDateFilterVisible(false); 
    if (isMonthFilterVisible) setIsMonthFilterVisible(false);
    if (isSingleDateFilterVisible) setSingleDateFilterVisible(false);
    if (isMonthRangeFilterVisible) setIsMonthRangeFilterVisible(false); 

    clearInput();
  };

  const handleSingleDateFilterToggle = () => {
    setSingleDateFilterVisible(!isSingleDateFilterVisible);
    if (isSearchFilterVisible) setIsSearchFilterVisible(false); 
    if (isMonthFilterVisible) setIsMonthFilterVisible(false);
    if (isMonthRangeFilterVisible) setIsMonthRangeFilterVisible(false);
    if (isDateFilterVisible) setIsDateFilterVisible(false);

    if (isSingleDateFilterVisible) {
      setStartDate('');
    }
  };

  const handleDateFilterToggle = () => {
    setIsDateFilterVisible(!isDateFilterVisible);
    if (isSearchFilterVisible) setIsSearchFilterVisible(false); 
    if (isMonthFilterVisible) setIsMonthFilterVisible(false);
    if (isSingleDateFilterVisible) setSingleDateFilterVisible(false);
    if (isMonthRangeFilterVisible) setIsMonthRangeFilterVisible(false);

    if (isDateFilterVisible) {
      setStartDate('');
      setEndDate('');
    }
    clearInput();
  };

  const handleMonthFilterToggle = () => {
    setIsMonthFilterVisible(!isMonthFilterVisible);
    
    if (isSearchFilterVisible) setIsSearchFilterVisible(false);
    if (isDateFilterVisible) setIsDateFilterVisible(false);
    if (isSingleDateFilterVisible) setSingleDateFilterVisible(false);
    if (isMonthRangeFilterVisible) setIsMonthRangeFilterVisible(false); 

    if (!isMonthFilterVisible) {
      setStartMonth('');
    }
  };

  const handleMonthRangeFilterToggle = () => {
    setIsMonthRangeFilterVisible(!isMonthRangeFilterVisible);
    if (isSearchFilterVisible) setIsSearchFilterVisible(false); 
    if (isDateFilterVisible) setIsDateFilterVisible(false); 
    if (isSingleDateFilterVisible) setSingleDateFilterVisible(false);
    if (isMonthFilterVisible) setIsMonthFilterVisible(false); 

    if (isMonthRangeFilterVisible) {
      setStartMonth('');
      setEndMonth('');
    }
  };
  // End Button Filter
  

  const clearInput = () => {
    setQuery('');
  };


  return(
    <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
      {modalUpdateDataPatient && <ModalDataPatient GetResponseData={GetResponseData} idUpdatePatient={idUpdatePatient} show={modalUpdateDataPatient} onHide={() => setModalUpdateDataPatient(false)} />}
      {modalInvoiceAdmin && <ModalAddInvoiceAdmin GetResponseData={GetResponseData}  idInvoiceAdmin={idInvoiceAdmin} show={modalInvoiceAdmin} idInvoiceAdminPatient={idInvoiceAdminPatient} idInvoiceAdminDoctor={idInvoiceAdminDoctor} idInvoiceIdAdmin={idInvoiceIdAdmin} onHide={() => setModalInvoiceAdmin(false)} />}
      {modalArragment && <ModalAddArragment GetResponseData={GetResponseData}  id={id} show={modalArragment} doctoData={doctoData} onHide={() => setModalArragment(false)} />}
      {modalWaiting && <ModalAcceptWaiting GetResponseData={GetResponseData} id={id} show={modalWaiting} onHide={() => setModalWaiting(false)} />}
      {modalInvoice && <ModalAddInvoice GetResponseData={GetResponseData}  id={id} show={modalInvoice} patientData={patientData} doctoData={doctoData} onHide={() => setModalInvoice(false)} />}
      {modalUpload && <ModalUploadMedis  GetResponseData={GetResponseData} idUploadInvoice={idUploadInvoice} idUpload={idUpload} show={modalUpload}  onHide={() => setModalUpload(false)} />}
      {modalAddPhysicalCheck  && <ModalAddPhysicalCheck GetResponseData={GetResponseData} idAppoinmentPhysicalCheck={idAppoinmentPhysicalCheck} idPatientPhysicalCheckPatient={idPatientPhysicalCheckPatient} show={modalAddPhysicalCheck} onHide={() => setModalPhysicalCheck(false)} />}
      {modalMedicalRecord  && <ModalAddMedicalRecord GetResponseData={GetResponseData} idAppointmentPhysicalCheckPatient={idAppointmentPhysicalCheckPatient} idPatientMedicalRecordId={idPatientMedicalRecordId} idPatientMedicalRecordName={idPatientMedicalRecordName} show={modalMedicalRecord} onHide={() => setModalMedicalRecord(false)} />}
      {modalMedicalRecordObat  && <ModalAddMedicalRecordObat idAppointmentMedicalPrescriptionObat={idAppointmentMedicalPrescriptionObat} show={modalMedicalRecordObat} onHide={() => setModalMedicalRecordObat(false)} />}
      {modalAppointmentAdd && <ModalAddAppoinment GetResponseData={GetResponseData} idUpdatePatient={idUpdatePatient} show={modalAppointmentAdd}  onHide={() => setModalAppointmentAdd(false)} />}
      {modalMedicalRecordSoapPatient && <ModalDataSoapPatient GetResponseData={GetResponseData} idAppointmentModalSoapPatient={idAppointmentModalSoapPatient} idPatientModalSoapPatient={idPatientModalSoapPatient} idDoctorModalSoapPatient={idDoctorModalSoapPatient} patientNameModalSoapPatient={patientNameModalSoapPatient} show={modalMedicalRecordSoapPatient} onHide={() => setModalMedicalRecordSoapPatient(false)} />}
      {modalSatuSehat && <ModalDataSatuSehat GetResponseData={GetResponseData} idAppointmentModalSatuSehat={idAppointmentModalSatuSehat} idPatientModalSatuSehat={idPatientModalSatuSehat} idDoctorModalSatuSehat={idDoctorModalSatuSehat} patientNameModalSatuSehat={patientNameModalSatuSehat} getDataSatuSehat={getDataSatuSehat} dateSatuSehat={dateSatuSehat} timeSatuSehat={timeSatuSehat} arrangementSatuSehat={arrangementSatuSehat} appointmentSSP={appointmentSSP} show={modalSatuSehat} onHide={() => setModalSatuSehat(false)} />}
      {loading && <LoaderHome />}
      
  
      {isTabletOrMobile ? 
        <div style={{paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "", padding: "0px 0px 10px 0px" }}>
          <Col xl="6" style={{fontSize:"16px",display:"flex", justifyContent:"center", alignItems:"center", padding:"7px", color:"#001F8B", backgroundColor:"#E9EFFF"}}>
              <FaAddressBook style={{marginRight:"5px"}}/>List Appoinment
          </Col>
          <Col className="mt-2" xl="6" style={{ display: "flex", justifyContent:"end", paddingRight:"5px" }}>
            <div onClick={modalMedisAppointmentAdd} style={{display:"flex",alignItems:"center",marginRight:"5px",backgroundColor:"#3D64FF",color:"white",padding:"0px 12px", borderRadius:"3px", cursor:"pointer", fontSize:"12px", cursor:"pointer"}}>
              <FaPlus/>
            </div>
            <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "10px 10px", borderRadius: "2px", cursor: "pointer", border: "1px solid #DEDEDE" }}>
              <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
            </div>
            <form onSubmit={searchData} style={{display:"flex", paddingRight:"0px"}}>
              <div style={{marginRight:"5px",borderRadius:"3px"}}>
                <input 
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="focused"
                  style={{backgroundColor:"#E9E9E9", border:"none",height:"100%"}}
                  type="text"
                  placeholder=""
                />
                </div>
              <button type="submit"  style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius:"2px", fontWeight:"600", fontSize:"12px",alignItems:"center", padding:"0px 10px" }}>
                Search
              </button>
            </form>
          </Col>
        </div>
        :
        <div >
          <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: "10px 0px" }}>
            <div style={{flex:"50%",fontSize:"16px",display:"flex", alignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
              <FaAddressBook style={{marginRight:"5px"}}/>List Appoinment
            </div>
            <div style={{ flex: "50%", display: "flex", justifyContent:"end" }}>
              <div onClick={modalMedisAppointmentAdd} style={{display:"flex",alignItems:"center",marginRight:"5px",backgroundColor:"#3D64FF",color:"white",padding:"0px 10px", borderRadius:"3px", cursor:"pointer", fontSize:"12px", cursor:"pointer"}}>
                <FaPlus/>
              </div>
              <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "0px 10px", border: "1px solid #DEDEDE", borderRadius: "3px", cursor: "pointer" }}>
                <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
              </div>
              <form onSubmit={searchData} style={{display:"flex", paddingRight:"10px"}}>
                <div style={{marginRight:"5px",borderRadius:"3px"}}>
                  <input value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="focused"
                    style={{backgroundColor:"#E9E9E9", border:"none",height:"5vh"}}
                    type="text"
                    placeholder=""
                  />
                </div>
                <button type="submit"  style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius:"3px", fontWeight:"600", fontSize:"12px",alignItems:"center", padding:"0px 10px" }}>
                  Search
                </button>
              </form>
            </div>
          </div>
          {/* Start filter */}
          <div className="container is-flex gap-2 p-1" style={{ justifyContent: "flex-end"}}>
            <div className="has-text-centered is-fullwidth">
              <div className=" is-flex gap-2" >
                {/* Start Filter Search  */}
                  <SearchFilter 
                    isSearchFilterVisible = {isSearchFilterVisible}
                    GetResponseData={GetResponseData}
                    query={query}
                    setQuery={setQuery}
                    isDateFilterVisible={isDateFilterVisible}
                    isMonthFilterVisible={isMonthFilterVisible}
                    isSingleDateFilterVisible={isSingleDateFilterVisible}
                    isMonthRangeFilterVisible={isMonthRangeFilterVisible}
                    handleSearchToggle = {handleSearchToggle} 
                  />
                
                {/* End Filter Search */}

                {/* Start Filter Single Date */}
                <SingleDateFilter
                  startDate={startDate}
                  setStartDate={setStartDate}
                  handleSingleDateFilterToggle={handleSingleDateFilterToggle}
                  GetResponseData={GetResponseData}
                  isSingleDateFilterVisible={isSingleDateFilterVisible}
                  isSearchFilterVisible={isSearchFilterVisible}
                  isDateFilterVisible={isDateFilterVisible}
                  isMonthFilterVisible={isMonthFilterVisible}
                  isMonthRangeFilterVisible={isMonthRangeFilterVisible}
                />
                {/* End Filter Single Date */}

                {/* Start Filter Range Date Date */}
                <DateRangeFilter
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  GetResponseData={GetResponseData}
                  isDateFilterVisible={isDateFilterVisible}
                  isSearchFilterVisible={isSearchFilterVisible}
                  isMonthFilterVisible={isMonthFilterVisible}
                  isSingleDateFilterVisible={isSingleDateFilterVisible}
                  isMonthRangeFilterVisible={isMonthRangeFilterVisible}
                  handleDateFilterToggle={handleDateFilterToggle}
                />
                {/* End Filter Range Date */}

                {/* Start Filter Month */}
                <MonthFilter
                  isMonthFilterVisible={isMonthFilterVisible}
                  GetResponseData={GetResponseData}
                  startMonth={startMonth}
                  setStartMonth={setStartMonth}
                  isSearchFilterVisible={isSearchFilterVisible} 
                  isDateFilterVisible={isDateFilterVisible} 
                  isSingleDateFilterVisible={isSingleDateFilterVisible} 
                  isMonthRangeFilterVisible={isMonthRangeFilterVisible} 
                  handleMonthFilterToggle={handleMonthFilterToggle}
                />
                {/* End Filter Month */}

                {/* Start Filter Range Month */}
                <MonthRangeFilter
                  isMonthRangeFilterVisible={isMonthRangeFilterVisible}
                  GetResponseData={GetResponseData}
                  startMonth={startMonth}
                  setStartMonth={setStartMonth}
                  endMonth={endMonth}
                  setEndMonth={setEndMonth}
                  isSearchFilterVisible={isSearchFilterVisible} 
                  isDateFilterVisible={isDateFilterVisible} 
                  isSingleDateFilterVisible={isSingleDateFilterVisible} 
                  isMonthFilterVisible={isMonthFilterVisible} 
                  handleMonthRangeFilterToggle={handleMonthRangeFilterToggle}
                />
                {/* End Filter Range Month */}
                
              </div>
            </div>
          </div>
        </div >
      }
      
      <Col xl='12' sm='12'> 
        <div>
          <div style={{display:"block", height:"100%", overflowY:"auto",overflowX:"auto"}}>
            <div >
              <table className="table is-bordered">
                <thead>
                  <tr style={{backgroundColor:isTabletOrMobile? "white" : "#E9EFFF"}}>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>No</th>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Anamnesa</th>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Pasien</th>    
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Dokter</th>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Tanggal</th>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Waktu</th>
                    {filteredRolesANurse.length > 0 && levelUser !== "developer" ? "" :
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Action</th>
                    }
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Physical Check</th>
                    {filteredRolesANurse.length > 0 && levelUser !== "developer" ? "" :
                      <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Preview</th>
                    }
                    {filteredRolesANurse.length > 0 && levelUser !== "developer" ? "" :
                      <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Satu Sehat</th>
                    }
                  </tr>
                </thead>
                <tbody>
                      {getData.map((user, index) => (
                        <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center" }}>
                          <td style={{ lineHeight: "2" }}>{page === 1 ? index + 1 : page === 2 ? (index + 1) + 10 : (index + 1) + (page * 10)}</td>
                          <td style={{ lineHeight: "2" }}>{user.anamnesa}</td>
                          <td style={{ lineHeight: "2" }}>{user.patient_name}</td>
                          <td style={{ lineHeight: "2" }}>{user.doctor_name}</td>
                          <td style={{ lineHeight: "2" }}>
                            {user.date.split('-')[2]}&nbsp;
                            {user.date.split('-')[1] === "01" ? "Januari" : "" ||
                            user.date.split('-')[1] === "02" ? "Februari" : "" ||
                            user.date.split('-')[1] === "03" ? "Maret" : "" ||
                            user.date.split('-')[1] === "04" ? "April" : "" ||  
                            user.date.split('-')[1] === "05" ? "Mei" : "" ||
                            user.date.split('-')[1] === "06" ? "Juni" : "" ||
                            user.date.split('-')[1] === "07" ? "Juli" : "" ||
                            user.date.split('-')[1] === "08" ? "Agustus" : "" ||
                            user.date.split('-')[1] === "09" ? "September" : "" ||
                            user.date.split('-')[1] === "10" ? "Oktober" : "" ||
                            user.date.split('-')[1] === "11" ? "November" : "" ||
                            user.date.split('-')[1] === "12" ? "Desember" : ""}&nbsp;
                            {user.date.split('-')[0]}
                          </td>

                          <td style={{ lineHeight: "2" }}>
                            <div style={{  display:"flex", justifyContent:"center" }}>
                              <div style={{ borderRadius:"3px", fontWeight:"bold", backgroundColor:"#FFB450", color:"white", padding:"0px 5px", width:"60px", justifyContent:"center"}}>
                                {user.time.split(':')[0]} : {user.time.split(':')[1]}
                              </div>
                            </div>
                          </td>
                          {/* ////////////////////////// Action \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */}
                          {levelUser === "developer" ?
                            <td style={{ lineHeight: "2" }}>
                            {user?.status === 0 || user?.status === null || user?.status === undefined ?
                            <div style={{display:"flex",justifyContent:"center"}}>
                              <div onClick={() => WaitingNewAppointmentPasien(user?.id)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#F0A800", color:"white", fontWeight:"bold", width:"135px", justifyContent:"center"}}>
                                <div  style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                  <FaWatchmanMonitoring/>
                                </div>
                                <div style={{display:"flex", alignItems:"center"}}>
                                  New Appointment
                                </div>
                              </div>
                              </div>
                              :
                              <>
                              {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false?
                                <div style={{display:"flex",justifyContent:"center"}}>
                                  <div onClick={() => modalViewTreatment(user?.id, user?.id_doctor)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#3D64FF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div  style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaTable/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Arrangement
                                    </div>
                                  </div>
                                </div>
                                :
                                <div style={{display:"flex",justifyContent:"center"}}>
                                  {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false  ? 
                                  <div onClick={() => {AcceptWaitingList(user?.id)}} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#FF6661", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaStreetView/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      waiting list
                                    </div>
                                  </div>
                                      : 
                                  <div>
                                  {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ? 
                                  <div className="button-treatment-plan" onClick={() => TreatmentPlanlAdd(user?.id, user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaUserMd/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Treatment Plan
                                    </div>
                                  </div>
                                    : 
                                  <div>
                                  {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ?
                                    <div>              
                                      <div onClick={() => modalViewInvoiceAdmin(user?.id, user?.id_patient, user?.id_doctor, user?.id_admin)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#A9A9A9", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaFileInvoice />
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          Buat Invoice
                                        </div>
                                      </div>
                                      {/* obat pasien */}
                                      <div className="mt-2" onClick={() => viewModalMedicalRecordObat(user?.id)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#0044FF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaNotesMedical/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          Obat Pasien
                                        </div>
                                      </div>
                                      <div className="mt-2" onClick={() => viewModalSoapPatient(user?.id, user?.id_patient, user?.id_doctor, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#66FFB2", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaRegStickyNote/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          Soap Patient
                                        </div>
                                      </div>
                                    </div>
                                      : 
                                    <div>
                                      {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ? 
                                      <>
                                        <div style={{display:"flex", justifyContent:"center"}}>
                                          <div onClick={() => viewModalUpload(user?.id, user?.invoice_id)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#AB00D5", color:"white", fontWeight:"bold", width:"140px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaCamera/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Upload Pembayaran 
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{display:"flex", justifyContent:"center"}}>
                                          <div className="mt-2" onClick={() => viewModalSoapPatient(user?.id, user?.id_patient, user?.id_doctor, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#66FFB2", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaRegStickyNote/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Soap Patient
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                        : 
                                        <>
                                        <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#96FF86", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                          <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                            <FaCheckCircle />
                                          </div>
                                          <div style={{display:"flex", alignItems:"center"}}>
                                            Lunas
                                          </div>
                                        </div>
                                      
                                          <div className="mt-2" onClick={() => viewModalSoapPatient(user?.id, user?.id_patient, user?.id_doctor, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#66FFB2", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaRegStickyNote/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Soap Patient
                                            </div>
                                          </div>
                                        </>
                                      }
                                    </div>
                                    }
                                  </div>
                                  }
                                </div>
                                  }
                                </div>
                              } 
                            </>
                          }    
                            </td>
                            :
                            ""
                          }
                          
                          {/* {levelUser !== "developer" && filteredRolesDoctor.length > 0 ? */}
                          {/* {filteredRolesDoctor.length > 0? */}
                          {filteredRolesDoctor.length > 0 && levelUser !== "developer"?
                            <td style={{ lineHeight: "2" }}>
                            {user?.status === 0 || user?.status === null || user?.status === undefined ?
                            <div style={{display:"flex",justifyContent:"center"}}>
                              <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#3D64FF", color:"white", fontWeight:"bold"}}>
                                <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                  <FaStreetView/>
                                </div>
                                <div style={{display:"flex", alignItems:"center"}}>
                                  Menunggu
                                </div>
                              </div>
                              </div>
                              :
                              <>
                              {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false?
                                <div style={{display:"flex",justifyContent:"center"}}>
                                  <div  style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#3D64FF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                  <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaStreetView/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Menunggu
                                    </div>
                                  </div>
                                </div>
                                :
                                <div style={{display:"flex",justifyContent:"center"}}>
                                  {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false  ? 
                                  <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#3D64FF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaStreetView/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Menunggu
                                    </div>
                                  </div>
                                      : 
                                  <div>
                                  {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ? 
                                  <div className="button-treatment-plan" onClick={() => TreatmentPlanlAdd(user?.id, user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaUserMd/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Treatment Plan
                                    </div>
                                  </div>
                                    : 
                                  <div>
                                  {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ? 
                                    <>
                                    <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#96FF86", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                      <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaCheckCircle />                            </div>
                                      <div style={{display:"flex", alignItems:"center"}}>
                                        Selesai
                                      </div>
                                    </div>
                                    <div className="mt-2" onClick={() => viewModalMedicalRecordObat(user?.id)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#0044FF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                      <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                        <FaNotesMedical/>
                                      </div>
                                      <div style={{display:"flex", alignItems:"center"}}>
                                        Obat Pasien
                                      </div>
                                      </div>
                                      <div className="mt-2" onClick={() => viewModalSoapPatient(user?.id, user?.id_patient, user?.id_doctor, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#66FFB2", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaRegStickyNote/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          Soap Patient
                                        </div>
                                      </div>
                                    </>
                                      : 
                                      <div>
                                      {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ? 
                                      <>
                                      <div style={{display:"flex", justifyContent:"center"}}>
                                        <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#96FF86", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                          <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaCheckCircle />                              </div>
                                          <div style={{display:"flex", alignItems:"center"}}>
                                          Selesai
                                          </div>
                                        </div>
                                      </div>
                                      <div style={{display:"flex", justifyContent:"center"}}>
                                        <div className="mt-2" onClick={() => viewModalSoapPatient(user?.id, user?.id_patient, user?.id_doctor, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#66FFB2", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                          <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                            <FaRegStickyNote/>
                                          </div>
                                          <div style={{display:"flex", alignItems:"center"}}>
                                            Soap Patient
                                          </div>
                                        </div>
                                      </div>
                                      </>
                                        : 
                                        <>
                                        <div style={{display:"flex", justifyContent:"center"}}>
                                          <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#96FF86", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                            <FaCheckCircle />                              </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                            Selesai
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{display:"flex", justifyContent:"center"}}>
                                          <div className="mt-2" onClick={() => viewModalSoapPatient(user?.id, user?.id_patient, user?.id_doctor, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#66FFB2", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaRegStickyNote/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Soap Patient
                                            </div>
                                          </div>
                                        </div>
                                        </>
                                      }
                                    </div>
                                    }
                                  </div>
                                  }
                                </div>
                                  }
                                </div>
                              } 
                            </>
                          }    
                            </td>
                            :
                            ""
                          }
                                  
                          {filteredRolesAdmin.length > 0 && levelUser !== "developer" ? 
                            <td style={{ lineHeight: "2" }}>
                              {user?.status === 0 || user?.status === null || user?.status === undefined ?
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                  <div onClick={() => WaitingNewAppointmentPasien(user?.id)} style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#F0A800", color: "white", fontWeight: "bold", width:"135px", justifyContent:"center" }}>
                                    <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                                      <FaWatchmanMonitoring />
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      New Appointment  
                                    </div>
                                  </div>
                                </div>
                                :
                                <>
                                  {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false ?
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                      <div onClick={() => modalViewTreatment(user?.id, user?.id_doctor)} style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#3D64FF", color: "white", fontWeight: "bold", width:"120px", justifyContent:"center" }}>
                                        <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                                          <FaTable />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                          Arrangement
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                      {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false ?
                                        <div onClick={() => { AcceptWaitingList(user?.id) }} style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#FF6661", color: "white", fontWeight: "bold", width:"120px", justifyContent:"center" }}>
                                          <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                                            <FaStreetView />
                                          </div>
                                          <div style={{ display: "flex", alignItems: "center" }}>
                                            waiting list
                                          </div>
                                        </div>
                                        :
                                        <div>
                                          {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ?
                                            <div className="button-treatment-plan" style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "", borderRadius: "3px", color: "white", fontWeight: "bold", width:"120px", justifyContent:"center" }}>
                                              <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                                                <FaUserMd />
                                              </div>
                                              <div style={{ display: "flex", alignItems: "center" }}>
                                                Menunggu
                                              </div>
                                            </div>
                                            :
                                            <div>
                                                {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ?
                                                  <>
                                                  <div onClick={() => modalViewInvoiceAdmin(user?.id, user?.id_patient, user?.id_doctor, user?.id_admin)} style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#A9A9A9", color: "white", fontWeight: "bold", width:"120px", justifyContent:"center" }}>
                                                    <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                                                      <FaFileInvoice />
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                      Buat Invoice
                                                    </div>
                                                  </div>
                                                  <div className="mt-2" onClick={() => viewModalMedicalRecordObat(user?.id)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#0044FF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                                      <FaNotesMedical/>
                                                    </div>
                                                    <div style={{display:"flex", alignItems:"center"}}>
                                                      Obat Pasien
                                                    </div>
                                                  </div>
                                                    <div className="mt-2" onClick={() => viewModalSoapPatient(user?.id, user?.id_patient, user?.id_doctor, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#66FFB2", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                                      <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                                        <FaRegStickyNote/>
                                                      </div>
                                                      <div style={{display:"flex", alignItems:"center"}}>
                                                        Soap Patient
                                                      </div>
                                                    </div>
                                                  </>
                                                :
                                                <div>
                                                  {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ?
                                                 <>
                                                 <div style={{display:"flex", justifyContent:"center"}}>
                                                   <div onClick={() => viewModalUpload(user?.id, user?.invoice_id)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#AB00D5", color:"white", fontWeight:"bold", width:"140px", justifyContent:"center"}}>
                                                     <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                                       <FaCamera/>
                                                     </div>
                                                     <div style={{display:"flex", alignItems:"center"}}>
                                                       Upload Pembayaran
                                                     </div>
                                                   </div>
                                                 </div>
                                                 <div style={{display:"flex", justifyContent:"center"}}>
                                                   <div className="mt-2" onClick={() => viewModalSoapPatient(user?.id, user?.id_patient, user?.id_doctor, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#66FFB2", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                                     <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                                       <FaRegStickyNote/>
                                                     </div>
                                                     <div style={{display:"flex", alignItems:"center"}}>
                                                       Soap Patient
                                                     </div>
                                                   </div>
                                                 </div>
                                               </>
                                                    :
                                                     <>
                                                     <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#96FF86", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                                       <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                                         <FaCheckCircle />
                                                       </div>
                                                       <div style={{display:"flex", alignItems:"center"}}>
                                                         Lunas
                                                       </div>
                                                     </div>
                                                   
                                                       <div className="mt-2" onClick={() => viewModalSoapPatient(user?.id, user?.id_patient, user?.id_doctor, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#66FFB2", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                                         <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                                           <FaRegStickyNote/>
                                                         </div>
                                                         <div style={{display:"flex", alignItems:"center"}}>
                                                           Soap Patient
                                                         </div>
                                                       </div>
                                                     </>
                                                  }
                                                </div>
                                              }
                                            </div>
                                          }
                                        </div>
                                      }
                                    </div>
                                  }
                                </>
                              }
                            </td>
                            :
                            ""
                          } 

                          {filteredRolesANurse.length > 0 && levelUser !== "developer" ? 
                            ""
                            :
                            ""
                          } 

                          {/* ////////////////////////// Data Medis \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */}
                          {/* {levelUser === "developer" ? */}
                          {levelUser === "developer" ?
                            <td style={{ lineHeight: "2" }}>
                            {user?.status === 0 || user?.status === null || user?.status === undefined ?
                              <div style={{display:"flex",justifyContent:"center"}}>
                              
                              </div>
                              :
                              <>
                              {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false?
                                <div style={{display:"flex",justifyContent:"center"}}>
                                
                                </div>
                                :
                                <div style={{display:"",justifyContent:""}}>
                                {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false ? 
                                  <>
                                  <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaUserCog/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Biodata
                                    </div>
                                  </div>             
                                  <div className="button-treatment-plan mt-2" onClick={() => {viewModalPhysicalCheck(user?.id, user?.id_patient)}} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaMedkit/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Physical Check 
                                    </div>
                                  </div>
                                </>
                                    : 
                                <div>
                                  {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ? 
                                  <>
                                  <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaUserCog/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Biodata
                                    </div>
                                  </div>             
                                  <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaMedkit/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Physical Check 
                                    </div>
                                  </div>
                                </>
                                    : 
                                  <div>
                                    {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ? 
                                    <>
                                    <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                      <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                        <FaUserCog/>
                                      </div>
                                      <div style={{display:"flex", alignItems:"center"}}>
                                        Biodata
                                      </div>
                                    </div>             
                                    <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                      <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                        <FaMedkit/>
                                      </div>
                                      <div style={{display:"flex", alignItems:"center"}}>
                                        Physical Check 
                                      </div>
                                    </div>
                                  </>
                                      : 
                                      <div>
                                      {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ? 
                                      <>
                                      <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaUserCog/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          Biodata
                                        </div>
                                      </div>             
                                      <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaMedkit/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          Physical Check 
                                        </div>
                                      </div>
                                    </>
                                        : 
                                        <>
                                          <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaUserCog/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Biodata
                                            </div>
                                          </div>             
                                          <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaMedkit/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Physical Check 
                                            </div>
                                          </div>
                                          <div className="mt-2" onClick={() => viewMedicalRecord(user?.id, user?.id_patient )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#FF7D5C", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaFileInvoice/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Medical Record
                                            </div>
                                          </div>
                                        </>
                                      }
                                    </div>
                                    }
                                  </div>
                                  }
                                </div>
                                  }
                                </div>
                              } 
                            </>
                          }    
                            </td>
                            :
                            ""
                          }
                            
                          {/* {filteredRolesDoctor.length > 0? */}
                          {filteredRolesDoctor.length > 0 && levelUser !== "developer"?
                          <td style={{ lineHeight: "2" }}>
                          {user?.status === 0 || user?.status === null || user?.status === undefined ?
                            <div style={{display:"flex",justifyContent:"center"}}>
                            
                            </div>
                            :
                            <>
                            {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false?
                              <div style={{display:"flex",justifyContent:"center"}}>
                              
                              </div>
                              :
                              <div style={{display:"",justifyContent:""}}>
                                {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false  ? 
                                <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#D0C200", color:"white", fontWeight:"bold"}}>
                                
                                </div>
                                    : 
                                <div>
                                {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ? 
                                <>
                                <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                  <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                    <FaUserCog/>
                                  </div>
                                  <div style={{display:"flex", alignItems:"center"}}>
                                    Biodata
                                  </div>
                                </div>             
                                <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                  <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                    <FaMedkit/>
                                  </div>
                                  <div style={{display:"flex", alignItems:"center"}}>
                                    Physical Check 
                                  </div>
                                </div>
                              </>
                                  : 
                                <div>
                                  {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ? 
                                  <>
                                  <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaUserCog/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Biodata
                                    </div>
                                  </div>             
                                  <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaMedkit/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Physical Check 
                                    </div>
                                  </div>
                                </>
                                    : 
                                    <div>
                                    {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ? 
                                     <>
                                     <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                       <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                         <FaUserCog/>
                                       </div>
                                       <div style={{display:"flex", alignItems:"center"}}>
                                         Biodata
                                       </div>
                                     </div>             
                                     <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                       <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                         <FaMedkit/>
                                       </div>
                                       <div style={{display:"flex", alignItems:"center"}}>
                                         Physical Check 
                                       </div>
                                     </div>
                                   </>
                                      : 
                                      <>
                                          <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaUserCog/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Biodata
                                            </div>
                                          </div>             
                                          <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaMedkit/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Physical Check 
                                            </div>
                                          </div>
                                          <div className="mt-2" onClick={() => viewMedicalRecord(user?.id, user?.id_patient )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#FF7D5C", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaFileInvoice/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Medical Record
                                            </div>
                                          </div>
                                        </>
                                    }
                                  </div>
                                  }
                                </div>
                                }
                              </div>
                                }
                              </div>
                            } 
                          </>
                        }    
                          </td>
                          :
                          ""
                          }
                              
                          {filteredRolesAdmin.length > 0 && levelUser !== "developer" ? 
                            <td style={{ lineHeight: "2" }}>
                              {user?.status === 0 || user?.status === null || user?.status === undefined ?
                                <div style={{display:"flex",justifyContent:"center"}}>
                                
                                </div>
                                :
                                <>
                                {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false?
                                  <div style={{display:"flex",justifyContent:"center"}}>
                                  
                                  </div>
                                  :
                                  <div style={{display:"",justifyContent:""}}>
                                    {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false  ? 
                                    <>
                                    <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                      <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                        <FaUserCog/>
                                      </div>
                                      <div style={{display:"flex", alignItems:"center"}}>
                                        Biodata
                                      </div>
                                    </div>             
                                    <div className="button-treatment-plan mt-2" onClick={() => {viewModalPhysicalCheck(user?.id, user?.id_patient)}} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                      <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                        <FaMedkit/>
                                      </div>
                                      <div style={{display:"flex", alignItems:"center"}}>
                                        Physical Check 
                                      </div>
                                    </div>
                                  </>
                                        : 
                                    <div>
                                    {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ? 
                                     <>
                                     <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                       <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                         <FaUserCog/>
                                       </div>
                                       <div style={{display:"flex", alignItems:"center"}}>
                                         Biodata
                                       </div>
                                     </div>             
                                     <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                       <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                         <FaMedkit/>
                                       </div>
                                       <div style={{display:"flex", alignItems:"center"}}>
                                         Physical Check 
                                       </div>
                                     </div>
                                   </>
                                      : 
                                    <div>
                                      {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ? 
                                      <>
                                      <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaUserCog/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          Biodata
                                        </div>
                                      </div>             
                                      <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaMedkit/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          Physical Check 
                                        </div>
                                      </div>
                                    </>
                                        : 
                                        <div>
                                        {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ? 
                                        <>
                                        <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                          <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                            <FaUserCog/>
                                          </div>
                                          <div style={{display:"flex", alignItems:"center"}}>
                                            Biodata
                                          </div>
                                        </div>             
                                        <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                          <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                            <FaMedkit/>
                                          </div>
                                          <div style={{display:"flex", alignItems:"center"}}>
                                            Physical Check 
                                          </div>
                                        </div>
                                      </>
                                          : 
                                          <>
                                          <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaUserCog/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Biodata
                                            </div>
                                          </div>             
                                          <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaMedkit/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Physical Check 
                                            </div>
                                          </div>
                                          <div className="mt-2" onClick={() => viewMedicalRecord(user?.id, user?.id_patient )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#FF7D5C", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaFileInvoice/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Medical Record
                                            </div>
                                          </div>
                                        </>
                                        }
                                      </div>
                                      }
                                    </div>
                                    }
                                  </div>
                                    }
                                  </div>
                                } 
                              </>
                            }    
                            </td>
                              :
                            ""
                          } 

                          {filteredRolesANurse.length > 0 && levelUser !== "developer" ? 
                            <td style={{ lineHeight: "2" }}>
                            {user?.status === 0 || user?.status === null || user?.status === undefined ?
                              <div style={{display:"flex",justifyContent:"center"}}>
                              
                              </div>
                              :
                              <>
                              {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false?
                                <div style={{display:"flex",justifyContent:"center"}}>
                                
                                </div>
                                :
                                <div style={{display:"",justifyContent:""}}>
                                {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false ? 
                                  <>
                                  <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaUserCog/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Biodata
                                    </div>
                                  </div>             
                                  <div className="button-treatment-plan mt-2" onClick={() => {viewModalPhysicalCheck(user?.id, user?.id_patient)}} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaMedkit/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Physical Check 
                                    </div>
                                  </div>
                                </>
                                    : 
                                <div>
                                  {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ? 
                                  <>
                                  <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaUserCog/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Biodata
                                    </div>
                                  </div>             
                                  <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaMedkit/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Physical Check 
                                    </div>
                                  </div>
                                </>
                                    : 
                                  <div>
                                    {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ? 
                                    <>
                                    <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                      <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                        <FaUserCog/>
                                      </div>
                                      <div style={{display:"flex", alignItems:"center"}}>
                                        Biodata
                                      </div>
                                    </div>             
                                    <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                      <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                        <FaMedkit/>
                                      </div>
                                      <div style={{display:"flex", alignItems:"center"}}>
                                        Physical Check 
                                      </div>
                                    </div>
                                  </>
                                      : 
                                      <div>
                                      {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ? 
                                      <>
                                      <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaUserCog/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          Biodata
                                        </div>
                                      </div>             
                                      <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaMedkit/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          Physical Check 
                                        </div>
                                      </div>
                                    </>
                                        : 
                                        <>
                                          <div className="button-treatment-plan" onClick={() => modalMedisRoleUpdate(user?.id_patient)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#E7B6FF", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaUserCog/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Biodata
                                            </div>
                                          </div>             
                                          <div className="button-treatment-plan mt-2" onClick={() => viewModalMedicalRecord(user?.id, user?.id_patient, user?.patient_name)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer",backgroundColor:"#00A537", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaMedkit/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Physical Check 
                                            </div>
                                          </div>
                                          <div className="mt-2" onClick={() => viewMedicalRecord(user?.id, user?.id_patient )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#FF7D5C", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                              <FaFileInvoice/>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              Medical Record
                                            </div>
                                          </div>
                                        </>
                                      }
                                    </div>
                                    }
                                  </div>
                                  }
                                </div>
                                  }
                                </div>
                              } 
                            </>
                          }    
                            </td>
                            :
                            ""
                          }

                          {/* ///////////////////////////// Preview Medical dan Invoice \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */}
                          {/* {levelUser === "developer" ? */}
                          {levelUser === "developer" ?
                            <td style={{ lineHeight: "2" }}>
                          {user?.status === 0 || user?.status === null || user?.status === undefined ?
                          <div style={{display:"flex",justifyContent:"center"}}>
                          
                          
                          </div>
                          :
                          <>
                          {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false?
                            <div style={{display:"flex",justifyContent:"center"}}>
                              
                            </div>
                            :
                            <div style={{display:"flex",justifyContent:"center"}}>
                              {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false  ? 
                              <div>
                              
                              </div>
                                  : 
                              <div>
                              {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ? 
                              <div >
                              
                              </div>
                                : 
                              <div>
                                {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ? 
                                <div onClick={() => viewAddInvoices(user?.id)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaMedkit/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Medical Invoice
                                    </div>
                                  </div>
                                  : 
                                  <div>
                                  {user.salesinvoice_data !== null && user.salesinvoice_data.status_assurance !== null ?
                                  <>
                                    {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ? 
                                      <div  onClick={() => viewAddInvoiceAssurancesSetup(user?.id, user?.invoice_id,user?.payment_id )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaPrint/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                            Asuransi
                                        </div>
                                      </div>
                                      : 
                                      <div onClick={() => viewAddInvoiceAssurancesSetup(user?.id, user?.invoice_id,user?.payment_id )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaPrint/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          Asuransi 
                                        </div>
                                      </div>
                                    }
                                  </>
                                  :user.salesinvoice_data !== null && user.salesinvoice_data.status_assurance === null ?
                                  <>
                                    {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ? 
                                      <div  onClick={() => viewAddInvoicesSetup(user?.id, user?.invoice_id,user?.payment_id )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaPrint/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                            Invoice 
                                        </div>
                                      </div>
                                      : 
                                      <div onClick={() => viewAddInvoicesSetup(user?.id, user?.invoice_id,user?.payment_id )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                          <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaPrint/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                            Invoice 
                                        </div>
                                      </div>
                                      
                                    }
                                  </>
                                  :
                                  <></>}
                                </div>
                                }
                              </div>
                              }
                            </div>
                              }
                            </div>
                          } 
                        </>
                      }    
                            </td>
                              :
                            ""
                          }

                          {/* {filteredRolesDoctor.length > 0? */}
                          {filteredRolesDoctor.length > 0 && levelUser !== "developer" ? 
                            <td style={{ lineHeight: "2" }}>
                             {user?.status === 0 || user?.status === null || user?.status === undefined ?
                            <div style={{display:"flex",justifyContent:"center"}}>
                          
                            
                              </div>
                              :
                              <>
                              {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false?
                                <div style={{display:"flex",justifyContent:"center"}}>
                                
                                </div>
                                :
                                <div style={{display:"flex",justifyContent:"center"}}>
                                  {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false  ? 
                                  <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#3D64FF", color:"white", fontWeight:"bold"}}>
                                  
                                  </div>
                                      : 
                                  <div>
                                  {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ? 
                                  <div >
                                  
                                  </div>
                                    : 
                                  <div>
                                  {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ? 
                                    <div onClick={() => viewAddInvoices(user?.id)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaMedkit/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          Medical Invoice
                                        </div>
                                      </div>
                                      : 
                                      <div>
                                      {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ? 
                                      <div  onClick={() => viewAddInvoicesSetup(user?.id, user?.invoice_id,user?.payment_id )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaPrint/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          Invoice 
                                        </div>
                                      </div>
                                        : 
                                      <div onClick={() => viewAddInvoicesSetup(user?.id, user?.invoice_id,user?.payment_id )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                        <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                          <FaPrint/>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                            Invoice
                                        </div>
                                      </div>
                                      }
                                    </div>
                                    }
                                  </div>
                                  }
                                </div>
                                  }
                                </div>
                              } 
                            </>
                          }    
                            </td>
                            :
                            ""
                          }
                        
                          {filteredRolesAdmin.length > 0 && levelUser !== "developer" ? 
                            <td style={{ lineHeight: "2" }}>
                            {user?.status === 0 || user?.status === null || user?.status === undefined ?
                            <div style={{display:"flex",justifyContent:"center"}}>
                              
                              
                              </div>
                              :
                              <>
                              {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false?
                                <div style={{display:"flex",justifyContent:"center"}}>
                                  
                                </div>
                                :
                                <div style={{display:"flex",justifyContent:"center"}}>
                                  {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false  ? 
                                  <div >
                                  
                                  </div>
                                      : 
                                  <div>
                                  {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ? 
                                  <div >
                                  
                                  </div>
                                    : 
                                  <div>
                                  {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ? 
                                    <div onClick={() => viewAddInvoices(user?.id)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                      <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                        <FaMedkit/>
                                      </div>
                                      <div style={{display:"flex", alignItems:"center"}}>
                                        Medical Invoice
                                      </div>
                                    </div>
                                    : 
                                    <div>
                                      {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ? 
                                        <div  onClick={() => viewAddInvoicesSetup(user?.id, user?.invoice_id,user?.payment_id )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                          <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                            <FaPrint/>
                                          </div>
                                          <div style={{display:"flex", alignItems:"center"}}>
                                            Invoice 
                                          </div>
                                        </div>
                                          : 
                                        <div onClick={() => viewAddInvoicesSetup(user?.id, user?.invoice_id,user?.payment_id )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                          <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                            <FaPrint/>
                                          </div>
                                          <div style={{display:"flex", alignItems:"center"}}>
                                              Invoice
                                          </div>
                                        </div>
                                      }
                                    </div>
                                    }
                                  </div>
                                  }
                                </div>
                                  }
                                </div>
                              } 
                            </>
                          }    
                              </td>
                              :
                          ""
                          } 

                          {filteredRolesANurse.length > 0 && levelUser !== "developer" ? 
                           ""
                            :
                            ""
                          }

                          {/* ///////////////////////////// Preview Asuransi \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */}
                          {/* {levelUser === "developer" ?
                            <td style={{ lineHeight: "2" }}>
                                {user?.status === 0 || user?.status === null || user?.status === undefined ?
                                    <div style={{display:"flex",justifyContent:"center"}}>
                                    
                                    
                                    </div>
                                    :
                                    <>
                                    {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false?
                                      <div style={{display:"flex",justifyContent:"center"}}>
                                        
                                      </div>
                                      :
                                      <div style={{display:"flex",justifyContent:"center"}}>
                                        {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false  ? 
                                        <div >
                                        
                                        </div>
                                        : 
                                        <div>
                                          {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ? 
                                            <div>
                                            
                                            </div>
                                              : 
                                            <div>
                                              {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ? 
                                                <div  style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                                  
                                                </div>
                                                : 
                                                <div>
                                                  {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ? 
                                                    <div  onClick={() => viewAddInvoiceAssurancesSetup(user?.id, user?.invoice_id,user?.payment_id )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                                      <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                                        <FaPrint/>
                                                      </div>
                                                      <div style={{display:"flex", alignItems:"center"}}>
                                                          Asuransi 2
                                                      </div>
                                                    </div>
                                                    : 
                                                    <div onClick={() => viewAddInvoiceAssurancesSetup(user?.id, user?.invoice_id,user?.payment_id )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                                      <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                                        <FaPrint/>
                                                      </div>
                                                      <div style={{display:"flex", alignItems:"center"}}>
                                                        Asuransi 2
                                                      </div>
                                                    </div>
                                                  }
                                                </div>
                                              }
                                            </div>
                                          }
                                        </div>
                                        }
                                      </div>
                                    } 
                                  </>
                                }    
                            </td>
                              :
                            ""
                          } */}

                          {/* {filteredRolesAdmin.length > 0 && levelUser !== "developer" ? 
                          <td style={{ lineHeight: "2" }}>
                            {user?.status === 0 || user?.status === null || user?.status === undefined ?
                            <div style={{display:"flex",justifyContent:"center"}}>
                            
                            
                            </div>
                          :
                          <>
                          {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false?
                            <div style={{display:"flex",justifyContent:"center"}}>
                              
                            </div>
                            :
                            <div style={{display:"flex",justifyContent:"center"}}>
                              {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false  ? 
                              <div >
                              
                              </div>
                                  : 
                              <div>
                              {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ? 
                              <div >
                              
                              </div>
                                : 
                              <div>
                                {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ? 
                                <div  style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    
                                  </div>
                                  : 
                                  <div>
                                  {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ? 
                                  <div  onClick={() => viewAddInvoiceAssurancesSetup(user?.id, user?.invoice_id,user?.payment_id )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaPrint/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        Asuransi  1
                                    </div>
                                  </div>
                                    : 
                                  <div onClick={() => viewAddInvoiceAssurancesSetup(user?.id, user?.invoice_id,user?.payment_id )} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#5ECAFF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                      <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                      <FaPrint/>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                      Asuransi  1
                                    </div>
                                  </div>
                                  }
                                </div>
                                }
                              </div>
                              }
                            </div>
                              }
                            </div>
                          } 
                        </>
                      }    
                            </td>
                              :
                            ""
                          }    */}

                          {/* Satu Sehat  */}
                          {filteredRolesANurse.length > 0 && levelUser !== "developer" ? "" :
                          <td style={{ lineHeight: "2" }}>
                              {user?.status === 0 || user?.status === null || user?.status === undefined ?
                            <div style={{display:"flex",justifyContent:"center"}}>
                             
                              </div>
                              :
                              <>
                              {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false?
                                <div style={{display:"flex",justifyContent:"center"}}>
                               
                                </div>
                                :
                                <div style={{display:"flex",justifyContent:"center"}}>
                                  {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false  ? 
                                  <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#FF6661", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                  
                                  </div>
                                      : 
                                  <div>
                                  {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ? 
                                  <div className="button-treatment-plan" style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                  
                                  </div>
                                    : 
                                  <div>
                                  {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ?   
                                    <div>              
                                    
                                    </div>
                                      : 
                                    <div>
                                      {
                                         user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false  ? (
                                          <> 
                                          </>
                                         ): (
                                          <>
                                           <div className="" onClick={() => sendToSatuSehat(user?.id, user?.id_patient, user?.id_doctor, user?.patient_name, getDataTokenSatuSehat, user?.date, user?.time, user?.arrangement_data, user?.appointment_ssp)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#FFB441", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                              <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                                <FaTelegramPlane/>
                                              </div>
                                              <div style={{display:"flex", alignItems:"center"}}>
                                                Satu Sehat
                                              </div>
                                            </div>
                                          { user.appointment_ssp !== null   ? (
                                              <> 
                                                {
                                                    user.appointment_ssp.status_sistole_ssp === 1 && 
                                                    user.appointment_ssp.status_distole_ssp === 1 && 
                                                    user.appointment_ssp.status_observation_ssp === 1 && 
                                                    user.appointment_ssp.status_diagnosis_ssp === 1 && 
                                                    user.appointment_ssp.status_procedure_ssp === 1  ? (
                                                      <>
                                                        <div className="" style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#C0EBA6", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                                          <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                                            <FaCheckCircle />
                                                          </div>
                                                          <div style={{display:"flex", alignItems:"center"}}>
                                                            Terkirim
                                                          </div>
                                                        </div>
                                                      </> 
                                                    ) : (
                                                      <>
                                                      </>
                                                      // <div className="" onClick={() => sendToSatuSehat(user?.id, user?.id_patient, user?.id_doctor, user?.patient_name, getDataTokenSatuSehat, user?.date, user?.time, user?.arrangement_data, user?.appointment_ssp)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#FFB441", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                                      //   <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                                      //     <FaTelegramPlane/>
                                                      //   </div>
                                                      //   <div style={{display:"flex", alignItems:"center"}}>
                                                      //     Satu Sehat
                                                      //   </div>
                                                      // </div>
                                                    )
                                                  }
                                              </>
                                              ): (
                                                <>
                                                  {/* <div className="" onClick={() => sendToSatuSehat(user?.id, user?.id_patient, user?.id_doctor, user?.patient_name, getDataTokenSatuSehat, user?.date, user?.time, user?.arrangement_data, user?.appointment_ssp)} style={{display:"flex",border:"none", padding:"0px 7px", cursor:"pointer", borderRadius:"3px", backgroundColor:"#FFB441", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                                    <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                                      <FaTelegramPlane/>
                                                    </div>
                                                    <div style={{display:"flex", alignItems:"center"}}>
                                                      Satu Sehat
                                                    </div>
                                                  </div> */}
                                              </>
                                              )
                                            }
                                          </>
                                         )
                                      }

                                    </div>
                                    }
                                  </div>
                                  }
                                </div>
                                  }
                              </div>
                              } 
                            </>
                          }    
                          </td>
                          }
                        
                        {/* {user.medical_invoice_data === true || user.invoice_data === true || user.invoice_paid === true ?
                          <td style={{ lineHeight: "2" }}>
                          <div style={{display:"flex", justifyContent:"center"}}>
                            <div className="button-treatment-plan"onClick={() => viewAddInvoices(user?.id)} style={{backgroundColor:"#944500", display: "flex", border: "none", padding: "0px 8px", cursor: "pointer", borderRadius: "3px", color: "white", fontWeight: "bold" }}>
                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                              <FaMedkit/>
                            </div>
                            <div style={{display:"flex", alignItems:"center"}}>
                              Medical Invoice
                            </div>
                            </div>
                          </div>
                          </td>
                          : 
                        ""
                        } */}
                                
                        {/* <td style={{lineHeight:"2"}}>
                        <div onClick={() => {AddAppoinment(user?.code, user?.id)}} style={{display: "flex",justifyContent:"center", padding: "0px 10px", backgroundColor:"#00C00D", borderRadius:"3px", cursor:"pointer" }}>
                          <div style={{ display: "flex"}}>
                            <FaUserPlus style={{display:"flex", alignItems:"center", height:"100%", fontSize:"11px", marginRight:"3px", color:"white"}}/>  
                          </div>
                          <div style={{ display: "flex"}}>
                            <div style={{ display: "flex", alignItems: "center", height: "100%", fontSize: "11px", marginTop:"1px", color: "white", fontWeight:"bold"}}>
                              Add
                            </div>  
                          </div>
                        </div>  
                        </td> */}

                        {/* <td style={{ textAlign: "center" }}>
                          <button style={{padding: "0px 15px", borderRadius: "5px", border: "none" }}>256</button>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <button style={{ backgroundColor: "orange", color: "white", padding: "0px 7px", borderRadius: "5px", border: "none" }}><FaStethoscope style={{ marginBottom: "1px", marginRight: "4px" }} /></button>
                        </td> */}

                        {/*<td style={{lineHeight:"2"}}>
                            <div style={{display:"flex",justifyContent:"center", alignItems:"center", alignContent:"center"}}>
                                <button onClick={UpdateUser} style={{ fontSize: "13px", color: "white", backgroundColor: "#ff9933", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display:"flex", marginRight:"3px" }}>
                                  <FaEdit/>
                                </button>
                                  
                                <button onClick={() => {deleteById(user?.id)}} style={{ fontSize: "12px", color: "white", backgroundColor: "#B60000", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display:"flex" }}>
                                  <FaTrash/>
                                </button>
                            </div>
                        </td> */}
                        
                      </tr>
                    ))}
                </tbody>
              </table>
              </div>
              </div>
              </div>
              <div style={{ display: "flex", padding:"10px"}}>
                <div>
                  <div style={{fontSize:"12px"}}>
                    Total Rows: {rows} 
                  </div>
                  <div style={{fontSize:"12px"}}>
                    Page: {rows ? page : 0} of {pages}
                  </div>
                  <p className="has-text-centered has-text-danger">{msg}</p>
                </div> 
                  <div style={{flex:"50%", display:"flex", justifyContent:"end"}}>
                    <nav
                      style={{fontSize:"12px"}}
                      className="pagination is-centered"
                      key={rows}
                      role="navigation"
                      aria-label="pagination"
                      >
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={Math.min(10, pages)}
                        onPageChange={changePage}
                        containerClassName={"pagination-list"}
                        pageLinkClassName={"pagination-link"}
                        previousLinkClassName={"pagination-previous"}
                        nextLinkClassName={"pagination-next"}
                        activeLinkClassName={"pagination-link is-current"}
                        disabledLinkClassName={"pagination-link is-disabled"}
                      />
                    </nav>
                  </div>
                </div>    
          </Col>
        </div>
  );
}
