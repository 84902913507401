import React from "react";

const DateRangeFilter = ({ 
  isDateFilterVisible,
  GetResponseData,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isSearchFilterVisible,
  isMonthFilterVisible,
  isSingleDateFilterVisible,
  isMonthRangeFilterVisible,
  handleDateFilterToggle
  }) => {
  return (
    <>
    {/* Start Filter Date */}
      {isDateFilterVisible && (
        <div className="p-3">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              GetResponseData();
            }}
            className="is-flex is-align-items-center"
            style={{ marginTop: '10px' }}
          >
            <div className="field has-addons is-flex is-align-items-center">
              <div className="control mr-2">
                <label className="label is-small">Start Date</label>
                <input
                  className="input is-small"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  placeholder="Start Date"
                />
              </div>
              <div className="control mr-2 mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                <span>-</span>
              </div>
              <div className="control mr-2">
                <label className="label is-small">End Date</label>
                <input
                  className="input is-small"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  placeholder="End Date"
                />
              </div>
              <div className="control mt-4">
                <button
                  type="submit"
                  className="button is-small is-primary"
                  style={{
                    borderRadius: '3px',
                    fontWeight: '600',
                    fontSize: '12px',
                    padding: '0px 10px',
                  }}
                >
                  Filter
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      {/* End Filter Date */}

      {/* Start Filter Date Button */}
      {!isSearchFilterVisible && !isMonthFilterVisible && !isSingleDateFilterVisible && !isMonthRangeFilterVisible && (
        <div className="buttons is-flex is-justify-content-center mt-4">
          <button
            className={`button is-small ${isDateFilterVisible ? 'is-info' : 'is-primary'}`}
            onClick={handleDateFilterToggle}
          >
            {isDateFilterVisible ? 'Close Range Date' : 'Range Date'}
          </button>
        </div>
      )}
      {/* End Filter Date Button */}
    </>
  );
};

export default DateRangeFilter;
