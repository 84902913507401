import React from 'react';
import { FaEdit, FaTrash } from "react-icons/fa";


const TableFloor = ({ getData, page, viewModalUpdate, deleteById, isTabletOrMobile }) => {

  const commonCellStyle = {
    fontFamily: "sans-serif",
    fontSize: "12px",
    textAlign: "center",
    color: "#525252",
    border: "none"
  };

  const commonButtonStyle = {
    fontSize: "13px",
    padding: "5px 5px",
    borderRadius: "3px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    border: "none",
  };

  const editButtonStyle = {
    ...commonButtonStyle,
    backgroundColor: "#ff9933",
    marginRight: "3px",
  };

  const deleteButtonStyle = {
    ...commonButtonStyle,
    backgroundColor: "#B60000",
  };

//   // Hitung Nomor Baris
  const getRowNumber = (index) => {
    return page === 1 ? index + 1 :
           page === 2 ? (index + 1) + 10 :
           (index + 1) + (page * 10);
  }

  return (
    <div style={{ display: "block", height: "100%", overflowY: "auto", overflowX: "auto" }}>
      <div>
        <table className="table is-bordered">
          <thead>
            <tr style={{ backgroundColor: isTabletOrMobile ? "white" : "#E9EFFF" }}>
              <th style={commonCellStyle}>No</th>
              <th style={commonCellStyle}>Lantai</th>
              <th style={commonCellStyle}>Setup</th>
            </tr>
          </thead>
          <tbody>
            {getData.map((user, index) => (
              <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center" }}>
                <td style={{ lineHeight: "2" }}>{getRowNumber(index)}</td>
                <td style={{ lineHeight: "2" }}>{user.name}</td>
                <td style={{ lineHeight: "2" }}>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button 
                      onClick={() => viewModalUpdate(user?.id, user?.name)} 
                      style={editButtonStyle}
                    >
                      <FaEdit />
                    </button>
                    <button 
                      onClick={() => deleteById(user?.id)} 
                      style={deleteButtonStyle}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableFloor;
