import React from "react";

const SearchFilter = ({ 
    isSearchFilterVisible,
    GetResponseData,
    query,
    setQuery,
    isDateFilterVisible,
    isMonthFilterVisible,
    isSingleDateFilterVisible,
    isMonthRangeFilterVisible,
    handleSearchToggle 
  }) => {
  return (
    <>
      {/* Start Filter Search */}
      {isSearchFilterVisible && (
        <div className="p-3">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              GetResponseData();
            }}
            className="is-flex is-align-items-center"
            style={{ marginTop: '10px' }}
          >
            <div className="field has-addons is-flex is-align-items-center">
              <div className="control mr-2">
                <input
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  type="text"
                  className="input is-small"
                  placeholder="Search..."
                  style={{ width: '250px' }}
                />
              </div>
              <div className="control">
                <button
                  type="submit"
                  className="button is-small is-primary"
                  style={{
                    borderRadius: '3px',
                    fontWeight: '600',
                    fontSize: '12px',
                    padding: '0px 10px',
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      {/* End Filter Search */}

      {/* Start Filter Search Button */}
      {!isDateFilterVisible && !isMonthFilterVisible && !isSingleDateFilterVisible && !isMonthRangeFilterVisible && (
        <div className="buttons is-flex is-justify-content-center mt-4">
          <button
            className={`button is-small ${isSearchFilterVisible ? 'is-info' : 'is-primary'}`}
            onClick={handleSearchToggle}
          >
            {isSearchFilterVisible ? 'Close Search' : 'Search'}
          </button>
        </div>
      )}
    </>
  );
};

export default SearchFilter;
