import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API } from "../../config/api";
import { FaAddressBook, FaEdit, FaTrash, FaPlus, FaSync } from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import "bulma/css/bulma.css";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import ModalAddMedis from "./ModalAdminTreatment/ModalAdd"
import ModalUpdateMedis from "./ModalAdminTreatment/ModalUpdate"
import LoaderHome from "../Loader/LoaderHome"

export default function Login() {
  document.title = "SDC Apps";
  const [getData, setGetData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  // Responsive to mobile or dekstop
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)'})
  // modal role add 
  const [id, setId] = useState();
  const [modalRoleAdd, setModalRoleAdd] = useState(false);
  const [modalRoleUpdate, setModalRoleUpdate] = useState(false);

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseData = async () => {
    try {
      setLoading(true)
      const response = await API.get(`transaction-service/treatment-categories?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false)
        }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } 
  }

 useEffect(() => {
    GetResponseData()
  }, [page])

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response =  await API.delete(`transaction-service/treatment-categories/${id}`,fetchParams);
        if (response.data.error === false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }  
      }
    })
  };

  const changePage = ({ selected }) => {
    setPage(selected+1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };
 
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const buttonRefresh = () => {
    window.location.reload();
  }
  
  const modalMedisRoleAdd = () => {
    setModalRoleAdd(true)
  }

  const modalMedisRoleUpdate = (id) => {
    setModalRoleUpdate(true)
    setId(id)
  }
 
  return (
    <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
      
    {modalRoleAdd && <ModalAddMedis show={modalRoleAdd} onHide={() => setModalRoleAdd(false)} />}
    {modalRoleUpdate && <ModalUpdateMedis id={id} show={modalRoleUpdate} onHide={() => setModalRoleUpdate(false)} />}
    {loading && <LoaderHome />}
    {isTabletOrMobile ? 
      <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "", padding: "0px 0px 10px 0px" }}>
        <Col xl="6" style={{fontSize:"16px",display:"flex", justifyContent:"center", alignItems:"center", padding:"7px", color:"#001F8B", backgroundColor:"#E9EFFF"}}>
            <FaAddressBook style={{marginRight:"5px"}}/>List Treatment Category
        </Col>
        <Col className="mt-2" xl="6" style={{ display: "flex", justifyContent:"end", paddingRight:"5px" }}>
          <div onClick={modalMedisRoleAdd} style={{display:"flex",alignItems:"center",marginRight:"5px",backgroundColor:"#3D64FF",color:"white",padding:"0px 12px", borderRadius:"3px", cursor:"pointer", fontSize:"12px", cursor:"pointer"}}>
            <FaPlus/>
          </div>
          <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "10px 10px", borderRadius: "2px", cursor: "pointer", border: "1px solid #DEDEDE" }}>
            <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
          </div>
          <form onSubmit={searchData} style={{display:"flex", paddingRight:"0px"}}>
              <div style={{marginRight:"5px",borderRadius:"3px"}}>
                  <input value={query}
                            onChange={(e) => setQuery(e.target.value)}
                  className="focused"
                  style={{backgroundColor:"#E9E9E9", border:"none",height:"100%"}}
                  type="text"
                  placeholder=""
                />
            </div>
              <button type="submit"  style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius:"2px", fontWeight:"600", fontSize:"12px",alignItems:"center", padding:"0px 10px" }}>
                Search
              </button>
          </form>

        </Col>
      </div>
        :
      <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: "10px 0px" }}>
        <div style={{flex:"50%",fontSize:"16px",display:"flex", alignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
          <FaAddressBook style={{marginRight:"5px"}}/>List Treatment Category
        </div>
        <div style={{ flex: "50%", display: "flex", justifyContent:"end" }}>
          <div onClick={modalMedisRoleAdd} style={{display:"flex",alignItems:"center",marginRight:"5px",backgroundColor:"#3D64FF",color:"white",padding:"0px 10px", borderRadius:"3px", cursor:"pointer", fontSize:"12px", cursor:"pointer"}}>
            <FaPlus/>
          </div>
          <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "0px 10px", border: "1px solid #DEDEDE", borderRadius: "3px", cursor: "pointer" }}>
            <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
          </div>
          <form onSubmit={searchData} style={{display:"flex", paddingRight:"10px"}}>
            <div style={{marginRight:"5px",borderRadius:"3px"}}>
                  <input value={query}
                            onChange={(e) => setQuery(e.target.value)}
                    className="focused"
                style={{backgroundColor:"#E9E9E9", border:"none",height:"5vh"}}
                type="text"
                placeholder=""
              />
            </div>
            <button type="submit"  style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius:"3px", fontWeight:"600", fontSize:"12px",alignItems:"center", padding:"0px 10px" }}>
              Search
            </button>
          </form>
        </div>
      </div>  
    }
 
  <Col xl='12' sm='12'> 
  <div>
    <div style={{display:"block", height:"100%", overflowY:"auto",overflowX:"auto"}}>
      <div >
        <table className="table is-bordered">
          <thead>
          <tr style={{backgroundColor: isTabletOrMobile? "white" : "#E9EFFF"}}>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>No</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Treatment Category</th>
              <th style={{fontFamily:"revert",fontSize:"12px",textAlign:"center",  color:"#525252",border:"none", fontFamily:"sans-serif"}}>Setup</th>
            </tr>
          </thead>
          <tbody>
            {getData.map((user,index) => (
              <tr key={index} style={{fontFamily:"sans-serif", fontSize:"11px", textAlign:"center"}}>
                <td style={{lineHeight:"2"}}>{page === 1 ? index + 1 : page === 2 ? (index + 1) + 10 : (index + 1) + ( page * 10 )}</td>
                  <td style={{ lineHeight: "2" }}>{user.name}</td>
                  <td style={{lineHeight:"2"}}>
                    <div style={{display:"flex",justifyContent:"center", alignItems:"center", alignContent:"center"}}>
                      <button onClick={() => modalMedisRoleUpdate(user?.id)} style={{ fontSize: "13px", color: "white", backgroundColor: "#ff9933", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display:"flex", marginRight:"3px" }}>
                        <FaEdit/>
                      </button>
                      <button onClick={() => {deleteById(user?.id)}} style={{ fontSize: "12px", color: "white", backgroundColor: "#B60000", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display:"flex" }}>
                        <FaTrash/>
                      </button>
                    </div>
                  </td> 
              </tr>
            ))}
          </tbody>
        </table>
           
          </div>
        </div>
      </div>

      <div style={{ display: "flex", padding:"10px"}}>
          <div>
            <div style={{fontSize:"12px"}}>
              Total Rows: {rows} 
            </div>
            <div style={{fontSize:"12px"}}>
              Page: {rows ? page : 0} of {pages}
            </div>
            <p className="has-text-centered has-text-danger">{msg}</p>
          </div> 
            <div style={{flex:"50%", display:"flex", justifyContent:"end"}}>
            <nav
                    style={{fontSize:"12px"}}
                    className="pagination is-centered"
                    key={rows}
                    role="navigation"
                    aria-label="pagination"
                    >
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={Math.min(10, pages)}
                      onPageChange={changePage}
                      containerClassName={"pagination-list"}
                      pageLinkClassName={"pagination-link"}
                      previousLinkClassName={"pagination-previous"}
                      nextLinkClassName={"pagination-next"}
                      activeLinkClassName={"pagination-link is-current"}
                      disabledLinkClassName={"pagination-link is-disabled"}
                    />
                  </nav>
            </div>
          </div> 
  </Col>

</div>
  );
}
