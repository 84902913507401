import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';

const TablePaymentType = ({ getData, page, viewModalUpdate, deleteById, isTabletOrMobile }) => {
  return (
    <div style={{ display: "block", height: "100%", overflowY: "auto", overflowX: "auto" }}>
      <table className="table is-bordered">
        <thead>
          <tr style={{ backgroundColor: isTabletOrMobile ? "white" : "#E9EFFF" }}>
            <th style={{ fontFamily: "sans-serif", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>
              No
            </th>
            <th style={{ fontFamily: "sans-serif", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>
              Jenis Payment
            </th>
            <th style={{ fontFamily: "sans-serif", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>
              Setup
            </th>
          </tr>
        </thead>
        <tbody>
          {getData.map((user, index) => (
            <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center" }}>
              <td style={{ lineHeight: "2" }}>
                {page === 1
                  ? index + 1
                  : page === 2
                  ? (index + 1) + 10
                  : (index + 1) + (page * 10)}
              </td>
              <td style={{ lineHeight: "2" }}>
                {user.name} ({user?.percentage}%)
              </td>
              <td style={{ lineHeight: "2" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <button
                    onClick={() => viewModalUpdate(user?.id, user?.name, user?.percentage)}
                    style={{
                      fontSize: "13px",
                      color: "white",
                      backgroundColor: "#ff9933",
                      padding: "5px 5px",
                      borderRadius: "3px",
                      cursor: "pointer",
                      border: "none",
                      display: "flex",
                      marginRight: "3px"
                    }}
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => deleteById(user?.id)}
                    style={{
                      fontSize: "12px",
                      color: "white",
                      backgroundColor: "#B60000",
                      padding: "5px 5px",
                      borderRadius: "3px",
                      cursor: "pointer",
                      border: "none",
                      display: "flex"
                    }}
                  >
                    <FaTrash />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TablePaymentType;
