import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
// import "bulma/css/bulma.css";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import ModalAddMedis from "./ModalAdmin/ModalAdd"
import ModalUpdateMedis from "./ModalAdmin/ModalUpdate"
import ModalTrashMedis from "./ModalAdmin/ModalTrash"

import LoaderHome from "../Loader/LoaderHome"

export default function Login() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const [nameDoctor, setNameDoctor] = useState("");
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [search,setSearch] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataMap, setDataMap] = useState("");
  const [dataSearch, setDataSearch] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [loading, setLoading] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

  // Responsive to mobile or dekstop
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  // modal role add 
  const [idAdd, setIdAdd] = useState();
  const [idUpdate, setIdUpdate] = useState();
  const [idTrash, setIdTrash] = useState();

  const [modalRoleAdd, setModalRoleAdd] = useState(false);
  const [modalRoleUpdate, setModalRoleUpdate] = useState(false);
  const [modalRoleTrash, setModalRoleTrash] = useState(false);

  const { id, slug_name } = useParams()

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/privileges/module-menus?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&search=${search}&id_module=${id}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    GetResponseData()
  }, [page])

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response =  await API.delete(`user-service/privileges/module-menus/${id}`,fetchParams);
        if (response.data.error == false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }  
      }
    })
  };

  const changePage = ({ selected }) => {
    setPage(selected+1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };
 
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const buttonRefresh = () => {
    window.location.reload();
  }
  
  const modalMedisRoleAdd = () => {
    setModalRoleAdd(true)
    setIdAdd(id)
  }

  const modalMedisRoleTrash = () => {
    setModalRoleTrash(true)
    setIdTrash(id)
  }

  const navigateSubModules = (id) => {
    navigate("/privileges/roles-permission/" + id)
  }

  const modalMedisRoleUpdate = (id) => {
    setModalRoleUpdate(true)
    setIdUpdate(id)
  }

  const navigateHome = () => {
    navigate('/privileges/modules');
  }

  return (
    <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
      
      {modalRoleAdd && <ModalAddMedis GetResponseData={GetResponseData} idAdd={idAdd} show={modalRoleAdd} onHide={() => setModalRoleAdd(false)} />}
      {modalRoleUpdate && <ModalUpdateMedis GetResponseData={GetResponseData} idUpdate={idUpdate} show={modalRoleUpdate} onHide={() => setModalRoleUpdate(false)} />}
      {modalRoleTrash && <ModalTrashMedis GetResponseData={GetResponseData} idTrash={idTrash} show={modalRoleTrash} onHide={() => setModalRoleTrash(false)} />}
      {loading && <LoaderHome />}

      <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: "10px 0px" }}>
      <div style={{flex:"40%",fontSize:"16px",display:"flex", aunlocklignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
        <div onClick={navigateHome} style={{display:"flex" , marginRight:"10px",fontSize: "11px", color: "white", backgroundColor: "#ABC1FF", padding: "5px 10px", borderRadius: "3px", cursor: "pointer", border: "none", borderTopLeftRadius:"30px", borderBottomLeftRadius:"30px"}}>
        <div style={{ marginRight: "3px", display:"flex" }}>
          <div style={{display:"flex",alignItems:"center", height:"100%"}}>
          <FaRegArrowAltCircleLeft style={{display:"flex",alignItems:"center", fontSize:"15px"}}/>
          </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{display:"flex", alignItems:"center", height:"100%", fontSize:"12px"}}>
              Kembali
            </div>
          </div>
        </div>
          Sub Modules <div style={{color:"black", marginLeft:"5px", fontFamily:"revert", textDecoration:"underline"}}>{getData.length !== 0 ? slug_name : "Kosong" }</div>
      </div>
        <div style={{ flex: "60%", display: "flex", justifyContent: "end" }}>
      {/* <div onClick={AddUser} style={{display:"flex",alignItems:"center",marginRight:"15px",backgroundColor:"#3D64FF",color:"white",padding:"0px 10px", boxShadow:"1px 1px 4px #8B8B8B", borderRadius:"3px", cursor:"pointer", fontSize:"12px", cursor:"pointer"}}>
        Tambah Pasien
      </div> */}
      {/* <div onClick={AddAppoinment} style={{display:"flex",alignItems:"center",marginRight:"15px",backgroundColor:"#FFCC3F",color:"white",padding:"0px 10px", boxShadow:"1px 1px 4px #8B8B8B", borderRadius:"3px", cursor:"pointer", fontSize:"12px",cursor:"pointer"}}>
        Treatment Pasien
      </div> */}
      <div onClick={modalMedisRoleAdd} style={{ display: "flex", alignItems: "center", marginRight: "10px", backgroundColor: "#3D64FF", color: "white", padding: "0px 10px", boxShadow: "1px 1px 4px #8B8B8B", borderRadius: "3px", cursor: "pointer", fontSize: "12px", cursor: "pointer" }}>
        Tambah Modules
      </div>
      <div onClick={modalMedisRoleTrash} style={{ display: "flex", alignItems: "center", marginRight: "10px", backgroundColor: "#B60000", color: "white", padding: "0px 10px", boxShadow: "1px 1px 4px #8B8B8B", borderRadius: "3px", cursor: "pointer", fontSize: "12px", cursor: "pointer" }}>
        <FaTrash/>
      </div>
      <div onClick={buttonRefresh} style={{height:"100%", marginRight:"5px", paddingTop:"0px",backgroundColor:"white",padding:"0px 10px", boxShadow:"1px 1px 4px #8B8B8B", borderRadius:"3px", cursor:"pointer"}}>
        <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
      </div>
      <form style={{display:"flex", paddingRight:"10px"}}>
          <div style={{marginRight:"5px",borderRadius:"3px"}}>
            <input
              style={{backgroundColor:"#E9E9E9", border:"none"}}
              type="text"
              placeholder=""
            />
          </div>
          <button type="submit"  style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius:"3px", fontWeight:"600", fontSize:"12px",alignItems:"center", padding:"0px 10px" }}>
            {/* <div style={{height:"100%", marginRight:"5px", paddingTop:"0px"}}>
              <FaSearch style={{ fontSize: "10px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%" }} />
            </div> */}
            Search
          </button>
        </form>
      </div>
      </div>
      
      
 
  <Col xl='12' sm='12'> 
  <div>
    <div style={{display:"block", height:"100%", overflowY:"auto",overflowX:"auto"}}>
      <div >
        <table className="table is-bordered">
          <thead>
            <tr style={{backgroundColor:"#E9EFFF"}}>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>No</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Nama Sub Modules</th>  
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Url</th>  
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Number Order</th>      
              {/* <th style={{fontFamily:"revert",fontSize:"12px",textAlign:"center",  color:"#525252",border:"none", fontFamily:"sans-serif"}}>Setup</th> */}
            </tr>
          </thead>
          <tbody>
              {getData.map((user,index) => (
                <tr key={index} style={{fontFamily:"sans-serif", fontSize:"11px", textAlign:"center"}}>
                  <td style={{lineHeight:"2"}}>{page === 1 ? index + 1 : page === 2 ? (index + 1) + 10 : (index + 1) + ( page * 10 )}</td>
                  <td style={{ lineHeight: "2" }}>
                    {user.menu_data.name}<i className={user.menu_data.icon} style={{ color: "#3D64FF", fontSize: "15px", marginLeft: "10px" }} />
                  </td>
                  <td style={{ lineHeight: "2" }}>
                    {user.menu_data.url}
                  </td>
                  <td style={{ lineHeight: "2", display: "", justifyContent: "" }}>
                    <div style={{display:"flex", justifyContent:"center"}}>
                      <div style={{display: "flex",justifyContent:"center", padding: "0px 10px", backgroundColor:"white", borderRadius:"3px", cursor:"", border:"1px solid #00C670" }}>
                        <div style={{ display: "flex"}}>
                          <div style={{ display: "flex", alignItems: "center", fontSize: "11px", marginTop:"1px", color: "", fontWeight:"bold"}}>
                            {user?.menu_data.number_order}
                          </div>  
                        </div>
                      </div>  
                    </div>  
                  </td>
                 
                    {/* <td style={{lineHeight:"2"}}>
                        <div style={{display:"flex",justifyContent:"center", alignItems:"center", alignContent:"center"}}>
                      <button onClick={() => modalMedisRoleUpdate(user?.id)} style={{ fontSize: "13px", color: "white", backgroundColor: "#ff9933", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display:"flex", marginRight:"3px" }}>
                            <FaEdit/>
                          </button>
                          <button onClick={() => {deleteById(user?.id)}} style={{ fontSize: "12px", color: "white", backgroundColor: "#B60000", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display:"flex" }}>
                            <FaTrash/>
                          </button> 
                        </div>
                    </td>  */}
                </tr>
              ))}
          </tbody>
        </table>
      
          </div>
        </div>
      </div>
      <div style={{ display: "flex", padding:"10px"}}>
          <div>
            <div style={{fontSize:"12px"}}>
              Total Rows: {rows} 
            </div>
            <div style={{fontSize:"12px"}}>
              Page: {rows ? page : 0} of {pages}
            </div>
            <p className="has-text-centered has-text-danger">{msg}</p>
          </div> 
            <div style={{flex:"50%", display:"flex", justifyContent:"end"}}>
            <nav
                    style={{fontSize:"12px"}}
                    className="pagination is-centered"
                    key={rows}
                    role="navigation"
                    aria-label="pagination"
                    >
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={Math.min(10, pages)}
                      onPageChange={changePage}
                      containerClassName={"pagination-list"}
                      pageLinkClassName={"pagination-link"}
                      previousLinkClassName={"pagination-previous"}
                      nextLinkClassName={"pagination-next"}
                      activeLinkClassName={"pagination-link is-current"}
                      disabledLinkClassName={"pagination-link is-disabled"}
                    />
                  </nav>
            </div>
          </div>   
 
  </Col>

</div>
  );
}
