import React from "react";

const MonthRangeFilter = ({ 
    isMonthRangeFilterVisible, 
    GetResponseData, 
    startMonth,
    setStartMonth,
    endMonth,
    setEndMonth,
    isSearchFilterVisible,
    isDateFilterVisible,
    isSingleDateFilterVisible,
    isMonthFilterVisible,
    handleMonthRangeFilterToggle
}) => {
  return (
    <>
        {/* Start Filter Range Month */}
        {isMonthRangeFilterVisible && (
            <div className="p-3">
            <form
                onSubmit={(e) => {
                e.preventDefault();
                GetResponseData();
                }}
                className="is-flex is-align-items-center"
                style={{ marginTop: '10px' }}
            >
                <div className="field has-addons is-flex is-align-items-center">
                <div className="control mr-2">
                    <div className="select is-small">
                    <select onChange={(e) => setStartMonth(e.target.value)} value={startMonth}>
                        <option value="">Select Start Month</option>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                    </div>
                </div>
                <div className="control mr-2">
                    <div className="select is-small">
                    <select onChange={(e) => setEndMonth(e.target.value)} value={endMonth}>
                        <option value="">Select End Month</option>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                    </div>
                </div>

                <div className="control">
                    <button
                    type="submit"
                    className="button is-small is-primary"
                    style={{
                        borderRadius: '3px',
                        fontWeight: '600',
                        fontSize: '12px',
                        padding: '0px 10px',
                    }}
                    >
                    Filter
                    </button>
                </div>
                </div>
            </form>
            </div>
        )}
        {/* End Filter Range Month */}

        {/* Start Filter Range Month Button */}
        {!isSearchFilterVisible && !isDateFilterVisible && !isSingleDateFilterVisible && !isMonthFilterVisible && (
            <div className="buttons is-flex is-justify-content-center">
            <button
                className={`button is-small ${isMonthRangeFilterVisible ? 'is-info' : 'is-primary'}`}
                onClick={handleMonthRangeFilterToggle}
            >
                {isMonthRangeFilterVisible ? 'Close Month Range' : 'Month Range'}
            </button>
            </div>
        )}
        {/* End Filter Range Month Button */}
    </>
  );
};

export default MonthRangeFilter;
