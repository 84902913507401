import React from 'react';
import { FaAddressBook, FaPlus, FaSync, FaFileExcel, FaNotEqual } from 'react-icons/fa';

const Button = ({ onClick, backgroundColor, children, style }) => {
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '5px',
        backgroundColor: backgroundColor || '#3D64FF',
        color: 'white',
        padding: '0px 10px',
        borderRadius: '3px',
        cursor: 'pointer',
        fontSize: '12px',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

const HeaderTreatment = ({
  isTabletOrMobile,
  modalMedisRoleAdd,
  buttonRefresh,
  searchData,
  query,
  setQuery,
  handleFileUpload,
  modalTreatmentOneQty,
}) => {
  return isTabletOrMobile ? (
    <div
      style={{
        paddingLeft: '0px',
        width: '100%',
        borderBottom: '5px solid #EEEEEE',
        padding: '0px 0px 10px 0px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            fontSize: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '7px',
            color: '#001F8B',
            backgroundColor: '#E9EFFF',
          }}
        >
          <FaAddressBook style={{ marginRight: '5px' }} />
          List Treatment
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '5px' }}>
          <Button onClick={modalMedisRoleAdd} backgroundColor="#3D64FF">
            <FaPlus />
          </Button>
          <Button onClick={buttonRefresh} backgroundColor="white">
            <FaSync style={{ fontSize: '15px', color: '#3D64FF' }} />
          </Button>
          <form onSubmit={searchData} style={{ display: 'flex', paddingRight: '0px' }}>
            <div style={{ marginRight: '5px', borderRadius: '3px' }}>
              <input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="focused"
                style={{ backgroundColor: '#E9E9E9', border: 'none', height: '100%' }}
                type="text"
                placeholder=""
              />
            </div>
            <button
              type="submit"
              style={{
                border: 'none',
                backgroundColor: '#3D64FF',
                color: 'white',
                display: 'flex',
                borderRadius: '2px',
                fontWeight: '600',
                fontSize: '12px',
                alignItems: 'center',
                padding: '0px 10px',
              }}
            >
              Search
            </button>
          </form>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        paddingLeft: '0px',
        width: '100%',
        borderBottom: '5px solid #EEEEEE',
        display: 'flex',
        padding: '10px 0px',
      }}
    >
      <div
        style={{
          flex: '50%',
          fontSize: '16px',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '10px',
          color: '#001F8B',
        }}
      >
        <FaAddressBook style={{ marginRight: '5px' }} />
        List Treatment
      </div>
      <div style={{ flex: '50%', display: 'flex', justifyContent: 'end' }}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {/* Button Upload */}
          <Button backgroundColor="#00CC66">
            <label htmlFor="file-upload" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: '0px 10px' }}>
              <FaFileExcel style={{ cursor: 'pointer' }} />
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
            />
          </Button>

          {/* Button Modal Treatment One */}
          <Button onClick={modalTreatmentOneQty} backgroundColor="#FFA937">
            <FaNotEqual />
          </Button>

          {/* Button Modal Add Medis Role */}
          <Button onClick={modalMedisRoleAdd} backgroundColor="#3D64FF">
            <FaPlus />
          </Button>

          {/* Button Refresh */}
          <Button onClick={buttonRefresh} backgroundColor="white">
            <FaSync style={{ fontSize: '15px', color: '#3D64FF' }} />
          </Button>
        </div>

        {/* Search Form */}
        <form onSubmit={searchData} style={{ display: 'flex', paddingRight: '10px' }}>
          <div style={{ marginRight: '5px', borderRadius: '3px' }}>
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="focused"
              style={{ backgroundColor: '#E9E9E9', border: 'none', height: '5vh' }}
              type="text"
              placeholder=""
            />
          </div>
          <button
            type="submit"
            style={{
              border: 'none',
              backgroundColor: '#3D64FF',
              color: 'white',
              display: 'flex',
              borderRadius: '3px',
              fontWeight: '600',
              fontSize: '12px',
              alignItems: 'center',
              padding: '0px 10px',
            }}
          >
            Search
          </button>
        </form>
      </div>
    </div>
  );
};

export default HeaderTreatment;
