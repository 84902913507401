import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
// import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import { Card, CardBody } from 'reactstrap'
import { Fragment } from 'react';
  
export default function ModalRoleUpdate(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getDataDokter, setGetDataDokter] = useState([]);
  const [propsData, setProopsData] = useState()
  const [title, setTitle] = useState([])
  const [loading, setLoading] = useState(false);
  const [getDataRoom, setGetDataRoom] = useState([]);

  // console.log(props)
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [form, setForm] = useState({
    create:false,
    read:false,
    update:false,
    delete:false
  });

  const handleChange = async (e) => {
    setForm({
      ...form,
      [e.target.name] : e.target.type === "radio" ? e.target.value : e.target.checked,
    });
  };

  const UpdateMenu = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const response = await API.put(`user-service/privileges/permissions/${props.idModalUpdate}`,
    {
      create: form.create,
      read: form.read,
      update: form.update,
      delete: form.delete
    },
    fetchParams)
    
    if (response.data.error == false) {
      swal({
        title: 'Success',
        text: response.data.message,
        icon: 'success',
        timer: 3000,
        buttons: false
      });
      props.GetResponseData()
      props.onHide()
      setLoading(false)
    }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }
  

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif",border:"none"}}>
      <div style={{width: "100%",display:"flex",padding:"10px 0px", backgroundColor:"#29B8FF"}}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Update User Permission
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none" }}>
      <Card style={{ boxShadow:"2px 2px 10px #BFBFBF"}}>
    <CardBody>
      <div>
        <Fragment>
          <form onSubmit={(e) =>UpdateMenu}>
          <Row style={{paddingLeft:"2vh",paddingRight:"2vh"}}>
                
            <Col xl='12' sm='12'>
             <div>
                <p style={{marginBottom:"30px",fontFamily:"revert", fontSize:"20px"}}/>
                <div style={{display: "grid", gridTemplateColumns:"repeat(4,2fr)"}}>
                    <>
                      {form.create?
                      <label className='label-custom' style={{marginTop:"10px", backgroundColor:"#4d5b9e", color:"white"}}>
                        <input
                          type="checkbox" 
                          name="create"
                      
                          onChange={handleChange}
                          className="check-btn"
                        />{" "}
                        <span className="text-inner">Create</span>
                      </label>
                      :
                      <label className='label-custom' style={{marginTop:"10px", backgroundColor:"", color:"black"}}>
                        <input
                          type="checkbox" 
                          name="create"
                       
                          onChange={handleChange}
                          className="check-btn"
                        />{" "}
                        <span className="text-inner">Create</span>
                      </label>
                      }
                    </>

                    <>
                      {form.read?
                      <label className='label-custom' style={{marginTop:"10px", backgroundColor:"#4d5b9e", color:"white"}}>
                        <input
                          type="checkbox" 
                          name="read"
                       
                          onChange={handleChange}
                          className="check-btn"
                        />{" "}
                        <span className="text-inner">Read</span>
                      </label>
                      :
                      <label className='label-custom' style={{marginTop:"10px", backgroundColor:"", color:"black"}}>
                        <input
                          type="checkbox" 
                          name="read"
                        
                          onChange={handleChange}
                          className="check-btn"
                        />{" "}
                        <span className="text-inner">Read</span>
                      </label>
                      }
                    </>

                    <>
                      {form.update?
                      <label className='label-custom' style={{marginTop:"10px", backgroundColor:"#4d5b9e", color:"white"}}>
                        <input
                          type="checkbox" 
                                  name="update"
                                 
                          onChange={handleChange}
                          className="check-btn"
                        />{" "}
                        <span className="text-inner">Update</span>
                      </label>
                      :
                      <label className='label-custom' style={{marginTop:"10px", backgroundColor:"", color:"black"}}>
                        <input
                          type="checkbox" 
                                  name="update"
                                
                          onChange={handleChange}
                          className="check-btn"
                        />{" "}
                        <span className="text-inner">Update</span>
                      </label>
                      }
                    </>

                    <>
                      {form.delete?
                      <label className='label-custom' style={{marginTop:"10px", backgroundColor:"#4d5b9e", color:"white"}}>
                        <input
                          type="checkbox" 
                                  name="delete"
                                  value={form?.delete}
                          onChange={handleChange}
                          className="check-btn"
                        />{" "}
                        <span className="text-inner">Delete</span>
                      </label>
                      :
                      <label className='label-custom' style={{marginTop:"10px", backgroundColor:"", color:"black"}}>
                        <input
                          type="checkbox" 
                                  name="delete"
                                  value={form?.delete}
                          onChange={handleChange}
                          className="check-btn"
                        />{" "}
                        <span className="text-inner">Delete</span>
                      </label>
                      }
                    </>
                </div> 
             </div>
            </Col>

            <Col xl='12' className='mt-5'>
              <div onClick={UpdateMenu} className='tombol_save' style={{display:"flex",justifyContent:"center", backgroundColor:"white", color:"white"}}>
                <button type='submit' style={{marginRight:"5px", fontFamily:"sans-serif",border:"none", backgroundColor:"#4040FF",color:"white",borderRadius:"5px",cursor:"pointer", width:"200px", height:"35px"}}><FaSave style={{marginRight:"5px"}}/>save</button>
              </div>
            </Col>

          </Row>
          </form>
        </Fragment>
      </div>
    </CardBody>
  </Card>
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
    </Modal>
    );
}
