import React from "react";
import { FaMedkit, FaTrash, FaRegStickyNote, FaDeviantart } from "react-icons/fa";

const TablePatient = ({
  getData,
  page,
  modalPhysicalCheck,
  modalMedicalRecord,
  soapPatient,
  odontogramPatient,
  deleteById,
  isTabletOrMobile
}) => {
  return (
    <div style={{ display: "block", height: "100%", overflowY: "auto", overflowX: "auto" }}>
      <div>
        <table className="table is-bordered">
          <thead>
            <tr style={{ backgroundColor: isTabletOrMobile ? "white" : "#E9EFFF" }}>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>No</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Nama Lengkap</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Jenis Kelamin</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Tanggal Lahir</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Usia</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Alamat Domisili</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Nomor Handphone</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Data Medis</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Setup</th>
            </tr>
          </thead>
          <tbody>
            {getData.map((user, index) => (
              <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center" }}>
                <td style={{ lineHeight: "2" }}>
                  {page === 1 ? index + 1 : page === 2 ? index + 1 + 10 : index + 1 + page * 10}
                </td>
                <td style={{ lineHeight: "2" }}>{user.first_name}&nbsp;{user.last_name}</td>
                <td style={{ lineHeight: "2" }}>{user.gender}</td>
                <td style={{ lineHeight: "2" }}>
                  {user.date_birth.split('-')[2]}&nbsp;
                  {user.date_birth.split('-')[1] === "01" ? "Januari" : "" ||
                    user.date_birth.split('-')[1] === "02" ? "Februari" : "" ||
                    user.date_birth.split('-')[1] === "03" ? "Maret" : "" ||
                    user.date_birth.split('-')[1] === "04" ? "April" : "" ||
                    user.date_birth.split('-')[1] === "05" ? "Mei" : "" ||
                    user.date_birth.split('-')[1] === "06" ? "Juni" : "" ||
                    user.date_birth.split('-')[1] === "07" ? "Juli" : "" ||
                    user.date_birth.split('-')[1] === "08" ? "Agustus" : "" ||
                    user.date_birth.split('-')[1] === "09" ? "September" : "" ||
                    user.date_birth.split('-')[1] === "10" ? "Oktober" : "" ||
                    user.date_birth.split('-')[1] === "11" ? "November" : "" ||
                    user.date_birth.split('-')[1] === "12" ? "Desember" : ""}&nbsp;
                  {user.date_birth.split('-')[0]}
                </td>
                <td style={{ lineHeight: "2" }}>{user.age} Tahun</td>
                <td style={{ lineHeight: "2" }}>{user.residence_address}</td>
                <td style={{ lineHeight: "2" }}>{user.phone_number}</td>
                <td style={{ lineHeight: "2" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      onClick={() => modalPhysicalCheck(user?.id)}
                      style={{
                        display: "flex",
                        border: "none",
                        padding: "0px 7px",
                        cursor: "pointer",
                        borderRadius: "3px",
                        backgroundColor: "#3D64FF",
                        color: "white",
                        fontWeight: "bold",
                        width: "120px",
                        justifyContent: "center"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                        <FaMedkit />
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>Physical Check</div>
                    </div>
                  </div>
                  <div className="mt-1" style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      onClick={() => modalMedicalRecord(user?.id, user?.code)}
                      style={{
                        display: "flex",
                        border: "none",
                        padding: "0px 7px",
                        cursor: "pointer",
                        borderRadius: "3px",
                        backgroundColor: "#FF9090",
                        color: "white",
                        fontWeight: "bold",
                        width: "120px",
                        justifyContent: "center"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                        <FaMedkit />
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>Medical Record</div>
                    </div>
                  </div>
                  <div className="mt-1" style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      onClick={() => soapPatient(user?.id, user?.first_name, user?.last_name)}
                      style={{
                        display: "flex",
                        border: "none",
                        padding: "0px 7px",
                        cursor: "pointer",
                        borderRadius: "3px",
                        backgroundColor: "#FFE84D",
                        color: "white",
                        fontWeight: "bold",
                        width: "120px",
                        justifyContent: "center"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                        <FaRegStickyNote />
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>Soap Patient</div>
                    </div>
                  </div>
                  <div className="mt-1" style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      onClick={() => odontogramPatient(user?.id, user?.first_name, user?.last_name)}
                      style={{
                        display: "flex",
                        border: "none",
                        padding: "0px 7px",
                        cursor: "pointer",
                        borderRadius: "3px",
                        backgroundColor: "#00EB8D",
                        color: "white",
                        fontWeight: "bold",
                        width: "120px",
                        justifyContent: "center"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                        <FaDeviantart />
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>Odontogram</div>
                    </div>
                  </div>
                </td>
                <td style={{ lineHeight: "2" }}>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button
                      onClick={() => deleteById(user?.id)}
                      style={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: "#B60000",
                        padding: "5px 5px",
                        borderRadius: "3px",
                        cursor: "pointer",
                        border: "none",
                        display: "flex"
                      }}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TablePatient;
