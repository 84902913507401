import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaFileInvoice, FaPrint} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import "bulma/css/bulma.css";
import { useMutation } from "react-query";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import { ScaleLoader } from "react-spinners";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import toRupiah from '@develoka/angka-rupiah-js';
import LoaderHome from "../Loader/LoaderHome"
import axios from "axios";

export default function Login() {
  document.title = "SDC Apps";

  const navigate = useNavigate()
  const [dataManage, setDatamanage] = useState(false)
  const [pages, setPages] = useState();
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const [ascending, setAscending] = useState(0);
  // filter name role users
  const [idRoleDoctor, setIdRoleDoctor] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");
  const [idRoleNurse, setIdRoleNurse] = useState("");
  const [nameRoleNurse, setNameRoleNurse] = useState("Nurse");
  const [getData, setGetData] = useState([]);
  const [getDataPayment, setGetDataPayment] = useState([]);
  const [getDataPaymentType, setGetDataPaymentType] = useState([]);
  const [getDataAppointmentById, setGetDataAppointmentById] = useState([]);
  const [getDataAppointmentByIdPatient, setGetDataAppointmentByIdPatient] = useState([]);
  const [getDataAppointmentByIdDoctor, setGetDataAppointmentByIdDoctor] = useState([]);
  const [getDataMedicalInvoiceByIdDate, setGetDataMedicalInvoiceByIdDate] = useState([])
  const [getDataMedicalInvoiceById, setGetDataMedicalInvoiceById] = useState([]);
  const [getDataMedicalInvoiceByIdPreceptions, setGetDataMedicalInvoiceByIdPreceptions] = useState([]);
  const [getDataFee, setGetDataFee] = useState([]);
  const [getDataDetails, setGetDataDetails] = useState([]);
  const [getDataNurse, setGetDataNurse] = useState([]);
  const [getDataNurseSecond, setGetDataNurseSecond] = useState([]);
  const [getDataFloor, setGetDataFloor] = useState([]);
  const [getDataRoom, setGetDataRoom] = useState([]);
  const [rows, setRows] = useState(1);
  const [loading, setLoading] = useState(false);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [getCode, setGetCode] = useState([]);
  const {id, invoice_id} = useParams()
  const myRef = useRef(null);
   // List data kirim ke modal proops update
  const [getDateInvoice, setGetDataInvoiceDate] = useState([])
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [getPersentaseAssurance, setPersentaseAssurance] = useState(false)
  const [getDataTreatmentOneQty, setGetDataTreatmentOneQty] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => myRef.current
  });

  const dummyStatusAssurance = "full";

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };
  

  const [form, setForm] = useState({
    nama_doktor: "",
    nama_patient: "",
    nama_admin: "",
    date_invoice_medis: "",
    code_invoice_medis: "",
    nameBank: "",
    namePembayaran:"",
    status_assurance: "full"
  });

  const [formAssurance, setFormAssurance] = useState({
    persentase: 0,
    admin_persentase: 0,
  });

  const fetchDataRef = useRef();

  const fetchData = async () => {
    try {
      setLoading(true);
      const [feeResponse, salesInvoiceResponse, medicalInvoiceResponse, medicalPreceptions, persentaseAssurance, treatmentOneQty] = await axios.all([
        API.get(`transaction-service/fees?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams),
        API.get(`transaction-service/sales-invoices/${invoice_id}`,fetchParams),
        API.get(`emr-service/medical-invoices/${id}`, fetchParams),
        API.get(`emr-service/medical-prescriptions?id_appointment=${id}`,fetchParams),
        API.get(`user-service/persentase-assurances?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams),
        API.get(`transaction-service/treatment-one-qtys?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams)
      ]);

      if (feeResponse.status === 200 &&
        salesInvoiceResponse.status === 200 &&
        medicalInvoiceResponse.status === 200 &&
        persentaseAssurance.status === 200 ) {
        setGetDataTreatmentOneQty(treatmentOneQty.data.data)
        setGetDataFee(feeResponse.data.data);
        setGetDataPayment(salesInvoiceResponse.data.data.payment_data.name)
        setGetDataPaymentType(salesInvoiceResponse.data.data.payment_data.payment_type.name)
        setGetDataMedicalInvoiceByIdDate(medicalInvoiceResponse.data.data);
        setGetDataMedicalInvoiceById(medicalInvoiceResponse.data.data.medical_treatment_invoices);
        setGetDataMedicalInvoiceByIdPreceptions(medicalPreceptions.data.data);
        setGetDataInvoiceDate(salesInvoiceResponse.data.data);
        setPersentaseAssurance(persentaseAssurance.data.data);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  };

  useEffect(() => {
    fetchDataRef.current = fetchData;

    // Panggil fetchData saat komponen pertama kali dimuat
    fetchDataRef.current();
  }, []);

  useEffect(() => {
    setForm({
      ...form, 
      nama_doktor: `${getDataMedicalInvoiceByIdDate?.doctor_name}`.toUpperCase() ,
      nama_patient: `${getDataMedicalInvoiceByIdDate?.patient_name}`.toUpperCase() 
    });
  }, [getDataMedicalInvoiceByIdDate])
  
  useEffect(() => {
    setForm({
      ...form, 
      nama_admin: `${getDateInvoice?.admin_name}`.toUpperCase() ,
      date_invoice_medis: `${getDateInvoice?.date}`,
      code_invoice_medis: `${getDateInvoice?.code}`,
      status_assurance: `${getDateInvoice?.status_assurance === null || getDateInvoice?.status_assurance === "null" ? "full" : getDateInvoice?.status_assurance}`,
    });
  }, [getDateInvoice])
  
  useEffect(() => {
    setForm({
      ...form, 
      namePembayaran: `${getDataPayment}`,
    });
  }, [getDataPayment])
  
  useEffect(() => {
    setForm({
      ...form, 
      nameBank: `${getDataPaymentType}`,
    });
  }, [getDataPaymentType])

  useEffect(() => {
    if (getPersentaseAssurance) {
      setFormAssurance({
          ...formAssurance,
          persentase: getPersentaseAssurance.length === 0 ? 0 : getPersentaseAssurance[0].persentase,
          admin_persentase: getPersentaseAssurance.length === 0 ? 0 : getPersentaseAssurance[0].admin_persentase,
      });
    }
    },[getPersentaseAssurance]);

  function calculatePrice(userPrice) {
    const minPrice = 1;
    const maxPrice = 10000000;
    const step = 50000;

    // Membulatkan userPrice ke kelipatan step
    const roundedPrice = Math.ceil(userPrice / step) * step;

    // Membatasi harga di antara rentang minPrice dan maxPrice
    return Math.min(Math.max(roundedPrice, minPrice), maxPrice);
  }

  function calculatePriceUp(userPrice) {
  const minPrice = 10000001;
  const maxPrice = 100000000;
  const step = 500000;

  // Membulatkan userPrice ke kelipatan step
  const roundedPrice = Math.ceil(userPrice / step) * step;

  // Membatasi harga di antara rentang minPrice dan maxPrice
  return Math.min(Math.max(roundedPrice, minPrice), maxPrice);
  }

  function formatToRupiah(angka) {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });
  
    // Format angka ke format mata uang Rupiah
    const formattedString = formatter.format(angka);
  
    // Menghilangkan simbol "RP" (simbol mata uang) dari hasil format
    const rupiahWithoutSymbol = formattedString.replace('Rp', '');
  
    return rupiahWithoutSymbol.trim(); // Menghilangkan spasi yang mungkin ada
  }
  
  useEffect(() => {
    if (getDataFee && getDataMedicalInvoiceById && getDataMedicalInvoiceByIdPreceptions && formAssurance?.persentase !== 0) {
      
      let totalFee = 0;
      getDataFee.forEach((user) => {
        totalFee += 100000;
      });
  
      let totalMedicalInvoice = 0;
      getDataMedicalInvoiceById.forEach((user) => {
      if (form?.status_assurance === "full" && user?.discount === null) {
        totalMedicalInvoice +=
        100000 > 10000000 ?
        calculatePriceUp(100000 / formAssurance?.persentase)*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty) :
        calculatePrice(100000 / formAssurance?.persentase)*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty);
      }

      if (form?.status_assurance === "full" && user?.discount !== null) {
        totalMedicalInvoice +=
        100000 > 10000000 ?
        calculatePriceUp(100000 / formAssurance?.persentase) - user?.discount *(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty) :
        calculatePrice(100000 / formAssurance?.persentase) - user?.discount *(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty);
      }
  
      if (form?.status_assurance === "partial" && user?.discount === null) {
        totalMedicalInvoice +=
        100000 > 10000000 ?
        calculatePriceUp((100000 / formAssurance?.persentase) + calculatePrice(formAssurance?.admin_persentase)) *(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty):
        calculatePrice((100000 / formAssurance?.persentase) + calculatePrice(formAssurance?.admin_persentase)) *(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty);
      }

      if (form?.status_assurance === "partial" && user?.discount !== null) {
        totalMedicalInvoice +=
        100000 > 10000000 ?
        calculatePriceUp((100000 / formAssurance?.persentase) + calculatePrice(formAssurance?.admin_persentase)) - user?.discount *(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty):
        calculatePrice((100000 / formAssurance?.persentase) + calculatePrice(formAssurance?.admin_persentase)) - user?.discount *(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty);
      }
      });
      
       let totalMedicalInvoicePreceptions = 0;
       getDataMedicalInvoiceByIdPreceptions.forEach((user) => {
        totalMedicalInvoicePreceptions += user.price_item * user.qty ;
       });
      
      const totalHargaKeseluruhan = totalFee + totalMedicalInvoice + totalMedicalInvoicePreceptions;
      const hargaDalamRupiah = formatToRupiah(totalHargaKeseluruhan);
      const totalElement = document.getElementById('totalPrice');
      totalElement.innerText = hargaDalamRupiah;
    }
  }, [getDataFee, getDataMedicalInvoiceById, getDataMedicalInvoiceByIdPreceptions, formAssurance]);

  // Menghitung total harga dari getDataFee
  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return (
    <Row style={{minHeight:"100vh"}}>
     {loading && <LoaderHome />}
      <Col xl='12' sm='6' style={{ padding: "20px 350px 20px 350px", backgroundColor:"#E9E9E9", }}>
        {/* TOMBOL PRINT */}
        <div className="mb-2" style={{display:"flex", justifyContent:"end"}}>
          <button onClick={() => handlePrint()} style={{display:"flex", justifyContent:"",alignItems:"center", border:"none", backgroundColor:"#00409F",borderRadius:"3px", fontSize:"15px", padding:"5px 15px", color:"white"}}><div style={{display:"", height:"", alignItems:"center", marginRight:"5px"}}><FaPrint /></div>Print</button>
        </div>
        <form ref={myRef} style={{width:"100%" }}>
          <div style={{ display: "flex", backgroundColor: "white", opacity: ".9", borderBottom:"4px solid black", paddingBottom:"10px", paddingTop:"50px"}}>
            <div style={{ flex: "30%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={Logo_Signature} alt="logo-signature" style={{width:"60%"}}/>
            </div> 
    
            <div style={{ flex: "40%", display:"flex", alignItems:"center",paddingLeft:"20px"}}>
              <div style={{display:"flex", justifyContent:"start", fontFamily:"sans-serif",fontWeight:"bolder", fontSize:"20px"}}>
                BUKTI PEMBAYARAN 
              </div>
            </div> 
          
            <div style={{ flex: "30%", display:"flex", alignItems:"end"}}>
              <div style={{display:"flex", justifyContent:"start", fontFamily:"sans-serif",fontWeight:"bold", fontSize:"10px"}}>
              NO. INVOICE &nbsp;{form?.code_invoice_medis}
              </div>
            </div> 
          </div>
          <div style={{ backgroundColor: "white", opacity: ".9", fontFamily:"sans-serif"}}>
            <div style={{ padding: "10px 30px" }}>
              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  TELAH TERIMA DARI
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{form?.nama_patient}</p>
                </div>
              </div>

              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  NAMA DOKTER
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{form?.nama_doktor}</p>
                </div>
              </div>

              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  PEMBAYARAN
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{form?.nameBank.toUpperCase()} / {form?.namePembayaran.toUpperCase()}</p>
                </div>
              </div>

              {/* <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  BANK
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}></p>
                </div>
              </div> */}
             
              <table className="table mt-4">
              <thead>
                <tr style={{backgroundColor:"white", borderTop:"3px solid black", borderBottom:"3px solid black"}}>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>NO</th>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif", }}>KETERANGAN</th>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>JUMLAH</th>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "", border: "none", fontFamily: "sans-serif" }}>HARGA</th>
                </tr>
                </thead>
                <tbody>
                  {getDataFee.map((user,index) => (
                    <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center" }}>
                      <td style={{ lineHeight: "1", border: "none" }}>{index + 1}</td>
                      <td style={{ lineHeight: "1", border: "none", textAlign: "start" }}>{user?.name.toUpperCase()}</td>
                      <td style={{ lineHeight: "1", border: "none" }}>1</td>
                      <td style={{ lineHeight: "1", border: "none", display: "flex" }}>
                        <div style={{ flex: "10%", display: "flex", justifyContent: "start" }}>
                          Rp 
                        </div>
                        <div style={{ flex: "90%", display: "flex", justifyContent: "end" }}>
                          {formatToRupiah(100000)} {user?.price_item}
                        </div>
                      </td>
                    </tr>
                  ))}

                  {getDataMedicalInvoiceById.map((user,index) => (
                    <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center", }}>
                      <td style={{ lineHeight: "1", border: "none" }}>{getDataFee.length + index + 1}</td>
                      <td style={{ lineHeight: "1", border: "none", textAlign: "start" }}>
                          <div>
                            {user?.medical_record.treatment_plan_detail.treatment_data.name.toUpperCase()}&nbsp;
                            {user?.medical_record.treatment_plan_detail.tooth_number === null || user?.medical_record.treatment_plan_detail.tooth_number === "null" ? "" :
                            getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ?
                            (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  55  54  53  52  51  61  62  63  64  65  85  84  83  82  81  71  72  73  74  75  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RA+RB)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RA+RB)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "55  54  53  52  51  61  62  63  64  65  85  84  83  82  81  71  72  73  74  75" && "(RA+RB)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  55  54  53  52  51  61  62  63  64  65" && "(RA)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28" && "(RA)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "55  54  53  52  51  61  62  63  64  65" && "(RA)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "85  84  83  82  81  71  72  73  74  75  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RB)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "(RB)") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "85  84  83  82  81  71  72  73  74  75" && "(RB)") 
                            :
                            `(${user?.medical_record.treatment_plan_detail.tooth_number})`                          
                            }
                            </div>
                          {user?.medical_record.diagnose === null || user?.medical_record.diagnose === "null"? "" : 
                          <div style={{fontWeight:"800"}}>
                              DIAGNOSA : {user?.medical_record.diagnose.toUpperCase()}
                          </div>
                          }
                        </td>
                        <td style={{ lineHeight: "1", border: "none" }}>{getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty}</td>

                        {user?.discount === null ? 
                          <td style={{ lineHeight: "1", border:"none", display: "flex"}}>
                            <div style={{flex:"10%",display:"flex", justifyContent:"start" }}>
                              Rp
                            </div>
                            <div style={{flex:"90%",display:"flex", justifyContent:"end"  }}>
                            {form?.status_assurance === "full" && 
                            (100000 > 10000000 ? 
                            formatToRupiah(calculatePriceUp(100000 / formAssurance?.persentase)*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty)):
                            formatToRupiah(calculatePrice(100000 / formAssurance?.persentase)*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty))
                            )} 

                            {form?.status_assurance === "partial" && 
                            (100000 > 10000000 ? 
                            formatToRupiah((calculatePriceUp((100000 / formAssurance?.persentase)) + calculatePrice(formAssurance?.admin_persentase))*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty)):
                            formatToRupiah((calculatePrice((100000 / formAssurance?.persentase)) + calculatePrice(formAssurance?.admin_persentase))*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty))
                            )} 
                            </div>
                          </td>
                          :
                          <td style={{ lineHeight: "1", border: "none", textAlign: "start" }}>
                            <div style={{display:"flex"}}>
                              <div style={{flex:"10%",display:"flex", justifyContent:"start" }}>
                                Rp
                              </div>
                              <div style={{flex:"90%",display:"flex", justifyContent:"end"  }}>
                                <s>
                                {form?.status_assurance === "full" && 
                                (100000 > 10000000 ? 
                                formatToRupiah(calculatePriceUp(100000 / formAssurance?.persentase)*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty)):
                                formatToRupiah(calculatePrice(100000 / formAssurance?.persentase)*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty))
                                )} 

                                {form?.status_assurance === "partial" && 
                                (100000 > 10000000 ? 
                                formatToRupiah(calculatePriceUp((100000 / formAssurance?.persentase)) + calculatePrice(formAssurance?.admin_persentase)*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty)):
                                formatToRupiah(calculatePrice((100000 / formAssurance?.persentase)) + calculatePrice(formAssurance?.admin_persentase)*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty))
                                )} 
                                </s>
                              </div>
                            </div>
                            <div style={{display:"flex"}}>
                              <div style={{flex:"10%",display:"flex", justifyContent:"start" }}>
                                Rp
                              </div>
                              <div style={{flex:"90%",display:"flex", justifyContent:"end"  }}>
                              {form?.status_assurance === "full" && 
                                (100000 > 10000000 ? 
                                formatToRupiah((calculatePriceUp(100000 / formAssurance?.persentase) - user?.discount)*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty)):
                                formatToRupiah((calculatePrice(100000 / formAssurance?.persentase) - user?.discount)*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty))
                                )} 

                                {form?.status_assurance === "partial" && 
                                (100000 > 10000000 ? 
                                formatToRupiah(((calculatePriceUp(100000 / formAssurance?.persentase) + calculatePrice(formAssurance?.admin_persentase)) - user?.discount)*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty)):
                                formatToRupiah(((calculatePrice(100000 / formAssurance?.persentase) + calculatePrice(formAssurance?.admin_persentase)) - user?.discount)*(getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ? 1 : user?.qty))
                                )} 
                              </div>
                            </div>
                          </td>
                          }
                    </tr>
                  ))} 

                  {getDataMedicalInvoiceByIdPreceptions.map((user,index) => (
                    <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center" }}>
                      <td style={{ lineHeight: "1", border: "none" }}>{getDataFee.length + getDataMedicalInvoiceById.length + (index + 1)}</td>
                      <td style={{ lineHeight: "1", border: "none", textAlign: "start" }}>
                        <div>
                        {user?.name_item.toUpperCase()}
                          </div>
                          {user?.description === null ?
                            <></>
                            :
                            <div style={{fontWeight:"800"}}>
                              DESKRIPSI : {user?.description.toUpperCase()}
                            </div>
                          }
                        </td>
                        <td style={{ lineHeight: "1", border: "none" }}>{user?.qty}</td>
                      <td style={{ lineHeight: "1", border: "none", display: "flex" }}>
                        <div style={{ flex: "10%", display: "flex", justifyContent: "start" }}>
                          Rp
                        </div>
                        <div style={{ flex: "90%", display: "flex", justifyContent: "end" }}>
                          {formatToRupiah(user?.price_item * user.qty)} 
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* <div style={{display:"flex", justifyContent:"end"}}>
                <div style={{flex:"50%"}}>
                  
                </div>
                <div style={{ flex: "50%", borderTop: "3px solid black", borderBottom: "3px solid black", display: "flex", fontSize: "12px", fontWeight: "bold", padding: "5px 5px", borderRight: "none", borderLeft: "none" }}>
                  <div style={{ flex: "50%" }}>
                    TOTAL
                  </div>
                  <div style={{ flex: "50%", display: "flex", alignItems: "center" }}>
                    <div style={{ flex: "10%", display: "flex", justifyContent: "start" }}>
                      Rp
                    </div>
                    <div style={{ flex: "90%", display: "flex", justifyContent: "end" }}>
                      <span id="totalPrice">0</span> 
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2" style={{display:"flex", justifyContent:"end"}}>
                <div style={{flex:"50%"}}>
                </div>
                <div style={{ flex: "50%", display: "flex", fontSize:"12px", fontWeight:"bold", padding:"5px 5px"}}>
                  <div>
                    TANGERANG, {date}&nbsp;
                     {month === 1 ? "JANUARI" : "" ||
                      month === 2 ? "FEBRUARI" : "" ||
                      month === 3 ? "MARET" : "" ||
                      month === 4 ? "APRIL" : "" ||  
                      month === 5 ? "MEI" : "" ||
                      month === 6 ? "JUNI" : "" ||
                      month === 7 ? "JULI" : "" ||
                      month === 8 ? "AGUSTUS" : "" ||
                      month === 9 ? "SEPTEMBER" : "" ||
                      month === 10 ? "OKTOBER" : "" ||
                      month === 11 ? "NOVEMBER" : "" ||
                      month === 12 ? "DESEMBER" : ""}&nbsp; 
                      {year}
                  </div>
                </div>
              </div> */}

              <div style={{display:"flex", justifyContent:"end"}}>
                <div style={{flex:"50%"}}>
                  
                </div>
                <div style={{ flex: "50%", borderTop: "3px solid black", borderBottom: "3px solid black", display: "flex", fontSize: "12px", fontWeight: "bold", padding: "5px 5px", borderRight: "none", borderLeft: "none" }}>
                  <div style={{ flex: "50%" }}>
                    TOTAL
                  </div>
                  <div style={{ flex: "50%", display: "flex", alignItems: "center" }}>
                    <div style={{ flex: "10%", display: "flex", justifyContent: "start" }}>
                      Rp
                    </div>
                    <div style={{ flex: "90%", display: "flex", justifyContent: "end" }}>
                      <span id="totalPrice">0</span> 
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2" style={{display:"flex", justifyContent:"end"}}>
                <div style={{flex:"50%", display: "flex", justifyContent:"center", fontSize:"12px", fontWeight:"bold", padding:"5px 5px"}}>
                </div>
                <div style={{ flex: "50%", display: "flex", justifyContent:"center", fontSize:"12px", fontWeight:"bold", padding:"5px 5px"}}>
                  <div>
                    TANGERANG, {date}&nbsp;
                     {month === 1 ? "JANUARI" : "" ||
                      month === 2 ? "FEBRUARI" : "" ||
                      month === 3 ? "MARET" : "" ||
                      month === 4 ? "APRIL" : "" ||  
                      month === 5 ? "MEI" : "" ||
                      month === 6 ? "JUNI" : "" ||
                      month === 7 ? "JULI" : "" ||
                      month === 8 ? "AGUSTUS" : "" ||
                      month === 9 ? "SEPTEMBER" : "" ||
                      month === 10 ? "OKTOBER" : "" ||
                      month === 11 ? "NOVEMBER" : "" ||
                      month === 12 ? "DESEMBER" : ""}&nbsp; 
                      {year}
                  </div>
                </div>
              </div>
              <div className="mt-2" style={{display:"flex", justifyContent:"end"}}>
                <div style={{flex:"50%", display: "flex", justifyContent:"center", fontSize:"12px", fontWeight:"bold", padding:"5px 5px"}}>
                  MENGETAHUI
                </div>
                <div style={{ flex: "50%", display: "flex", justifyContent:"center", fontSize:"12px", fontWeight:"bold", padding:"5px 5px"}}>
                  <div>
                    DOKTER JAGA
                  </div>
                </div>
              </div>
              <div className="" style={{marginTop:"100PX",display:"flex", justifyContent:"end"}}>
                <div style={{flex:"50%", display: "flex", justifyContent:"center", fontSize:"12px", fontWeight:"bold", padding:"5px 5px"}}>
                  <div>
                    <u>{form?.nama_admin}</u>
                  </div>                
                </div>
                <div style={{ flex: "50%", display: "flex", justifyContent:"center", fontSize:"12px", fontWeight:"bold", padding:"5px 5px"}}>
                  <div>
                    {/* <u>{form?.nama_doktor}</u> */}
                  </div>
                </div>
              </div>
              
              {/* <div className="" style={{marginTop:"100PX",display:"flex", justifyContent:"end"}}>
                <div style={{flex:"50%"}}>
                </div>
                <div style={{ flex: "50%", display: "flex", fontSize:"12px", fontWeight:"bold", padding:"5px 5px"}}>
                  <div>
                    {form?.nama_doktor} 
                  </div>
                </div>
              </div> */}
              
              {/* <div className="mt-" style={{ display: "flex"}}>
                <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
                  TELAH TERIMA DARI
                </div>
                <div style={{ flex: "60%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                  <div style={{ width:"100%", outline:"none", padding:"10px 0px"}}>
                    <input autoFofcus type='text' name="name_floor" onChange={handleChange} style={{width:"100%",height:"100%", paddingLeft:"0px",borderTop:"none",borderRight:"none",borderLeft:"none", borderBottom:"1px solid #B6B6B6", outline:"none"}}/>
                  </div>
                </div>
              </div> */}

            </div>
          </div>

        </form>
      </Col>
    </Row>
   
  );
}
