import React from "react";
import { FaAddressBook, FaPlus, FaRegArrowAltCircleLeft, FaSync, FaTrash } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";

const HeaderPatient = ({ 
  isTabletOrMobile, 
  query, 
  setQuery, 
  searchData, 
  buttonRefresh, 
  modalMedisRoleAdd 
}) => {

  const navigate = useNavigate();
  const navigatePatients = () => {
    navigate('/patients');
  }
  const levelUser = localStorage.getItem('level');
  const location = useLocation();
  const patientTrashUrl = location.pathname === "/trash/patients";

  return (
    <>
      {/* start header */}
      {isTabletOrMobile ? (
        <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", padding: "0px 0px 10px 0px" }}>
          <Col xl="6" style={{ fontSize: "16px", display: "flex", justifyContent: "center", alignItems: "center", padding: "7px", color: "#001F8B", backgroundColor: "#E9EFFF" }}>
            <FaAddressBook style={{ marginRight: "5px" }} />List Pasien 
          </Col> 
          <Col className="mt-2" xl="6" style={{ display: "flex", justifyContent: "end", paddingRight: "5px" }}>
            <div onClick={modalMedisRoleAdd} style={{ display: "flex", alignItems: "center", marginRight: "5px", backgroundColor: "#3D64FF", color: "white", padding: "0px 12px", borderRadius: "3px", cursor: "pointer", fontSize: "12px" }}>
              <FaPlus />
            </div>
            <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "10px 10px", borderRadius: "2px", cursor: "pointer", border: "1px solid #DEDEDE" }}>
              <FaSync style={{ fontSize: "15px", marginRight: "0px", display: "flex", alignItems: "center", height: "100%", color: "#3D64FF" }} />
            </div>
            <form onSubmit={searchData} style={{ display: "flex", paddingRight: "0px" }}>
              <div style={{ marginRight: "5px", borderRadius: "3px" }}>
                <input
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="focused"
                  style={{ backgroundColor: "#E9E9E9", border: "none", height: "100%" }}
                  type="text"
                  placeholder=""
                />
              </div>
              <button type="submit" style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius: "2px", fontWeight: "600", fontSize: "12px", alignItems: "center", padding: "0px 10px" }}>
                Search
              </button>
            </form>
          </Col>
        </div>
      ) : (
        <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: "10px 0px" }}>
          <div style={{ flex: "50%", fontSize: "16px", display: "flex", alignItems: "center", paddingLeft: "10px", color: "#001F8B" }}>
              {patientTrashUrl && 
                <>
                  <div style={{display:"flex" , marginRight:"10px",fontSize: "11px", color: "white", backgroundColor: "#ABC1FF", padding: "5px 10px", borderRadius: "3px", cursor: "pointer", border: "none", borderTopLeftRadius:"30px", borderBottomLeftRadius:"30px"}}>
                    <div style={{ marginRight: "3px", display:"flex" }}>
                      <div style={{display:"flex",alignItems:"center", height:"100%"}}>
                        <FaRegArrowAltCircleLeft style={{display:"flex",alignItems:"center", fontSize:"15px"}}/>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div onClick={navigatePatients} style={{display:"flex", alignItems:"center", height:"100%", fontSize:"12px"}}>
                        Kembali
                      </div>
                    </div>
                  </div>
                </>
              }
            {patientTrashUrl &&
              <>
                <FaAddressBook style={{ marginRight: "5px" }} />
                List Trash Patients
              </>
            }
            
            {!patientTrashUrl && (
              <>
                <FaAddressBook style={{ marginRight: "5px" }} />
                List Patients
              </>
            )}
          </div>
          <div style={{ flex: "50%", display: "flex", justifyContent: "end" }}>
            {!patientTrashUrl && 
              <>
                <div onClick={modalMedisRoleAdd} style={{ display: "flex", alignItems: "center", marginRight: "5px", backgroundColor: "#3D64FF", color: "white", padding: "0px 12px", borderRadius: "3px", cursor: "pointer", fontSize: "12px" }}>
                  <FaPlus />
                </div>
              </>
            }
            <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "0px 10px", border: "1px solid #DEDEDE", borderRadius: "3px", cursor: "pointer" }}>
              <FaSync style={{ fontSize: "15px", marginRight: "0px", display: "flex", alignItems: "center", height: "100%", color: "#3D64FF" }} />
            </div>
            {levelUser === 'developer' && !patientTrashUrl &&
              <>
                <Link to="/trash/patients" >
                  <div style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "0px 10px", border: "1px solid #DEDEDE", borderRadius: "3px", cursor: "pointer" }}
                  >
                    <FaTrash  style={{ fontSize: "15px", marginRight: "0px", display: "flex", alignItems: "center", height: "100%", color: "#3D64FF" }}  />
                  </div>
                </Link>
              </>
            }
            <form onSubmit={searchData} style={{ display: "flex", paddingRight: "10px" }}>
              <div style={{ marginRight: "5px", borderRadius: "3px" }}>
                <input
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="focused"
                  style={{ backgroundColor: "#E9E9E9", border: "none", height: "5vh" }}
                  type="text"
                  placeholder=""
                />
              </div>
              <button type="submit" style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius: "3px", fontWeight: "600", fontSize: "12px", alignItems: "center", padding: "0px 10px" }}>
                Search
              </button>
            </form>
          </div>
        </div>
      )}
      {/* end header */}
    </>
  );
};

export default HeaderPatient;
