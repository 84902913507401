import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaBullhorn, FaRegBuilding} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import "bulma/css/bulma.css";
import "../../index.css"

function MyVerticallyCenteredModal(props) {
  const navigate = useNavigate()
  const registerBaru = () => {      
    navigate("/");
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif"}}>
      <div style={{ display: "flex", backgroundColor: "#E6E6E6", opacity: ".9", borderBottom:"5px solid #804D00", borderTopLeftRadius:"5px", borderTopRightRadius:"5px", padding:"0px 10px", height:"10vh"}}>
          <div style={{ flex: "5%", fontSize: "18px", textAlign: "right", display: "flex", alignItems: "center", fontWeight: "600", borderRight: "1px solid #DBDBDB",color: "#804D00" }}>
            Tambah Item
          </div> 
          <div style={{ flex: "50%", fontSize: "15px", textAlign: "right", display: "flex", alignItems: "center", fontWeight: "600", color:"#804D00"}}>
            <img src={Logo_Signature} alt="logo-signature" style={{ display: "flex", width: "40px",height:"6vh", marginBottom:"0px"}}/>
            PT. SIGNATURE ANUGERAH SENTOSA
        </div>    
        <Modal.Header closeButton style={{backgroundColor:""}}>
          {/*<Modal.Title id="contained-modal-title-vcenter">
            Pilih Status Pasien
          </Modal.Title>*/}
        </Modal.Header>
      </div>
      <Modal.Body style={{backgroundColor:"", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
      <div className="mt-2" style={{ display: "flex"}}>
        <div style={{flex:"5%", display:"flex", alignItems:"center"}}>
          Nama Item
        </div>
        <div style={{ flex: "60%", display:"flex"}}>
          <p style={{marginRight:"10px", display:"flex",alignItems:"center", height:"100%"}}>:</p>
          <input style={{width:"100%", border:"1px solid #989898", borderRadius:"5px", outlineColor:"#228AFF", paddingLeft:"10px"}}/>
        </div>
      </div>
      
        <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%",display:"flex", alignItems:"center"}}>
            Category
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"10px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            <select className="form-select" aria-label="Default select example" name="font_size_title" style={{ textAlign:"", cursor:"pointer"}}>
              <option value="" hidden>Select</option>
              <option value="A" style={{ textAlign: "" }}>GG</option>
              <option value="A+" style={{textAlign:""}}>L</option>
              <option value="A-" style={{textAlign:""}}>Meizani</option>
              <option value="B" style={{textAlign:""}}>1</option>
              <option value="B+" style={{textAlign:""}}>2</option>
              <option value="B-" style={{textAlign:""}}>3</option>  
            </select>
          </div>
        </div>

        <div className="mt-4" style={{ display: "flex"}}>
        <div style={{flex:"5%", display:"flex", alignItems:"center"}}>
          Code Item
        </div>
        <div style={{ flex: "60%", display:"flex"}}>
          <p style={{marginRight:"10px", display:"flex",alignItems:"center", height:"100%"}}>:</p>
          <input style={{width:"100%", border:"1px solid #989898", borderRadius:"5px", outlineColor:"#228AFF", paddingLeft:"10px"}}/>
        </div>
        </div>

        <div className="mt-4" style={{ display: "flex"}}>
        <div style={{flex:"5%", display:"flex", alignItems:"center"}}>
          QTY
        </div>
        <div style={{ flex: "60%", display:"flex"}}>
          <p style={{marginRight:"10px", display:"flex",alignItems:"center", height:"100%"}}>:</p>
          <input style={{width:"100%", border:"1px solid #989898", borderRadius:"5px", outlineColor:"#228AFF", paddingLeft:"10px"}}/>
        </div>
        </div>

        <div className="mt-4" style={{ display: "flex"}}>
        <div style={{flex:"5%", display:"flex", alignItems:"center"}}>
          Unit
        </div>
        <div style={{ flex: "60%", display:"flex"}}>
          <p style={{marginRight:"10px", display:"flex",alignItems:"center", height:"100%"}}>:</p>
          <input style={{width:"100%", border:"1px solid #989898", borderRadius:"5px", outlineColor:"#228AFF", paddingLeft:"10px"}}/>
        </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
      <div style={{ display: "flex" }}>
          {/* <Button onClick={props.onHide} style={{marginRight:"8px", height:}}>Close</Button> */}
          <div style={{ width:"150px"}}>
            <Button onClick={registerBaru} type='submit' color='primary' style={{ fontSize:"15px", borderRadius:"3px", height:"100%", width:"100%"}}>
              Tambahkan
            </Button>
          </div>
          
        </div>
      </Modal.Footer>
    </Modal>
    );
}
  
function MyVerticallyCenteredModalUpdate(props) {
  const navigate = useNavigate()
  const registerBaru = () => {      
    navigate("/");
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif"}}>
    <div style={{ display: "flex", backgroundColor: "#E6E6E6", opacity: ".9", borderBottom:"5px solid #804D00", borderTopLeftRadius:"5px", borderTopRightRadius:"5px", padding:"0px 10px", height:"10vh"}}>
        <div style={{ flex: "5%", fontSize: "18px", textAlign: "right", display: "flex", alignItems: "center", fontWeight: "600", borderRight: "1px solid #DBDBDB",color: "#804D00" }}>
          Tambah Item
        </div> 
        <div style={{ flex: "50%", fontSize: "15px", textAlign: "right", display: "flex", alignItems: "center", fontWeight: "600", color:"#804D00"}}>
          <img src={Logo_Signature} alt="logo-signature" style={{ display: "flex", width: "40px",height:"6vh", marginBottom:"0px"}}/>
          PT. SIGNATURE ANUGERAH SENTOSA
      </div>    
      <Modal.Header closeButton style={{backgroundColor:""}}>
        {/*<Modal.Title id="contained-modal-title-vcenter">
          Pilih Status Pasien
        </Modal.Title>*/}
      </Modal.Header>
    </div>
    <Modal.Body style={{backgroundColor:"", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
    <div className="mt-2" style={{ display: "flex"}}>
      <div style={{flex:"5%", display:"flex", alignItems:"center"}}>
        Nama Item
      </div>
      <div style={{ flex: "60%", display:"flex"}}>
        <p style={{marginRight:"10px", display:"flex",alignItems:"center", height:"100%"}}>:</p>
        <input style={{width:"100%", border:"1px solid #989898", borderRadius:"5px", outlineColor:"#228AFF", paddingLeft:"10px"}}/>
      </div>
    </div>
    
      <div className="mt-3" style={{ display: "flex"}}>
        <div style={{flex:"5%",display:"flex", alignItems:"center"}}>
          Category
        </div>
        <div style={{ flex: "60%", display:"flex"}}>
          <p style={{marginRight:"10px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
          <select className="form-select" aria-label="Default select example" name="font_size_title" style={{ textAlign:"", cursor:"pointer"}}>
            <option value="" hidden>Select</option>
            <option value="A" style={{ textAlign: "" }}>GG</option>
            <option value="A+" style={{textAlign:""}}>L</option>
            <option value="A-" style={{textAlign:""}}>Meizani</option>
            <option value="B" style={{textAlign:""}}>1</option>
            <option value="B+" style={{textAlign:""}}>2</option>
            <option value="B-" style={{textAlign:""}}>3</option>  
          </select>
        </div>
      </div>

      <div className="mt-4" style={{ display: "flex"}}>
      <div style={{flex:"5%", display:"flex", alignItems:"center"}}>
        Code Item
      </div>
      <div style={{ flex: "60%", display:"flex"}}>
        <p style={{marginRight:"10px", display:"flex",alignItems:"center", height:"100%"}}>:</p>
        <input style={{width:"100%", border:"1px solid #989898", borderRadius:"5px", outlineColor:"#228AFF", paddingLeft:"10px"}}/>
      </div>
      </div>

      <div className="mt-4" style={{ display: "flex"}}>
      <div style={{flex:"5%", display:"flex", alignItems:"center"}}>
        QTY
      </div>
      <div style={{ flex: "60%", display:"flex"}}>
        <p style={{marginRight:"10px", display:"flex",alignItems:"center", height:"100%"}}>:</p>
        <input style={{width:"100%", border:"1px solid #989898", borderRadius:"5px", outlineColor:"#228AFF", paddingLeft:"10px"}}/>
      </div>
      </div>

      <div className="mt-4" style={{ display: "flex"}}>
      <div style={{flex:"5%", display:"flex", alignItems:"center"}}>
        Unit
      </div>
      <div style={{ flex: "60%", display:"flex"}}>
        <p style={{marginRight:"10px", display:"flex",alignItems:"center", height:"100%"}}>:</p>
        <input style={{width:"100%", border:"1px solid #989898", borderRadius:"5px", outlineColor:"#228AFF", paddingLeft:"10px"}}/>
      </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
    <div style={{ display: "flex" }}>
        {/* <Button onClick={props.onHide} style={{marginRight:"8px", height:}}>Close</Button> */}
        <div style={{ width:"150px"}}>
          <Button onClick={registerBaru} type='submit' color='primary' style={{ fontSize:"15px", borderRadius:"3px", height:"100%", width:"100%"}}>
            Tambahkan
          </Button>
        </div>
        
      </div>
    </Modal.Footer>
  </Modal>
    );
  }

export default function Login() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);
  document.title = "SDC Apps";
  let navigate = useNavigate();

  const [state, dispatch] = useContext(UserContext);
  const [message, setMessage] = useState(null);
  const token = localStorage.getItem("token")
  
  const sweetAlert = () => {
    swal({
      title: 'Login Success',
      text: ' ',
      icon: 'success',
      timer: 3000,
      buttons: false
    });
  }

  const AddTreatment = (e)=>{
    e.preventDefault();
    navigate('/pasien-admin-add-treatment')
  }

  const UpdateTreatment = (e)=>{
    e.preventDefault();
    navigate('/pasien-admin-update-treatment')
  }

  const AddRoom = (e)=>{
    e.preventDefault();
    navigate('/room-admin-add')
  }

  const UpdateUser = (e)=>{
    e.preventDefault();
    navigate('/pasien-admin-update')
  }


  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  const [loading, setLoading] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [modalShow, setModalShow] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);

  const modalViewAdd = ()=>{
    setModalShow(true)
  }

  const modalViewUpdate = ()=>{
    setModalUpdate(true)
  }

  return (  
  <div style={{backgroundColor:"white", marginTop:"15px", marginLeft:"10px",marginRight:"10px",boxShadow: "2px 2px 10px #BFBFBF"}}>
    <MyVerticallyCenteredModal
      show={modalShow}
      onHide={() => setModalShow(false)}
    />
    <MyVerticallyCenteredModalUpdate
      show={modalUpdate}
      onHide={() => setModalUpdate(false)}
    />
    <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: "10px 0px" }}>
      <div style={{flex:"50%",fontSize:"16px",display:"flex", alignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
        List Item
      </div>
        <div style={{ flex: "50%", display: "flex", justifyContent: "end" }}>
      <div onClick={modalViewAdd}  style={{display:"flex",alignItems:"center",marginRight:"15px",backgroundColor:"#3D64FF",color:"white",padding:"0px 10px", boxShadow:"1px 1px 4px #8B8B8B", borderRadius:"3px", cursor:"pointer", fontSize:"12px", cursor:"pointer"}}>
        Tambah Item
      </div>
      {/* <div onClick={AddTreatment} style={{display:"flex",alignItems:"center",marginRight:"15px",backgroundColor:"#FFCC3F",color:"white",padding:"0px 10px", boxShadow:"1px 1px 4px #8B8B8B", borderRadius:"3px", cursor:"pointer", fontSize:"12px",cursor:"pointer"}}>
        Treatment Pasien
      </div> */}
      <div style={{height:"100%", marginRight:"5px", paddingTop:"0px",backgroundColor:"white",padding:"0px 10px", boxShadow:"1px 1px 4px #8B8B8B", borderRadius:"3px", cursor:"pointer"}}>
        <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
      </div>
      <form style={{display:"flex", paddingRight:"10px"}}>
          <div style={{marginRight:"5px",borderRadius:"3px"}}>
            <input
              style={{backgroundColor:"#E9E9E9", border:"none"}}
              type="text"
              placeholder=""
            />
          </div>
          <button type="submit"  style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius:"3px", fontWeight:"600", fontSize:"12px",alignItems:"center", padding:"0px 10px" }}>
            {/* <div style={{height:"100%", marginRight:"5px", paddingTop:"0px"}}>
              <FaSearch style={{ fontSize: "10px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%" }} />
            </div> */}
            Search
          </button>
        </form>
      </div>
    </div>
 
  <Col xl='12' sm='12'> 
  <div>
    <div style={{display:"block", height:"100%", overflowY:"auto",overflowX:"auto"}}>
      <div >
        <table className="table is-bordered">
          <thead>
            <tr style={{backgroundColor:"#E9EFFF"}}>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>No</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Nama Item</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Category Item</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Code Item</th> 
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>QTY</th>  
              <th style={{fontFamily:"revert",fontSize:"12px",textAlign:"center",  color:"#525252",border:"none", fontFamily:"sans-serif"}}>Unit</th>  
              {/* <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Lantai</th> */}
              <th style={{fontFamily:"revert",fontSize:"12px",textAlign:"center",  color:"#525252",border:"none", fontFamily:"sans-serif"}}>Setup</th>  
                    
              {/* <th style={{fontFamily:"revert",fontSize:"13px",textAlign:"center",  color:"black",border:"none"}}>Action</th>*/}
            </tr>
          </thead>
          <tbody>
            <tr style={{fontFamily:"sans-serif", fontSize:"11px", textAlign:"center"}}>
              <td style={{lineHeight:"2"}}>1</td>
              <td style={{lineHeight:"2"}}>Masker Bedah Karet Hijau Onemed</td>
              {/* <td>Islam</td> */}
              {/* <td>Lajang</td> */}
              {/* <td style={{lineHeight:"2"}}>SLTA</td> */}
              <td style={{lineHeight:"2"}}>Barang Medis</td>
              <td style={{lineHeight:"2"}}>SDC-N95-00234</td>
              <td style={{ lineHeight: "2" }}>50</td>
              <td style={{lineHeight:"2"}}>Pcs</td>
              {/* <td style={{ lineHeight: "2" }}>
                <div style={{display:"flex", justifyContent:"center"}}>
                    <div style={{display: "flex",justifyContent:"center", padding: "0px 10px", backgroundColor:"#00C00D", borderRadius:"3px", width:"50%"  }}>
                      <div style={{ display: "flex"}}>
                        <FaRegBuilding style={{display:"flex", alignItems:"center", height:"100%", fontSize:"11px", marginRight:"5px", color:"white"}}/>  
                      </div>
                      <div style={{ display: "flex"}}>
                        <div style={{ display: "flex", alignItems: "center", height: "100%", fontSize: "11px", marginTop:"1px", color: "white", fontWeight:"bold"}}>
                          Meizani
                        </div>
                      </div>
                  </div> 
                </div>    
              </td> */}
                  
              {/* <td style={{ textAlign: "center" }}>
                <button style={{padding: "0px 15px", borderRadius: "5px", border: "none" }}>256</button>
              </td>

              <td style={{ textAlign: "center" }}>
                <button style={{ backgroundColor: "orange", color: "white", padding: "0px 7px", borderRadius: "5px", border: "none" }}><FaStethoscope style={{ marginBottom: "1px", marginRight: "4px" }} /></button>
              </td> */}
                    
              <td style={{lineHeight:"2"}}>
                  <div style={{display:"flex",justifyContent:"center", alignItems:"center", alignContent:"center"}}>
                      <button onClick={modalViewUpdate} style={{ fontSize: "13px", color: "white", backgroundColor: "#ff9933", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display:"flex", marginRight:"3px" }}>
                        <FaEdit/>
                      </button>
                    
                      <button style={{ fontSize: "12px", color: "white", backgroundColor: "#B60000", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display:"flex" }}>
                        <FaTrash/>
                      </button>
                  </div>
              </td> 
            </tr>
          </tbody>
        </table>
          <div style={{ display: "flex"}}>
            <div style={{flex:"50%"}}>
              <div style={{fontSize:"12px", paddingLeft:"10px"}}>
              Total Rows: 1 Page: 10 of 100
              </div>
            </div>
            <div style={{flex:"50%", display:"flex", justifyContent:"end"}}>
            <nav
              style={{fontSize:"10px"}}
              className=""
            
              role="navigation"
              aria-label="pagination"
              >
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
          
                containerClassName={"pagination-list"}
                pageLinkClassName={"pagination-link"}
                previousLinkClassName={"pagination-previous"}
                nextLinkClassName={"pagination-next"}
                activeLinkClassName={"pagination-link is-current"}
                disabledLinkClassName={"pagination-link is-disabled"}
              />
            </nav> 
            </div>
          </div>     
          </div>
        </div>
      </div>
    </Col>
  </div>
  );
}
