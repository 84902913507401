import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaFileInvoice, FaPrint} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import "bulma/css/bulma.css";
import { useMutation } from "react-query";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import { ScaleLoader } from "react-spinners";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import toRupiah from '@develoka/angka-rupiah-js';
import LoaderHome from "../Loader/LoaderHome"
import axios from "axios";
// Component gigi
import RahangAtasKananDewasa from "./ToochPrintout/RahangAtasKananDewasaTooch"
import RahangAtasKiriDewasa from "./ToochPrintout/RahangAtasKiriDewasaTooch"
import RahangAtasKananAnak from "./ToochPrintout/RahangAtasKananAnakTooch"
import RahangAtasKiriAnak from "./ToochPrintout/RahangAtasKiriAnakTooch"
import RahangBawahKananAnak from "./ToochPrintout/RahangBawahKananAnakTooch"
import RahangBawahKiriAnak from "./ToochPrintout/RahangBawahKiriAnakTooch"
import RahangBawahKananDewasa from "./ToochPrintout/RahangBawahKananDewasaTooch"
import RahangBawahKiriDewasa from "./ToochPrintout/RahangBawahKiriDewasaTooch"

export default function Login() {
  const navigate = useNavigate()
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const [ascending, setAscending] = useState(0);
  // filter name role users
  const [getDataOdontogram, setGetDataOdontogram] = useState([]);
  const [getDataBiodata, setGetDataBiodata] = useState([])
  const [loading, setLoading] = useState(false);
  const {id} = useParams()
  const myRef = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [infoGigi, setInfoGigi] = useState("XX-X")
  const [formchoiceGigi, setFormchoiceGigi] = useState({
    choiceGigi: "per_gigi"
  });

   // check date
  let newDate = new Date()

  const params = "0"
  const tooch11 = "11"
  const tooch51 = "51"

  const viewToochSingle = (params) => {
    return (
    <>
        {/* amf */}
        {form?.tooch_number.match(new RegExp(`\\b${params}L-amf(?!-rct)\\b`)) && "L amf "}
        {form?.tooch_number.match(new RegExp(`\\b${params}B-amf(?!-rct)\\b`)) && "B amf "}
        {form?.tooch_number.match(new RegExp(`\\b${params}P-amf(?!-rct)\\b`)) && "P amf "}
        {form?.tooch_number.match(new RegExp(`\\b${params}M-amf(?!-rct)\\b`)) && "M amf "}
        {form?.tooch_number.match(new RegExp(`\\b${params}D-amf(?!-rct)\\b`)) && "D amf "}
        {form?.tooch_number.match(new RegExp(`\\b${params}O-amf(?!-rct)\\b`)) && "O amf "}
        {/* cof */}
        {form?.tooch_number.match(new RegExp(`\\b${params}L-cof(?!-rct)\\b`)) && "L cof "}
        {form?.tooch_number.match(new RegExp(`\\b${params}B-cof(?!-rct)\\b`)) && "B cof "}
        {form?.tooch_number.match(new RegExp(`\\b${params}P-cof(?!-rct)\\b`)) && "P cof "}
        {form?.tooch_number.match(new RegExp(`\\b${params}M-cof(?!-rct)\\b`)) && "M cof "}
        {form?.tooch_number.match(new RegExp(`\\b${params}D-cof(?!-rct)\\b`)) && "D cof "}
        {form?.tooch_number.match(new RegExp(`\\b${params}O-cof(?!-rct)\\b`)) && "O cof "}    
        {/* fis */}
        {form?.tooch_number.match(new RegExp(`\\b${params}L-fis\\b`)) && "L fis "}
        {form?.tooch_number.match(new RegExp(`\\b${params}B-fis\\b`)) && "B fis "}
        {form?.tooch_number.match(new RegExp(`\\b${params}P-fis\\b`)) && "P fis "}
        {form?.tooch_number.match(new RegExp(`\\b${params}M-fis\\b`)) && "M fis "}
        {form?.tooch_number.match(new RegExp(`\\b${params}D-fis\\b`)) && "D fis "}
        {form?.tooch_number.match(new RegExp(`\\b${params}O-fis\\b`)) && "O fis "}
        {/* nvt */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-nvt\\b`)) && "nvt "}
        {/* rct */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-rct\\b`)) && "rct "}
        {/* non */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-non\\b`)) && "non "}
        {/* une */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-une\\b`)) && "une "}  
        {/* pre */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-pre\\b`)) && "pre "}
        {/* sou */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-sou\\b`)) && "sou "}
        {/* ano */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-ano\\b`)) && "ano "}
        {/* car */}
        {form?.tooch_number.match(new RegExp(`\\b${params}L-car\\b`)) && "L car "}
        {form?.tooch_number.match(new RegExp(`\\b${params}B-car\\b`)) && "B car "}
        {form?.tooch_number.match(new RegExp(`\\b${params}P-car\\b`)) && "P car "}
        {form?.tooch_number.match(new RegExp(`\\b${params}M-car\\b`)) && "M car "}
        {form?.tooch_number.match(new RegExp(`\\b${params}D-car\\b`)) && "D car "}
        {form?.tooch_number.match(new RegExp(`\\b${params}O-car\\b`)) && "O car "}
        {/* cfr */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-cfr\\b`)) && "cfr "}   
        {/* amf - rct */}
        {form?.tooch_number.match(new RegExp(`\\b${params}L-amf-rct\\b`)) && "L amf - rct "}
        {form?.tooch_number.match(new RegExp(`\\b${params}B-amf-rct\\b`)) && "B amf - rct "}
        {form?.tooch_number.match(new RegExp(`\\b${params}P-amf-rct\\b`)) && "P amf - rct "}
        {form?.tooch_number.match(new RegExp(`\\b${params}M-amf-rct\\b`)) && "M amf - rct "}
        {form?.tooch_number.match(new RegExp(`\\b${params}D-amf-rct\\b`)) && "D amf - rct "}
        {form?.tooch_number.match(new RegExp(`\\b${params}O-amf-rct\\b`)) && "O amf - rct "}
        {/* cof - rct */}
        {form?.tooch_number.match(new RegExp(`\\b${params}L-cof-rct\\b`)) && "L cof - rct "}
        {form?.tooch_number.match(new RegExp(`\\b${params}B-cof-rct\\b`)) && "B cof - rct "}
        {form?.tooch_number.match(new RegExp(`\\b${params}P-cof-rct\\b`)) && "P cof - rct "}
        {form?.tooch_number.match(new RegExp(`\\b${params}M-cof-rct\\b`)) && "M cof - rct "}
        {form?.tooch_number.match(new RegExp(`\\b${params}D-cof-rct\\b`)) && "D cof - rct "}
        {form?.tooch_number.match(new RegExp(`\\b${params}O-cof-rct\\b`)) && "O cof - rct "}     
        {/* fmc */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-fmc(?!-rct)\\b`)) && "fmc "}   
        {/* fmc - rct */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-fmc-rct\\b`)) && "fmc-rct "}   
        {/* poc */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-poc(?!-rct)\\b`)) && "poc "}    
        {/* poc - rct */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-poc-rct\\b`)) && "poc-rct "}
        {/* rrx */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-rrx\\b`)) && "rrx "}
        {/* mis */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-mis\\b`)) && "mis "}  
        {/* ipx - poc */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-ipx-poc\\b`)) && "ipx-poc "}    
        {/* meb */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-meb\\b`)) && "meb "}    
        {/* mis - pon - meb */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-miss-pon-meb\\b`)) && "miss-pon-meb "} 
        {/* pob */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-pob\\b`)) && "pob "}    
        {/* mis - pon - pob */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-miss-pon-pob\\b`)) && "miss-pon-pob "}
        {/* mis - prd - acr */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-mis-prd-acr\\b`)) && "mis-prd-acr "}
        {/* mis - fld - acr */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-mis-fld-acr\\b`)) && "mis-fld-acr "}
    </>
    );
  };

  const viewToochDouble = (params) => {
    return (
    <>
        {/* amf */}
        {form?.tooch_number.match(new RegExp(`\\b${params}L-amf(?!-rct)\\b`)) && `${params} : L amf ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}B-amf(?!-rct)\\b`)) && `${params} : B amf ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}P-amf(?!-rct)\\b`)) && `${params} : P amf ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}M-amf(?!-rct)\\b`)) && `${params} : M amf ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}D-amf(?!-rct)\\b`)) && `${params} : D amf ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}O-amf(?!-rct)\\b`)) && `${params} : O amf ; `}
        {/* cof */}
        {form?.tooch_number.match(new RegExp(`\\b${params}L-cof(?!-rct)\\b`)) && `${params} : L cof ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}B-cof(?!-rct)\\b`)) && `${params} : B cof ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}P-cof(?!-rct)\\b`)) && `${params} : P cof ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}M-cof(?!-rct)\\b`)) && `${params} : M cof ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}D-cof(?!-rct)\\b`)) && `${params} : D cof ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}O-cof(?!-rct)\\b`)) && `${params} : O cof ; `}
        {/* fis */}
        {form?.tooch_number.match(new RegExp(`\\b${params}L-fis\\b`)) && `${params} : L fis ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}B-fis\\b`)) && `${params} : B fis ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}P-fis\\b`)) && `${params} : P fis ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}M-fis\\b`)) && `${params} : M fis ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}D-fis\\b`)) && `${params} : D fis ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}O-fis\\b`)) && `${params} : O fis ; `}
        {/* nvt */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-nvt\\b`)) && `${params} : nvt ; `}
        {/* rct */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-rct\\b`)) && `${params} : rct ; `}
        {/* non */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-non\\b`)) && `${params} : non ; `}
        {/* une */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-une\\b`)) && `${params} : une ; `}  
        {/* pre */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-pre\\b`)) && `${params} : pre ; `}
        {/* sou */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-sou\\b`)) && `${params} : sou ; `}
        {/* ano */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-ano\\b`)) && `${params} : ano ; `}
        {/* car */}
        {form?.tooch_number.match(new RegExp(`\\b${params}L-car\\b`)) && `${params} : L car ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}B-car\\b`)) && `${params} : B car ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}P-car\\b`)) && `${params} : P car ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}M-car\\b`)) && `${params} : M car ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}D-car\\b`)) && `${params} : D car ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}O-car\\b`)) && `${params} : O car ; `}
        {/* cfr */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-cfr\\b`)) && `${params} : cfr ; `} 
        {/* amf - rct */}
        {form?.tooch_number.match(new RegExp(`\\b${params}L-amf-rct\\b`)) && `${params} : L amf - rct ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}B-amf-rct\\b`)) && `${params} : B amf - rct ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}P-amf-rct\\b`)) && `${params} : P amf - rct ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}M-amf-rct\\b`)) && `${params} : M amf - rct ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}D-amf-rct\\b`)) && `${params} : D amf - rct ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}O-amf-rct\\b`)) && `${params} : O amf - rct ; `}
        {/* cof - rct */}
        {form?.tooch_number.match(new RegExp(`\\b${params}L-cof-rct\\b`)) && `${params} : L cof - rct ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}B-cof-rct\\b`)) && `${params} : B cof - rct ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}P-cof-rct\\b`)) && `${params} : P cof - rct ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}M-cof-rct\\b`)) && `${params} : M cof - rct ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}D-cof-rct\\b`)) && `${params} : D cof - rct ; `}
        {form?.tooch_number.match(new RegExp(`\\b${params}O-cof-rct\\b`)) && `${params} : O cof - rct ; `}     
        {/* fmc */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-fmc(?!-rct)\\b`)) && `${params} : fmc ; `}   
        {/* fmc - rct */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-fmc-rct\\b`)) && `${params} : fmc-rct ; `}   
        {/* poc */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-poc(?!-rct)\\b`)) && `${params} : poc ; `}    
        {/* poc - rct */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-poc-rct\\b`)) && `${params} : poc-rct ; `}
        {/* rrx */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-rrx\\b`)) && `${params} : rrx ; `}
        {/* mis */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-mis\\b`)) && `${params} : mis ; `}  
        {/* ipx - poc */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-ipx-poc\\b`)) && `${params} : ipx-poc ; `}    
        {/* meb */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-meb\\b`)) && `${params} : meb ; `}    
        {/* mis - pon - meb */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-miss-pon-meb\\b`)) && `${params} : mis-pon-meb ; `} 
        {/* pob */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-pob\\b`)) && `${params} : pob ; `}    
        {/* mis - pon - pob */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-miss-pon-pob\\b`)) && `${params} : miss-pon-pob ; `}
        {/* mis - prd - acr */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-mis-prd-acr\\b`)) && `${params} : mis-prd-acr ; `}
        {/* mis - fld - acr */}
        {form?.tooch_number.match(new RegExp(`\\b${params}-mis-fld-acr\\b`)) && `${params} : mis-fld-acr ; `} 
    </>
    );
  };

  // const [form, setForm] = useState({
  //   // Biodata
  //   first_name: "Dwiki",
  //   last_name: "Wantara",
  //   gender: "Laki-Laki",
  //   date_birth: "1998-04-17",
  //   nic_number: "367107170498",
  //   // Odontogram
  //   id_patient: "b10fa80e-2668-49f6-8914-9a3c24d9b292",
  //   id_doctor: "f5115cda-b42c-4aa7-8058-3f7f2d2a939c",
  //   docter_name:"Drg ganesha",
  //   date_check: "2024-01-24",
  //   tooch_number: "84P-cof 18O-amf 17-rct 16-nvt 15-cfr 14M-car 13-rrx 12-mis 11M-fis 51-rct 53-nvt 85-nvt 71-nvt 74-nvt 75-nvt 23-meb-24-miss-pon-meb-25-meb 48-non 47-une 46-pre 42-ano 45O-car 44-poc",
  //   qty_photo: "5",
  //   photo_type: "digital",
  //   qty_rontgen_photo: "10",
  //   rontgen_photo_type: "pa",
  //   occlusion: "normal_bite",
  //   torus_palatinus: "kecil",
  //   torus_mandibularis: "sisi_kanan",
  //   palatum: "dalam",
  //   diastema: "ada",
  //   diastema_note: "21 shape",
  //   anomaly: "ada",
  //   anomaly_note: "21 peg",
  //   other: "selalu gosok gigi",
  //   decayed: "5",
  //   missing: "11",
  //   filled: "12"
  // });

  const [form, setForm] = useState({
    // Biodata
    first_name: "",
    last_name: "",
    gender: "",
    date_birth: "",
    nic_number: "",
    // Odontogram
    id_patient: "",
    id_doctor: "",
    docter_name:"",
    date_check: "",
    tooch_number: "",
    qty_photo: "",
    photo_type: "",
    qty_rontgen_photo: "",
    rontgen_photo_type: "",
    occlusion: "",
    torus_palatinus: "",
    torus_mandibularis: "",
    palatum: "",
    diastema: "",
    diastema_note: "",
    anomaly: "",
    anomaly_note: "",
    other: "",
    decayed: "",
    missing: "",
    filled: "",
    doctor_name: ""
  });

  // console.log(form)

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };
  
  const fetchDataRef = useRef();
  const fetchData = async () => {
    try {
      setLoading(true);
      const [odontogramResponse, biodataRecord] = await axios.all([
        API.get(`emr-service/odontogram-news?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_patient=${id}`, fetchParams),
        API.get(`transaction-service/patients/${id}`, fetchParams)
      ]);
      if (odontogramResponse.status === 200 && biodataRecord.status === 200) {
        setGetDataOdontogram(odontogramResponse.data.data[0]);
        setGetDataBiodata(biodataRecord.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  };

  useEffect(() => {
    fetchDataRef.current = fetchData;
    // Panggil fetchData saat komponen pertama kali dimuat
    fetchDataRef.current();
  }, []);

  useEffect(() => {
    setForm({
      ...form, 
    id_patient: `${getDataOdontogram?.id_patient}` === null || `${getDataOdontogram?.id_patient}` === "null" ? "" : `${getDataOdontogram?.id_patient}`,
    id_doctor: `${getDataOdontogram?.id_doctor}` === null || `${getDataOdontogram?.id_doctor}` === "null" ? "" : `${getDataOdontogram?.id_doctor}`,
    docter_name: `${getDataOdontogram?.docter_name}` === null || `${getDataOdontogram?.docter_name}` === "null" ? "" : `${getDataOdontogram?.docter_name}`,
    date_check: `${getDataOdontogram?.date_check}` === null || `${getDataOdontogram?.date_check}` === "null" ? "" : `${getDataOdontogram?.date_check}`,
    tooch_number: `${getDataOdontogram?.tooch_number}` === null || `${getDataOdontogram?.tooch_number}` === "null" ? "" : `${getDataOdontogram?.tooch_number}`,
    qty_photo: `${getDataOdontogram?.qty_photo}` === null || `${getDataOdontogram?.qty_photo}` === "null" ? "" : `${getDataOdontogram?.qty_photo}`,
    photo_type: `${getDataOdontogram?.photo_type}` === null || `${getDataOdontogram?.photo_type}` === "null" ? "" : `${getDataOdontogram?.photo_type}`,
    qty_rontgen_photo: `${getDataOdontogram?.qty_rontgen_photo}` === null || `${getDataOdontogram?.qty_rontgen_photo}` === "null" ? "" : `${getDataOdontogram?.qty_rontgen_photo}`,
    rontgen_photo_type: `${getDataOdontogram?.rontgen_photo_type}` === null || `${getDataOdontogram?.rontgen_photo_type}` === "null" ? "" : `${getDataOdontogram?.rontgen_photo_type}`,
    occlusion: `${getDataOdontogram?.occlusion}` === null || `${getDataOdontogram?.occlusion}` === "null" ? "" : `${getDataOdontogram?.occlusion}`,
    torus_palatinus: `${getDataOdontogram?.torus_palatinus}` === null || `${getDataOdontogram?.torus_palatinus}` === "null" ? "" : `${getDataOdontogram?.torus_palatinus}`,
    torus_mandibularis: `${getDataOdontogram?.torus_mandibularis}` === null || `${getDataOdontogram?.torus_mandibularis}` === "null" ? "" : `${getDataOdontogram?.torus_mandibularis}`,
    palatum: `${getDataOdontogram?.palatum}` === null || `${getDataOdontogram?.palatum}` === "null" ? "" : `${getDataOdontogram?.palatum}`,
    diastema: `${getDataOdontogram?.diastema}` === null || `${getDataOdontogram?.diastema}` === "null" ? "" : `${getDataOdontogram?.diastema}`,
    diastema_note: `${getDataOdontogram?.diastema_note}` === null || `${getDataOdontogram?.diastema_note}` === "null" ? "" : `${getDataOdontogram?.diastema_note}`,
    anomaly: `${getDataOdontogram?.anomaly}` === null || `${getDataOdontogram?.anomaly}` === "null" ? "" : `${getDataOdontogram?.anomaly}`,
    anomaly_note: `${getDataOdontogram?.anomaly_note}` === null || `${getDataOdontogram?.anomaly_note}` === "null" ? "" : `${getDataOdontogram?.anomaly_note}`,
    other: `${getDataOdontogram?.other}` === null || `${getDataOdontogram?.other}` === "null" ? "" : `${getDataOdontogram?.other}`,
    decayed: `${getDataOdontogram?.decayed}` === null || `${getDataOdontogram?.decayed}` === "null" ? "" : `${getDataOdontogram?.decayed}`,
    missing: `${getDataOdontogram?.missing}` === null || `${getDataOdontogram?.missing}` === "null" ? "" : `${getDataOdontogram?.missing}`,
    filled: `${getDataOdontogram?.filled}` === null || `${getDataOdontogram?.filled}` === "null" ? "" : `${getDataOdontogram?.filled}`,
    doctor_name: `${getDataOdontogram?.doctor_name}` === null || `${getDataOdontogram?.doctor_name}` === "null" ? "" : `${getDataOdontogram?.doctor_name}`
    });
  }, [getDataOdontogram])

  useEffect(() => {
    setForm({
      ...form, 
      first_name: `${getDataBiodata?.first_name}` === null || `${getDataBiodata?.first_name}` === "null" ? "" : `${getDataBiodata?.first_name}`,
      last_name: `${getDataBiodata?.last_name}` === null || `${getDataBiodata?.last_name}` === "null" ? "" : `${getDataBiodata?.last_name}`,
      gender: `${getDataBiodata?.gender}` === null || `${getDataBiodata?.gender}` === "null" ? "" : `${getDataBiodata?.gender}`,
      date_birth: `${getDataBiodata?.date_birth}` === null || `${getDataBiodata?.date_birth}` === "null" ? "" : `${getDataBiodata?.date_birth}`,
      nic_number: `${getDataBiodata?.nic_number}` === null || `${getDataBiodata?.nic_number}` === "null" ? "" : `${getDataBiodata?.nic_number}`,
    });
  }, [getDataBiodata])
 
  const handlePrint = useReactToPrint({
    content: () => myRef.current
  });

  return (
    <Row style={{minHeight:"100vh"}}>
     {loading && <LoaderHome />}
      <Col xl='12' sm='12'>
        {/* TOMBOL PRINT */}
        <div className="mb-2" style={{display:"flex", justifyContent:"end"}}>
          <button onClick={() => handlePrint()} style={{display:"flex", justifyContent:"",alignItems:"center", border:"none", backgroundColor:"#00409F",borderRadius:"3px", fontSize:"15px", padding:"5px 15px", color:"white"}}><div style={{display:"", height:"", alignItems:"center", marginRight:"5px"}}><FaPrint /></div>Print</button>
        </div>
        <form ref={myRef} style={{ width: "100%", zoom:"85%", paddingLeft:"10px" }}>
          <div style={{ padding: "10px 30px", backgroundColor: "white", display: "flex" }}>
            <div style={{padding:"4px 0px", borderBottom:"1px solid black"}}>
              <div style={{ display: "", border:"1px solid black", padding:"5px 50px" }}>
                KOP SATKER
              </div>
            </div>
          </div>
          
          <div style={{ padding: "0px 30px", backgroundColor: "white", display: "flex", justifyContent:"center" }}>
            <div style={{padding:"4px 0px"}}>
              FORMULIR PEMERIKSAAN ODONTOGRAM (DEWASA)
            </div>
          </div>

          <div style={{ padding: "0px 30px 0px 30px", backgroundColor: "white", display: "flex", justifyContent:"center" }}>
            <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
              <div style={{ display: "flex", height:"22px" }}>
                <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center",fontWeight:""}}>
                  NAMA LENGKAP
                </div>
                <div style={{ flex: "60%", display:"flex"  }}>
                  <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                  <div style={{display:"flex", width:"100%"}}>
                    <div>
                      {form?.first_name.toLocaleUpperCase()} {form?.last_name.toLocaleUpperCase()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{flex:"50%", padding: "0px 30px 0px 0px" }}>
              <div style={{ display: "flex", height:"22px" }}>
                <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center",fontWeight:""}}>
                  JENIS KELAMIN
                </div>
                <div style={{ flex: "60%", display:"flex"  }}>
                  <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                  <div style={{display:"flex", width:"100%"}}>
                    <>{form.gender.toUpperCase()}</>
                  </div>

                  {/* <div style={{display:"flex", width:"100%"}}>
                    {form.gender === "Laki-Laki" && 
                      <div>
                        L / <del>P</del>
                      </div>
                    }

                    {form.gender === "Wanita" && 
                      <div>
                        <del>L</del> / P  
                      </div>
                    }

                    {form.gender === "" && 
                      <div>
                        L / P  
                      </div>
                    }

                  </div> */}
                </div>
              </div>
            </div>

          </div>

          <div style={{ padding: "0px 30px 0px 30px", backgroundColor: "white", display: "flex", justifyContent:"center" }}>
            <div style={{ flex: "50%", padding: "0px 30px 0px 10px" }}>
              <div style={{ display: "flex", height:"22px" }}>
                <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center",fontWeight:""}}>
                  NIK/No.KTP
                </div>
                <div style={{ flex: "60%", display:"flex"  }}>
                  <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                  <div style={{display:"flex", width:"100%"}}>
                    <div>
                      {form?.nic_number === "" ? "" : form?.nic_number}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{flex:"50%", padding: "0px 30px 0px 0px" }}>
              <div style={{ display: "flex", height:"22px" }}>
                <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center",fontWeight:""}}>
                  TTL
                </div>
                <div style={{ flex: "60%", display: "flex" }}>
                  <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                  <div style={{display:"flex", width:"100%"}}>
                    <div>
                      {form?.date_birth === "" ? "" : form?.date_birth}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div style={{ padding: "0px 30px", backgroundColor: "white", display: "flex" }}>
            <div style={{padding:"4px 0px", borderBottom:"3px solid black", width:"100%"}}>
              <div style={{ display: "", padding:"5px 50px" }}>
              </div>
            </div>
          </div>

          <Col xl='12' sm='12' style={{ padding: "10px 30px 0px 30px", backgroundColor: "white"}}> 
            <div>
              <div style={{display:"block", height:"100%", overflowY:"auto",overflowX:"auto"}}>
                <div >
                <table className="table is-bordered" >
                <tbody>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>11 [51]</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(11)}
                      {viewToochDouble(51)}
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(61)}
                      {viewToochDouble(21)}
                    </td>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>[61] 21</td>
                  </tr>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>12 [52]</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(12)}
                      {viewToochDouble(52)}
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(62)}
                      {viewToochDouble(22)}   
                    </td>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>[62] 22</td>
                  </tr>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>13 [53]</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(13)}
                      {viewToochDouble(53)}  
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(63)}
                      {viewToochDouble(23)}      
                    </td>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>[63] 23</td>
                  </tr>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>14 [54]</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(14)}
                      {viewToochDouble(54)}
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(64)}
                      {viewToochDouble(24)}</td>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>[64] 24</td>
                  </tr>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>15 [55]</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(15)}
                      {viewToochDouble(55)}      
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(65)}
                      {viewToochDouble(25)}
                    </td>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>[65] 25</td>
                  </tr>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>16</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochSingle(16)} 
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochSingle(26)}
                    </td>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>26</td>
                  </tr>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>17</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochSingle(17)}
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochSingle(27)}
                    </td>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>27</td>
                  </tr>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>18</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochSingle(18)}
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochSingle(28)}
                    </td>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>28</td>
                  </tr> 
                </tbody>
              </table> 
              </div>
            </div>
            </div>
          </Col>  
          
          {formchoiceGigi?.choiceGigi === "per_gigi" ?
            <Col xl='12' sm='12' style={{ fontSize:"12px", backgroundColor: "white"}} >

            {/* Gigi Atas Dewasa */}
            <div style={{ display: "flex", height:"10vh", alignItems:"start"}}> 
            <RahangAtasKananDewasa form={form} setForm={setForm} />
            <RahangAtasKiriDewasa form={form} setForm={setForm} />
            </div>

            {/* Gigi Atas Anak */}
            <div style={{display:"flex", height:"10vh", alignItems:"start"}}> 
            <RahangAtasKananAnak form={form} setForm={setForm} />
            <RahangAtasKiriAnak form={form} setForm={setForm} />
            </div>

              {/* Gigi Bawah Anak */}
            <div  style={{display:"flex", height:"10vh", alignItems:"start"}}> 
            <RahangBawahKananAnak form={form} setForm={setForm} />
              <RahangBawahKiriAnak form={form} setForm={setForm} />
            </div>

              {/* Gigi Bawah Dewasa */}
            <div style={{display:"flex", height:"10vh", alignItems:"start"}}> 
            <RahangBawahKananDewasa form={form} setForm={setForm} />
              <RahangBawahKiriDewasa form={form} setForm={setForm} />
            </div>

            </Col> 
              :
            <></>  
            }
                  
          <Col xl='12' sm='12' style={{ padding: "0px 30px 0px 30px", backgroundColor: "white"}}> 
            <div>
            <div style={{display:"block", height:"100%", overflowY:"auto",overflowX:"auto"}}>
              <div>
              <table className="table is-bordered" >
                  <tbody>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>48</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochSingle(48)} 
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochSingle(38)}
                    </td>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>38</td>
                  </tr>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>47</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochSingle(47)}
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochSingle(37)}
                    </td>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>37</td>
                  </tr>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>46</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochSingle(46)}
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochSingle(36)}
                    </td>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>36</td>
                  </tr>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>45 [85]</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(45)}
                      {viewToochDouble(85)}    
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(75)}
                      {viewToochDouble(35)}    
                    </td>
                    <td className="element"  style={{  minWidth: "20px", maxWidth: "20px", wordWrap: "break-word", border: "1px solid black" }}>[75] 35</td>
                  </tr>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>44 [84]</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(44)}
                      {viewToochDouble(84)}    
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(74)}
                      {viewToochDouble(34)}    
                    </td>
                    <td className="element"  style={{  minWidth: "20px", maxWidth: "20px", wordWrap: "break-word", border: "1px solid black" }}>[74] 34</td>
                  </tr>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>43 [83]</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(43)}
                      {viewToochDouble(83)}    
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(33)}
                      {viewToochDouble(73)}    
                    </td>
                    <td className="element"  style={{  minWidth: "20px", maxWidth: "20px", wordWrap: "break-word", border: "1px solid black" }}>[33] 73</td>
                  </tr>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>42 [82]</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(42)}
                      {viewToochDouble(82)}    
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(32)}
                      {viewToochDouble(72)}    
                    </td>
                    <td className="element"  style={{  minWidth: "20px", maxWidth: "20px", wordWrap: "break-word", border: "1px solid black" }}>[32] 72</td>
                  </tr>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td className="element"  style={{  minWidth:"50px",maxWidth:"50px", wordWrap: "break-word", border:"1px solid black" }}>41 [81]</td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(41)}
                      {viewToochDouble(81)}    
                    </td>
                    <td className="element"  style={{  minWidth:"150px",maxWidth:"150px", wordWrap: "break-word", border: "1px solid black", textAlign: "left" }}>
                      {viewToochDouble(31)}
                      {viewToochDouble(71)}    
                    </td>
                    <td className="element"  style={{  minWidth: "20px", maxWidth: "20px", wordWrap: "break-word", border: "1px solid black" }}>[31] 71</td>
                  </tr>
                    
                </tbody>
              </table>
                  
                </div>
              </div>
            </div>
          </Col>
          
          <div style={{ padding: "0px 30px 0px 30px", backgroundColor: "white"}}>
            <div>
              <div style={{ display: "flex", height:"12px" }}>
                <div style={{flex:"20%", height:"100%", display:"flex", alignItems:"center",fontWeight:""}}>
                  Occlusi
                </div>
                <div style={{ flex: "80%", display:"flex", alignItems:"center"  }}>
                  <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", paddingBottom:"5px" }}>:</p>
                  <div style={{ display: "flex", width: "100%" }}>
                    
                    {form.occlusion === "normal_bite" && 
                      <div>
                        Normal Bite / <del>Cross Bite</del> / <del>Steep Bite</del>  
                      </div>
                    }

                    {form.occlusion === "cross_bite" && 
                      <div>
                         <del>Normal Bite</del> / Cross Bite / <del>Steep Bite</del>   
                      </div>
                    }

                    {form.occlusion === "steep_bite" && 
                      <div>
                        <del>Normal Bite</del> / <del>Cross Bite</del> / Steep Bite
                      </div>
                    }

                    {form.occlusion === "" && 
                      <div>
                         Normal Bite / Cross Bite / Steep Bite   
                      </div>
                    }
                   
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ padding: "10px 30px 0px 30px", backgroundColor: "white", marginTop:"1px"}}>
            <div>
              <div style={{ display: "flex", height:"12px" }}>
                <div style={{flex:"20%", height:"100%", display:"flex", alignItems:"center",fontWeight:""}}>
                  Torus Platinus
                </div>
                <div style={{ flex: "80%", display:"flex", alignItems:"center"  }}>
                  <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", paddingBottom:"5px" }}>:</p>
                  <div style={{ display: "flex", width: "100%" }}>  
                    {form.torus_palatinus === "tidak_ada" && 
                      <div>
                        Tidak Ada / <del>Kecil</del> / <del>Sedang</del> / <del>Besar</del> / <del>Multiple</del> 
                      </div>
                    }

                    {form.torus_palatinus === "kecil" && 
                      <div>
                        <del>Tidak Ada</del> / Kecil / <del>Sedang</del> / <del>Besar</del> / <del>Multiple</del> 
                      </div>
                    }

                    {form.torus_palatinus === "sedang" && 
                      <div>
                        <del>Tidak Ada</del> / <del>Kecil</del> / Sedang / <del>Besar</del> / <del>Multiple</del> 
                      </div>
                    }

                    {form.torus_palatinus === "besar" && 
                      <div>
                        <del>Tidak Ada</del> / <del>Kecil</del> / <del>Sedang</del> / Besar / <del>Multiple</del> 
                      </div>
                    }

                    {form.torus_palatinus === "multiple" && 
                      <div>
                        <del>Tidak Ada</del> / <del>Kecil</del> / <del>Sedang</del> / <del>Besar</del> / Multiple 
                      </div>
                    }

                    {form.torus_palatinus === "" && 
                      <div>
                        Tidak Ada / Kecil / Sedang / Besar / Multiple
                      </div>
                    }
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        <div style={{ padding: "10px 30px 0px 30px", backgroundColor: "white", marginTop:"1px"}}>
            <div>
              <div style={{ display: "flex", height:"12px" }}>
                <div style={{flex:"20%", height:"100%", display:"flex", alignItems:"center",fontWeight:""}}>
                  Torus Mandibularis
                </div>
                <div style={{ flex: "80%", display:"flex", alignItems:"center"  }}>
                  <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", paddingBottom:"5px" }}>:</p>
                  <div style={{ display: "flex", width: "100%" }}>
                    {form.torus_mandibularis === "tidak_ada" && 
                      <div>
                        Tidak Ada / <del>sisi kiri</del> / <del>sisa kanan</del> / <del>kedua sisi</del>  
                      </div>
                    }

                    {form.torus_mandibularis === "sisi_kiri" && 
                      <div>
                        <del>Tidak Ada</del> / sisi kiri / <del>sisa kanan</del> / <del>kedua sisi</del>  
                      </div>
                    }

                    {form.torus_mandibularis === "sisi_kanan" && 
                      <div>
                        <del>Tidak Ada</del> / <del>sisi kiri</del> / sisa kanan / <del>kedua sisi</del>  
                      </div>
                    }

                    {form.torus_mandibularis === "kedua_sisi" && 
                      <div>
                        <del>Tidak Ada</del> / <del>sisi kiri</del> / <del>sisa kanan</del> / kedua sisi  
                      </div>
                    }

                    {form.torus_mandibularis === "" && 
                      <div>
                        Tidak Ada / sisi kiri / sisa kanan / kedua sisi
                      </div>
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ padding: "10px 30px 0px 30px", backgroundColor: "white", marginTop:"1px"}}>
            <div>
              <div style={{ display: "flex", height:"12px" }}>
                <div style={{flex:"20%", height:"100%", display:"flex", alignItems:"center",fontWeight:""}}>
                  Palatum
                </div>
                <div style={{ flex: "80%", display:"flex", alignItems:"center"  }}>
                  <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", paddingBottom:"5px" }}>:</p>
                  <div style={{ display: "flex", width: "100%" }}>
                    {form.palatum === "dalam" && 
                      <div>
                        Dalam / <del>Sedang</del> / <del>Rendah</del> 
                      </div>
                    }

                    {form.palatum === "Sedang" && 
                      <div>
                        <del>Dalam</del> / Sedang / <del>Rendah</del> 
                      </div>
                    }

                    {form.palatum === "rendah" && 
                      <div>
                        <del>Dalam</del> / <del>Sedang</del> / Rendah 
                      </div>
                    }

                    {form.palatum === "" && 
                      <div>
                        Dalam / Sedang / Rendah
                      </div>
                    }
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ padding: "10px 30px 0px 30px", backgroundColor: "white", marginTop:"1px"}}>
            <div>
              <div style={{ display: "flex", height:"12px" }}>
                <div style={{flex:"20%", height:"100%", display:"flex", alignItems:"center",fontWeight:""}}>
                  Diastema
                </div>
                <div style={{flex:"80%",display:"flex", alignItems: "center"}}>
                  <p className="" style={{ marginRight: "10px", height: "100%", display: "flex", paddingBottom: "5px" }}>:</p>
                  <div style={{ display: "flex" }}>
                    
                  {form.diastema === "tidak_ada" && 
                    <>
                      Tidak Ada/ <del>Ada</del>:<em style={{}}>(dijelaskan dimana dan berapa lebarnya)</em>&nbsp;<div style={{ display: "flex" }}><input style={{ width: "150px", height:"2vh", fontSize:"12px", border:"none", borderBottom:"1px dotted black" }} value={form?.diastema_note}></input></div>
                    </>
                  }
                    
                  {form.diastema === "ada" && 
                    <>
                      <del>Tidak Ada</del>/ Ada:<em style={{}}>(dijelaskan dimana dan berapa lebarnya)</em>&nbsp;<div style={{ display: "flex" }}><input style={{ width: "150px", height:"2vh", fontSize:"12px", border:"none", borderBottom:"1px dotted black" }} value={form?.diastema_note}></input></div>
                    </>
                  }
                    
                  {form.diastema === "" && 
                    <>
                      Tidak Ada/ Ada:<em style={{}}>(dijelaskan dimana dan berapa lebarnya)</em>&nbsp;<div style={{ display: "flex" }}><input style={{ width: "150px", height:"2vh", fontSize:"12px", border:"none", borderBottom:"1px dotted black" }} value={form?.diastema_note}></input></div>
                    </>
                  }
                  
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ padding: "10px 30px 0px 30px", backgroundColor: "white", marginTop:"3px"}}>
            <div>
              <div style={{ display: "flex", height:"12px" }}>
                <div style={{flex:"20%", height:"100%", display:"flex", alignItems:"center",fontWeight:""}}>
                  Gigi Anomali
                </div>
                <div style={{flex:"80%",display:"flex", alignItems: "center"}}>
                  <p className="" style={{ marginRight: "10px", height: "100%", display: "flex", paddingBottom: "5px" }}>:</p>
                  <div style={{ display: "flex" }}>
                  {form.anomaly === "tidak_ada" && 
                    <>
                      Tidak Ada/ <del>Ada</del>:<em style={{}}>(dijelaskan gigi yang mana, dan bentuknya)</em>&nbsp;<div style={{ display: "flex" }}><input style={{ width: "132px", height:"2vh", fontSize:"12px", border:"none", borderBottom:"1px dotted black" }} value={form?.anomaly_note}></input></div>
                    </>
                  }
                    
                  {form.anomaly === "ada" && 
                    <>
                      <del>Tidak Ada</del>/ Ada:<em style={{}}>(dijelaskan gigi yang mana, dan bentuknya)</em>&nbsp;<div style={{ display: "flex" }}><input style={{ width: "132px", height:"2vh", fontSize:"12px", border:"none", borderBottom:"1px dotted black" }} value={form?.anomaly_note}></input></div>
                    </>
                  }
                    
                  {form.anomaly === "" && 
                    <>
                      Tidak Ada/ Ada: <em style={{}}>(dijelaskan gigi yang mana, dan bentuknya)</em>&nbsp;<div style={{ display: "flex" }}><input style={{ width: "132px", height:"2vh", fontSize:"12px", border:"none", borderBottom:"1px dotted black" }}></input></div>
                    </>
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ padding: "10px 30px 0px 30px", backgroundColor: "white", marginTop:"1px"}}>
            <div>
              <div style={{ display: "flex", height:"12px" }}>
                <div style={{flex:"20%", height:"100%", display:"flex", alignItems:"center",fontWeight:""}}>
                  Lain-lain
                </div>
                <div style={{flex:"80%",display:"flex", alignItems: "center"}}>
                  <p className="" style={{ marginRight: "10px", height: "100%", display: "flex", paddingBottom: "5px" }}>:</p>
                  <div style={{display:"flex"}}>
                    <em style={{}}>(hal-hal yang tidak tercakup diatas)</em>&nbsp;<div style={{ display: "flex" }}>
                      &nbsp;<div style={{ display: "flex" }}><input style={{ width: "295px", height: "2vh", fontSize: "12px", border: "none", borderBottom: "1px dotted black" }} value={form?.other}></input></div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ padding: "10px 30px 0px 30px", backgroundColor: "white"}}>
            <div>
              <div style={{ display: "flex", height:"12px"}}>
                <div style={{display:"flex"}}>
                  D :&nbsp;<div style={{ display: "flex" }}><input style={{ width: "20px", height: "2vh", fontSize: "12px", border: "none", borderBottom: "1px dotted black", display: "flex", justifyContent: "center" }} value={form?.decayed}></input></div>
                  &nbsp;M :&nbsp;<div style={{ display: "flex" }}><input style={{ width: "20px", height: "2vh", fontSize: "12px", border: "none", borderBottom: "1px dotted black", display: "flex", justifyContent: "center" }} value={form?.missing}></input></div>
                  &nbsp;F :&nbsp;<div style={{ display: "flex" }}><input style={{ width: "20px", height: "2vh", fontSize: "12px", border: "none", borderBottom: "1px dotted black", display: "flex", justifyContent: "center" }} value={form?.filled}></input></div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ padding: "10px 30px 0px 30px", backgroundColor: "white", marginTop:"1px"}}>
            <div>
              <div style={{ display: "flex", height:"12px" }}>
                <div style={{ display: "flex", alignItems: "center", fontWeight: "" }}>
                    <div style={{display:"flex"}}>
                      Jumlah photo yang diambil&nbsp;<div style={{ display: "flex" }}><input style={{ width: "120px", height: "2vh", fontSize: "12px", border: "none", borderBottom: "1px dotted black", display: "flex", justifyContent: "center", textAlign:"center" }} value={form?.qty_photo}></input></div>
                      (
                      {form.photo_type === "digital" && 
                        <>
                          digital/<del>intraoral</del>
                        </>
                      }
                      {form.photo_type === "intraoral" && 
                        <>
                          <del>digital</del>/intraoral
                        </>
                      }
                       {form.photo_type === "" && 
                        <>
                          digital/intraoral
                        </>
                      }
                      )*
                    </div>
              
                </div>
              </div>
            </div>
          </div>

          <div style={{ padding: "10px 30px 0px 30px", backgroundColor: "white", marginTop:"1px"}}>
            <div>
              <div style={{ display: "flex", height:"12px" }}>
                <div style={{ display: "flex", alignItems: "center", fontWeight: "" }}>
                Jumlah rontgen yang diambil&nbsp;<div style={{ display: "flex" }}><input style={{ width: "120px", height: "2vh", fontSize: "12px", border: "none", borderBottom: "1px dotted black", display: "flex", justifyContent: "center", textAlign:"center" }} value={form?.qty_rontgen_photo}></input></div>
                      (
                      {form.rontgen_photo_type === "dental" && 
                        <>
                          Dental/<del>PA</del>/<del>OPG</del>/<del>Ceph</del>
                        </>
                      }
                      {form.rontgen_photo_type === "pa" && 
                        <>
                          <del>Dental</del>/PA/<del>OPG</del>/<del>Ceph</del>
                        </>
                      }
                       {form.rontgen_photo_type === "opg" && 
                        <>
                          <del>Dental</del>/<del>PA</del>/OPG/<del>Ceph</del>
                        </>
                      }
                      {form.rontgen_photo_type === "ceph" && 
                            <>
                          <del>Dental</del>/<del>PA</del>/<del>OPG</del>/Ceph
                        </>
                      }
                      {form.rontgen_photo_type === "" && 
                        <>
                          Dental/PA/OPG/Ceph
                        </>
                      }
                      )*
                </div>
              </div>
            </div>
          </div>

          <div style={{ padding: "10px 30px 0px 30px", backgroundColor: "white"}}>
            <div style={{border: "1px solid black" }}>
              <div style={{ display: "flex", height:"42px"}}>
                <div style={{display:"flex", alignItems:"center",fontWeight:"", width:"33%",justifyContent:"center"}}>
                  DIPERIKSA OLEH:
                </div>
                <div style={{display:"flex", alignItems:"center",fontWeight:"", width:"33%",justifyContent:"center"}}>
                  TANGGAL PEMERIKSAAN:
                </div>
                <div style={{display:"flex", alignItems:"center",fontWeight:"", width:"33%",justifyContent:"center"}}>
                  TANDA TANGAN PEMERIKSA:
                </div>
              </div>

              <div style={{ display: "flex", height:"42px" }}>
                <div style={{display:"flex", alignItems:"center",fontWeight:"", width:"33%",justifyContent:"center"}}>
                  <>
                    Drg<div style={{ display: "flex" }}><input value={form?.doctor_name.includes("drg") || form?.doctor_name.includes("Drg")? form?.doctor_name.split(' ')[1] : form?.doctor_name} style={{ width: "120px", height:"2vh", fontSize:"12px", border:"none", borderBottom:"1px dotted black", textAlign:"center" }}></input></div>
                  </>
                </div>
                <div style={{display:"flex", alignItems:"center",fontWeight:"", width:"33%",justifyContent:"center"}}>
                  <div style={{ display: "flex" }}><input value={form.date_check.split('-')[2]} style={{ width: "30px", height: "2vh", fontSize: "12px", border: "none", borderBottom: "1px dotted black", textAlign:"center"  }}></input></div>
                  /<div style={{ display: "flex" }}><input value={form.date_check.split('-')[1]} style={{ width: "30px", height: "2vh", fontSize: "12px", border: "none", borderBottom: "1px dotted black", textAlign:"center"  }}></input></div>
                  /<div style={{ display: "flex" }}><input value={form.date_check.split('-')[0]} style={{ width: "80px", height: "2vh", fontSize: "12px", border: "none", borderBottom: "1px dotted black", textAlign:"center" }}></input></div>
                </div>
                <div style={{display:"flex", alignItems:"center",fontWeight:"", width:"33%",justifyContent:"center"}}>
                  <div style={{ display: "flex" }}><input style={{ width: "150px", height: "2vh", fontSize: "12px", border: "none", borderBottom: "1px dotted black", textAlign: "center" }}></input></div>
                </div>
              </div>
            </div>
          </div>

        </form>
      </Col>
    </Row>
  );
}
