import React from 'react';
import ReactPaginate from 'react-paginate';

const PaginationFloor = ({ rows, page, pages, msg, changePage }) => {

  // Gaya Bersama
  const containerStyle = {
    display: "flex", 
    padding: "10px", 
    justifyContent: "space-between"
  };

  const infoStyle = {
    fontSize: "12px",
  };

  const paginationNavStyle = {
    fontSize: "12px", 
    display: "flex", 
    justifyContent: "flex-end"
  };

  // Fungsi untuk mengatur nomor halaman (page)
  const getPageInfo = () => {
    return rows ? `Page: ${page} of ${pages}` : 'Page: 0 of 0';
  };

  return (
    <div style={containerStyle}>
      {/* Info Panel */}
      <div>
        <div style={infoStyle}>Total Rows: {rows}</div>
        <div style={infoStyle}>{getPageInfo()}</div>
        <p className="has-text-centered has-text-danger">{msg}</p>
      </div>

      {/* Pagination */}
      <div style={paginationNavStyle}>
        <nav className="pagination is-centered" role="navigation" aria-label="pagination">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={Math.min(10, pages)}  // Membatasi jumlah halaman menjadi maksimal 10
            onPageChange={changePage}
            containerClassName={"pagination-list"}
            pageLinkClassName={"pagination-link"}
            previousLinkClassName={"pagination-previous"}
            nextLinkClassName={"pagination-next"}
            activeLinkClassName={"pagination-link is-current"}
            disabledLinkClassName={"pagination-link is-disabled"}
          />
        </nav>
      </div>
    </div>
  );
};

export default PaginationFloor;
