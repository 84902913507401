import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes, FaMedkit} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import LoaderHome from "../../Loader/LoaderHome"
import swal from "sweetalert";
import ModalAddPhysicalCheck from "../ModalPhysicalCheck/ModalAddAfter"
import ModalUpdatePhysicalCheck from "../ModalPhysicalCheck/ModalUpdateAfter"

export default function ModalRoleAdd(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getDataDokter, setGetDataDokter] = useState([]);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);
  const [getDataPhysicalCheck, setGetDataPhysicalCheck] = useState(null);
  const [getDataPatientDataName, setGetDataPatientDataName] = useState(null);
  const [getDataMedAttention, setGetDataMedAttention] = useState([]);
  const [modalAddPhysicalCheck, setModalPhysicalCheck] = useState(false);
  const [idPatientPhysicalCheckPatientAfter, setIdPatientPhysicalCheckPatientAfter] = useState();
  const [idAppoinmentPhysicalCheckAfter, setIdAppointmentPhysicalCheckAfter] = useState();
  const [modalUpdatePhysicalCheckAfter, setModalPhysicalCheckAfterUpdate] = useState(false);

  const [idPhysicalCheckUpdate, setIdPhysicalCheckUpdate] = useState(false);
  const [idAppointmentPhysicalCheckPatientupdate, setIdAppointmentPhysicalCheckPatientupdate] = useState(false);
  const [modalPhysicalCheckUpdate, setModalPhysicalCheckUpdate] = useState(false);

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [form, setForm] = useState({
    username: "",
    date_check: "",
    height: "",
    weight: "",
    blood_type: "",
    body_temperature: "",
    blood_pressure: "",
    med_allergy: "",
    med_consuming: "",
    surgery_history: "",
    cardiac_disease: "",
    hemophillia: "",
    diabetes: "",
    hepatitis: "",
    gastritis: "",
    special_condition: ""
  });
  console.log(props)

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/emr-service/phy-checks/patient/${props.idAppointmentPhysicalCheckPatient}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataPhysicalCheck(response.data.data.length === 0 ? null : response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    GetResponseData()
  }, [])

  useEffect(() => {
    setForm({
      ...form, 
      height: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.height}`,
      weight: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.weight}`,
      blood_type: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.blood_type}`,
      body_temperature: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.body_temperature}`,
      blood_pressure: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.blood_pressure}`,
      date_check: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.date_check}`,
      med_allergy: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.med_allergy}`,
      med_consuming: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.med_consuming}`,
      surgery_history: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.surgery_history}`,
      cardiac_disease: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.cardiac_disease}`,
      hemophillia: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.hemophillia}`,
      diabetes: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.diabetes}`,
      hepatitis: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.hepatitis}`,
      gastritis: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.gastritis}`,
      special_condition: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.special_condition}`
    });
  }, [getDataPhysicalCheck])

  const viewModalPhysicalCheck = (idAppointmentPhysicalCheckPatient, idPatientMedicalRecordId) => {
    setIdAppointmentPhysicalCheckAfter(idAppointmentPhysicalCheckPatient)
    setIdPatientPhysicalCheckPatientAfter(idPatientMedicalRecordId)
    setModalPhysicalCheck(true)
  }

  const viewModalPhysicalCheckUpdate = (id, idAppointmentPhysicalCheckPatient) => {
    setIdPhysicalCheckUpdate(id)
    setIdAppointmentPhysicalCheckPatientupdate(idAppointmentPhysicalCheckPatient)
    setModalPhysicalCheckUpdate(true)
  }

  

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif",border:"none"}}>
      {loading && <LoaderHome />}
      {modalAddPhysicalCheck  && <ModalAddPhysicalCheck GetResponseData={GetResponseData} idAppoinmentPhysicalCheckAfter={idAppoinmentPhysicalCheckAfter} idPatientPhysicalCheckPatientAfter={idPatientPhysicalCheckPatientAfter} show={modalAddPhysicalCheck} onHide={() => setModalPhysicalCheck(false)} />}
      {modalPhysicalCheckUpdate  && <ModalUpdatePhysicalCheck GetResponseData={GetResponseData} idPhysicalCheckUpdate={idPhysicalCheckUpdate} idAppointmentPhysicalCheckPatientupdate={idAppointmentPhysicalCheckPatientupdate} show={modalPhysicalCheckUpdate} onHide={() => setModalPhysicalCheckUpdate(false)} />}

      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          {props?.idPatientMedicalRecordName} ||&nbsp;
          {getDataPhysicalCheck === null || getDataPhysicalCheck?.date_check === null ? "-" : getDataPhysicalCheck?.date_check.split("-")[2]}&nbsp;
          {getDataPhysicalCheck === null || getDataPhysicalCheck?.date_check === null ? "-" :
           getDataPhysicalCheck?.date_check.split("-")[1] === "01" ? "Januari" : "" ||
           getDataPhysicalCheck?.date_check.split("-")[1] === "02" ? "Februari" : "" ||
           getDataPhysicalCheck?.date_check.split("-")[1] === "03" ? "Maret" : "" ||
           getDataPhysicalCheck?.date_check.split("-")[1] === "04" ? "April" : "" ||  
           getDataPhysicalCheck?.date_check.split("-")[1] === "05" ? "Mei" : "" ||
           getDataPhysicalCheck?.date_check.split("-")[1] === "06" ? "Juni" : "" ||
           getDataPhysicalCheck?.date_check.split("-")[1] === "07" ? "Juli" : "" ||
           getDataPhysicalCheck?.date_check.split("-")[1] === "08" ? "Agustus" : "" ||
           getDataPhysicalCheck?.date_check.split("-")[1] === "09" ? "September" : "" ||
           getDataPhysicalCheck?.date_check.split("-")[1] === "10" ? "Oktober" : "" ||
           getDataPhysicalCheck?.date_check.split("-")[1] === "11" ? "November" : "" ||
           getDataPhysicalCheck?.date_check.split("-")[1] === "12" ? "Desember" : ""}&nbsp;
          {getDataPhysicalCheck === null || getDataPhysicalCheck?.date_check === null ? "-" :  getDataPhysicalCheck?.date_check.split("-")[0]}
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>

      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none" }}>
      <Form className="mt-" >
        <div className="mb-1" style={{fontSize:"15px"}}>Physical Check</div> 
        <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
          <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "#804D00"}}>
                Tinggi Badan 
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                  <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.height === null ? "" :  getDataPhysicalCheck?.height} Cm</p>
              </div>
            </div>
          </div>
          <div style={{flex:"50%", paddingRight: "30px",  }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "#804D00"}}>
                Berat Badan
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                <p style={{ fontSize:"12px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.weight === null ? "" :  getDataPhysicalCheck?.weight} Kg</p>
              </div>
            </div>
          </div>
        </div>
        
        <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
          <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "#804D00"}}>
                Golongan Darah
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                <p style={{ fontSize:"12px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.blood_type === null ? "" :  getDataPhysicalCheck?.blood_type}</p>
              </div>
            </div>
          </div>
          <div style={{flex:"50%", paddingRight: "30px",  }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "#804D00"}}>
                Suhu Badan
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                <p style={{ fontSize:"12px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.body_temperature === null ? "" :  getDataPhysicalCheck?.body_temperature}  &deg;c</p>
              </div>
            </div>
          </div>
          </div>
          
          <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
          <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "#804D00"}}>
               Tekanan Darah
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                <p style={{ fontSize:"12px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.blood_pressure === null ? "" :  getDataPhysicalCheck?.blood_pressure}</p>
              </div>
            </div>
          </div>
          <div style={{flex:"50%", paddingRight: "30px", color:"white" }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "white"}}>
                -
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}></p>
                <p style={{ fontSize:"12px",color: "", height:"100%", display:"flex", alignItems:"center" }}>-</p>
              </div>
            </div>
          </div>
          </div>
          
        <div className="mt-3 mb-1" style={{fontSize:"15px"}}>Medical Check</div> 
        <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
          <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "#804D00"}}>
                Alergi
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                <p style={{ fontSize:"12px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.med_allergy === null ? "-" :  getDataPhysicalCheck?.med_allergy}</p>
              </div>
            </div>
          </div>
          <div style={{flex:"50%", paddingRight: "30px",  }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "#804D00"}}>
                Konsumsi Obat
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                <p style={{ fontSize:"12px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.med_consuming === null ? "-" :  getDataPhysicalCheck?.med_consuming}</p>
              </div>
            </div>
          </div>
          </div>
          
          <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
          <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "#804D00"}}>
                Riwayat Operasi
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                <p style={{ fontSize:"12px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.surgery_history === null ? "-" :  getDataPhysicalCheck?.surgery_history}</p>
              </div>
            </div>
          </div>
          <div style={{flex:"50%", paddingRight: "30px",  }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "#804D00"}}>
                Penyakit Jantung
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                <p style={{ fontSize:"12px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.cardiac_disease === null ? "-" :  getDataPhysicalCheck?.cardiac_disease}</p>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
          <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "#804D00"}}>
              Hemophilia
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                <p style={{ fontSize:"12px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.hemophillia === null ? "-" :  getDataPhysicalCheck?.hemophillia}</p>
              </div>
            </div>
          </div>
          <div style={{flex:"50%", paddingRight: "30px",  }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "#804D00"}}>
              Diabetes
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                <p style={{ fontSize:"12px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.diabetes === null ? "-" :  getDataPhysicalCheck?.diabetes}</p>
              </div>
            </div>
          </div>
          </div>
          
          <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
          <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "#804D00"}}>
              Hepatitis
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                <p style={{ fontSize:"12px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.hepatitis === null ? "-" :  getDataPhysicalCheck?.hepatitis}</p>
              </div>
            </div>
          </div>
          <div style={{flex:"50%", paddingRight: "30px",  }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "#804D00"}}>
              Gastritis
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                <p style={{ fontSize:"12px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.gastritis === null ? "-" :  getDataPhysicalCheck?.gastritis}</p>
              </div>
            </div>
          </div>
        </div>
          
        <div style={{ display: "flex", backgroundColor: "white", opacity: ".9" }}>
          <div style={{flex:"50%", padding: "0px 30px 0px 10px" }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "#804D00"}}>
              Kondisi Khusus
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                <p style={{ fontSize:"11px",color: "black", height:"100%", display:"flex", alignItems:"center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.special_condition === null ? "-" :  getDataPhysicalCheck?.special_condition}</p>
              </div>
            </div>
          </div>
          <div style={{flex:"50%", paddingRight: "30px", color:"white" }}>
            <div style={{ display: "flex", height:"22px" }}>
              <div style={{flex:"40%", dheight:"100%", display:"flex", alignItems:"center", fontSize:"11px",color: "white"}}>
                -
              </div>
              <div style={{ flex: "60%", display:"flex"  }}>
                <p style={{ marginRight: "10px",height:"100%", height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px", color:"white" }}>-</p>
                <p style={{ fontSize:"11px",color: "white", height:"100%", display:"flex", alignItems:"center" }}>-</p>
              </div>
            </div>
          </div>
        </div>

        {getDataPhysicalCheck === null || getDataPhysicalCheck === "null"? 
        <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
          <div>
            <Button className="mt-4" onClick={() => {viewModalPhysicalCheck(props.idAppointmentPhysicalCheckPatient,props.idPatientMedicalRecordId)}} color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
              Check
            </Button>
          </div>
        </div>
        :
        <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
          <div>
            <Button className="mt-4" onClick={() => {viewModalPhysicalCheckUpdate(getDataPhysicalCheck?.id, props.idAppointmentPhysicalCheckPatient)}} color='warning' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px", color:"white"}}>
              Update
            </Button>
          </div>
        </div>
        }
        

      </Form>
        
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
    </Modal>
    );
}
  