import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes, FaBold, FaItalic, FaStrikethrough, FaMicrophone} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import LoaderHome from "../../Loader/LoaderHome"
import swal from "sweetalert";
import React, { useRef } from 'react';
import axios from "axios";
import { useSpeechRecognition } from 'react-speech-kit';

export default function ModalRoleAdd(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(90);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(1);
  const [idRoleDokter, setIdRoleDokter] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");
  const token = localStorage.getItem("token");
  const [getDataDokter, setGetDataDokter] = useState([]);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);
  const [getOpenAddObat, setGetOpenAddObat] = useState(false);
  const [getData, setGetData] = useState([]);
  const [getDataItem, setGetDataItem] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const firstNameInputRef = useRef(null);
  const [search,setSearch] = useState("")
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [getDataPayment, setGetDataPayment] = useState([]);

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [form, setForm] = useState({
    id_patient: "",
    id_doctor: "",
    id_appointment: "",
    subjective: "",
    objective: "",
    assessment: "",
    planning: "",
    implementation: "",
    note: ""
  });

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/transaction-service/soap-patients/${props?.idAppointmentModalSoapPatient}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataDokter = async () => {
    try {
      // e.preventDefault();
      const response = await API.get(`/user-service/users?page=${page}&limit=${limit}&ascending=${ascending}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataDokter(response.data.data)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    GetResponseData()
    GetResponseDataDokter()  
  },[])

  useEffect(() => {
    setForm({
      ...form, 
      id_doctor: getData === null? `${props?.idDoctorModalSoapPatient}` : `${getData?.id_doctor}`,
      subjective: getData === null? "" : `${getData?.subjective === null? "" : getData?.subjective}`,
      objective: getData === null? "" : `${getData?.objective === null? "" : getData?.objective}`,
      assessment: getData === null? "" : `${getData?.assessment === null? "" : getData?.assessment}`,
      planning: getData === null? "" : `${getData?.planning === null? "" : getData?.planning}`,
      implementation: getData === null? "" : `${getData?.implementation === null? "" : getData?.implementation}`,
      note: getData === null? "" : `${getData?.note === null? "" : getData?.note}`,
    });
  }, [getData])

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response =  await API.delete(`emr-service/medical-prescriptions/${id}`,fetchParams);
        if (response.data.error == false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
          
        }  
      }
    })
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
    
      // Insert data for login process
      const response = await API.post("/transaction-service/soap-patients/store", {
        id_patient: `${props?.idPatientModalSoapPatient}`,
        id_appointment: `${props?.idAppointmentModalSoapPatient}`,
        id_doctor: form?.id_doctor,
        subjective: form?.subjective,
        objective: form?.objective,
        assessment: form?.assessment,
        planning: form?.planning,
        implementation: form?.implementation,
        note: form?.note
      }, fetchParams);
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        GetResponseData()
        setLoading(false)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    } 
  };

  const handleSubmitUpdate = async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
  
      // Insert data for login process
      const response = await API.put(`/transaction-service/soap-patients/${props.idAppointmentModalSoapPatient}`, {
        id_doctor: form?.id_doctor,
        subjective: form?.subjective,
        objective: form?.objective,
        assessment: form?.assessment,
        planning: form?.planning,
        implementation: form?.implementation,
        note: form?.note,
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 3000,
          buttons: false
        });
        GetResponseData()
        setLoading(false)
      }
    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
  };

  const [isBoldActive, setIsBoldActive] = useState(false);
  const [isItalicActive, setIsItalicActive] = useState(false);
  const [isStrikeThroughActive, setIsStrikeThroughActive] = useState(false);

  const handleBoldClick = () => {
    setIsBoldActive(!isBoldActive);
  }

  const handleItalicClick = () => {
    setIsItalicActive(!isItalicActive);
  }

  const handleStrikeThroughClick = () => {
    setIsStrikeThroughActive(!isStrikeThroughActive);
  }

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const renderSubjectiveText = () => {
    let text = form.subjective;
    if (isBoldActive) {
      text = `<b>${text}</b>`;
    }
    if (isItalicActive) {
      text = `<i>${text}</i>`;
    }
    if (isStrikeThroughActive) {
      text = `<s>${text}</s>`;
    }
    return text;
  }

  // const handleChange = (e) => {
  //   const { value } = e.target;
  //   let formattedText = value;
  //   if(isBoldActive) {
  //     formattedText = `<b>${formattedText}</b>`;
  //   }
  //   if(isItalicActive) {
  //     formattedText = `<i>${formattedText}</i>`;
  //   }
  //   if(isStrikeThroughActive) {
  //     formattedText = `<s>${formattedText}</s>`;
  //   }
  //   setForm({
  //     ...form,
  //     subjective: formattedText,
  //   });
  // }

  // const [value, setValue] = useState('')
  // const { listen, stop } = useSpeechRecognition({
  //   onResult: (result) => {
  //     setValue(result)
  //   },
  //   language: 'id-ID' 
  // });
  // console.log(value)

  return (
    <Modal {...props}  aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif",border:"none"}}>
      {loading && <LoaderHome />}
      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Soap Patient || 
        </div> 
        <div style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={props.onHide} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{backgroundColor:"",borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px", border:"none" }}>
      <Form className="mt-" >
        <Col xl='12' sm='12'>

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
              Subjective (S) <span style={{color:"red"}}>*</span>
            </div>
            {/* <div style={{display:"flex", justifyContent:"start", border:"1px solid #D1D1D1", padding:"6px 5px", borderTopLeftRadius:"5px", borderTopRightRadius:"5px", borderBottom:"none"}}>
          <div className="mr-2" onClick={handleBoldClick} style={{borderRadius:"5px", backgroundColor: isBoldActive ? "lightblue" : ""}}> 
            <FaBold/>
          </div>
          <div className="mr-2" onClick={handleItalicClick} style={{borderRadius:"5px", backgroundColor: isItalicActive ? "lightblue" : ""}}>
            <FaItalic/>
          </div>
          <div className="mr-2" onClick={handleStrikeThroughClick} style={{borderRadius:"5px", backgroundColor: isStrikeThroughActive ? "lightblue" : ""}}> 
            <FaStrikethrough/>
          </div>
        </div> */}
        <div style={{border:"1px solid #D1D1D1", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
          <textarea type="text" name="subjective"  value={form?.subjective} onChange={handleChange} style={{paddingLeft:"5px", outline:"none", width:"100%", border:"none"}}/>        
        </div>
        {/* <div dangerouslySetInnerHTML={{ __html: renderSubjectiveText() }}></div> */}
            <div style={{display:"flex", justifyContent:"end", marginTop:"5px"}}>
              <div style={{padding:"3px 6px", backgroundColor:"#66B2FF", borderRadius:"4px"}}>
                <FaMicrophone style={{color:"white"}}/>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
              Objective (O) <span style={{color:"red"}}>*</span>
            </div>
            {/* <div style={{display:"flex", justifyContent:"start", border:"1px solid #D1D1D1", padding:"6px 5px", borderTopLeftRadius:"5px", borderTopRightRadius:"5px", borderBottom:"none"}}>
          <div className="mr-2" onClick={handleBoldClick} style={{borderRadius:"5px", backgroundColor: isBoldActive ? "lightblue" : ""}}> 
            <FaBold/>
          </div>
          <div className="mr-2" onClick={handleItalicClick} style={{borderRadius:"5px", backgroundColor: isItalicActive ? "lightblue" : ""}}>
            <FaItalic/>
          </div>
          <div className="mr-2" onClick={handleStrikeThroughClick} style={{borderRadius:"5px", backgroundColor: isStrikeThroughActive ? "lightblue" : ""}}> 
            <FaStrikethrough/>
          </div>
        </div> */}
        <div style={{border:"1px solid #D1D1D1", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
          <textarea type="text" name="objective"  value={form?.objective} onChange={handleChange} style={{paddingLeft:"5px", outline:"none", width:"100%", border:"none"}}/>        
        </div>
        {/* <div dangerouslySetInnerHTML={{ __html: renderSubjectiveText() }}></div> */}
            <div style={{display:"flex", justifyContent:"end", marginTop:"5px"}}>
              <div style={{padding:"3px 6px", backgroundColor:"#66B2FF", borderRadius:"4px"}}>
                <FaMicrophone style={{color:"white"}}/>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
              Assessment (A) <span style={{color:"red"}}>*</span>
            </div>
            {/* <div style={{display:"flex", justifyContent:"start", border:"1px solid #D1D1D1", padding:"6px 5px", borderTopLeftRadius:"5px", borderTopRightRadius:"5px", borderBottom:"none"}}>
          <div className="mr-2" onClick={handleBoldClick} style={{borderRadius:"5px", backgroundColor: isBoldActive ? "lightblue" : ""}}> 
            <FaBold/>
          </div>
          <div className="mr-2" onClick={handleItalicClick} style={{borderRadius:"5px", backgroundColor: isItalicActive ? "lightblue" : ""}}>
            <FaItalic/>
          </div>
          <div className="mr-2" onClick={handleStrikeThroughClick} style={{borderRadius:"5px", backgroundColor: isStrikeThroughActive ? "lightblue" : ""}}> 
            <FaStrikethrough/>
          </div>
        </div> */}
        <div style={{border:"1px solid #D1D1D1", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
          <textarea type="text" name="assessment"  value={form?.assessment} onChange={handleChange} style={{paddingLeft:"5px", outline:"none", width:"100%", border:"none"}}/>        
        </div>
        {/* <div dangerouslySetInnerHTML={{ __html: renderSubjectiveText() }}></div> */}
            <div style={{display:"flex", justifyContent:"end", marginTop:"5px"}}>
              <div style={{padding:"3px 6px", backgroundColor:"#66B2FF", borderRadius:"4px"}}>
                <FaMicrophone style={{color:"white"}}/>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
              Planning (P) <span style={{color:"red"}}>*</span>
            </div>
            {/* <div style={{display:"flex", justifyContent:"start", border:"1px solid #D1D1D1", padding:"6px 5px", borderTopLeftRadius:"5px", borderTopRightRadius:"5px", borderBottom:"none"}}>
          <div className="mr-2" onClick={handleBoldClick} style={{borderRadius:"5px", backgroundColor: isBoldActive ? "lightblue" : ""}}> 
            <FaBold/>
          </div>
          <div className="mr-2" onClick={handleItalicClick} style={{borderRadius:"5px", backgroundColor: isItalicActive ? "lightblue" : ""}}>
            <FaItalic/>
          </div>
          <div className="mr-2" onClick={handleStrikeThroughClick} style={{borderRadius:"5px", backgroundColor: isStrikeThroughActive ? "lightblue" : ""}}> 
            <FaStrikethrough/>
          </div>
        </div> */}
        <div style={{border:"1px solid #D1D1D1", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
          <textarea type="text" name="planning"  value={form?.planning} onChange={handleChange} style={{paddingLeft:"5px", outline:"none", width:"100%", border:"none"}}/>        
        </div>
        {/* <div dangerouslySetInnerHTML={{ __html: renderSubjectiveText() }}></div> */}
            <div style={{display:"flex", justifyContent:"end", marginTop:"5px"}}>
              <div style={{padding:"3px 6px", backgroundColor:"#66B2FF", borderRadius:"4px"}}>
                <FaMicrophone style={{color:"white"}}/>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
              Implementation (I) <span style={{color:"red"}}>*</span>
            </div>
            {/* <div style={{display:"flex", justifyContent:"start", border:"1px solid #D1D1D1", padding:"6px 5px", borderTopLeftRadius:"5px", borderTopRightRadius:"5px", borderBottom:"none"}}>
          <div className="mr-2" onClick={handleBoldClick} style={{borderRadius:"5px", backgroundColor: isBoldActive ? "lightblue" : ""}}> 
            <FaBold/>
          </div>
          <div className="mr-2" onClick={handleItalicClick} style={{borderRadius:"5px", backgroundColor: isItalicActive ? "lightblue" : ""}}>
            <FaItalic/>
          </div>
          <div className="mr-2" onClick={handleStrikeThroughClick} style={{borderRadius:"5px", backgroundColor: isStrikeThroughActive ? "lightblue" : ""}}> 
            <FaStrikethrough/>
          </div>
        </div> */}
        <div style={{border:"1px solid #D1D1D1", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
          <textarea type="text" name="implementation"  value={form?.implementation} onChange={handleChange} style={{paddingLeft:"5px", outline:"none", width:"100%", border:"none"}}/>        
        </div>
        {/* <div dangerouslySetInnerHTML={{ __html: renderSubjectiveText() }}></div> */}
            <div style={{display:"flex", justifyContent:"end", marginTop:"5px"}}>
              <div style={{padding:"3px 6px", backgroundColor:"#66B2FF", borderRadius:"4px"}}>
                <FaMicrophone style={{color:"white"}}/>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{fontFamily:"sans-serif", fontSize:"12px", fontWeight:"bold"}}>
              Dokter Approval <span style={{color:"red"}}>*</span>
            </div>
              <select className="form-select mt-1" aria-label="Default select example" value={form?.id_doctor} onChange={handleChange} name="id_doctor" style={{ textAlign:"", cursor:"pointer"}}>
                  <option value="" hidden>Select</option>
                  {getDataDokter.map((user, index) => (
                    <option value={user?.id} style={{textAlign: ""}}>{user?.firstname} {user?.lastname}</option>
                  ))}
              </select>
          </div>
        </div>

        {getData === null? 
        <div className="mt-1" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{ padding: "0px 0px", marginTop: "20px", display:"flex", justifyContent:"end" }}>
              <div>
                <Button className="mt-2" onClick={handleSubmit} color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                  Simpan
                </Button>
              </div>
            </div>
          </div>
        </div>
      :
        <div className="mt-1" style={{display:"flex",justifyContent:"center"}}>
          <div style={{width:"80%"}}>
            <div style={{ padding: "0px 0px", marginTop: "20px", display:"flex", justifyContent:"end" }}>
              <div>
                <Button className="mt-2" onClick={handleSubmitUpdate} color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                  Simpan
                </Button>
              </div>
            </div>
          </div>
        </div>
        }
        
        {/* <div>
          <textarea value={value} onChange={(event) => setValue(event.target.value)}/>
          <button onMouseEnter={listen} onMouseUp={stop}>
            🎤
          </button>
       </div> */}

        </Col>
      </Form>
      </Modal.Body>
    </Modal>
    );
}
  