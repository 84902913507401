// SingleDateFilter.js
import React from 'react';

const SingleDateFilter = ({
  startDate,
  setStartDate,
  handleSingleDateFilterToggle,
  GetResponseData,
  isSingleDateFilterVisible,
  isSearchFilterVisible,
  isDateFilterVisible,
  isMonthFilterVisible,
  isMonthRangeFilterVisible
}) => {
  return (
    <>
      {/* Start Filter Single Date */}
      {isSingleDateFilterVisible && (
        <div className="p-3">
        <form
            onSubmit={(e) => {
            e.preventDefault();
            GetResponseData();
            }}
            className="is-flex is-align-items-center"
            style={{ marginTop: '10px' }}
        >
            <div className="field has-addons is-flex is-align-items-center">
            <div className="control mr-2">
                <input
                className="input is-small"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Start Date"
                />
            </div>
            <div className="control">
                <button
                type="submit"
                className="button is-small is-primary"
                style={{
                    borderRadius: '3px',
                    fontWeight: '600',
                    fontSize: '12px',
                    padding: '0px 10px',
                }}
                >
                Filter
                </button>
            </div>
            </div>
        </form>
        </div>
    )}
    {/* End Filter Single Date */}

    {/* Start Filter Single Date Button */}
    {!isSearchFilterVisible && !isDateFilterVisible && !isMonthFilterVisible && !isMonthRangeFilterVisible && (
        <div className="buttons is-flex is-justify-content-center mt-4">
        <button
            className={`button is-small ${isSingleDateFilterVisible ? 'is-info' : 'is-primary'}`}
            onClick={handleSingleDateFilterToggle}
        >
            {isSingleDateFilterVisible ? 'Close Date' : 'Single Date'}
        </button>
        </div>
    )}
    {/* End Filter Single Date Button */}
    </>
  );
};

export default SingleDateFilter;
