import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaSpinner, FaCheckCircle, FaTable, FaSignal, FaWeightHanging, FaWatchmanMonitoring, FaPager, FaFileInvoice, FaCamera} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import logo_side from "../../assets/signature/logo-side-detail.png"
import Swal from "sweetalert2";
import { useParams } from 'react-router-dom';
import "bulma/css/bulma.css";
import "../../index.css"

export default function Login() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);

  document.title = "SDC Apps";
 
  const [pagination,setPagination] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(1);
  const [rows, setRows] = useState(1);
  const [getCountAppointment, setCountAppointment] = useState();
  const [getCountPatient, setCountPatient] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const level = localStorage.getItem('level');
  const rolesData = JSON.parse(localStorage.getItem('roles')) || [];
  const filteredRoles = rolesData.filter(role => role.name === 'Doctor');
  const filteredRolesAdmin = rolesData.filter(role => role.name === 'Admin');
  const filteredRolesANurse = rolesData.filter(role => role.name === 'Nurse');
  const levelUser = localStorage.getItem('level');
  // const user_data = localStorage.getItem("user_data");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [search,setSearch] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataMap, setDataMap] = useState("");
  const [dataSearch, setDataSearch] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const { code, id } = useParams();
  const [getData, setGetData] = useState([]);
  const [getDataCountDokter, setGetDataCountDokter] = useState([]);
  const [getDataProfile, setGetDataProfile] = useState([]);
  const [getDataProfileDetail, setGetDataProfileDetail] = useState([]);
  const [getDataTreatment, setGetDataTreatment] = useState([]);
  const [getDataAgreement, setGetDataAgreement] = useState([]);
  const [getCode, setGetCode] = useState([]);
  const { getCode2, setGetCode2 } = useState("");
  const [loading, setLoading] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseDataCountDoctor = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/transaction-service/dashboard-doctor/count?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataCountDokter(response.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/transaction-service/transaction-service/dashboard/get-appointment/today?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setCountAppointment(response.data.countAppointment);
        setCountPatient(response.data.countPatient);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataProfile = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/auth/profile`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataProfile(response.data.data)
        setGetDataProfileDetail(response.data.data.user_detail)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }
  
  useEffect(() => {
    GetResponseDataProfile()
    GetResponseDataCountDoctor()
  }, [])

  useEffect(() => {
    GetResponseData()
  }, [page])

  const changePage = ({ selected }) => {
    setPage(selected+1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };
 
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response =  await API.delete(`transaction-service/patients/${id}`,fetchParams);
        if (response.data.error == false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }  
      }
    })
  };
  
  const sweetAlert = () => {
    swal({
      title: 'Login Success',
      text: ' ',
      icon: 'success',
      timer: 3000,
      buttons: false
    });
  }

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const RefreshBottom = () => {
    window.location.reload()
  }

  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  console.log(getCountAppointment, "getCountAppointment");
  console.log(getCountPatient, "getCountPatient");
  
  return (
    <div >
        <Row className='match-height' style={{ backgroundColor: "#F3FFFD", padding: isTabletOrMobile? "0px 0px 20px 0px":"0px 20px 30px 10px"}}>
          <Col xl='8' style={{  marginTop:"10px"}}>
            <div style={{display:"flex", justifyContent:"center", backgroundColor:"white",boxShadow: "2px 2px 10px #BFBFBF"}}>
              <div style={{padding:"10px", textAlign:"center"}}>
                <div style={{fontSize:"20px", color:"#001F8B"}}>
                  Hello, {getDataProfileDetail === null?  "" : getDataProfileDetail?.firstname} {getDataProfileDetail === null? "" : getDataProfileDetail?.lastname} 
                </div>
                <div style={{fontSize:"10px", color:"#848484"}}>
                  Selamat Bertugas, Have A Nice Day
                </div>
              </div>
            </div>

            <div style={{display:"flex", backgroundColor:"", marginTop:"15px"}}>
              <div style={{ flex: "50%", paddingTop:"10px",paddingBottom:"10px",  paddingLeft:"15px",marginRight:"10px", backgroundColor:"white", display:"flex",boxShadow: "2px 2px 10px #BFBFBF" }}>
                <div style={{ display:"flex", alignItems:"center", marginRight:"10px", padding:"0px 8px", backgroundColor:"#3D64FF", borderRadius:"3px"}}>
                  <FaUser style={{fontSize:"30px", color:"white"}}/>
                </div>
                <div>
                  <div style={{ fontSize: "20px", color:"#001F8B" }}>
                      {getCountPatient? getCountPatient: "0"}
                    </div>
                    <div style={{fontSize:"10px"}}>
                      Jumlah pasien hari ini
                    </div>
                </div>
              </div>
              
              <div style={{ flex: "50%", paddingTop:"10px",paddingBottom:"10px",  paddingLeft:"15px",marginRight:"0px", backgroundColor:"white", display:"flex",boxShadow: "2px 2px 10px #BFBFBF" }}>
                <div style={{ display:"flex", alignItems:"center", marginRight:"10px", padding:"0px 8px", backgroundColor:"#3D64FF", borderRadius:"3px"}}>
                  <FaPager style={{fontSize:"30px", color:"white"}}/>
                </div>
                <div>
                  <div style={{ fontSize: "20px", color:"#001F8B" }}>
                      {getCountAppointment? getCountAppointment : "0"}
                    </div>
                    <div style={{fontSize:"10px"}}>
                      Jumlah appointment hari ini
                    </div>
                </div>
              </div>
            </div>

            <div style={{backgroundColor:"white", marginTop:"15px",height:"",boxShadow: "2px 2px 10px #BFBFBF"}}>
              
            {isTabletOrMobile ? 
              <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "", padding: "0px 0px 10px 0px" }}>
                <Col xl="6" style={{fontSize:"16px",display:"flex", justifyContent:"center", alignItems:"center", padding:"7px", color:"#001F8B", backgroundColor:"#E9EFFF"}}>
                  <FaAddressBook style={{marginRight:"5px"}}/>Daftar Antrian Pasien
                </Col>
                <Col className="mt-2" xl="6" style={{ display: "flex", justifyContent:"end", paddingRight:"5px" }}>
                  <div onClick={RefreshBottom} style={{height:"100%", marginRight:"5px", paddingTop:"0px",backgroundColor:"white",padding:"10px 10px", borderRadius:"2px", cursor:"", border:"1px solid #DEDEDE"}}>
                    <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
                  </div>
                  <form onSubmit={searchData} style={{display:"flex", paddingRight:"0px"}}>
                <div style={{marginRight:"5px",borderRadius:"3px"}}>
                    <input value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="focused"
                    style={{backgroundColor:"#E9E9E9", border:"none",height:"100%"}}
                    type="text"
                    placeholder=""
                  />
              </div>
                <button type="submit"  style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius:"2px", fontWeight:"600", fontSize:"12px",alignItems:"center", padding:"0px 10px" }}>
                  Search
                </button>
            </form>
                </Col>
              </div>
                :
              <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: "10px 0px" }}>
                <div style={{flex:"50%",fontSize:"16px",display:"flex", alignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
                  <FaAddressBook style={{marginRight:"5px"}}/>Daftar Antrian Pasien
                </div>
                <div style={{ flex: "50%", display: "flex", justifyContent:"end" }}>
                  <div onClick={RefreshBottom} style={{height:"100%", marginRight:"5px", paddingTop:"0px",backgroundColor:"white",padding:"0px 10px", border:"1px solid #DEDEDE", borderRadius:"3px", cursor:""}}>
                    <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
                  </div>
                  <form onSubmit={searchData} style={{display:"flex", paddingRight:"10px"}}>
              <div style={{marginRight:"5px",borderRadius:"3px"}}>
                    <input value={query}
                              onChange={(e) => setQuery(e.target.value)}
                      className="focused"
                  style={{backgroundColor:"#E9E9E9", border:"none",height:"5vh"}}
                  type="text"
                  placeholder=""
                />
              </div>
              <button type="submit"  style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius:"3px", fontWeight:"600", fontSize:"12px",alignItems:"center", padding:"0px 10px" }}>
                Search
              </button>
            </form>
                </div>
              </div>  
            }
            
            <Col xl='12' sm='12'> 
            <div>
              <div  style={{display:"block", height:"100%", overflowY:"auto",overflowX:"auto"}}>
                <div >
                <table className="table is-bordered">
                  <thead>
                    <tr style={{backgroundColor:"#E9EFFF"}}>
                      <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>No</th>
                      {/* <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Code</th> */}
                      <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Anamnesa</th>
                      <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Pasien</th>    
                      <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Dokter</th>
                      {filteredRolesANurse.length > 0 && levelUser !== "developer" ? "" :
                      <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Action</th>
                      }
                      {/* <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Action Testing</th>                   */}
                      <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Tanggal</th>
                      <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Waktu</th>
                      {/* <th style={{fontFamily:"revert",fontSize:"12px",textAlign:"center",  color:"#525252",border:"none", fontFamily:"sans-serif"}}>Setup</th> */}
                      {/* <th style={{fontFamily:"revert",fontSize:"13px",textAlign:"center",  color:"black",border:"none"}}>Action</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                  {/* <tr style={{fontFamily:"sans-serif", fontSize:"11px", textAlign:"center"}}>
                        <td style={{lineHeight:"2"}}>1</td>
                        <td style={{lineHeight:"2"}}>SDC-1687722.ASE123</td>
                        <td style={{lineHeight:"2"}}>Dwiki Wantara</td>
                        <td style={{lineHeight:"2"}}>Laki-Laki</td>
                        <td style={{lineHeight:"2"}}>Tangerang, 17 April 1998</td>
                        <td style={{lineHeight:"2"}}>25 Tahun</td>
                        <td style={{lineHeight:"2"}}>Jl. Samudra Raya, Tangerang</td>
                        <td style={{lineHeight:"2"}}>082119576144</td>
                        <td>Indonesia</td>
                        <td style={{lineHeight:"2"}}>Karyawan swasta</td>
                        <td style={{lineHeight:"2"}}>
                          <div onClick={AddAppoinment} style={{display: "flex",justifyContent:"center", padding: "0px 10px", backgroundColor:"#00C00D", borderRadius:"3px", cursor:"" }}>
                            <div style={{ display: "flex"}}>
                              <FaUserPlus style={{display:"flex", alignItems:"center", height:"100%", fontSize:"11px", marginRight:"3px", color:"white"}}/>  
                            </div>
                            <div style={{ display: "flex"}}>
                              <div style={{ display: "flex", alignItems: "center", height: "100%", fontSize: "11px", marginTop:"1px", color: "white", fontWeight:"bold"}}>
                                Add
                              </div>  
                            </div>
                          </div>  
                        </td>
                        <td style={{lineHeight:"2"}}>
                            <div style={{display:"flex",justifyContent:"center", alignItems:"center", alignContent:"center"}}>
                                <button onClick={UpdateUser} style={{ fontSize: "13px", color: "white", backgroundColor: "#ff9933", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display:"flex", marginRight:"3px" }}>
                                  <FaEdit/>
                                </button>
                                <button style={{ fontSize: "12px", color: "white", backgroundColor: "#B60000", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display:"flex" }}>
                                  <FaTrash/>
                                </button>
                            </div>
                        </td> 
                      </tr> */}
                      {getData.map((user,index) => (
                        <tr key={index} style={{fontFamily:"sans-serif", fontSize:"11px", textAlign:"center"}}>
                          <td style={{lineHeight:"2"}}>{page === 1 ? index + 1 : page === 2 ? (index + 1) + 10 : (index + 1) + ( page * 10 )}</td>
                            {/* <td style={{ lineHeight: "2" }}>{user.code}</td> */}
                            <td style={{ lineHeight: "2" }}>{user.anamnesa}</td>
                            <td style={{ lineHeight: "2" }}>{user.patient_name}</td>
                          <td style={{ lineHeight: "2" }}>{user.doctor_name}</td>
                
                    {/* ////////////////////////// Action \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */}
                    {/* {levelUser === "developer" ? */}
                    {levelUser === "developer" ?
                      <td style={{ lineHeight: "2" }}>
                      {user?.status === 0 || user?.status === null || user?.status === undefined ?
                      <div style={{display:"flex",justifyContent:"center"}}>
                        <div  style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#F0A800", color:"white", fontWeight:"bold", width:"135px", justifyContent:"center"}}>
                          <div  style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                            <FaWatchmanMonitoring/>
                          </div>
                          <div style={{display:"flex", alignItems:"center"}}>
                            New Appointment
                          </div>
                        </div>
                        </div>
                        :
                        <>
                        {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false?
                          <div style={{display:"flex",justifyContent:"center"}}>
                            <div  style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#3D64FF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                              <div  style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                <FaTable/>
                              </div>
                              <div style={{display:"flex", alignItems:"center"}}>
                                Arrangement
                              </div>
                            </div>
                          </div>
                          :
                          <div style={{display:"flex",justifyContent:"center"}}>
                            {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false  ? 
                            <div  style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#FF6661", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                              <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                <FaStreetView/>
                              </div>
                              <div style={{display:"flex", alignItems:"center"}}>
                                waiting list
                              </div>
                            </div>
                                : 
                            <div>
                            {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ? 
                            <div style={{backgroundColor:"#00d4cd",display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                              <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                <FaUserMd/>
                              </div>
                              <div style={{display:"flex", alignItems:"center"}}>
                                Treatment Plan
                              </div>
                            </div>
                              : 
                            <div>
                              {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ? 
                              <div  style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#A9A9A9", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                  <FaFileInvoice />
                                </div>
                                <div style={{display:"flex", alignItems:"center"}}>
                                  Buat Invoice
                                </div>
                              </div>
                                : 
                                <div>
                                {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ? 
                                <div  style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#AB00D5", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                  <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                    <FaCamera/>
                                  </div>
                                  <div style={{display:"flex", alignItems:"center"}}>
                                    Upload Pembayaran
                                  </div>
                                </div>
                                  : 
                                <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#96FF86", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                  <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                    <FaCheckCircle />
                                  </div>
                                  <div style={{display:"flex", alignItems:"center"}}>
                                    Lunas
                                  </div>
                                </div>
                                }
                              </div>
                              }
                            </div>
                            }
                          </div>
                            }
                          </div>
                        } 
                      </>
                    }    
                      </td>
                      :
                      ""
                    }
                    
                    {/* {filteredRoles.length > 0? */}
                    {filteredRoles.length > 0 && levelUser !== "developer"?
                      <td style={{ lineHeight: "2" }}>
                      {user?.status === 0 || user?.status === null || user?.status === undefined ?
                      <div style={{display:"flex",justifyContent:"center"}}>
                        <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#3D64FF", color:"white", fontWeight:"bold"}}>
                          <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                <FaStreetView/>
                              </div>
                              <div style={{display:"flex", alignItems:"center"}}>
                                Menunggu
                              </div>
                        </div>
                        </div>
                        :
                        <>
                        {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false?
                          <div style={{display:"flex",justifyContent:"center"}}>
                            <div  style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#3D64FF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                            <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                <FaStreetView/>
                              </div>
                              <div style={{display:"flex", alignItems:"center"}}>
                                Menunggu
                              </div>
                            </div>
                          </div>
                          :
                          <div style={{display:"flex",justifyContent:"center"}}>
                            {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false  ? 
                            <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#3D64FF", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                              <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                <FaStreetView/>
                              </div>
                              <div style={{display:"flex", alignItems:"center"}}>
                                Menunggu
                              </div>
                            </div>
                                : 
                            <div>
                            {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ? 
                            <div  style={{backgroundColor:"#00d4cd",display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px",  color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                              <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                <FaUserMd/>
                              </div>
                              <div style={{display:"flex", alignItems:"center"}}>
                                Treatment Plan
                              </div>
                            </div>
                              : 
                            <div>
                              {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ? 
                              <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#96FF86", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                <FaCheckCircle />                            </div>
                                <div style={{display:"flex", alignItems:"center"}}>
                                  Selesai
                                </div>
                              </div>
                                : 
                                <div>
                                {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ? 
                                <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#96FF86", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                  <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                  <FaCheckCircle />                              </div>
                                  <div style={{display:"flex", alignItems:"center"}}>
                                  Selesai
                                  </div>
                                </div>
                                  : 
                                <div style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#96FF86", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                  <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                    <FaCheckCircle />
                                  </div>
                                  <div style={{display:"flex", alignItems:"center"}}>
                                  Selesai
                                  </div>
                                </div>
                                }
                              </div>
                              }
                            </div>
                            }
                          </div>
                            }
                          </div>
                        } 
                      </>
                    }    
                      </td>
                      :
                      ""
                    }
                          
                    {filteredRolesAdmin.length > 0 && levelUser !== "developer" ? 
                      <td style={{ lineHeight: "2" }}>
                        {user?.status === 0 || user?.status === null || user?.status === undefined ?
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <div  style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#F0A800", color: "white", fontWeight: "bold", width:"135px", justifyContent:"center" }}>
                              <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                                <FaWatchmanMonitoring />
                              </div>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                New Appointment  
                              </div>
                            </div>
                          </div>
                          :
                          <>
                            {user.arrangement_data === null || user.arrangement_data === undefined || user.arrangement_data === false ?
                              <div style={{ display: "flex", justifyContent: "center" }}>
                                <div  style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#3D64FF", color: "white", fontWeight: "bold", width:"120px", justifyContent:"center" }}>
                                  <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                                    <FaTable />
                                  </div>
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    Arrangement
                                  </div>
                                </div>
                              </div>
                              :
                              <div style={{ display: "flex", justifyContent: "center" }}>
                                {user.waiting_data === null || user.waiting_data === undefined || user.waiting_data === false ?
                                  <div  style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#FF6661", color: "white", fontWeight: "bold", width:"120px", justifyContent:"center" }}>
                                    <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                                      <FaStreetView />
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      waiting list
                                    </div>
                                  </div>
                                  :
                                  <div>
                                    {user.medical_invoice_data === null || user.medical_invoice_data === undefined || user.medical_invoice_data === false ?
                                      <div  style={{backgroundColor:"#00d4cd", display: "flex", border: "none", padding: "0px 7px", cursor: "", borderRadius: "3px", color: "white", fontWeight: "bold", width:"120px", justifyContent:"center" }}>
                                        <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                                          <FaUserMd />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                          Menunggu
                                        </div>
                                      </div>
                                      :
                                      <div>
                                        {user.invoice_data === null || user.invoice_data === undefined || user.invoice_data === false ?
                                          <div  style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#A9A9A9", color: "white", fontWeight: "bold", width:"120px", justifyContent:"center" }}>
                                            <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                                              <FaFileInvoice />
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                              Buat Invoice
                                            </div>
                                          </div>
                                          :
                                          <div>
                                            {user.invoice_paid === null || user.invoice_paid === undefined || user.invoice_paid === false ?
                                              <div  style={{display:"flex",border:"none", padding:"0px 7px", cursor:"", borderRadius:"3px", backgroundColor:"#AB00D5", color:"white", fontWeight:"bold", width:"120px", justifyContent:"center"}}>
                                              <div style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
                                                <FaCamera/>
                                              </div>
                                              <div style={{display:"flex", alignItems:"center"}}>
                                                  Upload Pembayaran
                                              </div>
                                            </div>
                                              :
                                              <div style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "", borderRadius: "3px", backgroundColor: "#96FF86", color: "white", fontWeight: "bold", width:"120px", justifyContent:"center" }}>
                                                <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                                                  <FaCheckCircle />
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                  Lunas
                                                </div>
                                              </div>
                                            }
                                          </div>
                                        }
                                      </div>
                                    }
                                  </div>
                                }
                              </div>
                            }
                          </>
                        }
                      </td>
                      :
                      ""
                    } 

                    {filteredRolesANurse.length > 0 && levelUser !== "developer" ? 
                      ""
                      :
                      ""
                    } 
                      
                    <td style={{ lineHeight: "2" }}>
                      {user.date.split('-')[2]}&nbsp;
                      {user.date.split('-')[1] === "01" ? "Januari" : "" ||
                      user.date.split('-')[1] === "02" ? "Februari" : "" ||
                      user.date.split('-')[1] === "03" ? "Maret" : "" ||
                      user.date.split('-')[1] === "04" ? "April" : "" ||  
                      user.date.split('-')[1] === "05" ? "Mei" : "" ||
                      user.date.split('-')[1] === "06" ? "Juni" : "" ||
                      user.date.split('-')[1] === "07" ? "Juli" : "" ||
                      user.date.split('-')[1] === "08" ? "Agustus" : "" ||
                      user.date.split('-')[1] === "09" ? "September" : "" ||
                      user.date.split('-')[1] === "10" ? "Oktober" : "" ||
                      user.date.split('-')[1] === "11" ? "November" : "" ||
                      user.date.split('-')[1] === "12" ? "Desember" : ""}&nbsp;
                      {user.date.split('-')[0]}
                    </td>
                    <td style={{ lineHeight: "2" }}>
                      <div style={{  display:"flex", justifyContent:"center", width:"50px" }}>
                          <div style={{ borderRadius:"3px", fontWeight:"bold", backgroundColor:"#FFB450", color:"white", padding:"0px 5px"}}>
                            {user.time.split(':')[0]} : {user.time.split(':')[1]}
                          </div>
                        </div>
                    </td>        
                  </tr>
                      ))}
                  </tbody>
                </table>
                  
                      </div>
                    </div>
              </div> 
              <div style={{ display: "flex", padding:"10px"}}>
                    <div>
                        <div style={{fontSize:"12px"}}>
                          Total Rows: {rows} 
                        </div>
                        <div style={{fontSize:"12px"}}>
                          Page: {rows ? page : 0} of {pages}
                        </div>
                        <p className="has-text-centered has-text-danger">{msg}</p>
                      </div> 
                        <div style={{flex:"50%", display:"flex", justifyContent:"end"}}>
                        <nav
                                style={{fontSize:"12px"}}
                                className="pagination is-centered"
                                key={rows}
                                role="navigation"
                                aria-label="pagination"
                                >
                                <ReactPaginate
                                  previousLabel={"<"}
                                  nextLabel={">"}
                                  pageCount={Math.min(10, pages)}
                                  onPageChange={changePage}
                                  containerClassName={"pagination-list"}
                                  pageLinkClassName={"pagination-link"}
                                  previousLinkClassName={"pagination-previous"}
                                  nextLinkClassName={"pagination-next"}
                                  activeLinkClassName={"pagination-link is-current"}
                                  disabledLinkClassName={"pagination-link is-disabled"}
                                />
                              </nav>
                        </div>
                      </div>    
              </Col>      

            </div>
          </Col>
          
          <Col xl='4' sm='12' style={{  height: "100%", paddingBottom:"20px", marginTop: "10px", backgroundColor:"white",boxShadow: "2px 2px 10px #BFBFBF", marginRight:"0px", paddingBottom:"30px" }}>
            <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:isTabletOrMobile?"10px":"0px",marginRight:"10px", backgroundColor:"white", }}>
                <div style={{fontSize:"17px", fontFamily:"revert-layer",color:"#001F8B"}}>
                  Profile
                </div>
            </div>


            {getDataProfile?.photo_profile === null ? 
            <div style={{display:"flex",justifyContent:"center", paddingTop:"0px",paddingBottom:"0px", marginRight:"0px", backgroundColor:"white" }}>
              <img  src={'https://storageapps.signatureanugerah.co.id//images//no-profile.png'} style={{width:"45%", borderRadius:"50%", border:"1px solid #C9C9C9"}}/>
            </div>
              :
            <div style={{ display: "flex", justifyContent: "center", paddingTop: "0px", paddingBottom: "0px", marginRight: "0px", backgroundColor: "white" }}>
              <img  src={getDataProfile?.photo_profile} style={{width:"45%", borderRadius:"50%", border:"1px solid #C9C9C9"}}/>
            </div>
            }

            <div className="mt-2" style={{display:"flex",justifyContent:"center", paddingTop:"5px",paddingBottom:"5px", marginRight:"0px", backgroundColor:"#BAD2FF", color:"#001F8B", fontWeight:"600" }}>
              {getDataProfileDetail === null?  "-" : getDataProfileDetail?.firstname} {getDataProfileDetail === null? "" : getDataProfileDetail?.lastname} 
            </div>

            <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white", marginTop:"10px" }}>
              <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                 Email
              </div>
              <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
                <FaBookMedical style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
                <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%", paddingBottom:"0px"}}>
                    {getDataProfile?.email}
                </div>
              </div>
            </div>

            <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
              <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                  Tanggal Lahir
              </div>
              <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
                <FaCalendarAlt style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
                <div style={{ color: "#CC6600", fontSize: "12px", marginLeft: "10px", display: "flex", alignItems: "center", height: "100%" }}>
                  {getDataProfileDetail === null? "": `${getDataProfileDetail.birth_place}`},&nbsp;
                {getDataProfileDetail === null?  "-" : `${getDataProfileDetail.birth_day}`.split("-")[2]}&nbsp;
                  { getDataProfileDetail === null?  "" : `${getDataProfileDetail.birth_day}`.split("-")[1] === "01" ? "Januari" : "" ||
                 `${getDataProfileDetail.birth_day}`.split("-")[1] === "02" ? "Februari" : "" ||
                 `${getDataProfileDetail.birth_day}`.split("-")[1] === "03" ? "Maret" : "" ||
                 `${getDataProfileDetail.birth_day}`.split("-")[1] === "04" ? "April" : "" ||
                 `${getDataProfileDetail.birth_day}`.split("-")[1] === "05" ? "Mei" : "" ||
                 `${getDataProfileDetail.birth_day}`.split("-")[1] === "06" ? "Juni" : "" ||
                 `${getDataProfileDetail.birth_day}`.split("-")[1] === "07" ? "Juli" : "" ||
                 `${getDataProfileDetail.birth_day}`.split("-")[1] === "08" ? "Agustus" : "" ||
                 `${getDataProfileDetail.birth_day}`.split("-")[1] === "09" ? "September" : "" ||
                 `${getDataProfileDetail.birth_day}`.split("-")[1] === "10" ? "Oktober" : "" ||
                 `${getDataProfileDetail.birth_day}`.split("-")[1] === "11" ? "November" : "" ||
                 `${getDataProfileDetail.birth_day}`.split("-")[1] === "12" ? "Desember" : ""}&nbsp;
                  {getDataProfileDetail === null?  "" : `${getDataProfileDetail.birth_day}`.split("-")[0]}
                </div>
              </div>
            </div>

            <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
              <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                  Jenis Kelamin
              </div>
              <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
                <FaTransgender style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
                <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%"}}>
                   {getDataProfileDetail === null?  "-" : capitalizeFirst(`${getDataProfileDetail?.gender}`)}
                </div>
              </div>
            </div>

            {/* <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
              <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                  Usia & Tanggal Lahir
              </div>
              <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
                <FaCalendarCheck style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
                <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%", paddingTop:"2px"}}>
                    24 Tahun, 22 April 1999
                </div>
              </div>
            </div> */}

            {/* <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
              <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                  Phone Number
              </div>
              <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
                <FaMobileAlt style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
                <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%"}}>
                    {getDataProfileDetail === null?  "-" : getDataProfileDetail?.phone_number}
                </div>
              </div>
            </div> */}

            {/* <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
              <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                  Status
              </div>
              <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
                <FaWpforms style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
                <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%"}}>
                    Belum Menikah
                </div>
              </div>
            </div> */}

            {/* <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
              <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                  Alamat
              </div>
              <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
                <FaAddressBook style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
                <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%"}}>
                {getDataProfileDetail === null?  "-" : getDataProfileDetail?.address}

                </div>
              </div>
            </div> */}
          </Col>
        </Row>
    </div>
  );
}
