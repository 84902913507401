import React from 'react';
import ReactPaginate from "react-paginate";

const PaginationRoom = ({ pageCount, currentPage, onPageChange }) => {
  return (
    <div style={{ display: "flex", padding: "10px" }}>
      <div>
        <div style={{ fontSize: "12px" }}>
          Total Rows: {currentPage ? currentPage * 10 : 0} 
        </div>
        <div style={{ fontSize: "12px" }}>
          Page: {currentPage} of {pageCount}
        </div>
      </div>
      <div style={{ flex: "50%", display: "flex", justifyContent: "end" }}>
        <nav
          style={{ fontSize: "12px" }}
          className="pagination is-centered"
          key={currentPage}
          role="navigation"
          aria-label="pagination"
        >
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={onPageChange}
            containerClassName={"pagination-list"}
            pageLinkClassName={"pagination-link"}
            previousLinkClassName={"pagination-previous"}
            nextLinkClassName={"pagination-next"}
            activeLinkClassName={"pagination-link is-current"}
            disabledLinkClassName={"pagination-link is-disabled"}
          />
        </nav>
      </div>
    </div>
  );
};

export default PaginationRoom;

// Clear
