import React from 'react';
import { FaPlusCircle, FaDollarSign, FaEdit, FaTrash } from 'react-icons/fa';

const TableTreatment = ({ 
  isTabletOrMobile, 
  getData, 
  page, 
  modalMedisTreatmentPrices, 
  modalViewUpdateTreatmentPrice, 
  modalMedisRoleUpdate, 
  deleteById 
}) => {
  return (
    <div style={{ display: "block", height: "100%", overflowY: "auto", overflowX: "auto" }}>
      <div>
        <table className="table is-bordered">
          <thead>
            <tr style={{ backgroundColor: isTabletOrMobile ? "white" : "#E9EFFF" }}>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>No</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Treatment</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Prices</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Setup</th>
            </tr>
          </thead>
          <tbody>
            {getData.map((user, index) => (
              <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center" }}>
                <td style={{ lineHeight: "2" }}>
                  {page === 1 ? index + 1 : page === 2 ? (index + 1) + 10 : (index + 1) + (page * 10)}
                </td>
                <td style={{ lineHeight: "2" }}>{user.name}</td>
                
                {user.treatment_price_min === null || user.treatment_price_min === "null" || user.treatment_price_min === 0 || user.treatment_price_min === "0" || user.treatment_price_max === null || user.treatment_price_max === "null" || user.treatment_price_max === 0 || user.treatment_price_max === "0" ?
                  <td style={{ lineHeight: "2" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div onClick={() => modalMedisTreatmentPrices(user?.id, user?.name)} style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#0036E8", color: "white", fontWeight: "bold", width: "120px", justifyContent: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                          <FaPlusCircle />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>Tambah Price</div>
                      </div>
                    </div>
                  </td>
                  :
                  <td style={{ lineHeight: "2" }}>{user.treatment_price_min} - {user.treatment_price_max}</td>
                }

                <td style={{ lineHeight: "2" }}>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {user.treatment_price_min === null || user.treatment_price_min === "null" || user.treatment_price_min === 0 || user.treatment_price_min === "0" || user.treatment_price_max === null || user.treatment_price_max === "null" || user.treatment_price_max === 0 || user.treatment_price_max === "0" ?
                      ""
                      :
                      <button onClick={() => modalViewUpdateTreatmentPrice(user?.id_treatment_price, user?.name, user?.treatment_price_min, user?.treatment_price_max)} style={{ fontSize: "13px", color: "white", backgroundColor: "#00B324", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display: "flex", marginRight: "3px" }}>
                        <FaDollarSign />
                      </button>
                    }
                    <button onClick={() => modalMedisRoleUpdate(user?.id, user?.id_category, user?.name, user?.id_room_cost_lab, user?.cost_lab, user?.fee_percentage, user?.code_icd9, user?.name_icd9)} style={{ fontSize: "13px", color: "white", backgroundColor: "#ff9933", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display: "flex", marginRight: "3px" }}>
                      <FaEdit />
                    </button>
                    <button onClick={() => deleteById(user?.id, user?.id_treatment_price)} style={{ fontSize: "12px", color: "white", backgroundColor: "#B60000", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display: "flex" }}>
                      <FaTrash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableTreatment;
