import React from 'react';
import ReactPaginate from 'react-paginate';

const PaginationPaymentType = ({ rows, page, pages, msg, changePage }) => {
  return (
    <div style={{ display: "flex", padding: "10px" }}>
      <div>
        <div style={{ fontSize: "12px" }}>
          Total Rows: {rows}
        </div>
        <div style={{ fontSize: "12px" }}>
          Page: {rows ? page : 0} of {pages}
        </div>
        <p className="has-text-centered has-text-danger">{msg}</p>
      </div>
      <div style={{ flex: "50%", display: "flex", justifyContent: "end" }}>
        <nav
          style={{ fontSize: "12px" }}
          className="pagination is-centered"
          role="navigation"
          aria-label="pagination"
        >
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={Math.min(10, pages)} // Mengatur batasan halaman yang ditampilkan
            onPageChange={changePage} // Fungsi yang dipanggil saat halaman diubah
            containerClassName={"pagination-list"}
            pageLinkClassName={"pagination-link"}
            previousLinkClassName={"pagination-previous"}
            nextLinkClassName={"pagination-next"}
            activeLinkClassName={"pagination-link is-current"}
            disabledLinkClassName={"pagination-link is-disabled"}
          />
        </nav>
      </div>
    </div>
  );
};

export default PaginationPaymentType;
