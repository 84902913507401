import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import "bulma/css/bulma.css";
import { useMutation } from "react-query";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import ModalAddMedis from "./ModalAdmin/ModalAdd"
import ModalUpdateMedis from "./ModalAdmin/ModalUpdate"
import { ScaleLoader } from "react-spinners";
import LoaderHome from "../Loader/LoaderHome"
import {useRef} from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';

export default function Login() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [getDataItemInventory, setGetDataItemInventory] = useState([]);
  const [getDataStockIn, setGetDataStockIn] = useState([]);
  const [getDataStockOut, setGetDataStockOut] = useState([]);
  const [pagination,setPagination] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [search,setSearch] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataMap, setDataMap] = useState("");
  const [dataSearch, setDataSearch] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  // Responsive to mobile or dekstop
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  // modal role add 
  const [id, setId] = useState();
  const [idVendorUpdate, setIdVendorUpdate] = useState();
  const [idUnitUpdate, setIdUnitUpdate] = useState();
  const [idCategoryInventoryUpdate, setIdCategoryInventoryUpdate] = useState();
  const [nameInventoryUpdate, setNameInventoryUpdate] = useState();
  const [firstStockQtyUpdate, setFirstStockQtyUpdate] = useState();
  const [firstStockExpiredUpdate, setFirstStockExpiredUpdate] = useState();
  const [firstStockPriceUpdate, setFirstStockPriceUpdate] = useState();
  const [noteUpdate, setNoteUpdate] = useState();
  const [nameUpdate, setNameUpdate] = useState();
  const [addressUpdate, setAddressUpdate] = useState();
  const [cityUpdate, setCityUpdate] = useState();
  const [provinceUpdate, setProvinceUpdate] = useState();
  const [phoneNumberUpdate, setPhoneNumberUpdate] = useState();
  const [urlUpdate, setUrlUpdate] = useState();
  const [iconUpdate, setIconUpdate] = useState();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const [formatInterger, setFormatInterger] = useState(false);
  const [filter_date, set_filter_date] = useState(`${(new Date().getMonth() + 1).toString()}-${new Date().getFullYear().toString()}`);
  console.log(filter_date)
  const [filter_category_inventory, set_filter_category_inventory] = useState("");

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [form, setForm] = useState({
    month: "",
    year: ""
  });

  const [form2, setForm2] = useState({
    filter_date: "",
    filter_category_inventory: "",
  });

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/inventorys?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&filter_date=${filter_date}&filter_category_inventory=${filter_category_inventory}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        // setGetDataStockIn(response.data.data.stock_in)
        // setGetDataStockOut(response.data.data.stock_out)
        // setPage(response.data.pagination.current_page);
        // setPages(response.data.pagination.total_pages);
        // setRows(response.data.pagination.total);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseData2 = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/inventorys?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&filter_date=${form2?.year}-${form2?.month}&filter_category_inventory=${form2?.filter_category_inventory}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        // setGetDataStockIn(response.data.data.stock_in)
        // setGetDataStockOut(response.data.data.stock_out)
        // setPage(response.data.pagination.current_page);
        // setPages(response.data.pagination.total_pages);
        // setRows(response.data.pagination.total);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataItemInventory = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/inventory-items?page=${page}&limit=100&ascending=${ascending}&search=${keyword}&search=${search}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataItemInventory(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const handleChange2 = (e) => {
    setForm2({
      ...form2,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    GetResponseData()
    GetResponseDataItemInventory()
  }, [page])

  useEffect(() => {
    setForm({
      ...form, 
      month: (new Date().getMonth() + 1).toString(),
      year: new Date().getFullYear().toString()
    });
  }, []);

  useEffect(() => {
    set_filter_date(`${(new Date().getMonth() + 1).toString()}-${new Date().getFullYear().toString()}`);
  }, []);

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response =  await API.delete(`transaction-service/inventorys/${id}`,fetchParams);
        if (response.data.error == false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }  
      }
    })
  };

  const handleChange = (e) => {
    setForm2({
      ...form2,
      [e.target.name]: e.target.value,
    });
  };

  const changePage = ({ selected }) => {
    setPage(selected+1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };
 
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const buttonRefresh = () => {
    window.location.reload();
  }
  
  const viewModalAdd = () => {
    setModalAdd(true)
  }

  const viewModalUpdate = (id, id_vendor, id_unit, id_category_inventory, name_inventory, first_stock_qty, first_stock_expired, first_stock_price, note) => {
    setModalUpdate(true)
    setId(id)
    setIdVendorUpdate(id_vendor)
    setIdUnitUpdate(id_unit)
    setIdCategoryInventoryUpdate(id_category_inventory)
    setNameInventoryUpdate(name_inventory)
    setFirstStockQtyUpdate(first_stock_qty)
    setFirstStockExpiredUpdate(first_stock_expired)
    setFirstStockPriceUpdate(first_stock_price)
    setNoteUpdate(note)
  }

  function formatToRupiah(number) {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0, // Set minimum fraction digits to 0
      maximumFractionDigits: 0, // Set maximum fraction digits to 0
    }).format(number);
  }

  const tahunSaatIni = new Date().getFullYear();
  // Membuat array variabel dengan 10 tahun terakhir
  const panjangTahun = 10;
  const arrayTahun = Array.from({ length: panjangTahun }, (_, index) => tahunSaatIni - index);

  return (
    <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
      {modalAdd && <ModalAddMedis GetResponseData={GetResponseData} show={modalAdd} onHide={() => setModalAdd(false)} />}
      {modalUpdate && <ModalUpdateMedis GetResponseData={GetResponseData} id={id} idVendorUpdate={idVendorUpdate} idUnitUpdate={idUnitUpdate} idCategoryInventoryUpdate={idCategoryInventoryUpdate} nameInventoryUpdate={nameInventoryUpdate} firstStockQtyUpdate={firstStockQtyUpdate} firstStockExpiredUpdate={firstStockExpiredUpdate} firstStockPriceUpdate={firstStockPriceUpdate} noteUpdate={noteUpdate} show={modalUpdate} onHide={() => setModalUpdate(false)} />}
      {loading && <LoaderHome />} 
      
      {isTabletOrMobile ? 
        <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "", padding: "0px 0px 10px 0px" }}>
          <Col xl="6" style={{fontSize:"16px",display:"flex", justifyContent:"center", alignItems:"center", padding:"7px", color:"#001F8B", backgroundColor:"#E9EFFF"}}>
              <FaAddressBook style={{marginRight:"5px"}}/>List Inventory
          </Col>
          <Col className="mt-2" xl="6" style={{ display: "flex", justifyContent:"end", paddingRight:"5px" }}>
            <div onClick={viewModalAdd} style={{display:"flex",alignItems:"center",marginRight:"5px",backgroundColor:"#3D64FF",color:"white",padding:"0px 12px", borderRadius:"3px", cursor:"pointer", fontSize:"12px", cursor:"pointer"}}>
              <FaPlus/>
            </div>
            <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "10px 10px", borderRadius: "2px", cursor: "pointer", border: "1px solid #DEDEDE" }}>
              <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
            </div>
            <form onSubmit={searchData} style={{display:"flex", paddingRight:"0px"}}>
              <div style={{marginRight:"5px",borderRadius:"3px"}}>
                <input value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="focused"
                  style={{backgroundColor:"#E9E9E9", border:"none",height:"100%"}}
                  type="text"
                  placeholder=""
                />
              </div>
                <button type="submit"  style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius:"2px", fontWeight:"600", fontSize:"12px",alignItems:"center", padding:"0px 10px" }}>
                  Search
                </button>
            </form>
          </Col>
        </div>
        :
        <>
        {/* <div style={{ paddingLeft: "0px", width: "100%", display: "flex", padding: "10px 0px 5px 0px" }}>
          <div style={{flex:"50%",fontSize:"16px",display:"flex", alignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
            <FaAddressBook style={{marginRight:"5px"}}/>List Inventory
          </div>
          <div style={{ flex: "50%", display: "flex", justifyContent:"end" }}>
            <div onClick={viewModalAdd} style={{display:"flex",alignItems:"center",marginRight:"5px",backgroundColor:"#3D64FF",color:"white",padding:"0px 10px", borderRadius:"3px", cursor:"pointer", fontSize:"12px", cursor:"pointer"}}>
              <FaPlus/>
            </div>
            <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "0px 10px", border: "1px solid #DEDEDE", borderRadius: "3px", cursor: "pointer" }}>
              <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
            </div>
            <form onSubmit={searchData} style={{display:"flex", paddingRight:"10px"}}>
              <div style={{marginRight:"5px",borderRadius:"3px"}}>
                <input value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="focused"
                  style={{backgroundColor:"#E9E9E9", border:"none",height:"5vh"}}
                  type="text"
                  placeholder=""
                />
              </div>
              <button type="submit"  style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius:"3px", fontWeight:"600", fontSize:"12px",alignItems:"center", padding:"0px 10px" }}>
                Search
              </button>
            </form>
          </div>
        </div>   */}
        <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: "10px 0px" }}>
          <div style={{flex:"50%",fontSize:"16px",display:"flex", alignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
            <div style={{flex:"50%",fontSize:"16px",display:"flex", alignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
              <FaAddressBook style={{marginRight:"5px"}}/>List Inventory
            </div>
          </div>
          <div style={{ flex: "50%", display: "flex", justifyContent:"end" }}>
          <div style={{ flex: "30%", display: "flex", justifyContent: "end" }}>
            <div onClick={viewModalAdd} style={{display:"flex",alignItems:"center",marginRight:"5px",backgroundColor:"#3D64FF",color:"white",padding:"0px 10px", borderRadius:"3px", cursor:"pointer", fontSize:"12px", cursor:"pointer"}}>
              <FaPlus/>
            </div>
            <DownloadTableExcel
              filename="users table"
              sheet="users"
              currentTableRef={tableRef.current}
              style={{border:"1px solid yellow"}}
              >
              <button onMouseEnter={()=> setFormatInterger(true)} onMouseLeave={()=> setFormatInterger(false)} style={{marginRight:"5px", height:"100%", padding:"0px 10px", borderRadius:"3px", border:"1px solid #D1D1D1"}}>Excel</button>
            </DownloadTableExcel>
            <div className="mr-1">
              <select className="form-select mr-1" aria-label="Default select example" value={form2?.filter_category_inventory} name="filter_category_inventory" onChange={handleChange2} style={{ textAlign:"center", cursor:"pointer", fontSize:"13px"}}>
                  <option value="" hidden>Category</option>
                  <option value=" " >All</option>
                  {getDataItemInventory.map((user, index) => (
                    <option key={index} value={user?.id}>{user?.name}</option>
                  ))}
              </select>
            </div>
            <div className="mr-1">
              <select className="form-select" aria-label="Default select example" value={form2?.month}   name="month" onChange={handleChange} style={{ textAlign:"center", cursor:"pointer", fontSize:"13px"}}>
                <option value="" hidden>Month</option>
                <option value="01" style={{ textAlign:"center"}}>Januari</option>
                <option value="02" style={{textAlign:"center"}}>Februari</option>w
                <option value="03" style={{textAlign:"center"}}>Maret</option>
                <option value="04" style={{textAlign:"center"}}>April</option>
                <option value="05" style={{textAlign:"center"}}>Mei</option>
                <option value="06" style={{textAlign:"center"}}>Juni</option>
                <option value="07" style={{textAlign:"center"}}>Juli</option>
                <option value="08" style={{textAlign:"center"}}>Agustus</option>
                <option value="09" style={{textAlign:"center"}}>September</option>
                <option value="10" style={{textAlign:"center"}}>Oktober</option>
                <option value="11" style={{textAlign:"center"}}>November</option>
                <option value="12" style={{textAlign:"center"}}>Desember</option>    
              </select>
            </div>
            <div className="mr-1">
              <select className="form-select " aria-label="Default select example" value={form2?.year} name="year" onChange={handleChange} style={{ textAlign:"center", cursor:"pointer", fontSize:"13px"}}>
                <option value="" hidden>Year</option>
                {arrayTahun.map((tahun, index) => (
                  <option key={index} value={tahun}>{tahun}</option>
                ))}
              </select>
            </div>
          <form  style={{display:"flex", paddingRight:"10px"}}>
            <div onClick={GetResponseData2}  style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius:"3px", fontWeight:"600", fontSize:"12px",alignItems:"center", padding:"0px 20px", cursor:"pointer" }}>
              Filter
            </div>
          </form>
          </div>
          </div>
        </div>  
        </>
      }
 
  <Col xl='12' sm='12'> 
  <div>
    <div style={{ display: "block", height: "100%", overflowY: "auto", overflowX: "auto", width:"100%" }}>
      <div >
        <table className="table is-bordered" ref={tableRef}>
          <thead>
          <tr style={{backgroundColor: isTabletOrMobile? "white" : "#E9EFFF"}}>
              <th rowSpan={3} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif", alignContent:"center" }}>NO</th>
              <th rowSpan={3} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif", alignContent:"center" }}>NAMA BARANG</th>
              <th colSpan={3} rowSpan={2} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif", alignContent:"center" }}>STOCK AWAL</th>
              <th colSpan={4} rowSpan={2} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif", alignContent:"center" }}>STOCK MASUK</th>
              <th colSpan={13} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>STOCK KELUAR</th>
              <th rowSpan={3} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif", alignContent:"center" }}>TOTAL STOCK KELUAR</th>
              <th rowSpan={3} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif", alignContent:"center" }}>STOCK SISA</th>
              <th rowSpan={3} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif", alignContent:"center" }}>UNIT</th>
              <th rowSpan={3} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif", alignContent:"center"}}>NAMA SUPPLIER</th>
              <th rowSpan={3} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif", alignContent:"center" }}>KATEGORI</th>
              {/* <th rowSpan={3} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif", alignContent:"center" }}>KETERANGAN</th> */}
              <th rowSpan={3} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif", alignContent:"center" }}>SETUP</th>
            </tr>
          <tr style={{backgroundColor: isTabletOrMobile? "white" : "#E9EFFF"}}>
              <th colSpan={2} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>SRIKANDI</th>
              <th colSpan={2} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>BRAHMANA</th>
              <th colSpan={2} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>SADEWA</th>
              <th colSpan={2} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>ARJUNA</th>
              <th colSpan={2} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>ADMIN</th>
              <th colSpan={3} style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>LAINNYA</th>
          </tr>
          <tr style={{backgroundColor: isTabletOrMobile? "white" : "#E9EFFF"}}>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>QTY</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>ED</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>TOTAL HARGA</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>TANGGAL</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>ED</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>QTY</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>TOTAL HARGA</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>TANGGAL</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>QTY</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>TANGGAL</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>QTY</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>TANGGAL</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>QTY</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>TANGGAL</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>QTY</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>TANGGAL</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>QTY</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>TANGGAL</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>QTY</th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "1px solid #E1E1E1", fontFamily: "sans-serif" }}>KETERANGAN</th>

            </tr>

          </thead>
          <tbody>
            {getData.map((user,index) => (
              <tr key={index} style={{fontFamily:"sans-serif", fontSize:"11px", textAlign:"center"}}>
                <td style={{ lineHeight: "2", minWidth :"50px"}}>{page === 1 ? index + 1 : page === 2 ? (index + 1) + 10 : (index + 1) + ( page * 10 )}</td>
                <td style={{ lineHeight: "2", minWidth :"80px" }}>{user.name_inventory}</td>
                <td style={{ lineHeight: "2", minWidth :"80px"}}>{user.first_stock_qty}</td>
                <td style={{ lineHeight: "2", minWidth :"120px" }}>
                  {user?.status_expired === "3" ? 
                      <div style={{display:"flex", justifyContent:"center"}}>
                        <div style={{backgroundColor:"orange", padding:"0px 20px", borderRadius:"3px"}}>
                          {user?.first_stock_expired}
                        </div>
                      </div>
                      :
                      user?.status_expired === "2" ? 
                      <div style={{display:"flex", justifyContent:"center"}}>
                        <div style={{backgroundColor:"orange", padding:"0px 20px", borderRadius:"3px"}}>
                          {user?.first_stock_expired}
                        </div>
                      </div>
                      :
                      user?.status_expired === "1" ? 
                      <div style={{display:"flex", justifyContent:"center"}}>
                        <div style={{backgroundColor:"orange", padding:"0px 20px", borderRadius:"3px"}}>
                          {user?.first_stock_expired}
                        </div>
                      </div>
                      :
                      user?.status_expired === "0" ? 
                      <div style={{display:"flex", justifyContent:"center"}}>
                        <div style={{backgroundColor:"red", padding:"0px 20px", borderRadius:"3px"}}>
                          {user?.first_stock_expired}
                        </div>
                      </div>
                      :
                      <div style={{}}>
                        {user?.first_stock_expired}
                      </div>
                    }  
                </td>
                <td style={{ lineHeight: "2", minWidth :"100px" }}>{user.first_stock_price}</td>
                <>
                  <td style={{ lineHeight: "2" , minWidth :"80px" }}>
                    {user.stock_in.length === 0? 
                    <></>
                    :
                    <>
                    {user.stock_in.map((user,index) => (
                    <div key={index}>
                      {user.date}
                    </div>
                    ))}
                    </>
                    }
                  </td>
                  <td style={{ lineHeight: "2" , minWidth :"80px" }}>
                    {user.stock_in.length === 0? 
                    <></>
                    :
                    <>
                    {user.stock_in.map((user,index) => (
                    <div key={index}>
                      {user.expired_date}
                    </div>
                    ))}
                    </>
                    }
                  </td>
                  <td style={{ lineHeight: "2", minWidth :"80px"  }}>
                    {user.stock_in.length === 0? 
                      <></>
                      :
                      <>
                    {user.stock_in.map((user,index) => (
                    <div key={index}>
                      {user.qty}
                    </div>
                    ))}
                    </>
                    }
                  </td>
                  <td style={{ lineHeight: "2", minWidth :"100px"  }}>
                    {user.stock_in.length === 0? 
                      <></>
                      :
                      <>
                      {user.stock_in.map((user,index) => (
                      <div key={index}>
                        {user.price}
                      </div>
                      ))}
                    </>
                    }
                  </td>
                </>
                {/* Srikandi */}
                <td style={{ lineHeight: "2" , minWidth :"80px" }}>
                  {user.stock_out.length === 0? 
                    <></>
                    :
                    <>
                    {user.stock_out.filter(item => item.id_room === 'baa5e419-b89f-40ad-95b2-4a045a40c3a0' || item.id_room === '3fe5b011-7880-48a7-811b-eb9d27a24a92').map((user,index) => (
                      <div key={index}>
                        {user.date}
                      </div>
                    ))}
                  </>
                  }
                </td>
                <td style={{ lineHeight: "2" , minWidth :"80px" }}>
                  {user.stock_out.length === 0? 
                    <></>
                    :
                    <>
                    {user.stock_out.filter(item => item.id_room === 'baa5e419-b89f-40ad-95b2-4a045a40c3a0' || item.id_room === '3fe5b011-7880-48a7-811b-eb9d27a24a92').map((user,index) => (
                      <div key={index}>
                        {user.qty}
                      </div>
                    ))}
                  </>
                  }
                </td>
                {/* Brahmana */}
                <td style={{ lineHeight: "2" , minWidth :"80px" }}>
                  {user.stock_out.length === 0? 
                    <></>
                    :
                    <>
                    {user.stock_out.filter(item => item.id_room === '5af377fe-1279-4e2d-a556-d4f216ea8d4b' || item.id_room === '6b52f3b6-38eb-4643-9777-fb6a2f3370e6').map((user,index) => (
                    <div key={index}>
                      {user.date}
                    </div>
                    ))}
                  </>
                  }
                </td>
                <td style={{ lineHeight: "2", minWidth :"80px"  }}>
                  {user.stock_out.length === 0? 
                    <></>
                    :
                    <>
                      {user.stock_out.filter(item => item.id_room === '5af377fe-1279-4e2d-a556-d4f216ea8d4b' || item.id_room === '6b52f3b6-38eb-4643-9777-fb6a2f3370e6').map((user,index) => (
                      <div key={index}>
                        {user.qty}
                      </div>
                      ))}
                    </>
                  } 
                </td>
                {/* Sadewa */}
                <td style={{ lineHeight: "2", minWidth :"80px"  }}>
                  {user.stock_out.length === 0? 
                    <></>
                    :
                    <>
                      {user.stock_out.filter(item => item.id_room === 'dc9723a1-8677-406a-b1f2-5fdafa679079' || item.id_room === 'a63a2abc-2c60-4678-bd46-1b1e7689d289').map((user,index) => (
                      <div key={index}>
                        {user.qty}
                      </div>
                      ))}
                    </>
                  } 
                  
                </td>
                <td style={{ lineHeight: "2", minWidth :"80px"  }}>
                  {user.stock_out.length === 0? 
                    <></>
                    :
                    <>
                      {user.stock_out.filter(item => item.id_room === 'dc9723a1-8677-406a-b1f2-5fdafa679079' || item.id_room === 'a63a2abc-2c60-4678-bd46-1b1e7689d289').map((user,index) => (
                        <div key={index}>
                          {user.qty}
                        </div>
                      ))}
                    </>
                  } 
                  
                </td>
                {/* Arjuna */}
                <td style={{ lineHeight: "2", minWidth :"80px"  }}>
                  {user.stock_out.length === 0? 
                    <></>
                    :
                    <>
                      {user.stock_out.filter(item => item.id_room === 'dc9723a1-8677-406a-b1f2-5fdafa679079' || item.id_room === '266680b7-5b60-4fd7-b362-33d452a13889').map((user,index) => (
                      <div key={index}>
                        {user.qty}
                      </div>
                      ))}
                    </>
                  } 
                  
                </td>
                <td style={{ lineHeight: "2", minWidth :"80px"  }}>
                  {user.stock_out.length === 0? 
                    <></>
                    :
                    <>
                      {user.stock_out.filter(item => item.id_room === 'dc9723a1-8677-406a-b1f2-5fdafa679079' || item.id_room === '266680b7-5b60-4fd7-b362-33d452a13889').map((user,index) => (
                        <div key={index}>
                          {user.qty}
                        </div>
                      ))}
                    </>
                  } 
                  
                </td>
                {/* Admin */}
                <td style={{ lineHeight: "2", minWidth :"80px"  }}>
                  {user.stock_out.length === 0? 
                    <></>
                    :
                    <>
                      {user.stock_out.filter(item => item.id_room === '149cf9af-8f41-42ee-ae77-e9e21e479bb0').map((user,index) => (
                        <div key={index}>
                          {user.date}
                        </div>
                      ))}
                    </>
                  } 
                </td>
                <td style={{ lineHeight: "2", minWidth :"80px"  }}>
                  {user.stock_out.length === 0? 
                    <></>
                    :
                    <>
                      {user.stock_out.filter(item => item.id_room === '149cf9af-8f41-42ee-ae77-e9e21e479bb0').map((user,index) => (
                        <div key={index}>
                          {user.qty}
                        </div>
                      ))}
                    </>
                  } 
                  
                </td>
                 {/* Lainnya */}
                <td style={{ lineHeight: "2", minWidth :"80px"  }}>
                  {user.stock_out.length === 0? 
                    <></>
                    :
                    <>
                      {user.stock_out.filter(item => item.id_room !== 'baa5e419-b89f-40ad-95b2-4a045a40c3a0' && 
                      item.id_room !== '5af377fe-1279-4e2d-a556-d4f216ea8d4b' && 
                      item.id_room !== 'dc9723a1-8677-406a-b1f2-5fdafa679079' && 
                      item.id_room !== '149cf9af-8f41-42ee-ae77-e9e21e479bb0').map((user,index) => (
                        <div key={index}>
                          {user.date}
                        </div>
                      ))}
                    </>
                  } 
                  
                </td>
                <td style={{ lineHeight: "2", minWidth :"80px"  }}>
                  {user.stock_out.length === 0? 
                    <></>
                    :
                    <>
                       {user.stock_out.filter(item => item.id_room !== 'baa5e419-b89f-40ad-95b2-4a045a40c3a0' && 
                      item.id_room !== '5af377fe-1279-4e2d-a556-d4f216ea8d4b' && 
                      item.id_room !== 'dc9723a1-8677-406a-b1f2-5fdafa679079' && 
                      item.id_room !== '149cf9af-8f41-42ee-ae77-e9e21e479bb0').map((user,index) => (
                        <div key={index}>
                          {user.qty}
                        </div>
                      ))}
                    </>
                  } 
                </td>
                <td style={{ lineHeight: "2", minWidth :"80px"  }}>
                  {user.stock_out.length === 0? 
                    <></>
                    :
                    <>
                       {user.stock_out.filter(item => item.id_room !== 'baa5e419-b89f-40ad-95b2-4a045a40c3a0' && 
                      item.id_room !== '5af377fe-1279-4e2d-a556-d4f216ea8d4b' && 
                      item.id_room !== 'dc9723a1-8677-406a-b1f2-5fdafa679079' && 
                      item.id_room !== '149cf9af-8f41-42ee-ae77-e9e21e479bb0').map((user,index) => (
                        <div key={index}>
                          {user.note}
                        </div>
                      ))}
                    </>
                  } 
                </td>
                <td style={{ lineHeight: "2", minWidth :"80px"  }}>{user.total_stock_out}</td>
                <td style={{ lineHeight: "2" , minWidth :"80px" }}>{user.remaining_stock_all_date.length === null? "":user.remaining_stock_all_date}</td>
                <td style={{ lineHeight: "2" , minWidth :"80px" }}>{user.name_unit}</td>
                <td style={{ lineHeight: "2", minWidth :"80px"  }}>{user.name_vendor}</td>
                <td style={{ lineHeight: "2" , minWidth :"80px" }}>{user.name_item}</td>
                <td style={{lineHeight:"2", minWidth :"80px" }}>
                  <div style={{display:"flex",justifyContent:"center", alignItems:"center", alignContent:"center"}}>
                    <button onClick={() => viewModalUpdate(user?.id, user?.id_vendor, user?.id_unit, user?.id_category_inventory, user?.name_inventory, user?.first_stock_qty, user?.first_stock_expired, user?.first_stock_price, user?.note)} style={{ fontSize: "13px", color: "white", backgroundColor: "#ff9933", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display:"flex", marginRight:"3px" }}>
                      <FaEdit/>
                    </button>
                    <button onClick={() => {deleteById(user?.id)}} style={{ fontSize: "12px", color: "white", backgroundColor: "#B60000", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display:"flex" }}>
                      <FaTrash/>
                    </button>
                  </div>
                </td> 
              </tr>
            ))}
          </tbody>
        </table>
          
          </div>
        </div>
        </div>
        <div style={{ display: "flex", padding:"10px"}}>
          <div>
            <div style={{fontSize:"12px"}}>
              Total Rows: {rows} 
            </div>
            <div style={{fontSize:"12px"}}>
              Page: {rows ? page : 0} of {pages}
            </div>
            <p className="has-text-centered has-text-danger">{msg}</p>
          </div> 
            <div style={{flex:"50%", display:"flex", justifyContent:"end"}}>
              <nav
                    style={{fontSize:"12px"}}
                    className="pagination is-centered"
                    key={rows}
                    role="navigation"
                    aria-label="pagination"
                    >
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={Math.min(10, pages)}
                      onPageChange={changePage}
                      containerClassName={"pagination-list"}
                      pageLinkClassName={"pagination-link"}
                      previousLinkClassName={"pagination-previous"}
                      nextLinkClassName={"pagination-next"}
                      activeLinkClassName={"pagination-link is-current"}
                      disabledLinkClassName={"pagination-link is-disabled"}
                    />
                  </nav>
              </div>
            </div>  
  </Col>
</div>
  );
}
