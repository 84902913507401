import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Col} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API } from "../../config/api";
import "bulma/css/bulma.css";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import ModalAddMedis from "./ModalAdmin/ModalAdd"
import ModalUpdateMedis from "./ModalAdmin/ModalUpdate"
import LoaderHome from "../Loader/LoaderHome"
import * as XLSX from "xlsx";
import HeaderDiagnosaPatient from "./Components/HeaderDiagnosaPatient";
import TableDiagnosaPatient from "./Components/TableDiagnosaPatient";
import PaginationDiagnosaPatient from "./Components/PaginationDiagnosaPatient";

export default function Login() {
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  // Responsive to mobile or dekstop
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)'})
  // modal role add 
  const [id, setId] = useState();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [codeSkri, setCodeSkri] = useState([]);
  const [nameStr, setNameStr] = useState([]);
  const [nameSab, setNameSab] = useState([]);

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };


  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/user-service/diagnosa-patients?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      if (error.response.status === 401) {
        navigate('/dashboard')
        swal({
          title: 'Failed',
          text: `Akses dibatasi Tidak Dapat Mengakses Halaman Ini`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }else {
        swal({
          title: 'Failed',
          text: `${error.response.data.message}`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }
    }
  }

  useEffect(() => {
    GetResponseData()
  }, [page])

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response =  await API.delete(`/user-service/diagnosa-patients/${id}`,fetchParams);
        if (response.data.error === false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }  
      }
    })
  };

  const changePage = ({ selected }) => {
    setPage(selected+1);
  if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };
 
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const buttonRefresh = () => {
    window.location.reload();
  }
  
  const viewModalAdd = () => {
    setModalAdd(true)
  }

  const viewModalUpdate = (id, code_skri, name_str, name_sab) => {
    setModalUpdate(true)
    setId(id)
    setCodeSkri(code_skri)
    setNameStr(name_str)
    setNameSab(name_sab)
  }

  const [data, setData] = useState("");
  const [dataName, setDataName] = useState("");

  const handleFileUpload = (event) => {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onload = (e) => {
  const binaryStr = e.target.result;
  const workbook = XLSX.read(binaryStr, { type: 'binary' });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  const jsonData = XLSX.utils.sheet_to_json(worksheet);
  setData(jsonData);
  setDataName(file.name);
  event.target.value = "";  
  };
  reader.readAsBinaryString(file);
  };

  const postDiagnosa = async () => {
  Swal.fire({
    title: 'Apakah Kamu Yakin?',
    text: `Mengirim ${dataName}`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Ya, Hapus'
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const response = await API.post(
          `/user-service/diagnosa-patients/excel/store`,
          { diagnosis: data },
          fetchParams
        );
        if(response.data.error === false) {
          setData("");
          setDataName("");
          GetResponseData();
          Swal.fire({
            title: 'Success',
            text: "Your data has been successfully",
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
          });
         
        }
      } catch (error) {
        setData("");
        setDataName("");
        Swal.fire({
          title: 'Failed',
          text: `${error.response.data.message}`,
          icon: 'error',
          timer: 3000,
          showConfirmButton: false
        });
      
      }
    } 
    if (result.isDismissed) {  
      setData("");
      setDataName("");
    }
  });
  };

  useEffect(() => {
  if (dataName !== "") {
    postDiagnosa();
  }
  },[dataName]);

  return (
    <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
      {modalAdd && <ModalAddMedis  GetResponseData={GetResponseData}  show={modalAdd} onHide={() => setModalAdd(false)} />}
      {modalUpdate && <ModalUpdateMedis GetResponseData={GetResponseData} codeSkri={codeSkri} nameStr={nameStr} nameSab={nameSab} id={id} show={modalUpdate} onHide={() => setModalUpdate(false)} />}
      {loading && <LoaderHome />}

      {/* header */}
      <HeaderDiagnosaPatient
        isTabletOrMobile={isTabletOrMobile}
        query={query}
        setQuery={setQuery}
        viewModalAdd={viewModalAdd}
        buttonRefresh={buttonRefresh}
        searchData={searchData}
        handleFileUpload={handleFileUpload}
      />
      {/* end header */}
 
      <Col xl='12' sm='12'> 
        <div>
          {/* start table */}
          <TableDiagnosaPatient
            getData={getData}
            page={page}
            viewModalUpdate={viewModalUpdate}
            deleteById={deleteById}
            isTabletOrMobile={isTabletOrMobile}
          />
          {/* end table */}
        </div>
        {/* start pagination */}
        <PaginationDiagnosaPatient
          rows={rows}
          page={page}
          pages={pages}
          changePage={changePage}
          msg={msg}
        />
        {/* end pagination */}
      </Col>
    </div>
  );
}

// Clear