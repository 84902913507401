import React,  {useRef}  from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';

const TableSchedule = ({ getData, page, viewModalUpdate, deleteById, isTabletOrMobile }) => {
  const tableRef = useRef(null);
  return (
    <div style={{ display: 'block', height: '100%', overflowY: 'auto', overflowX: 'auto' }}>
      <div>
        <table className="table is-bordered" ref={tableRef} style={styles.table}>
          <thead>
            <tr style={{ backgroundColor: isTabletOrMobile ? 'white' : '#E9EFFF' }}>
              <th style={styles.th}>No</th>
              <th style={styles.th}>Nama Dokter</th>
              <th style={styles.th}>Email</th>
              <th style={styles.th}>Date</th>
              <th style={styles.th}>Time</th>
              <th style={styles.th}>Setup</th>
            </tr>
          </thead>
          <tbody>
            {getData.map((user, index) => (
              <tr key={index} style={styles.tr}>
                <td style={styles.td}>{page === 1 ? index + 1 : page === 2 ? (index + 1) + 10 : (index + 1) + (page * 10)}</td>
                <td style={styles.td}>drg {user.user_data.username}</td>
                <td style={styles.td}>{user.user_data.email}</td>
                <td style={styles.td}>
                  {formatDate(user.ci_date)} - {formatDate(user.co_date)}
                </td>
                <td style={styles.td}>
                  {formatTime(user.ci_time)} - {formatTime(user.co_time)}
                </td>
                <td style={styles.td}>
                  <div style={styles.buttonContainer}>
                    <button 
                      onClick={() => viewModalUpdate(user.id, user.id_user, user.ci_date, user.ci_time, user.co_date, user.co_time)}
                      style={styles.buttonEdit}
                    >
                      <FaEdit />
                    </button>
                    <button 
                      onClick={() => deleteById(user.id)} 
                      style={styles.buttonDelete}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// Fungsi untuk memformat tanggal dan waktu
const formatDate = (date) => {
  const [year, month, day] = date.split('-');
  return `${day}-${month}-${year}`;
};

const formatTime = (time) => {
  const [hour, minute] = time.split(':');
  return `${hour}:${minute}`;
};

// Styling untuk elemen tabel
const styles = {
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    fontFamily: 'sans-serif',
    fontSize: '12px',
    textAlign: 'center',
    color: '#525252',
    border: 'none',
    padding: '10px',
  },
  tr: {
    fontFamily: 'sans-serif',
    fontSize: '11px',
    textAlign: 'center',
  },
  td: {
    lineHeight: '2',
    padding: '10px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonEdit: {
    fontSize: '13px',
    color: 'white',
    backgroundColor: '#ff9933',
    padding: '5px 10px',
    borderRadius: '3px',
    cursor: 'pointer',
    border: 'none',
    display: 'flex',
    marginRight: '5px',
  },
  buttonDelete: {
    fontSize: '12px',
    color: 'white',
    backgroundColor: '#B60000',
    padding: '5px 10px',
    borderRadius: '3px',
    cursor: 'pointer',
    border: 'none',
    display: 'flex',
  }
};

export default TableSchedule;
