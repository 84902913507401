import React from "react";
import { FaEdit, FaTrash } from "react-icons/fa";

const TableFee = ({ getData, page, formatToRupiah, viewModalUpdate, deleteById, isTabletOrMobile }) => {
  return (
    <div style={{ display: "block", height: "100%", overflowY: "auto", overflowX: "auto" }}>
      <div>
        <table className="table is-bordered">
          <thead>
            <tr style={{ backgroundColor: isTabletOrMobile ? "white" : "#E9EFFF" }}>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>
                No
              </th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>
                Nama Fee
              </th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>
                Biaya Fee
              </th>
              <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>
                Setup
              </th>
            </tr>
          </thead>
          <tbody>
            {getData.map((user, index) => (
              <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center" }}>
                <td style={{ lineHeight: "2" }}>
                  {page === 1
                    ? index + 1
                    : page === 2
                    ? index + 1 + 10
                    : index + 1 + page * 10}
                </td>
                <td style={{ lineHeight: "2" }}>{user.name}</td>
                <td style={{ lineHeight: "2" }}>{formatToRupiah(user.price)}</td>
                <td style={{ lineHeight: "2" }}>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button
                      onClick={() => viewModalUpdate(user?.id, user?.name, user?.price)}
                      style={{
                        fontSize: "13px",
                        color: "white",
                        backgroundColor: "#ff9933",
                        padding: "5px 5px",
                        borderRadius: "3px",
                        cursor: "pointer",
                        border: "none",
                        display: "flex",
                        marginRight: "3px",
                      }}
                    >
                    <FaEdit />
                    </button>
                    <button
                      onClick={() => deleteById(user?.id)}
                      style={{
                        fontSize: "12px",
                        color: "white",
                        backgroundColor: "#B60000",
                        padding: "5px 5px",
                        borderRadius: "3px",
                        cursor: "pointer",
                        border: "none",
                        display: "flex",
                      }}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableFee;
