import React from 'react';
import ReactPaginate from 'react-paginate';

const PaginationSchedule = ({ rows, page, pages, msg, changePage }) => {
  return (
    <div style={styles.container}>
      <div style={styles.infoContainer}>
        <div style={styles.text}>Total Rows: {rows}</div>
        <div style={styles.text}>Page: {rows ? page : 0} of {pages}</div>
        {msg && <p style={styles.errorMessage}>{msg}</p>}
      </div>

      <div style={styles.paginationContainer}>
        <nav style={styles.paginationNav} className="pagination is-centered" aria-label="pagination">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={Math.min(10, pages)}  // Limit the maximum pages to 10
            onPageChange={changePage}
            containerClassName="pagination-list"
            pageLinkClassName="pagination-link"
            previousLinkClassName="pagination-previous"
            nextLinkClassName="pagination-next"
            activeLinkClassName="pagination-link is-current"
            disabledLinkClassName="pagination-link is-disabled"
          />
        </nav>
      </div>
    </div>
  );
};

// Styling
const styles = {
  container: {
    display: "flex",
    padding: "10px",
    justifyContent: "space-between",
  },
  infoContainer: {
    fontSize: "12px",
  },
  text: {
    marginBottom: "5px",
  },
  errorMessage: {
    fontSize: "12px",
    color: "#D8000C",  // Red color for error message
    textAlign: "center",
  },
  paginationContainer: {
    flex: "50%",
    display: "flex",
    justifyContent: "flex-end",
  },
  paginationNav: {
    fontSize: "12px",
  },
};

export default PaginationSchedule;
