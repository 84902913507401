import AdminHome from "../../components/AdminPasien/AdminPasienTrash";
import React from "react";
import { useMediaQuery } from 'react-responsive'
import Sidebar from "../../components/Sidebar/Sidebar";
import NavbarMobile from "../../components/Navbar/NavbarAdmin/NavbarMobile"
import NavbarDekstop from "../../components/Navbar/NavbarAdmin/NavbarDekstop"

export default function Home() {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)'})
  
  return (
    <div style={{ minHeight:isTabletOrMobile? "100vh" : "100vh", display:isTabletOrMobile? "" : "flex" }}>
      {isTabletOrMobile ? (
      <div style={{display:""}}>
        <NavbarMobile />         
        <AdminHome />
      </div>
    ) : (
      <div style={{ display: "flex", flex: "1" }}>
        <div style={{ flex: "10%" }}>
          <Sidebar />
        </div>
        <div style={{ flex: "90%" }}>
          <NavbarDekstop />
          <AdminHome />
        </div>
      </div>
    )}
  </div>
  );
}