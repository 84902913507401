import React from 'react';
import { FaAddressBook, FaPlus, FaSync } from 'react-icons/fa';

const HeaderSchedule = ({ isTabletOrMobile, query, setQuery, searchData, viewModalAdd, buttonRefresh }) => {
  const commonButtonStyle = {
    fontSize: "12px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    borderRadius: "3px",
    padding: "5px 10px",
  };

  const commonInputStyle = {
    backgroundColor: "#E9E9E9",
    border: "none",
    height: "100%",
    padding: "0px 10px",
  };

  return (
    <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: isTabletOrMobile ? "0px 0px 10px 0px" : "10px 0px" }}>
      <div style={{ flex: isTabletOrMobile ? "none" : "50%", fontSize: "16px", display: "flex", alignItems: "center", paddingLeft: isTabletOrMobile ? "0" : "10px", color: "#001F8B", justifyContent: isTabletOrMobile ? "center" : "flex-start", backgroundColor: isTabletOrMobile ? "#E9EFFF" : "transparent" }}>
        <FaAddressBook style={{ marginRight: "5px" }} /> List Schedule
      </div>

      <div style={{ flex: "50%", display: "flex", justifyContent: "end", paddingRight: "10px" }}>
        <div onClick={viewModalAdd} style={{ ...commonButtonStyle, backgroundColor: "#3D64FF", color: "white", marginRight: "5px" }}>
          <FaPlus />
        </div>
        <div onClick={buttonRefresh} style={{ ...commonButtonStyle, backgroundColor: "white", border: "1px solid #DEDEDE", color: "#3D64FF", marginRight: "5px" }}>
          <FaSync style={{ fontSize: "15px", color: "#3D64FF" }} />
        </div>
        <form onSubmit={searchData} style={{ display: "flex", paddingRight: isTabletOrMobile ? "0px" : "10px" }}>
          <input
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="focused"
            style={commonInputStyle}
            type="text"
            placeholder=""
          />
          <button type="submit" style={{ ...commonButtonStyle, backgroundColor: "#3D64FF", color: "white", fontWeight: "600", fontSize: "12px", padding: "0px 10px" }}>
            Search
          </button>
        </form>
      </div>
    </div>
  );
};

export default HeaderSchedule;
