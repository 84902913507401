import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import Logo_Signature from "../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import BackgroundBatik from "../../assets/signature/background-batik.jpg"
import SignatureCanvas from 'react-signature-canvas'
import { FaAddressBook, FaAppStoreIos, FaBackspace, FaBackward, FaBell, FaCamera, FaCheckCircle, FaEdit, FaEnvelope, FaEnvelopeOpenText, FaGenderless, FaHandHoldingMedical, FaList, FaListAlt, FaMailBulk, FaMobile, FaMonero, FaMoneyBillAlt, FaMoneyBillWave, FaMoneyCheck, FaPhone, FaPhoneAlt, FaReply, FaReplyAll, FaReplyd, FaSearch, FaSignOutAlt, FaSpinner, FaSync, FaTransgender, FaTrash, FaUndo, FaUndoAlt, FaWatchmanMonitoring } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { Alert } from "react-bootstrap";
import { useMutation } from "react-query";
import { API } from "../../config/api";
import React, { useRef } from 'react';
import Dropdown from "../Auth/Dropdown";
import DropdownBotton from "../Auth/DropdownBotton";
import LoaderHome from "../Loader/LoaderHome"
import "../../index.css"
import logo_side from "../../assets/signature/logo-side-detail.png"
import { Link } from 'react-router-dom';
// import List from './Component/Home'
import { setAuthToken } from '../../config/api';
import masgan from "../../assets/signature/signatureNewIcon.png";
import 'chart.js/auto'
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "bulma/css/bulma.css";
import Swal from "sweetalert2";
import { Container} from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaGoogle, FaInstagram,FaLinkedin, FaGithub } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import NavbarPasienOnline from "../../components/Navbar/NavbarPasienOnline/NavbarDekstop"
import NavbarPasienOnlineRemake from "../../components/Navbar/NavbarPasienOnline/NavbarDekstopRemake"
import NavbarTopHeader from "../../components/Navbar/NavbarPasienOnline/NavbarTopHeader"
import NavbarHeader from "../../components/Navbar/NavbarPasienOnline/NavbarHeader"
import NavbarHeaderRemake from "../../components/Navbar/NavbarPasienOnline/NavbarHeaderToogle"
import FooterBottom from "../../components/Footer/Footer"

export default function Login() {
  document.title = "SDC Apps";
  const [state, dispatch] = useContext(UserContext);
  const [message, setMessage] = useState(null);
  const [imageName, setImageName] = useState([])
  const [imageContent, setImageContent] = useState([])
  const [preview, setPreview] = useState(null);
  const token = localStorage.getItem("token");
  const storageItems = JSON.parse(localStorage.getItem('user_data'));

  const nama = localStorage.getItem("username")
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [pagination,setPagination] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1000);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(1);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [search,setSearch] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataMap, setDataMap] = useState("");
  const [dataSearch, setDataSearch] = useState("");
  
  // modal role add 
  const [id, setId] = useState();
  const [nameUpdate, setNameUpdate] = useState();
  const [UrlUpdate, setUrlUpdate] = useState();
  const [IconUpdate, setIconUpdate] = useState();
  const [NumberUpdate, setNumberUpdate] = useState();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)'})
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileRemake = useMediaQuery({ query: '(max-width: 800px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [modalShow, setModalShow] = useState(false);
  const firstNameInputRef = useRef(null);

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/patient-appointments/${storageItems?.id}?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    GetResponseData()
  }, [page])

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response =  await API.delete(`user-service/privileges/menus/${id}`,fetchParams);
        if (response.data.error == false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }  
      }
    })
  };

  const changePage = ({ selected }) => {
    setPage(selected+1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };
 
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const buttonRefresh = () => {
    window.location.reload();
  }

  const viewModalAdd = () => {
   navigate("/appoinment-pasien-online")
  }

  const viewModalUpdate = (id, name, url, icon, number_order) => {
    setModalUpdate(true)
    setId(id)
    setNameUpdate(name)
    setUrlUpdate(url)
    setIconUpdate(icon)
    setNumberUpdate(number_order)
  }

  const logout = (e)=>{
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };

  return (
  <div style={{backgroundColor: "#FDFDFD",  }}>
    {loading && <LoaderHome />}
    
    <NavbarTopHeader />
      
    {isTabletOrMobileRemake? 
    <div style={{ position: "fixed", display: "flex", justifyContent: "center", bottom: "0", zIndex: "1", width:"100%" }}>
      <DropdownBotton  GetResponseData={GetResponseData} />
    </div>
      :
    <Col xl="12" style={{ padding: "0px 0px", position: "fixed",width:"90px"}}>
      <div style={{display: "flex", justifyContent: "", marginTop: "150px" }}>
        <Dropdown GetResponseData={GetResponseData}/>  
      </div>
    </Col>  
    }
     
    {isTabletOrMobileRemake ? 
      <NavbarHeaderRemake/>
      :
      <NavbarHeader />    
    }  

    {isTabletOrMobileRemake ? 
      <>
        <div style={{display: "flex",backgroundColor:"#0097D8", height:"17vh"}}></div>
        <div style={{display: "flex", width:"100%"}}>
          <NavbarPasienOnlineRemake/>
        </div>
      </>
      :
      <>
        <div style={{display: "flex",backgroundColor:"#0097D8",height:"20vh" }}></div>
        <div style={{display: "flex", width:"100%"}}>
            <NavbarPasienOnline />  
        </div>
      </>
      }
      
        <div style={{padding:isTabletOrMobileRemake? "20px 20px 20px 20px" : "20px 100px 20px 100px"}}>
          <Col xl='12' sm='12' style={{ backgroundColor: "white", boxShadow: "1px 1px 5px #BFBFBF",backgroundColor:"white", padding:"20px 25px 10px 25px", borderRadius:"5px" }}>
          <div>
          <div style={{display:"block", height:"100%", overflowY:"auto",overflowX:"auto"}}>
            <div>
              <table className="table" style={{border:"1px solid #DEDEDE"}}> 
                <thead>
                  <tr style={{backgroundImage:"linear-gradient(to left, #69D3FF, #69D3FF)"}}>
                    <th style={{ fontFamily: "revert", fontSize: "15px", textAlign: "center", color: "white", border: "none", fontFamily: "sans-serif" }}>Tanggal</th>
                    <th style={{ fontFamily: "revert", fontSize: "15px", textAlign: "center", color: "white", border: "none", fontFamily: "sans-serif" }}>Anamnesa</th>
                    <th style={{ fontFamily: "revert", fontSize: "15px", textAlign: "center", color: "white", border: "none", fontFamily: "sans-serif" }}>Pasien</th>
                    <th style={{ fontFamily: "revert", fontSize: "15px", textAlign: "center", color: "white", border: "none", fontFamily: "sans-serif" }}>Dokter</th>
                    <th style={{ fontFamily: "revert", fontSize: "15px", textAlign: "center", color: "white", border: "none", fontFamily: "sans-serif" }}>Jadwal</th>
                    <th style={{ fontFamily: "revert", fontSize: "15px", textAlign: "center", color: "white", border: "none", fontFamily: "sans-serif" }}>Status</th>
                    {/* <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "white", border: "none", fontFamily: "sans-serif" }}>Status</th> */}
                  </tr>
                </thead>
                <tbody>
                      {getData.map((user,index) => (
                      <tr key={index} style={{fontFamily:"sans-serif", fontSize:"15px", textAlign:"center", color:"#4D4D4D"}}>
                          {/* <td style={{lineHeight:"2"}}>{page === 1 ? index + 1 : page === 2 ? (index + 1) + 10 : (index + 1) + ( page * 10 )}</td> */}
                          <td style={{ lineHeight: "2" }}>
                            {user.date.split('-')[2]}&nbsp;
                            {user.date.split('-')[1] === "01" ? "Januari" : "" ||
                            user.date.split('-')[1] === "02" ? "Februari" : "" ||
                            user.date.split('-')[1] === "03" ? "Maret" : "" ||
                            user.date.split('-')[1] === "04" ? "April" : "" ||  
                            user.date.split('-')[1] === "05" ? "Mei" : "" ||
                            user.date.split('-')[1] === "06" ? "Juni" : "" ||
                            user.date.split('-')[1] === "07" ? "Juli" : "" ||
                            user.date.split('-')[1] === "08" ? "Agustus" : "" ||
                            user.date.split('-')[1] === "09" ? "September" : "" ||
                            user.date.split('-')[1] === "10" ? "Oktober" : "" ||
                            user.date.split('-')[1] === "11" ? "November" : "" ||
                            user.date.split('-')[1] === "12" ? "Desember" : ""}&nbsp;
                            {user.date.split('-')[0]}
                          </td>
                          <td style={{ lineHeight: "2" }}>{user?.anamnesa}</td>
                          <td style={{ lineHeight: "2" }}>{user?.patient_name}</td>
                          <td style={{ lineHeight: "2" }}>{user?.doctor_name}</td>
                          <td style={{ lineHeight: "2" }}>{user?.time.split(":")[0]}:{user?.time.split(":")[1]}</td>
                          {/* <td style={{ lineHeight: "2" }}>{user?.patient_name}</td> */}
                          {/* {/* <td style={{ lineHeight: "2" }}>{user?.doctor_name}</td> */}
                          <td style={{ lineHeight: "2" }}>
                            {user?.status === 0 || user?.status === false ? 
                            <div style={{display:"flex",justifyContent:"center"}}>
                                  MENUNGGU KONFIRMASI
                            </div>
                              :
                            <div>
                              {user?.invoice_paid === true? 
                                 <div style={{display:"flex",justifyContent:"center"}}>
                                    LUNAS
                                </div>
                                  :  
                                <div style={{display:"flex",justifyContent:"center"}}>
                                    TERKONFIRMASI
                                </div>
                                }
                              </div>
                          } 
                          </td> 
                      </tr>
                    ))}
                </tbody>
              </table> 
            </div>
          </div>
                {/* <div style={{ display: "flex", padding:"10px"}}>
                <div>
                  <div style={{fontSize:"12px"}}>
                    Total Rows: {rows} 
                  </div>
                  <div style={{fontSize:"12px"}}>
                    Page: {rows ? page : 0} of {pages}
                  </div>
                  <p className="has-text-centered has-text-danger">{msg}</p>
                </div> 
                  <div style={{flex:"50%", display:"flex", justifyContent:"end"}}>
                    <nav
                        style={{fontSize:"12px"}}
                        className="pagination is-centered"
                        key={rows}
                        role="navigation"
                        aria-label="pagination"
                        >
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          pageCount={Math.min(10, pages)}
                          onPageChange={changePage}
                          containerClassName={"pagination-list"}
                          pageLinkClassName={"pagination-lin"}
                          previousLinkClassName={"pagination-previous"}
                          nextLinkClassName={"pagination-next"}
                          activeLinkClassName={"pagination-link is-current"}
                          disabledLinkClassName={"pagination-link is-disabled"}
                        />
                      </nav>
                    </div>
                  </div>  */}
            </div>
          </Col>
        </div>
         
    <FooterBottom/>
  </div>
  );
}
