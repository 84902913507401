import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import { ScaleLoader } from "react-spinners";
import LoaderAction from "../../Loader/LoaderAction"
import LogoDatePicker from "../../../assets/signature/logo-date-pick.png";
import Select from "react-select"

  
export default function ModalRoleUpdate(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getData, setGetData] = useState([]);
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);
  const [idRoleDokter, setIdRoleDokter] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState([]);
  // console.log(props)
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };
// console.log(props)
  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/users?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    GetResponseData()
  }, [])

  const [form, setForm] = useState({
    id_user: "",
    ci_date: "",
    ci_time: "",
    co_date: "",
    co_time: "",
  });


  useEffect(() => {
    console.log("props:", props);  
    setForm({
      ...form,
      id_user: `${props?.id_user}`,
      ci_date: `${props?.ci_date}`,
      ci_time: `${props?.ci_time}`,
      co_date: `${props?.co_date}`,
      co_time: `${props?.co_time}`,
    });
  }, [props]);
  
  
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const response = await API.put(`user-service/schedules/${props.id}`, {
        id_user: form?.id_user,
        ci_date: form?.ci_date,
        ci_time: form?.ci_time,
        co_date: form?.co_date,
        co_time: form?.co_time,
      }, fetchParams);
  
      console.log(response, "response update");
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 3000,
          buttons: false
        });
        props.GetResponseData();
        props.onHide();
      } else {
        swal({
          title: 'Failed',
          text: 'Update failed!',
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }
    } catch (error) {
      setLoading(false);
      swal({
        title: 'Failed',
        text: `${error.response?.data?.message || error.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  });
  

  const handleChangeSelect = (e) => {
    const value = e?.value !== undefined ? e.value : e?.target?.value;
    const name = 'id_user';  
  
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));

    console.log(value);  // Periksa nilai yang dipilih
  };
  

  useEffect(() => {
    const formattedOptions = getData.map((user) => ({
      value: user?.id, 
      label: `${user.firstname} ${user.lastname}`, 
    }));

    setOptions(formattedOptions); 
  }, [getData]);

  const selectedOption = options.find((option) => option.value === form?.id_user);
  
  console.log(form); // Pastikan data yang dikirimkan sudah sesuai


  return (
  <div>
     {loading && <LoaderAction/>}
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>
          <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
            <div style={{ flex: "92%", fontSize: "20px", display: "flex", alignItems: "center", paddingLeft: "10px", color: "white", fontWeight: "600" }}>
              Update Schedule Dokter
            </div>
            <div style={{ flex: "8%", fontSize: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "white" }}>
              <FaTimes onClick={() => setProopsData(props.onHide)} style={{ cursor: "pointer" }} />
            </div>
          </div>
          <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "none" }}>
            <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >
            
            <div className="mt-3" style={{ display: "flex"}}>
          <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
            Pilih Dokter
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
            {/* <select className="form-select" aria-label="Default select example" onChange={handleChange} value={form?.id_user}  name="id_user" style={{ textAlign:"", cursor:"pointer"}}>
                <option value="" hidden>Select</option>
                {getData.map((user,index) => (
                  <option value={user?.id} style={{textAlign: "" }}>{user?.firstname} {user?.lastname}</option>                         
                ))}
            </select> */}

            <Select
              aria-label="Default select example"
              onChange={handleChangeSelect} 
              value={form?.id_user} 
              name="id_user"
              style={{ textAlign: "", cursor: "pointer" }}
              options={options} 
            />
          </div>
        </div>

        <div className="mt-4" style={{ display: "flex"}}>
          <div style={{flex:"5%"}}>
            <p>Tanggal Dan Waktu Checkin</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%"}}>:</p>
            <input type="date" name="ci_date" value={form?.ci_date} onChange={handleChange} style={{
                  width: "32%",
                  marginRight:"10px",
                  border: "1px solid #C9C9C9",
                  outline: "",
                  height: "37.5px",
                  borderRadius: "5px",
                  paddingLeft: "5px",
                  backgroundImage: `url(${LogoDatePicker})`,
                  backgroundPosition: 'calc(100% - 10px) center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '25px',
                  }}/>
            <select onChange={handleChange} value={form?.ci_time}  name="ci_time" style={{
                  cursor: "pointer",
                  width: "20%",
                  textAlign:"center",
                  border: "1px solid #C9C9C9",
                  outline: "",
                  height: "37.5px",
                  borderRadius: "5px",
                  paddingLeft: "5px",
                 }}>
              <option value="" hidden>Jam</option>
              <option value="09:00:00">09:00</option>
              <option value="09:30:00">09:30</option>
              <option value="10:00:00">10:00</option>
              <option value="10:30:00">10:30</option>
              <option value="11:00:00">11:00</option>
              <option value="11:30:00">11:30</option>
              <option value="12:00:00">12:00</option>
              <option value="12:30:00">12:30</option>
              <option value="13:00:00">13:00</option>
              <option value="13:30:00">13:30</option>
              <option value="14:00:00">14:00</option>
              <option value="14:30:00">14:30</option>
              <option value="15:00:00">15:00</option>
              <option value="15:30:00">15:30</option>
              <option value="16:00:00">16:00</option>
              <option value="16:30:00">16:30</option>
              <option value="17:00:00">17:00</option>
              <option value="17:30:00">17:30</option>
              <option value="18:00:00">18:00</option>
              <option value="18:30:00">18:30</option>
              <option value="19:00:00">19:00</option>
              <option value="19:30:00">19:30</option>
              <option value="20:00:00">20:00</option>
              <option value="20:30:00">20:30</option>
              <option value="21:00:00">21:00</option>
              <option value="21:30:00">21:30</option>
              <option value="22:00:00">22:00</option>   
            </select> 
                                      
          </div>
        </div>
        
            
        <div className="" style={{ display: "flex"}}>
          <div style={{flex:"5%"}}>
            <p>Tanggal Dan Waktu Checkout</p>
          </div>
          <div style={{ flex: "60%", display:"flex"}}>
            <p style={{marginRight:"5px", height:"100%"}}>:</p>
            <input type="date" name="co_date" value={form?.co_date} onChange={handleChange} style={{
                  width: "32%",
                  marginRight:"10px",
                  border: "1px solid #C9C9C9",
                  outline: "",
                  height: "37.5px",
                  borderRadius: "5px",
                  paddingLeft: "5px",
                  backgroundImage: `url(${LogoDatePicker})`,
                  backgroundPosition: 'calc(100% - 10px) center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '25px',
                  }}/>
            <select onChange={handleChange} value={form?.co_time}  name="co_time" style={{
                  cursor: "pointer",
                  width: "20%",
                  textAlign:"center",
                  border: "1px solid #C9C9C9",
                  outline: "",
                  height: "37.5px",
                  borderRadius: "5px",
                  paddingLeft: "5px",
                 }}>
              <option value="" hidden>Jam</option>
              <option value="09:00:00">09:00</option>
              <option value="09:30:00">09:30</option>
              <option value="10:00:00">10:00</option>
              <option value="10:30:00">10:30</option>
              <option value="11:00:00">11:00</option>
              <option value="11:30:00">11:30</option>
              <option value="12:00:00">12:00</option>
              <option value="12:30:00">12:30</option>
              <option value="13:00:00">13:00</option>
              <option value="13:30:00">13:30</option>
              <option value="14:00:00">14:00</option>
              <option value="14:30:00">14:30</option>
              <option value="15:00:00">15:00</option>
              <option value="15:30:00">15:30</option>
              <option value="16:00:00">16:00</option>
              <option value="16:30:00">16:30</option>
              <option value="17:00:00">17:00</option>
              <option value="17:30:00">17:30</option>
              <option value="18:00:00">18:00</option>
              <option value="18:30:00">18:30</option>
              <option value="19:00:00">19:00</option>
              <option value="19:30:00">19:30</option>
              <option value="20:00:00">20:00</option>
              <option value="20:30:00">20:30</option>
              <option value="21:00:00">21:00</option>
              <option value="21:30:00">21:30</option>
              <option value="22:00:00">22:00</option>   
            </select> 
                                      
          </div>
        </div>
            
              <div style={{ padding: "0px 0px", marginTop: "0px", display: "flex", justifyContent: "end" }}>
                <div>
                  <Button className="mt-4" type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px" }}>
                    Update
                  </Button>
                </div>
              </div>
            </Form>
          </Modal.Body>
          {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
      </Modal>
      
    </div>
    );
  }
