import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Col} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API } from "../../config/api";
import "bulma/css/bulma.css";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import LoaderHome from "../Loader/LoaderHome"
import ModalDataPatient from "./ModalPatientAdd/ModalAdd"
import HeaderPatient from "./Components/HeaderPatient";
import TablePatient from "./Components/TablePatient";
import PaginationPatient from "./Components/PaginationPatient";
import SearchFilter from "./PasienFilter/Search";
import SingleDateFilter from "./PasienFilter/SingleDate";
import DateRangeFilter from "./PasienFilter/RangeDate";
import MonthFilter from "./PasienFilter/SingleMonth";
import MonthRangeFilter from "./PasienFilter/RangeMonth";


export default function Login() {
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startMonth, setStartMonth] = useState('');
  const [endMonth, setEndMonth] = useState('');

  const [isDateFilterVisible, setIsDateFilterVisible] = useState(false);
  const [isSearchFilterVisible, setIsSearchFilterVisible] = useState(false);
  const [isMonthFilterVisible, setIsMonthFilterVisible] = useState(false);
  const [isSingleDateFilterVisible, setSingleDateFilterVisible] = useState(false);
  const [isMonthRangeFilterVisible, setIsMonthRangeFilterVisible] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)'})
  const [modalUpdateDataPatient, setModalUpdateDataPatient] = useState(false);
  const [idUpdatePatient , setIdUpdatePatient] = useState(false);
  
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)

      let url = `transaction-service/patients?page=${page}&limit=${limit}&ascending=${ascending}&search=${query}`;
      
      if (startDate) {
        url += `&start_date=${startDate}`; 
      }

      if (endDate) {
        url += `&end_date=${endDate}`;
      }

      if (startMonth) {
        url += `&start_month=${startMonth}`;
      }

      if (endMonth) {
        url += `&end_month=${endMonth}`;
      }
  
      const response = await API.get(url, fetchParams);
      // const response = await API.get(`transaction-service/patients?page=${page}&limit=${limit}&ascending=${ascending}&search=${query}`, fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      if (error.response.status === 401) {
        navigate('/dashboard')
        swal({
          title: 'Failed',
          text: `Akses dibatasi Tidak Dapat Mengakses Halaman Ini`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }else {
        swal({
          title: 'Failed',
          text: `${error.response.data.message}`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }
    }
  }

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response =  await API.delete(`/transaction-service/patients/${id}`,fetchParams);
        if (response.data.error === false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }  
      }
    })
  };

  const changePage = ({ selected }) => {
    setPage(selected+1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };
 
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  useEffect(() => {
    GetResponseData()
  }, [page])

  const buttonRefresh = () => {
    window.location.reload();
  };

  const odontogramPatient = (id, first_name, last_name) => {
    navigate('/odontograms/' + id + "/" + first_name + "/" + last_name);
  };

  const soapPatient = (id, first_name, last_name) => {
    navigate('/soap-patients/' + id + "/" + first_name + "/" + last_name);
  };

  let modalPhysicalCheck = async (id) => {
    navigate('/phy-checks/'+ id)
  };

  let modalMedicalRecord = async (id, code) => {
    navigate('/medical-record/'+ id + "/" + code)
  };

  const modalMedisRoleAdd = (id_patient) => {
    setModalUpdateDataPatient(true)
    setIdUpdatePatient(id_patient)
  }


  // Start Button Filter
  const handleSearchToggle = () => {
    setIsSearchFilterVisible(!isSearchFilterVisible);
    if (isDateFilterVisible) setIsDateFilterVisible(false); 
    if (isMonthFilterVisible) setIsMonthFilterVisible(false);
    if (isSingleDateFilterVisible) setSingleDateFilterVisible(false);
    if (isMonthRangeFilterVisible) setIsMonthRangeFilterVisible(false); 

    clearInput();
  };

  const handleSingleDateFilterToggle = () => {
    setSingleDateFilterVisible(!isSingleDateFilterVisible);
    if (isSearchFilterVisible) setIsSearchFilterVisible(false); 
    if (isMonthFilterVisible) setIsMonthFilterVisible(false);
    if (isMonthRangeFilterVisible) setIsMonthRangeFilterVisible(false);
    if (isDateFilterVisible) setIsDateFilterVisible(false);

    if (isSingleDateFilterVisible) {
      setStartDate('');
    }
  };

  const handleDateFilterToggle = () => {
    setIsDateFilterVisible(!isDateFilterVisible);
    if (isSearchFilterVisible) setIsSearchFilterVisible(false); 
    if (isMonthFilterVisible) setIsMonthFilterVisible(false);
    if (isSingleDateFilterVisible) setSingleDateFilterVisible(false);
    if (isMonthRangeFilterVisible) setIsMonthRangeFilterVisible(false);

    if (isDateFilterVisible) {
      setStartDate('');
      setEndDate('');
    }
    clearInput();
  };

  const handleMonthFilterToggle = () => {
    setIsMonthFilterVisible(!isMonthFilterVisible);
    
    if (isSearchFilterVisible) setIsSearchFilterVisible(false);
    if (isDateFilterVisible) setIsDateFilterVisible(false);
    if (isSingleDateFilterVisible) setSingleDateFilterVisible(false);
    if (isMonthRangeFilterVisible) setIsMonthRangeFilterVisible(false); 

    if (!isMonthFilterVisible) {
      setStartMonth('');
    }
  };

  const handleMonthRangeFilterToggle = () => {
    setIsMonthRangeFilterVisible(!isMonthRangeFilterVisible);
    if (isSearchFilterVisible) setIsSearchFilterVisible(false); 
    if (isDateFilterVisible) setIsDateFilterVisible(false); 
    if (isSingleDateFilterVisible) setSingleDateFilterVisible(false);
    if (isMonthFilterVisible) setIsMonthFilterVisible(false); 

    if (isMonthRangeFilterVisible) {
      setStartMonth('');
      setEndMonth('');
    }
  };
  // End Button Filter

  const clearInput = () => {
    setQuery('');
  };

  return (
    <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
      {modalUpdateDataPatient && <ModalDataPatient GetResponseData={GetResponseData} idUpdatePatient={idUpdatePatient} show={modalUpdateDataPatient} onHide={() => setModalUpdateDataPatient(false)} />}
      {loading && <LoaderHome />}
    
      {/* start header */}
      <HeaderPatient
        isTabletOrMobile={isTabletOrMobile}
        query={query}
        setQuery={setQuery}
        searchData={searchData}
        buttonRefresh={buttonRefresh}
        modalMedisRoleAdd={modalMedisRoleAdd}
      />
      {/* end header */}

      {/* Start filter */}
      <div className="container is-flex gap-2 p-1" style={{ justifyContent: "flex-end"}}>
        <div className="has-text-centered is-fullwidth">
          <div className=" is-flex gap-2" >
            {/* Start Filter Search  */}
            <SearchFilter 
              isSearchFilterVisible = {isSearchFilterVisible}
              GetResponseData={GetResponseData}
              query={query}
              setQuery={setQuery}
              isDateFilterVisible={isDateFilterVisible}
              isMonthFilterVisible={isMonthFilterVisible}
              isSingleDateFilterVisible={isSingleDateFilterVisible}
              isMonthRangeFilterVisible={isMonthRangeFilterVisible}
              handleSearchToggle = {handleSearchToggle} 
            />
            {/* End Filter Search */}

            {/* Start Filter Single Date */}
            <SingleDateFilter
              startDate={startDate}
              setStartDate={setStartDate}
              handleSingleDateFilterToggle={handleSingleDateFilterToggle}
              GetResponseData={GetResponseData}
              isSingleDateFilterVisible={isSingleDateFilterVisible}
              isSearchFilterVisible={isSearchFilterVisible}
              isDateFilterVisible={isDateFilterVisible}
              isMonthFilterVisible={isMonthFilterVisible}
              isMonthRangeFilterVisible={isMonthRangeFilterVisible}
            />
            {/* End Filter Single Date */}

            {/* Start Filter Range Date Date */}
            <DateRangeFilter
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              GetResponseData={GetResponseData}
              isDateFilterVisible={isDateFilterVisible}
              isSearchFilterVisible={isSearchFilterVisible}
              isMonthFilterVisible={isMonthFilterVisible}
              isSingleDateFilterVisible={isSingleDateFilterVisible}
              isMonthRangeFilterVisible={isMonthRangeFilterVisible}
              handleDateFilterToggle={handleDateFilterToggle}
            />
            {/* End Filter Range Date */}

            {/* Start Filter Month */}
            <MonthFilter
              isMonthFilterVisible={isMonthFilterVisible}
              GetResponseData={GetResponseData}
              startMonth={startMonth}
              setStartMonth={setStartMonth}
              isSearchFilterVisible={isSearchFilterVisible} 
              isDateFilterVisible={isDateFilterVisible} 
              isSingleDateFilterVisible={isSingleDateFilterVisible} 
              isMonthRangeFilterVisible={isMonthRangeFilterVisible} 
              handleMonthFilterToggle={handleMonthFilterToggle}
            />
            {/* End Filter Month */}

            {/* Start Filter Range Month */}
            <MonthRangeFilter
              isMonthRangeFilterVisible={isMonthRangeFilterVisible}
              GetResponseData={GetResponseData}
              startMonth={startMonth}
              setStartMonth={setStartMonth}
              endMonth={endMonth}
              setEndMonth={setEndMonth}
              isSearchFilterVisible={isSearchFilterVisible} 
              isDateFilterVisible={isDateFilterVisible} 
              isSingleDateFilterVisible={isSingleDateFilterVisible} 
              isMonthFilterVisible={isMonthFilterVisible} 
              handleMonthRangeFilterToggle={handleMonthRangeFilterToggle}
            />
            {/* End Filter Range Month */}
          </div>
        </div>
      </div>
      {/* End filter */}
      
      <Col xl='12' sm='12'> 
        <div>
          {/* start table */}
          <TablePatient
            getData={getData}
            page={page}
            modalPhysicalCheck={modalPhysicalCheck}
            modalMedicalRecord={modalMedicalRecord}
            soapPatient={soapPatient}
            odontogramPatient={odontogramPatient}
            deleteById={deleteById}
            isTabletOrMobile={isTabletOrMobile}
          />
          {/* end table */}
        </div>
        {/* start pagination */}
          <PaginationPatient
            rows={rows}
            page={page}
            pages={pages}
            msg={msg}
            changePage={changePage}
          />
        {/* end pagination */}
      </Col>
    </div>
  );
}
