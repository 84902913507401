import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Col} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API } from "../../config/api";
import {FaAddressBook, FaEdit, FaTrash, FaPlus, FaSync } from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import "bulma/css/bulma.css";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import ModalAddMedis from "./ModalAdmin/ModalAdd"
import ModalUpdateMedis from "./ModalAdmin/ModalUpdate"
import LoaderHome from "../Loader/LoaderHome"
import HeaderPayment from "./Components/HeaderPayment";
import TablePayment from "./Components/TablePayment";
import PaginationPayment from "./Components/PaginationPayment";

export default function Login() {
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  // Responsive to mobile or dekstop
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)'})
  // modal role add 
  const [id, setId] = useState();
  const [nameUpdate, setNameUpdate] = useState();
  const [NumberUpdate, setNumberUpdate] = useState();
  const [PaymentType, setPaymentType] = useState();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/payments?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      if (error.response.status === 401) {
        navigate('/dashboard')
        swal({
          title: 'Failed',
          text: `Akses dibatasi Tidak Dapat Mengakses Halaman Ini`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }else {
        swal({
          title: 'Failed',
          text: `${error.response.data.message}`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }
    }
  }

  useEffect(() => {
    GetResponseData()
  }, [page])

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response =  await API.delete(`transaction-service/payments/${id}`,fetchParams);
        if (response.data.error === false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }  
      }
    })
  };

  const changePage = ({ selected }) => {
    setPage(selected+1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };
 
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const buttonRefresh = () => {
    window.location.reload();
  }
  
  const viewModalAdd = () => {
    setModalAdd(true)
  }

  const viewModalUpdate = (id, name, payment_number, id_payment_type) => {
    setModalUpdate(true)
    setId(id)
    setNameUpdate(name)
    setNumberUpdate(payment_number)
    setPaymentType(id_payment_type)
  }

  return (
    <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
      {modalAdd && <ModalAddMedis  GetResponseData={GetResponseData}  show={modalAdd} onHide={() => setModalAdd(false)} />}
      {modalUpdate && <ModalUpdateMedis  GetResponseData={GetResponseData} nameUpdate={nameUpdate} NumberUpdate={NumberUpdate} PaymentType={PaymentType} id={id} show={modalUpdate} onHide={() => setModalUpdate(false)} />}
      {loading && <LoaderHome />}
      
      {/* start header */}
      <HeaderPayment
        isTabletOrMobile={isTabletOrMobile}
        viewModalAdd={viewModalAdd}
        buttonRefresh={buttonRefresh}
        searchData={searchData}
        query={query}
        setQuery={setQuery}
      />
      {/* end header */}
 
 
      <Col xl='12' sm='12'> 
        <div>
          {/* start table */}
          <TablePayment 
            isTabletOrMobile={isTabletOrMobile} 
            getData={getData} 
            page={page} 
            viewModalUpdate={viewModalUpdate} 
            deleteById={deleteById} 
          />
          {/* end table */}
        </div>
        {/* start pagination */}
        <PaginationPayment 
          rows={rows} 
          page={page} 
          pages={pages} 
          msg={msg} 
          changePage={changePage} 
        />
        {/* end pagianation */}
      </Col>
    </div>
  );
}
