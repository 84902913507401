import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaFileInvoice, FaPrint} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import "bulma/css/bulma.css";
import { useMutation } from "react-query";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import { ScaleLoader } from "react-spinners";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import toRupiah from '@develoka/angka-rupiah-js';
import LoaderHome from "../Loader/LoaderHome"
import axios from "axios";
import Footer from "./Footer"

export default function Login() {
  const navigate = useNavigate()
  const [dataManage, setDatamanage] = useState(false)
  const [pages, setPages] = useState();
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);
  const [ascending, setAscending] = useState(0);
  // filter name role users
  const [idRoleDoctor, setIdRoleDoctor] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");
  const [idRoleNurse, setIdRoleNurse] = useState("");
  const [nameRoleNurse, setNameRoleNurse] = useState("Nurse");
  const [getData, setGetData] = useState([]);
  const [getDataPayment, setGetDataPayment] = useState([]);
  const [getDataPaymentType, setGetDataPaymentType] = useState([]);

  const [getDataAppointmentById, setGetDataAppointmentById] = useState([]);
  const [getDataAppointmentByIdPatient, setGetDataAppointmentByIdPatient] = useState([]);
  const [getDataAppointmentByIdDoctor, setGetDataAppointmentByIdDoctor] = useState([]);
  const [getDataMedicalInvoiceByIdDate, setGetDataMedicalInvoiceByIdDate] = useState([])
  const [getDataMedicalInvoiceById, setGetDataMedicalInvoiceById] = useState([]);
  const [getDataMedicalInvoiceByIdPreceptions, setGetDataMedicalInvoiceByIdPreceptions] = useState([]);
  const [search,setSearch] = useState("")
  const [getDataFee, setGetDataFee] = useState([]);
  const [getDataDetails, setGetDataDetails] = useState([]);
  const [idRoleDokter, setIdRoleDokter] = useState("");
  const [getDataDokter, setGetDataDokter] = useState([]);
  const [getDataPhysicalCheck, setGetDataPhysicalCheckdataPhysicalCheck] = useState([]);
  const [getDataBiodata, setGetDataBiodata] = useState([])
  const [getDataTreatmentOneQty, setGetDataTreatmentOneQty] = useState([])
  const [getDataNurse, setGetDataNurse] = useState([]);
  const [getDataNurseSecond, setGetDataNurseSecond] = useState([]);
  const [getDataFloor, setGetDataFloor] = useState([]);
  const [getDataRoom, setGetDataRoom] = useState([]);
  const [rows, setRows] = useState(1);
  const [loading, setLoading] = useState(false);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [getCode, setGetCode] = useState([]);
  const {id, id_patient} = useParams()
  const myRef = useRef(null);
   // List data kirim ke modal proops update
  const [modalRoleUpdate, setModalRoleUpdate] = useState(false);
  const [nameTreatment, setNameTreatment] = useState()
  const [idModal, setIdModal] = useState();
  const [id_doctorModal, setIdDoctor] = useState();
  const [id_roomModal, setIdRoom] = useState();
  const [tooth_numberModal, setToothNumber] = useState();
  const [qtyModal, setQty] = useState();
  const [estimate_priceModal, setEstimatePrice] = useState();
  const [descriptionModal, setDescription] = useState();
  const [getAllResponse, setGetAllResponse] = useState(false)
  const [getDateInvoice, setGetDataInvoiceDate] = useState([])
  const [keyword, setKeyword] = useState("");

  const [form, setForm] = useState({
    username: "",
    date_check: "",
    height: "",
    weight: "",
    blood_type: "",
    body_temperature: "",
    blood_pressure: "",
    med_allergy: "",
    med_consuming: "",
    surgery_history: "",
    cardiac_disease: "",
    hemophillia: "",
    diabetes: "",
    hepatitis: "",
    gastritis: "",
    special_condition: "",
    date_birth: ""
  });

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };
  
  const fetchDataRef = useRef();
  const fetchData = async () => {
    try {
      setLoading(true);

      const [ treatmentOneQty, physicalCheckResponse, biodataRecord, medicalInvoice ] = await axios.all([
        // API.get(`transaction-service/treatments?page=${1}&limit=${1}&ascending=${1}`, fetchParams),
        API.get(`transaction-service/treatment-one-qtys?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams),
        API.get(`emr-service/phy-checks/patient/${id}`, fetchParams),
        API.get(`transaction-service/patients/${id_patient}`, fetchParams),
        API.get(`emr-service/medical-invoices/${id}`, fetchParams),
      ]);
      if (treatmentOneQty.status === 200 && physicalCheckResponse.status === 200 && biodataRecord.status === 200 && medicalInvoice.status === 200) {
        setGetDataTreatmentOneQty(treatmentOneQty.data.data);
        setGetDataPhysicalCheckdataPhysicalCheck(physicalCheckResponse.data.data);
        // setGetDataPhysicalCheckdataMedAttention(physicalCheckResponse.data);
        setGetDataBiodata(biodataRecord.data.data);
        setGetDataMedicalInvoiceByIdDate(medicalInvoice.data.data);
        setGetDataMedicalInvoiceById(medicalInvoice.data.data.medical_treatment_invoices);
        setGetDataMedicalInvoiceByIdPreceptions(medicalInvoice.data.data.medical_prescription_invoices);
        // setGetDataDokter(getResponseDokter.data.data)
        handlePrint();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  };

  useEffect(() => {
    fetchDataRef.current = fetchData;
    // Panggil fetchData saat komponen pertama kali dimuat
    fetchDataRef.current();
    // handlePrint()
  }, []);

  const handlePrint = useReactToPrint({
    content: () => myRef.current
  });

  useEffect(() => {
    setForm({
      ...form, 
      height: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.height}`,
      weight: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.weight}`,
      blood_type: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.blood_type}`,
      body_temperature: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.body_temperature}`,
      blood_pressure: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.blood_pressure}`,
      date_check: `${getDataPhysicalCheck}` === null ? "" :  `${getDataPhysicalCheck?.date_check}`,
      med_allergy: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.med_allergy}`,
      med_consuming: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.med_consuming}`,
      surgery_history: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.surgery_history}`,
      cardiac_disease: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.cardiac_disease}`,
      hemophillia: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.hemophillia}`,
      diabetes: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.diabetes}`,
      hepatitis: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.hepatitis}`,
      gastritis: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.gastritis}`,
      special_condition: `${getDataPhysicalCheck}` === null ? "" : `${getDataPhysicalCheck?.special_condition}`
    });
  }, [getDataPhysicalCheck])

  useEffect(() => {
    setForm({
      ...form, 
      date_birth: `${getDataBiodata}` === null ? "" : `${getDataBiodata?.date_birth}`
    });
  }, [getDataBiodata])

  function formatToRupiah(angka) {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });
  
    // Format angka ke format mata uang Rupiah
    const formattedString = formatter.format(angka);
  
    // Menghilangkan simbol "RP" (simbol mata uang) dari hasil format
    const rupiahWithoutSymbol = formattedString.replace('Rp', '');
  
    return rupiahWithoutSymbol.trim(); // Menghilangkan spasi yang mungkin ada
  }
  
  // useEffect(() => {
  //   if (getDataFee && getDataMedicalInvoiceById && getDataMedicalInvoiceByIdPreceptions) {
      
  //     let totalFee = 0;
  //     getDataFee.forEach((user) => {
  //       totalFee += user.price;
  //     });
  
  //     // Menghitung total harga dari getDataMedicalInvoiceById
  //     let totalMedicalInvoice = 0;
  //     getDataMedicalInvoiceById.forEach((user) => {
  //       totalMedicalInvoice += user.price;
  //     });
  
  //      // Menghitung total harga dari getDataMedicalInvoiceById
  //      let totalMedicalInvoicePreceptions = 0;
  //      getDataMedicalInvoiceByIdPreceptions.forEach((user) => {
  //       totalMedicalInvoicePreceptions += user.price;
  //      });
      
  //     // Menghitung total harga keseluruhan
  //     const totalHargaKeseluruhan = totalFee + totalMedicalInvoice + totalMedicalInvoicePreceptions;
  
  //     // Memformat total harga keseluruhan ke format Rupiah tanpa simbol "RP"
  //     const hargaDalamRupiah = formatToRupiah(totalHargaKeseluruhan);
  
  //     // Memperbarui elemen dengan ID "totalPrice" dengan total harga dalam format Rupiah tanpa "RP"
  //     const totalElement = document.getElementById('totalPrice');
  //     totalElement.innerText = hargaDalamRupiah;
  //   }
  // }, [getDataFee, getDataMedicalInvoiceById, getDataMedicalInvoiceByIdPreceptions]);

  // Menghitung total harga dari getDataFee
  
  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  const [infoGigi, setInfoGigi] = useState("XX-X")

  let pageNumber = 1;

  function increasePageNumber() {
    pageNumber++;
  }

  return (
    <Row style={{minHeight:"100vh"}}>
     {loading && <LoaderHome />}
      <Col xl='12' sm='6' style={{ }}>
        
        {/* TOMBOL PRINT */}
        {/* <div className="mb-2" style={{display:"flex", justifyContent:"end"}}>
          <button onClick={() => handlePrint()} style={{display:"flex", justifyContent:"",alignItems:"center", border:"none", backgroundColor:"#00409F",borderRadius:"3px", fontSize:"15px", padding:"5px 15px", color:"white"}}><div style={{display:"", height:"", alignItems:"center", marginRight:"5px"}}><FaPrint /></div>Print</button>
        </div> */}

        <form ref={myRef} className="printable-content" style={{ width: "100%" }}>
          <div style={{padding:"0px 30px", backgroundColor:"white"}}>
            <div style={{ display: "flex", backgroundColor: "white", opacity: ".9", borderBottom:"4px solid black", paddingBottom:"10px", paddingTop:"20px"}}>
              <div style={{ flex: "30%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={Logo_Signature} alt="logo-signature" style={{ width: "40%" }} />
              </div> 
      
              <div style={{ flex: "50%", display:"flex", alignItems:"center",paddingLeft:"20px"}}>
                <div>
                  <div style={{ display: "", justifyContent: "center", fontFamily: "sans-serif", fontWeight: "bolder", fontSize: "20px", textAlign:"center" }}>
                    REKAM MEDIS PASIEN 
                  </div>
                  <div style={{display:"", justifyContent:"center", fontFamily:"sans-serif",fontWeight:"", fontSize:"10px", textAlign:"center"}}>
                    Foresta Business Loft Signature Unit 1, Jl. BSD Raya Utama, Tangerang Regency
                  </div>
                </div>
              </div> 
            
              <div style={{ flex: "20%", display:"", alignItems:"center",paddingLeft:"20px"}}>
                    
              </div> 
            </div>
          </div>

          <div style={{ backgroundColor: "white",fontFamily:"sans-serif"}}>
            <div style={{ padding: "5px 30px" }}>

              <div style={{display:"flex"}}>
                <u style={{ fontWeight: "bold" }}>Biodata</u>
              </div>

              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  NO. REKAM MEDIS
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{getDataBiodata === null? "-" : getDataBiodata?.medical_record_code}</p>
                </div>
              </div>
              
              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  NAMA LENGKAP
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{getDataBiodata === null? "-" : getDataBiodata?.first_name } {getDataBiodata === null? "" : getDataBiodata?.last_name}</p>
                </div>
              </div>

              {/* <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  NIK
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{getDataBiodata === null? "-" : getDataBiodata?.nic_address }</p>
                </div>
              </div> */}

              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  JENIS KELAMIN
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>
                    {getDataBiodata === null? "-" : getDataBiodata?.gender === "male"? "Laki-Laki" : getDataBiodata?.gender === "female"? "Wanita" : getDataBiodata?.gender }
                  </p>
                </div>
              </div>

              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  TANGGAL LAHIR
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>
                  {getDataBiodata === null || getDataBiodata?.date_birth === null ? "-" : form?.date_birth.split("-")[2]}&nbsp;
                  {getDataBiodata === null || getDataBiodata?.date_birth === null ? "-" :
                  form?.date_birth.split("-")[1] === "01" ? "Januari" : "" ||
                  form?.date_birth.split("-")[1] === "02" ? "Februari" : "" ||
                  form?.date_birth.split("-")[1] === "03" ? "Maret" : "" ||
                  form?.date_birth.split("-")[1] === "04" ? "April" : "" ||  
                  form?.date_birth.split("-")[1] === "05" ? "Mei" : "" ||
                  form?.date_birth.split("-")[1] === "06" ? "Juni" : "" ||
                  form?.date_birth.split("-")[1] === "07" ? "Juli" : "" ||
                  form?.date_birth.split("-")[1] === "08" ? "Agustus" : "" ||
                  form?.date_birth.split("-")[1] === "09" ? "September" : "" ||
                  form?.date_birth.split("-")[1] === "10" ? "Oktober" : "" ||
                  form?.date_birth.split("-")[1] === "11" ? "November" : "" ||
                  form?.date_birth.split("-")[1] === "12" ? "Desember" : ""}&nbsp;
                  {getDataBiodata === null || getDataBiodata?.date_birth === null ? "-" :  form?.date_birth.split("-")[0]}
                  </p>
                </div>
              </div>

              {/* <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  UMUR
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{}</p>
                </div>
              </div> */}

              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  ALAMAT
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{getDataBiodata === null? "-" : getDataBiodata?.residence_address }</p>
                </div>
              </div>

              <div className="" style={{display: "flex",  height:"2vh"}}>
                <div style={{flex:"25%", display:"flex",alignItems:"center",fontSize:"12px", fontWeight:"bold"}}>
                  NO. TELP
                </div>
                <div style={{ flex: "75%", display:"flex"}}>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%"}}>:</p>
                  <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>{getDataBiodata === null? "-" : getDataBiodata?.phone_number}</p>
                </div>
              </div>
              
              <div className="mt-3" style={{}}>
                <div style={{display:"flex", paddingLeft:"0px"}}>
                  <u style={{ fontWeight: "bold" }}>Data Medis</u>
                  <div style={{ fontWeight: "", marginLeft: "5px", fontSize: "12px", display: "flex", alignItems: "center" }}>Pengecekan Terakhir :&nbsp;
                  {getDataPhysicalCheck === null || getDataPhysicalCheck?.date_check === null ? "-" : form?.date_check.split("-")[2]}&nbsp;
                  {getDataPhysicalCheck === null || getDataPhysicalCheck?.date_check === null ? "-" :
                  form?.date_check.split("-")[1] === "01" ? "Januari" : "" ||
                  form?.date_check.split("-")[1] === "02" ? "Februari" : "" ||
                  form?.date_check.split("-")[1] === "03" ? "Maret" : "" ||
                  form?.date_check.split("-")[1] === "04" ? "April" : "" ||  
                  form?.date_check.split("-")[1] === "05" ? "Mei" : "" ||
                  form?.date_check.split("-")[1] === "06" ? "Juni" : "" ||
                  form?.date_check.split("-")[1] === "07" ? "Juli" : "" ||
                  form?.date_check.split("-")[1] === "08" ? "Agustus" : "" ||
                  form?.date_check.split("-")[1] === "09" ? "September" : "" ||
                  form?.date_check.split("-")[1] === "10" ? "Oktober" : "" ||
                  form?.date_check.split("-")[1] === "11" ? "November" : "" ||
                  form?.date_check.split("-")[1] === "12" ? "Desember" : ""}&nbsp;
                  {form === null || form?.date_check === null ? "-" :  form?.date_check.split("-")[0]}
                  </div> 
                </div>

                <div style={{ border: "1px solid black", display: "flex", borderBottom:"none" }}>
                  <div style={{ flex:"50%", display:"flex", justifyContent:"center", fontSize:"12px",borderRight:"1px solid black"}}>
                    PHYSICAL CHECK
                  </div>
                  <div style={{ flex:"50%", display:"flex", justifyContent:"center", fontSize:"12px", }}>
                    MEDICAL CHECK
                  </div>
                </div>

                <div style={{ border: "1px solid black", display: "flex" }}>
                  <div style={{ flex:"50%", display:"", justifyContent:"", fontSize:"11px", paddingLeft:"10px", borderRight:"1px solid black"}}>
                    
                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Tinggi Badan 
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.height === null? "-" : getDataPhysicalCheck?.height} Cm</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Berat Badan 
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.weight === null? "-" : getDataPhysicalCheck?.weight} Kg</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Golongan Darah 
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.blood_type === null? "-" : getDataPhysicalCheck?.blood_type}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Suhu Badan 
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.body_temperature === null? "-" : getDataPhysicalCheck?.body_temperature} &deg;c</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Tekanan Darah 
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.blood_pressure === null ? "" :  getDataPhysicalCheck?.blood_pressure}</p>
                      </div>
                    </div>

                  </div>

                <div style={{ flex:"50%", display:"", justifyContent:"", fontSize:"11px", paddingLeft:"10px"}}>
                    
                  <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Alergi
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.med_allergy === null? "-" : getDataPhysicalCheck?.med_allergy}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Riwayat Operasi
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.surgery_history === null? "-" : getDataPhysicalCheck?.surgery_history}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Haemopilia
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.hemophillia === null? "-" : getDataPhysicalCheck?.hemophillia}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Hepatitis
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.hepatitis === null? "-" : getDataPhysicalCheck?.hepatitis}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Konsumsi Obat
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.med_consuming === null? "-" : getDataPhysicalCheck?.med_consuming}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Penyakit Jantung
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.cardiac_disease === null? "-" : getDataPhysicalCheck?.cardiac_disease}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Diabetes
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.diabetes === null? "-" : getDataPhysicalCheck?.diabetes}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Gastritis
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.gastritis === null? "-" : getDataPhysicalCheck?.gastritis}</p>
                      </div>
                    </div>

                    <div style={{ display: "flex", height: "22px" }}>
                      <div style={{flex:"35%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                        Kondisi Khusus
                      </div>
                      <div style={{ flex: "65%", display:"flex"  }}>
                        <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                          <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{getDataPhysicalCheck === null || getDataPhysicalCheck?.special_condition === null? "-" : getDataPhysicalCheck?.special_condition}</p>
                      </div>
                    </div>
                    
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div style={{ backgroundColor: "white", opacity: ".9", fontFamily:"sans-serif"}}>
            <div style={{ padding: "5px 30px" }}>
              {getDataMedicalInvoiceById.map((user,index) => (
              <div key={index} style={{marginTop: index % 2 !== 0 ? "300px" : "10px", paddingTop: index % 2 !== 0 ? "20px":"0px"}}>
                <div style={{display:"flex", paddingLeft:"0px"}}>
                  <u style={{ fontWeight: "bold" }}>Riwayat Perawatan</u>
                </div>
                <div style={{ border: "1px solid black", display: "flex", borderBottom:"none" }}>
                  <div style={{ display: "flex", justifyContent: "center", fontSize: "11px", paddingLeft: "10px" , transform:"scale(0.8)", transformOrigin:"left"}}>
                    <Col className="mt-2" xl='12' sm='12' style={{ padding: "0px 0px", fontSize: "12px" }}>
                      {/* Gigi Atas Dewasa */}
                      <div style={{ display: "flex" }}> 
                        <div style={{flex:"50%", display:"flex", justifyContent:"end", paddingRight:"30px"}}>
                    
                        {/(^|\s)18(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>18</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 18B " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("18-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 18P "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("18-Palatal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 18M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("18-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 18D "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("18-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 18O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("18-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>18</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)18B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 18B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("18-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 18B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("18-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)18P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 18P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("18-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 18P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("18-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)18M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 18M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("18-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 18M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("18-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)18D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 18D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("18-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 18D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("18-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)18O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 18O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("18-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 18O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("18-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                        </div>          
                        }
                                  
                        {/(^|\s)17(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>17</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 17B " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("17-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 17P "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("17-Palatal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 17M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("17-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 17D "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("17-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 17O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("17-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>17</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)17B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 17B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("17-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 17B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("17-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)17P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 17P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("17-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 17P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("17-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)17M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 17M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("17-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 17M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("17-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)17D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 17D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("17-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 17D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("17-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)17O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 17O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("17-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 17O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("17-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                        </div>          
                        }

                        {/(^|\s)16(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>16</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 16B " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("16-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 16P "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("16-Palatal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 16M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("16-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 16D "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("16-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 16O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("16-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>16</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)16B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 16B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("16-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 16B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("16-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)16P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 16P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("16-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 16P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("16-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)16M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 16M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("16-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 16M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("16-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)16D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 16D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("16-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 16D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("16-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)16O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 16O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("16-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 16O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("16-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                        </div>          
                        }  

                        {/(^|\s)15(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>15</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 15B " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("15-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 15P "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("15-Palatal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 15M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("15-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 15D "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("15-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 15O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("15-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>15</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)15B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 15B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("15-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 15B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("15-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)15P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 15P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("15-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 15P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("15-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)15M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 15M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("15-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 15M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("15-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)15D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 15D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("15-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 15D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("15-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)15O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 15O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("15-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 15O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("15-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                        </div>          
                        } 

                        {/(^|\s)14(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>14</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 14B " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("14-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 14P "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("14-Palatal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 14M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("14-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 14D "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("14-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 14O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("14-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>14</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)14B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 14B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("14-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 14B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("14-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)14P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 14P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("14-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 14P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("14-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)14M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 14M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("14-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 14M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("14-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)14D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 14D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("14-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 14D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("14-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)14O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 14O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("14-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 14O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("14-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                        </div>          
                        }      

                        {/(^|\s)13(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>13</div>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 13L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("13-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 13P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("13-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 13M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("13-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 13D " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("13-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>13</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black" }}>
                            <g>
                            {/* Top  */}
                            {/(^|\s)13L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 13L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("13-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 13L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("13-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)13P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 13P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("13-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 13P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("13-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                            
                                
                              {/* Right */}
                              {/(^|\s)13M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 13M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("13-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 13M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("13-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)13D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 13D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("13-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon  
                                data-nomor-gigi=" 13D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("13-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                            </svg>
                        </div>          
                        }  
                        
                        {/(^|\s)12(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>12</div>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 12L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("12-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 12P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("12-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 12M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("12-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 12D " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("12-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>12</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                            {/* Top  */}
                            {/(^|\s)12L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 12L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("12-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 12L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("12-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)12P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 12P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("12-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 12P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("12-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                            
                                
                              {/* Right */}
                              {/(^|\s)12M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 12M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("12-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 12M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("12-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)12D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 12D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("12-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon  
                                data-nomor-gigi=" 12D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("12-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                            </svg>
                        </div>          
                        }  
                          
                        {/(^|\s)11(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>11</div>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 11L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("11-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 11P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("11-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 11M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("11-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 11D " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("11-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>11</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                            {/* Top  */}
                            {/(^|\s)11L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 11L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("11-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 11L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("11-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)11P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 11P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("11-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 11P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("11-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                            
                                
                              {/* Right */}
                              {/(^|\s)11M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 11M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("11-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 11M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("11-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)11D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 11D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("11-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon  
                                data-nomor-gigi=" 11D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("11-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                            </svg>
                        </div>          
                        }             
                      </div>
                
                      <div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:"30px"}}>
                        {/(^|\s)21(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>21</div>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 21L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("21-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 21P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("21-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 21D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("21-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 21M " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("21-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>21</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                            {/* Top  */}
                            {/(^|\s)21L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 21L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("21-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 21L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("21-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)21P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 21P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("21-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 21P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("21-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              {/* Right */}
                              {/(^|\s)21D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 21D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("21-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 21D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("21-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)21M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 21M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("21-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon  
                                data-nomor-gigi=" 21M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("21-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                            </svg>
                        </div>          
                        }   

                        {/(^|\s)22(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>22</div>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 22L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("22-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 22P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("22-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 22D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("22-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 22M " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("22-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>22</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                            {/* Top  */}
                            {/(^|\s)22L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 22L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("22-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 22L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("22-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)22P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 22P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("22-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 22P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("22-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              {/* Right */}
                              {/(^|\s)22D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 22D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("22-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 22D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("22-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)22M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 22M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("22-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon  
                                data-nomor-gigi=" 22M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("22-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                            </svg>
                        </div>          
                        } 

                        {/(^|\s)23(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>23</div>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 23L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("23-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 23P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("23-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 23D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("23-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 23M " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("23-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>23</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                            {/* Top  */}
                            {/(^|\s)23L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 23L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("23-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 23L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("23-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)23P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 23P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("23-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 23P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("23-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              {/* Right */}
                              {/(^|\s)23D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 23D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("23-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 23D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("23-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)23M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 23M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("23-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon  
                                data-nomor-gigi=" 23M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("23-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                            </svg>
                        </div>          
                        }           
                        
                        {/(^|\s)24(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>24</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 24B " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("24-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 24P "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("24-Palatal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 24D "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("24-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 24M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("24-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 24O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("24-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>24</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)24B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 24B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("24-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 24B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("24-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)24P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 24P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("24-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 24P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("24-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)24D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 24D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("24-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 24D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("24-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)24M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 24M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("24-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 24M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("24-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)24O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 24O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("24-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 24O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("24-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                        </div>          
                        }  
                        
                        {/(^|\s)25(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>25</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 25B " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("25-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 25P "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("25-Palatal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 25D "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("25-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 25M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("25-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 25O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("25-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>25</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)25B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 25B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("25-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 25B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("25-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)25P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 25P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("25-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 25P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("25-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)25D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 25D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("25-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 25D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("25-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)25M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 25M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("25-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 25M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("25-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)25O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 25O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("25-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 25O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("25-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                        </div>          
                        }

                        {/(^|\s)26(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>26</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 26B " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("26-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 26P "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("26-Palatal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 26D "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("26-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 26M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("26-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 26O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("26-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>26</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)26B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 26B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("26-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 26B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("26-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)26P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 26P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("26-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 26P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("26-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)26D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 26D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("26-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 26D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("26-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)26M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 26M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("26-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 26M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("26-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)26O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 26O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("26-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 26O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("26-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                        </div>          
                        }

                        {/(^|\s)27(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>27</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 27B " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("27-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 27P "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("27-Palatal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 27D "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("27-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 27M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("27-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 27O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("27-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>27</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)27B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 27B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("27-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 27B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("27-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)27P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 27P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("27-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 27P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("27-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)27D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 27D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("27-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 27D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("27-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)27M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 27M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("27-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 27M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("27-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)27O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 27O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("27-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 27O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("27-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                        </div>          
                        } 

                        {/(^|\s)28(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>28</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 28B " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("28-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 28P "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("28-Palatal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 28D "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("28-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 28M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("28-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 28O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("28-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>28</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)28B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 28B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("28-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 28B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("28-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)28P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 28P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("28-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 28P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("28-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)28D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 28D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("28-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 28D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("28-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)28M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 28M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("28-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 28M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("28-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)28O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 28O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("28-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 28O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("28-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                        </div>          
                        }           
                      </div> 
                      </div>
                
                      {/* Gigi Atas Anak */}
                      <div style={{display:"flex"}}> 
                        <div style={{flex:"50%", display:"flex", justifyContent:"end", paddingRight:"30px"}}>
                
                        {/(^|\s)55(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>55</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 55B " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("55-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 55P "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("55-Palatal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 55M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("55-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 55D "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("55-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 55O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("55-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>55</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)55B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 55B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("55-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 55B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("55-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)55P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 55P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("55-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 55P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("55-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)55M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 55M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("55-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 55M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("55-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)55D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 55D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("55-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 55D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("55-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)55O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 55O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("55-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 55O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("55-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                        </div>          
                        }   

                        {/(^|\s)54(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>54</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 54B " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("54-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 54P "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("54-Palatal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 54M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("54-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 54D "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("54-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 54O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("54-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>54</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)54B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 54B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("54-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 54B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("54-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)54P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 54P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("54-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 54P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("54-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)54M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 54M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("54-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 54M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("54-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)54D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 54D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("54-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 54D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("54-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)54O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 54O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("54-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 54O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("54-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                        </div>          
                        }             

                        {/(^|\s)53(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>53</div>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 53L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("53-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 53P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("53-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 53M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("53-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 53D " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("53-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>53</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                            {/* Top  */}
                            {/(^|\s)53L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 53L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("53-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 53L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("53-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)53P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 53P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("53-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 53P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("53-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                            
                                
                              {/* Right */}
                              {/(^|\s)53M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 53M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("53-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 53M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("53-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)53D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 53D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("53-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon  
                                data-nomor-gigi=" 53D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("53-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                            </svg>
                        </div>          
                        }  
                
                        {/(^|\s)52(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>52</div>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 52L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("52-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 52P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("52-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 52M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("52-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 52D " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("52-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>52</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                            {/* Top  */}
                            {/(^|\s)52L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 52L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("52-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 52L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("52-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)52P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 52P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("52-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 52P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("52-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                            
                                
                              {/* Right */}
                              {/(^|\s)52M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 52M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("52-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 52M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("52-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)52D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 52D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("52-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon  
                                data-nomor-gigi=" 52D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("52-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                            </svg>
                        </div>          
                        }  
                          
                        {/(^|\s)51(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>51</div>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 51L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("51-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 51P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("51-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 51M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("51-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 51D " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("51-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>51</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                            {/* Top  */}
                            {/(^|\s)51L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 51L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("51-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 51L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("51-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)51P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 51P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("51-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 51P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("51-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                            
                                
                              {/* Right */}
                              {/(^|\s)51M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 51M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("51-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 51M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("51-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)51D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 51D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("51-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon  
                                data-nomor-gigi=" 51D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("51-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                            </svg>
                        </div>          
                        }  
                          
                      </div>
                
                      <div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:"30px"}}>
                    
                        {/(^|\s)61(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>61</div>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 61L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("61-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 61P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("61-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 61D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("61-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 61M " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("61-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>61</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                            {/* Top  */}
                            {/(^|\s)61L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 61L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("61-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 61L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("61-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)61P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 61P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("61-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 61P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("61-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                            
                                
                              {/* Right */}
                              {/(^|\s)61D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 61D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("61-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 61D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("61-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)61M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 61M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("61-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon  
                                data-nomor-gigi=" 61M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("61-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                            </svg>
                        </div>          
                        }             

                        {/(^|\s)62(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>62</div>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 62L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("62-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 62P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("62-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 62D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("62-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 62M " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("62-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>62</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                            {/* Top  */}
                            {/(^|\s)62L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 62L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("62-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 62L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("62-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)62P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 62P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("62-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 62P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("62-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                            
                                
                              {/* Right */}
                              {/(^|\s)62D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 62D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("62-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 62D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("62-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)62M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 62M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("62-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon  
                                data-nomor-gigi=" 62M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("62-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                            </svg>
                        </div>          
                        } 
                    
                        {/(^|\s)63(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>63</div>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 63L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("63-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 63P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("63-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 63D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("63-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 63M " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("63-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>63</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                            {/* Top  */}
                            {/(^|\s)63L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 63L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("63-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 63L "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("63-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)63P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 63P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("63-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 63P "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("63-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                            
                                
                              {/* Right */}
                              {/(^|\s)63D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 63D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("63-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 63D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("63-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)63M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 63M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("63-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon  
                                data-nomor-gigi=" 63M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("63-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                            </svg>
                        </div>          
                        } 
                                  
                        {/(^|\s)64(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>64</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 64B " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("64-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 64P "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("64-Palatal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 64D "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("64-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 64M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("64-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 64O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("64-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>64</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)64B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 64B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("64-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 64B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("64-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)64P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 64P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("64-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 64P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("64-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)64D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 64D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("64-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 64D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("64-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)64M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 64M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("64-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 64M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("64-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)64O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 64O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("64-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 64O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("64-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                        </div>          
                        }            
                    
                        {/(^|\s)65(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>65</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 65B " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("65-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 65P "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("65-Palatal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 65D "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("65-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 65M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("65-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 65O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("65-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>65</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)65B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 65B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("65-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 65B " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("65-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)65P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 65P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("65-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 65P "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("65-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)65D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 65D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("65-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 65D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("65-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)65M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 65M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("65-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 65M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("65-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)65O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 65O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("65-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 65O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("65-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                        </div>          
                        } 
                  
                        </div>
                      </div>
                
                      {/* Gigi Bawah Anak */}
                      <div className="mt-2" style={{display:"flex"}}> 
                        <div style={{ flex: "50%", display: "flex", justifyContent: "end", paddingRight: "30px" }}>
                                  
                        {/(^|\s)85(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>85</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 85L " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("85-Lingual")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 85B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("85-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 85M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("85-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 85D "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("85-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 85O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("85-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)85L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 85L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("85-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 85L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("85-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                              {/* Bawah */}
                              {/(^|\s)85B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 85B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("85-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 85B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("85-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Kanan */}
                              {/(^|\s)85M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 85M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("85-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 85M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("85-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            }
                              
                            {/* Kiri */}
                            {/(^|\s)85D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                            <polygon
                            data-nomor-gigi=" 85D "          
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'aqua', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("85-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            :
                            <polygon
                            data-nomor-gigi=" 85D "          
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("85-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            }
                
                            {/* Center */}
                            {/(^|\s)85O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                            <polygon
                            data-nomor-gigi=" 85O "                
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'aqua', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("85-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            :
                            <polygon
                            data-nomor-gigi=" 85O "                
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'white', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("85-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>85</div>
                        </div>          
                        } 

                        {/(^|\s)84(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>84</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 84L " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("84-Lingual")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 84B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("84-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 84M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("84-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 84D "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("84-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 84O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("84-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)84L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 84L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("84-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 84L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("84-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                              {/* Bawah */}
                              {/(^|\s)84B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 84B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("84-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 84B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("84-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Kanan */}
                              {/(^|\s)84M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 84M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("84-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 84M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("84-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            }
                              
                            {/* Kiri */}
                            {/(^|\s)84D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                            <polygon
                            data-nomor-gigi=" 84D "          
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'aqua', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("84-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            :
                            <polygon
                            data-nomor-gigi=" 84D "          
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("84-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            }
                
                            {/* Center */}
                            {/(^|\s)84O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                            <polygon
                            data-nomor-gigi=" 84O "                
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'aqua', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("84-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            :
                            <polygon
                            data-nomor-gigi=" 84O "                
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'white', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("84-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>84</div>
                        </div>          
                        } 

                        {/(^|\s)83(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 83P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("83-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 83L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("83-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 83M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("83-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 83D " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("83-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>83</div>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                                          
                              {/* Top  */}
                              {/(^|\s)83P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 83P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("83-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 83P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("83-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)83L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 83L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("83-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 83L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("83-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              {/* Right */}
                              {/(^|\s)83M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 83M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("83-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 83M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("83-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)83D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 83D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("83-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon  
                                data-nomor-gigi=" 83D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("83-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>83</div>   
                        </div>          
                        }
                                  
                        {/(^|\s)82(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 82P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("82-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 82L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("82-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 82M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("82-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 82D " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("82-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>82</div>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                                          
                              {/* Top  */}
                              {/(^|\s)82P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 82P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("82-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 82P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("82-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)82L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 82L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("82-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 82L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("82-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              {/* Right */}
                              {/(^|\s)82M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 82M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("82-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 82M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("82-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)82D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 82D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("82-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon  
                                data-nomor-gigi=" 82D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("82-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>82</div>   
                        </div>          
                        }
                        
                        {/(^|\s)81(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 81P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("81-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 81L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("81-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 81M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("81-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 81D " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("81-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>81</div>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                                          
                              {/* Top  */}
                              {/(^|\s)81P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 81P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("81-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 81P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("81-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)81L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 81L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("81-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 81L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("81-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              {/* Right */}
                              {/(^|\s)81M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 81M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("81-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 81M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("81-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)81D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 81D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("81-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon  
                                data-nomor-gigi=" 81D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("81-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>81</div>   
                        </div>          
                        }                                
                      </div>
                
                      <div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:"30px"}}>
                        
                        {/(^|\s)71(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 71P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("71-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 71L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("71-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 71D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("71-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 71M " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("71-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>71</div>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                                          
                              {/* Top  */}
                              {/(^|\s)71P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 71P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("71-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 71P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("71-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)71L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 71L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("71-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 71L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("71-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              {/* Right */}
                              {/(^|\s)71D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 71D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("71-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                                data-nomor-gigi=" 71D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("71-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)71M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 71M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("71-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon  
                                data-nomor-gigi=" 71M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("71-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>71</div>   
                        </div>          
                        } 
                                  
                        {/(^|\s)72(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 72P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("72-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 72L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("72-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 72D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("72-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 72M " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("72-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>72</div>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                                          
                              {/* Top  */}
                              {/(^|\s)72P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 72P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("72-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 72P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("72-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)72L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 72L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("72-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 72L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("72-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              {/* Right */}
                              {/(^|\s)72D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 72D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("72-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                                data-nomor-gigi=" 72D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("72-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)72M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 72M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("72-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon  
                                data-nomor-gigi=" 72M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("72-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>72</div>   
                        </div>          
                        } 

                        {/(^|\s)73(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 73P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("73-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 73L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("73-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 73D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("73-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 73M " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("73-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>73</div>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                                          
                              {/* Top  */}
                              {/(^|\s)73P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 73P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("73-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 73P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("73-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)73L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 73L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("73-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 73L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("73-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              {/* Right */}
                              {/(^|\s)73D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 73D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("73-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                                data-nomor-gigi=" 73D " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("73-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)73M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 73M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("73-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon  
                                data-nomor-gigi=" 73M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("73-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>73</div>   
                        </div>          
                        } 

                        {/(^|\s)74(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>74</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 74L " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("74-Lingual")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 74B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("74-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 74D "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("74-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 74M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("74-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 74O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("74-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)74L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 74L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("74-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 74L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("74-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                              {/* Bawah */}
                              {/(^|\s)74B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 74B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("74-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 74B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("74-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Kanan */}
                              {/(^|\s)74D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 74D "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("74-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 74D "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("74-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            }
                              
                            {/* Kiri */}
                            {/(^|\s)74M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                            <polygon
                            data-nomor-gigi=" 74M "          
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'aqua', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("74-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            :
                            <polygon
                            data-nomor-gigi=" 74M "          
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("74-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            }
                
                            {/* Center */}
                            {/(^|\s)74O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                            <polygon
                            data-nomor-gigi=" 74O "                
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'aqua', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("74-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            :
                            <polygon
                            data-nomor-gigi=" 74O "                
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'white', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("74-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>74</div>
                        </div>          
                        } 
                                  
                        {/(^|\s)75(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <div style={{textAlign:"center"}}>75</div>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 75L " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("75-Lingual")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 75B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("75-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 75D "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("75-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 75M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("75-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 75O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("75-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)75L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 75L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("75-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 75L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("75-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                              {/* Bawah */}
                              {/(^|\s)75B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 75B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("75-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 75B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("75-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Kanan */}
                              {/(^|\s)75D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 75D "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("75-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 75D "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("75-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            }
                              
                            {/* Kiri */}
                            {/(^|\s)75M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                            <polygon
                            data-nomor-gigi=" 75M "          
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'aqua', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("75-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            :
                            <polygon
                            data-nomor-gigi=" 75M "          
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'white', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("75-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            }
                
                            {/* Center */}
                            {/(^|\s)75O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                            <polygon
                            data-nomor-gigi=" 75O "                
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'aqua', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("75-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            :
                            <polygon
                            data-nomor-gigi=" 75O "                
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'white', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("75-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>75</div>
                        </div>          
                        } 
                  
                      </div>
                      </div>
                
                      {/* Gigi Bawah Dewasa */}
                      <div style={{display:"flex"}}> 
                        <div style={{flex:"50%", display:"flex", justifyContent:"end", paddingRight:"30px"}}>
                    
                        {/(^|\s)48(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                            {/* Atas */}
                            <polygon
                            
                            data-nomor-gigi=" 48L " 
                            points="0,0 40,0 30,10 10,10"
                            style={{ fill: 'aqua', stroke: 'black' }}  
                            onMouseEnter={() => setInfoGigi("48-Lingual")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                              
                            {/* Bawah */}
                            <polygon
                            
                            data-nomor-gigi=" 48B "          
                            points="10,30 30,30 40,40 0,40"
                            style={{ fill: 'aqua', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("48-Bukal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            
                            {/* Kanan */}
                            <polygon
                            
                            data-nomor-gigi=" 48M "          
                            points="30,10 40,0 40,40 30,30"
                            style={{ fill: 'aqua', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("48-Mesial")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                            
                            {/* Kiri */}
                            <polygon
                            
                            data-nomor-gigi=" 48D "          
                            points="0,0 10,10 10,30 0,40"
                            style={{ fill: 'aqua', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("48-Distal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
              
                            {/* Center */}
                            <polygon
                            
                            data-nomor-gigi=" 48O "                
                            points="10,10 30,10 30,30 10,30"
                            style={{ fill: 'aqua', stroke: 'black' }}
                            onMouseEnter={() => setInfoGigi("48-Oklusal")}
                            onMouseLeave={() => setInfoGigi("XX-X")}
                            />
                          </g>
                          </svg>
                          <div style={{textAlign:"center"}}>48</div>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)48L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 48L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("48-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 48L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("48-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)48B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 48B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("48-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 48B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("48-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)48M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 48M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("48-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 48M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("48-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)48D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 48D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("48-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 48D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("48-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)48O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 48O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("48-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 48O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("48-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>48</div>
                        </div>          
                        }
                                  
                        {/(^|\s)47(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 47L " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("47-Lingual")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 47B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("47-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 47M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("47-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 47D "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("47-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 47O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("47-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                          <div style={{ textAlign: "center" }}>47</div>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)47L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 47L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("47-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 47L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("47-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)47B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 47B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("47-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 47B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("47-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)47M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 47M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("47-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 47M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("47-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)47D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 47D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("47-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 47D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("47-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)47O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 47O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("47-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 47O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("47-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>47</div>
                        </div>          
                        }
                                  
                        {/(^|\s)46(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                          <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 46L " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("46-Lingual")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 46B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("46-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 46M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("46-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 46D "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("46-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 46O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("46-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>46</div>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)46L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 46L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("46-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 46L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("46-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)46B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 46B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("46-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 46B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("46-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)46M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 46M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("46-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 46M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("46-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)46D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 46D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("46-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 46D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("46-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)46O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 46O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("46-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 46O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("46-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>46</div>
                        </div>          
                        }
                
                        {/(^|\s)45(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 45L " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("45-Lingual")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 45B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("45-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 45M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("45-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 45D "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("45-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 45O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("45-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>45</div>            
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)45L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 45L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("45-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 45L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("45-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)45B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 45B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("45-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 45B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("45-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)45M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 45M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("45-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 45M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("45-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)45D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 45D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("45-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 45D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("45-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)45O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 45O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("45-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 45O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("45-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>45</div>
                        </div>          
                        }
                
                        {/(^|\s)44(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                              {/* Atas */}
                              <polygon
                              
                              data-nomor-gigi=" 44L " 
                              points="0,0 40,0 30,10 10,10"
                              style={{ fill: 'aqua', stroke: 'black' }}  
                              onMouseEnter={() => setInfoGigi("44-Lingual")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Bawah */}
                              <polygon
                              
                              data-nomor-gigi=" 44B "          
                              points="10,30 30,30 40,40 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("44-Bukal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kanan */}
                              <polygon
                              
                              data-nomor-gigi=" 44M "          
                              points="30,10 40,0 40,40 30,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("44-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              
                              {/* Kiri */}
                              <polygon
                              
                              data-nomor-gigi=" 44D "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("44-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Center */}
                              <polygon
                              
                              data-nomor-gigi=" 44O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("44-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>44</div>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>

                              {/* Atas */}
                              {/(^|\s)44L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 44L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("44-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 44L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'white', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("44-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }

                                {/* Bawah */}
                                {/(^|\s)44B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 44B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("44-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 44B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("44-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kanan */}
                                {/(^|\s)44M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                              
                                data-nomor-gigi=" 44M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("44-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                              
                                data-nomor-gigi=" 44M "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("44-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                              
                              
                                {/* Kiri */}
                                {/(^|\s)44D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 44D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("44-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 44D "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("44-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                
                                {/* Center */}
                                {/(^|\s)44O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 44O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("44-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 44O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("44-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>44</div>
                        </div>          
                        }
                                  
                        {/(^|\s)43(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 43P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("43-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 43L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("43-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 43M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("43-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 43D " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("43-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>43</div>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                                          
                              {/* Top  */}
                              {/(^|\s)43P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 43P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("43-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 43P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("43-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)43L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 43L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("43-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 43L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("43-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              {/* Right */}
                              {/(^|\s)43M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 43M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("43-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 43M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("43-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)43D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 43D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("43-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon  
                                data-nomor-gigi=" 43D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("43-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>43</div>   
                        </div>          
                        }  
                                  
                        {/(^|\s)42(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 42P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("42-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 42L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("42-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 42M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("42-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 42D " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("42-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>42</div>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                                          
                              {/* Top  */}
                              {/(^|\s)42P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 42P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("42-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 42P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("42-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)42L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 42L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("42-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 42L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("42-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              {/* Right */}
                              {/(^|\s)42M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 42M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("42-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 42M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("42-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)42D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 42D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("42-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon  
                                data-nomor-gigi=" 42D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("42-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>42</div>   
                        </div>          
                        }

                        {/(^|\s)41(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                        <div style={{ width: "42px", margin: "5px" }}>
                          <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                              {/* Top  */}
                              <polygon
                                data-nomor-gigi=" 41P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("41-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                  
                              {/* Botton */}
                              <polygon
                                data-nomor-gigi=" 41L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("41-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                
                              {/* Right */}
                              <polygon
                                data-nomor-gigi=" 41M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("41-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                
                              {/* Left     */}
                              <polygon
                              data-nomor-gigi=" 41D " 
                              points="0,0 5,10 10,20 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("41-Distal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>41</div>
                        </div>
                          :
                        <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                            <g>
                                          
                              {/* Top  */}
                              {/(^|\s)41P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 41P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("41-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 41P "    
                                points="0,0 40,0 30,20 0,20"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("41-Palatal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                              {/* Botton */}
                              {/(^|\s)41L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 41L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("41-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                                <polygon
                                data-nomor-gigi=" 41L "    
                                points="0,20 30,20 100,100 0,100"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("41-Labial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              {/* Right */}
                              {/(^|\s)41M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 41M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("41-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                                :
                              <polygon
                                data-nomor-gigi=" 41M " 
                                points="30,20 40,0 40,40 35,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("41-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                          
                              {/* Left */}            
                              {/(^|\s)41D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                                data-nomor-gigi=" 41D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("41-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon  
                                data-nomor-gigi=" 41D " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("41-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                              
                            </g>
                          </svg>
                          <div style={{textAlign:"center"}}>41</div>   
                        </div>          
                        }
                      </div>
              
                        <div style={{flex:"50%", display:"flex", justifyContent:"start", paddingLeft:"30px"}}>
                  
                          {/(^|\s)31(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                          <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                                {/* Top  */}
                                <polygon
                                  data-nomor-gigi=" 31P "    
                                  points="0,0 40,0 30,20 0,20"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("31-Palatal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                    
                                {/* Botton */}
                                <polygon
                                  data-nomor-gigi=" 31L "    
                                  points="0,20 30,20 100,100 0,100"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("31-Labial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  
                                {/* Right */}
                                <polygon
                                  data-nomor-gigi=" 31D " 
                                  points="30,20 40,0 40,40 35,30"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("31-Distal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                  
                                {/* Left     */}
                                <polygon
                                data-nomor-gigi=" 31M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("31-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>31</div>
                          </div>
                            :
                          <div style={{ width: "42px", margin: "5px" }}>
                              <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                                            
                                {/* Top  */}
                                {/(^|\s)31P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                  <polygon
                                  data-nomor-gigi=" 31P "    
                                  points="0,0 40,0 30,20 0,20"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("31-Palatal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  :
                                  <polygon
                                  data-nomor-gigi=" 31P "    
                                  points="0,0 40,0 30,20 0,20"
                                  style={{ fill: 'white', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("31-Palatal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                
                                {/* Botton */}
                                {/(^|\s)31L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                  <polygon
                                  data-nomor-gigi=" 31L "    
                                  points="0,20 30,20 100,100 0,100"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("31-Labial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  :
                                  <polygon
                                  data-nomor-gigi=" 31L "    
                                  points="0,20 30,20 100,100 0,100"
                                  style={{ fill: 'white', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("31-Labial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                  
                                {/* Right */}
                                {/(^|\s)31D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                  <polygon
                                  data-nomor-gigi=" 31D " 
                                  points="30,20 40,0 40,40 35,30"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("31-Distal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                  data-nomor-gigi=" 31D " 
                                  points="30,20 40,0 40,40 35,30"
                                  style={{ fill: 'white', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("31-Distal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                            
                                {/* Left */}            
                                {/(^|\s)31M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                  data-nomor-gigi=" 31M " 
                                  points="0,0 5,10 10,20 0,40"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("31-Mesial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon  
                                  data-nomor-gigi=" 31M " 
                                  points="0,0 5,10 10,20 0,40"
                                  style={{ fill: 'white', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("31-Mesial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>31</div>   
                          </div>          
                          }             

                          {/(^|\s)32(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                          <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                                {/* Top  */}
                                <polygon
                                  data-nomor-gigi=" 32P "    
                                  points="0,0 40,0 30,20 0,20"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("32-Palatal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                    
                                {/* Botton */}
                                <polygon
                                  data-nomor-gigi=" 32L "    
                                  points="0,20 30,20 100,100 0,100"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("32-Labial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  
                                {/* Right */}
                                <polygon
                                  data-nomor-gigi=" 32D " 
                                  points="30,20 40,0 40,40 35,30"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("32-Distal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                  
                                {/* Left     */}
                                <polygon
                                data-nomor-gigi=" 32M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("32-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>32</div>
                          </div>
                            :
                          <div style={{ width: "42px", margin: "5px" }}>
                              <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                                            
                                {/* Top  */}
                                {/(^|\s)32P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                  <polygon
                                  data-nomor-gigi=" 32P "    
                                  points="0,0 40,0 30,20 0,20"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("32-Palatal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  :
                                  <polygon
                                  data-nomor-gigi=" 32P "    
                                  points="0,0 40,0 30,20 0,20"
                                  style={{ fill: 'white', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("32-Palatal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                
                                {/* Botton */}
                                {/(^|\s)32L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                  <polygon
                                  data-nomor-gigi=" 32L "    
                                  points="0,20 30,20 100,100 0,100"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("32-Labial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  :
                                  <polygon
                                  data-nomor-gigi=" 32L "    
                                  points="0,20 30,20 100,100 0,100"
                                  style={{ fill: 'white', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("32-Labial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                  
                                {/* Right */}
                                {/(^|\s)32D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                  <polygon
                                  data-nomor-gigi=" 32D " 
                                  points="30,20 40,0 40,40 35,30"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("32-Distal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                  data-nomor-gigi=" 32D " 
                                  points="30,20 40,0 40,40 35,30"
                                  style={{ fill: 'white', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("32-Distal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                            
                                {/* Left */}            
                                {/(^|\s)32M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                  data-nomor-gigi=" 32M " 
                                  points="0,0 5,10 10,20 0,40"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("32-Mesial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon  
                                  data-nomor-gigi=" 32M " 
                                  points="0,0 5,10 10,20 0,40"
                                  style={{ fill: 'white', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("32-Mesial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>32</div>   
                          </div>          
                          } 

                          {/(^|\s)33(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                          <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                                {/* Top  */}
                                <polygon
                                  data-nomor-gigi=" 33P "    
                                  points="0,0 40,0 30,20 0,20"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("33-Palatal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                    
                                {/* Botton */}
                                <polygon
                                  data-nomor-gigi=" 33L "    
                                  points="0,20 30,20 100,100 0,100"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("33-Labial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  
                                {/* Right */}
                                <polygon
                                  data-nomor-gigi=" 33D " 
                                  points="30,20 40,0 40,40 35,30"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("33-Distal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                  
                                {/* Left     */}
                                <polygon
                                data-nomor-gigi=" 33M " 
                                points="0,0 5,10 10,20 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("33-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>33</div>
                          </div>
                            :
                          <div style={{ width: "42px", margin: "5px" }}>
                              <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                              <g>
                                            
                                {/* Top  */}
                                {/(^|\s)33P(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                  <polygon
                                  data-nomor-gigi=" 33P "    
                                  points="0,0 40,0 30,20 0,20"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("33-Palatal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  :
                                  <polygon
                                  data-nomor-gigi=" 33P "    
                                  points="0,0 40,0 30,20 0,20"
                                  style={{ fill: 'white', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("33-Palatal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                
                                {/* Botton */}
                                {/(^|\s)33L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                  <polygon
                                  data-nomor-gigi=" 33L "    
                                  points="0,20 30,20 100,100 0,100"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("33-Labial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  :
                                  <polygon
                                  data-nomor-gigi=" 33L "    
                                  points="0,20 30,20 100,100 0,100"
                                  style={{ fill: 'white', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("33-Labial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                  
                                {/* Right */}
                                {/(^|\s)33D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                  <polygon
                                  data-nomor-gigi=" 33D " 
                                  points="30,20 40,0 40,40 35,30"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("33-Distal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                  data-nomor-gigi=" 33D " 
                                  points="30,20 40,0 40,40 35,30"
                                  style={{ fill: 'white', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("33-Distal")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                            
                                {/* Left */}            
                                {/(^|\s)33M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                  data-nomor-gigi=" 33M " 
                                  points="0,0 5,10 10,20 0,40"
                                  style={{ fill: 'aqua', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("33-Mesial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon  
                                  data-nomor-gigi=" 33M " 
                                  points="0,0 5,10 10,20 0,40"
                                  style={{ fill: 'white', stroke: 'black' }}
                                  onMouseEnter={() => setInfoGigi("33-Mesial")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>33</div>   
                          </div>          
                          } 
                      
                          {/(^|\s)34(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                          <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                                <g>
                                {/* Atas */}
                                <polygon
                                
                                data-nomor-gigi=" 34L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("34-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  
                                {/* Bawah */}
                                <polygon
                                
                                data-nomor-gigi=" 34B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("34-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                
                                {/* Kanan */}
                                <polygon
                                
                                data-nomor-gigi=" 34D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("34-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                
                                {/* Kiri */}
                                <polygon
                                
                                data-nomor-gigi=" 34M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("34-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                  
                                {/* Center */}
                                <polygon
                                
                                data-nomor-gigi=" 34O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("34-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>34</div>
                          </div>
                            :
                          <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                                <g>

                                {/* Atas */}
                                {/(^|\s)34L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                  data-nomor-gigi=" 34L " 
                                  points="0,0 40,0 30,10 10,10"
                                  style={{ fill: 'aqua', stroke: 'black' }}  
                                  onMouseEnter={() => setInfoGigi("34-Lingual")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  :
                                  <polygon
                                  data-nomor-gigi=" 34L " 
                                  points="0,0 40,0 30,10 10,10"
                                  style={{ fill: 'white', stroke: 'black' }}  
                                  onMouseEnter={() => setInfoGigi("34-Lingual")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                  />
                                }

                                {/* Bawah */}
                                {/(^|\s)34B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 34B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("34-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 34B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("34-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                
                                {/* Kanan */}
                                {/(^|\s)34D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 34D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("34-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 34D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("34-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                                
                              {/* Kiri */}
                              {/(^|\s)34M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 34M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("34-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 34M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("34-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                  
                              {/* Center */}
                              {/(^|\s)34O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 34O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("34-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 34O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("34-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>34</div>
                          </div>          
                          } 
                                    
                          {/(^|\s)35(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                          <div style={{ width: "42px", margin: "5px" }}> 
                              <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                                <g>
                                {/* Atas */}
                                <polygon
                                
                                data-nomor-gigi=" 35L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("35-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  
                                {/* Bawah */}
                                <polygon
                                
                                data-nomor-gigi=" 35B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("35-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                
                                {/* Kanan */}
                                <polygon
                                
                                data-nomor-gigi=" 35D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("35-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                
                                {/* Kiri */}
                                <polygon
                                
                                data-nomor-gigi=" 35M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("35-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                  
                                {/* Center */}
                                <polygon
                                
                                data-nomor-gigi=" 35O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("35-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>35</div>
                          </div>
                            :
                          <div style={{ width: "42px", margin: "5px" }}>
                              <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                                <g>

                                {/* Atas */}
                                {/(^|\s)35L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                  data-nomor-gigi=" 35L " 
                                  points="0,0 40,0 30,10 10,10"
                                  style={{ fill: 'aqua', stroke: 'black' }}  
                                  onMouseEnter={() => setInfoGigi("35-Lingual")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  :
                                  <polygon
                                  data-nomor-gigi=" 35L " 
                                  points="0,0 40,0 30,10 10,10"
                                  style={{ fill: 'white', stroke: 'black' }}  
                                  onMouseEnter={() => setInfoGigi("35-Lingual")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                  />
                                }

                                {/* Bawah */}
                                {/(^|\s)35B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 35B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("35-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 35B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("35-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                
                                {/* Kanan */}
                                {/(^|\s)35D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 35D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("35-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 35D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("35-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                                
                              {/* Kiri */}
                              {/(^|\s)35M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 35M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("35-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 35M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("35-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                  
                              {/* Center */}
                              {/(^|\s)35O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 35O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("35-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 35O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("35-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>35</div>
                          </div>          
                          } 
                      
                          {/(^|\s)36(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                          <div style={{ width: "42px", margin: "5px" }}>                
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                                <g>
                                {/* Atas */}
                                <polygon
                                
                                data-nomor-gigi=" 36L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("36-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  
                                {/* Bawah */}
                                <polygon
                                
                                data-nomor-gigi=" 36B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("36-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                
                                {/* Kanan */}
                                <polygon
                                
                                data-nomor-gigi=" 36D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("36-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                
                                {/* Kiri */}
                                <polygon
                                
                                data-nomor-gigi=" 36M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("36-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                  
                                {/* Center */}
                                <polygon
                                
                                data-nomor-gigi=" 36O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("36-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>36</div>
                          </div>
                            :
                          <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                                <g>

                                {/* Atas */}
                                {/(^|\s)36L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                  data-nomor-gigi=" 36L " 
                                  points="0,0 40,0 30,10 10,10"
                                  style={{ fill: 'aqua', stroke: 'black' }}  
                                  onMouseEnter={() => setInfoGigi("36-Lingual")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  :
                                  <polygon
                                  data-nomor-gigi=" 36L " 
                                  points="0,0 40,0 30,10 10,10"
                                  style={{ fill: 'white', stroke: 'black' }}  
                                  onMouseEnter={() => setInfoGigi("36-Lingual")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                  />
                                }

                                {/* Bawah */}
                                {/(^|\s)36B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 36B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("36-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 36B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("36-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                
                                {/* Kanan */}
                                {/(^|\s)36D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 36D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("36-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 36D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("36-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                                
                              {/* Kiri */}
                              {/(^|\s)36M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 36M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("36-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 36M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("36-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                  
                              {/* Center */}
                              {/(^|\s)36O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 36O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("36-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 36O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("36-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>36</div>
                          </div>          
                          }   

                          {/(^|\s)37(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                          <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                                <g>
                                {/* Atas */}
                                <polygon
                                
                                data-nomor-gigi=" 37L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("37-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  
                                {/* Bawah */}
                                <polygon
                                
                                data-nomor-gigi=" 37B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("37-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                
                                {/* Kanan */}
                                <polygon
                                
                                data-nomor-gigi=" 37D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("37-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                
                                {/* Kiri */}
                                <polygon
                                
                                data-nomor-gigi=" 37M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("37-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                  
                                {/* Center */}
                                <polygon
                                
                                data-nomor-gigi=" 37O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("37-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>37</div>
                          </div>
                            :
                          <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                                <g>

                                {/* Atas */}
                                {/(^|\s)37L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                  data-nomor-gigi=" 37L " 
                                  points="0,0 40,0 30,10 10,10"
                                  style={{ fill: 'aqua', stroke: 'black' }}  
                                  onMouseEnter={() => setInfoGigi("37-Lingual")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  :
                                  <polygon
                                  data-nomor-gigi=" 37L " 
                                  points="0,0 40,0 30,10 10,10"
                                  style={{ fill: 'white', stroke: 'black' }}  
                                  onMouseEnter={() => setInfoGigi("37-Lingual")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                  />
                                }

                                {/* Bawah */}
                                {/(^|\s)37B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 37B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("37-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 37B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("37-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                
                                {/* Kanan */}
                                {/(^|\s)37D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 37D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("37-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 37D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("37-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                                
                              {/* Kiri */}
                              {/(^|\s)37M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 37M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("37-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 37M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("37-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                  
                              {/* Center */}
                              {/(^|\s)37O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 37O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("37-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 37O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("37-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>37</div>
                          </div>          
                          }

                          {/(^|\s)38(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                          <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                                <g>
                                {/* Atas */}
                                <polygon
                                
                                data-nomor-gigi=" 38L " 
                                points="0,0 40,0 30,10 10,10"
                                style={{ fill: 'aqua', stroke: 'black' }}  
                                onMouseEnter={() => setInfoGigi("38-Lingual")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  
                                {/* Bawah */}
                                <polygon
                                
                                data-nomor-gigi=" 38B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("38-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                
                                {/* Kanan */}
                                <polygon
                                
                                data-nomor-gigi=" 38D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("38-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                
                                {/* Kiri */}
                                <polygon
                                
                                data-nomor-gigi=" 38M "          
                                points="0,0 10,10 10,30 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("38-Mesial")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                  
                                {/* Center */}
                                <polygon
                                
                                data-nomor-gigi=" 38O "                
                                points="10,10 30,10 30,30 10,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("38-Oklusal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>38</div>
                          </div>
                            :
                          <div style={{ width: "42px", margin: "5px" }}>
                            <svg className="" width={42} height={42} style={{ border: "1px solid black", borderBottom:"2px solid black"  }}>
                                <g>

                                {/* Atas */}
                                {/(^|\s)38L(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                  data-nomor-gigi=" 38L " 
                                  points="0,0 40,0 30,10 10,10"
                                  style={{ fill: 'aqua', stroke: 'black' }}  
                                  onMouseEnter={() => setInfoGigi("38-Lingual")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                  :
                                  <polygon
                                  data-nomor-gigi=" 38L " 
                                  points="0,0 40,0 30,10 10,10"
                                  style={{ fill: 'white', stroke: 'black' }}  
                                  onMouseEnter={() => setInfoGigi("38-Lingual")}
                                  onMouseLeave={() => setInfoGigi("XX-X")}
                                  />
                                }

                                {/* Bawah */}
                                {/(^|\s)38B(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 38B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("38-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 38B "          
                                points="10,30 30,30 40,40 0,40"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("38-Bukal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                }
                                
                                {/* Kanan */}
                                {/(^|\s)38D(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                                <polygon
                                data-nomor-gigi=" 38D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'aqua', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("38-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                                :
                                <polygon
                                data-nomor-gigi=" 38D "          
                                points="30,10 40,0 40,40 30,30"
                                style={{ fill: 'white', stroke: 'black' }}
                                onMouseEnter={() => setInfoGigi("38-Distal")}
                                onMouseLeave={() => setInfoGigi("XX-X")}
                                />
                              }
                                
                              {/* Kiri */}
                              {/(^|\s)38M(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 38M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("38-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 38M "          
                              points="0,0 10,10 10,30 0,40"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("38-Mesial")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                  
                              {/* Center */}
                              {/(^|\s)38O(\s|$)/.test(user?.medical_record.treatment_plan_detail.tooth_number) ?
                              <polygon
                              data-nomor-gigi=" 38O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'aqua', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("38-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              :
                              <polygon
                              data-nomor-gigi=" 38O "                
                              points="10,10 30,10 30,30 10,30"
                              style={{ fill: 'white', stroke: 'black' }}
                              onMouseEnter={() => setInfoGigi("38-Oklusal")}
                              onMouseLeave={() => setInfoGigi("XX-X")}
                              />
                              }
                                
                              </g>
                            </svg>
                            <div style={{textAlign:"center"}}>38</div>
                          </div>          
                          }

                      </div>
                      </div>
                    </Col>
                  </div>
                </div>
                
                <div style={{border:"1px solid black", display:"", justifyContent:"", fontSize:"11px", paddingLeft:"10px"}}>               
                <div style={{ display: "flex", height: "22px" }}>
                    <div style={{flex:"15%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                      Nama Dokter
                    </div>
                    <div style={{ flex: "85%", display:"flex"  }}>
                      <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                      <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{user?.medical_record.treatment_plan_detail.doctor_name}</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", height: "22px" }}>
                    <div style={{flex:"15%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                      Anamnesa
                    </div>
                    <div style={{ flex: "85%", display:"flex"  }}>
                      <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                      <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{user?.medical_record.treatment_plan_detail.treatment_plan.anamnesa}</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", height: "22px" }}>
                    <div style={{flex:"15%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                      Diagnosa
                    </div>
                    <div style={{ flex: "85%", display:"flex"  }}>
                      <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                      <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{user?.medical_record.diagnose}</p>
                    </div>
                  </div>

                  <div style={{ display: "flex", height: "22px" }}>
                    <div style={{flex:"15%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                      Nomor Gigi
                    </div>
                    <div style={{ flex: "85%", display:"flex" }}>
                      <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                        <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>
                          {user?.medical_record.treatment_plan_detail.tooth_number === null || user?.medical_record.treatment_plan_detail.tooth_number === "null" ? "" :
                            getDataTreatmentOneQty.filter(item => item?.id_treatment === user?.medical_record.treatment_plan_detail.treatment_data.id).length > 0 ?
                            (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  55  54  53  52  51  61  62  63  64  65  85  84  83  82  81  71  72  73  74  75  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "RA+RB") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "RA+RB") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "55  54  53  52  51  61  62  63  64  65  85  84  83  82  81  71  72  73  74  75" && "RA+RB") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28  55  54  53  52  51  61  62  63  64  65" && "RA") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "18  17  16  15  14  13  12  11  21  22  23  24  25  26  27  28" && "RA") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "55  54  53  52  51  61  62  63  64  65" && "RA") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "85  84  83  82  81  71  72  73  74  75  48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "RB") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "48  47  46  45  44  43  42  41  31  32  33  34  35  36  37  38" && "RB") ||
                            (user?.medical_record.treatment_plan_detail.tooth_number === "85  84  83  82  81  71  72  73  74  75" && "RB") 
                            // (user?.medical_record.treatment_plan_detail.tooth_number === "85  84  83  82  81  71  72  73  74  75" && "RA+RB") 
                            :
                            `(${user?.medical_record.treatment_plan_detail.tooth_number})`                          
                          }
                        </p>
                    </div>
                  </div>

                  <div style={{ display: "flex", height: "22px" }}>
                    <div style={{flex:"15%", height:"100%", display:"flex", alignItems:"center", fontSize:"12px",color: "black"}}>
                      Perawatan
                    </div>
                    <div style={{ flex: "85%", display:"flex"  }}>
                      <p className="" style={{ marginRight: "10px",height:"100%", display:"flex", alignItems:"center", paddingBottom:"5px" }}>:</p>
                      <p style={{ fontSize: "11px", color: "black", height: "100%", display: "flex", alignItems: "center" }}>{user?.medical_record.treatment_plan_detail.treatment_data.name}</p>
                    </div>
                  </div>
                  
                </div>
              </div>
              ))} 
            </div>
          </div>
        </form>
        {/*<div className="footer">
          Page {pageNumber} 
        </div>*/}
      </Col>     
    </Row>
  );
}
